<!--
  The input is padded for the mobile tab bar using 'setBottom()'.
  Text is kept in sync across the inputs using 'inputText' and is emitted as 'stickyInputText'.
-->
<!-- <div> -->
  <div id="paddedInput" class="mobile-input">
    <ion-input #paddedInput type="text" placeholder="Message" [(ngModel)]="inputText" (ngModelChange)="setInput($event)">
    </ion-input>
    <span class="buttons">
      <!-- <button class="micButton" (click)="micButtonClicked()">
        <ion-icon name="mic"></ion-icon>
      </button> -->
      <ion-button [disabled]="isApiLoading" class="sendButton" (click)="sendButtonClicked()">Send</ion-button>
    </span>
  </div>

  <!-- 
    The input is duplicated with 'bottom: 0px' to solve for an issue where ng changes run before the keyboard event listeners.
    This causes the input to have a large blank vertical space when the keyboard is shown on mobile. (╯°□°)╯︵ ┻━┻
  --> 

  <div id="input" style="bottom: 0px;">
    <ion-input #input type="text" placeholder="Message" [(ngModel)]="inputText" (ngModelChange)="setInput($event)">
    </ion-input>
    <span class="buttons">
      <!-- <button class="micButton" (click)="micButtonClicked()">
        <ion-icon name="mic"></ion-icon>
      </button> -->
      <ion-button class="sendButton" (click)="sendButtonClicked()" [disabled]="isApiLoading">Send </ion-button>
    </span>
  </div>
<!-- </div> -->