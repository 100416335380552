import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CurrentTripActionTypes, GetCurrentTripActions} from './trip-load-infos.actions';
import * as fromRoot from '../../../../app.state';
import { WernerDwpContractsLogicJobTrip as trip} from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobTrip';
import { WernerDwpContractsLogicJobTrailerReservation  as trailerReservation} from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobTrailerReservation';
import { WernerDwpContractsLogicJobEquipment as equipment } from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobEquipment';



export interface State extends fromRoot.State {
    currentTrip: CurrentTripState;
   
}

export interface CurrentTripState {
    currentTrip: trip;
    trailerReservation: trailerReservation;
    trailers:  Array<equipment>;
    siteSafteyUrl: string;
    trailerReservationLoading: boolean;
    isTrailersLoading: boolean;
    siteSafteyUrlLoading: boolean;
    error: string;
    isLoading: boolean;
}

const initialState: CurrentTripState = {
    currentTrip: null,
    trailerReservation: null,
    trailers : [],
    siteSafteyUrl: '',
    trailerReservationLoading: false,
    isTrailersLoading: false,
    siteSafteyUrlLoading: false,
    error: '',
    isLoading: false
};

const getCurrentTripFeatureState = createFeatureSelector<CurrentTripState>('currentTrip');

export const getCurrentTrip = createSelector(
    getCurrentTripFeatureState ,
    state =>  (state !== null) ? state.currentTrip : null
);

export const isLoading = createSelector(
    getCurrentTripFeatureState ,
    state =>  (state !== null) ? state.isLoading : false
);

export const isTrailerReservationLoading = createSelector(
    getCurrentTripFeatureState ,
    state =>  (state !== null) ? state.trailerReservationLoading : false
);

export const isTrailersLoading = createSelector(
    getCurrentTripFeatureState ,
    state =>  (state !== null) ? state.isTrailersLoading : false
);

export const getTrailerReservation = createSelector(
    getCurrentTripFeatureState ,
    state =>  (state !== null) ? state.trailerReservation : null
);

export const getTrailers = createSelector(
    getCurrentTripFeatureState ,
    state =>  (state !== null) ? state.trailers : null
);

export const getSiteSafetyalertUrl = createSelector(
    getCurrentTripFeatureState ,
    state =>  (state != null) ? state.siteSafteyUrl : null
);


export const getError = createSelector(
    getCurrentTripFeatureState,
    state => state.error
);



export function tripLoadInfosReducer(state= initialState, action: GetCurrentTripActions): CurrentTripState {
    switch (action.type) {
        case CurrentTripActionTypes.GetCurrentTripSuccess:
            return {
                ...state,
                isLoading: false,
                currentTrip: action.payload,
                error: ''
            };
        case CurrentTripActionTypes.GetCurrentTrip:
            return {
                ...state,
                isLoading: true,
                currentTrip: null,
                error: ''
            };
        case CurrentTripActionTypes.GetCurrentTripFail:
            return {
                ...state,
                isLoading: false,
                currentTrip: null,
                error: action.payload
            };
            case CurrentTripActionTypes.GetTrailerReservationSuccess:
                return {
                    ...state,
                    trailerReservation: action.payload,
                    trailerReservationLoading: false,
                    error: ''
                };
                case CurrentTripActionTypes.GetTrailerReservation:
                return {
                    ...state,
                    trailerReservation: null,
                    trailerReservationLoading: true,
                    error: ''
                };
            case CurrentTripActionTypes.GetTrailerReservationFail:
                return {
                    ...state,
                    trailerReservation: null,
                    trailerReservationLoading: false,
                    error: action.payload
                };
                case CurrentTripActionTypes.GetTrailersSuccess:
                return {
                    ...state,
                    trailers: action.payload,
                    isTrailersLoading: false,
                    error: ''
                };
                case CurrentTripActionTypes.GetTrailers:
                return {
                    ...state,
                    trailers: null,
                    isTrailersLoading: true,
                    error: ''
                };
            case CurrentTripActionTypes.GetTrailersFail:
                return {
                    ...state,
                    trailers: null,
                    isTrailersLoading: false,
                    error: action.payload
                };
                case CurrentTripActionTypes.GetSiteSafetyalertUrlSuccess:
                    return {
                        ...state,
                        siteSafteyUrl: action.payload,
                        siteSafteyUrlLoading: false,
                        error: ''
                    };
                    case CurrentTripActionTypes.GetSiteSafetyalertUrl:
                    return {
                        ...state,
                        siteSafteyUrlLoading: true,
                        siteSafteyUrl: null,
                        error: ''
                    };
                case CurrentTripActionTypes.GetSiteSafetyalertUrlFail:
                    return {
                        ...state,
                        siteSafteyUrl: null,
                        siteSafteyUrlLoading: false,
                        error: action.payload
                    };
        default:
            return state;
    }
}
