/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OpportunityAverageMilesByDriverType } from './opportunityAverageMilesByDriverType';
import { OpportunityAveragePayByDriverType } from './opportunityAveragePayByDriverType';
import { OpportunityShift } from './opportunityShift';

export interface Opportunity { 
    isWaitlist?: boolean;
    isNew?: boolean;
    isOpen?: boolean;
    accountCode?: string;
    name?: string;
    description?: string;
    averageMiles?: string;
    home?: string;
    averageStops?: string;
    isVisibleToDriver?: boolean;
    isHotSheet?: boolean;
    pay?: string;
    operation?: string;
    other?: string;
    updatedDate?: Date;
    isRegional?: boolean;
    isDedicated?: boolean;
    isZone?: boolean;
    isTeams?: boolean;
    isOwnerOps?: boolean;
    isStudent?: boolean;
    isTrainer?: boolean;
    billTo?: string;
    division?: string;
    cityState?: string;
    tempInfo?: string;
    groupId?: number;
    groupName?: string;
    shifts?: Array<OpportunityShift>;
    averageMilesByDriverTypes?: Array<OpportunityAverageMilesByDriverType>;
    referral?: string;
    relocation?: string;
    signOnBonus?: string;
    averagePayByDriverTypes?: Array<OpportunityAveragePayByDriverType>;
    isActive?: boolean;
    isExperienced?: boolean;
    isCoDrivers?: boolean;
    divisionType?: number;
    isHotList?: boolean;
    payIncentives?: string;
}