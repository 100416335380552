import { PastPaychecksActions, PastPaychecksActionTypes } from './past-paychecks.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../app.state';
import { PayCheckSummary } from 'src/app/Swagger-Gen-V2/model/payCheckSummary';

export interface State extends fromRoot.State {
    pastPaychecks: PastPaychecksState;
}

export interface PastPaychecksState {
   pastPaychecks: Array<PayCheckSummary>;
    error: string;
    getPaycheckDataIsSuccess: boolean;
}

const initialState: PastPaychecksState = {
    pastPaychecks: null,
    error: '',
    getPaycheckDataIsSuccess: false
};

const getPastPaychecksFeatureState = createFeatureSelector<PastPaychecksState>('pastPaychecks');

export const getDriverPastPaychecks = createSelector(
    getPastPaychecksFeatureState ,
    state => state.pastPaychecks
);

export const isGetDriverPastPaychecksSuccess = createSelector(
    getPastPaychecksFeatureState ,
    state => state.getPaycheckDataIsSuccess
);

export const getError = createSelector(
    getPastPaychecksFeatureState,
    state => state.error
);


export function pastPaychecksReducer(state= initialState, action: PastPaychecksActions): PastPaychecksState {
    switch (action.type) {
        case PastPaychecksActionTypes.GetPastPaychecksSuccess:
            return {
                ...state,
                pastPaychecks: action.payload,
                error: '',
                getPaycheckDataIsSuccess: true
            };

            case PastPaychecksActionTypes.GetPastPaychecks:
                return {
                    ...state,
                    error: '',
                    getPaycheckDataIsSuccess: null
                };

        case PastPaychecksActionTypes.GetPastPaychecksFail:
            return {
                ...state,
                pastPaychecks: null,
                error: action.payload,
                getPaycheckDataIsSuccess: false
            };
        default:
            return state;
    }
}
