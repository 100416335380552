import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";

import { Action } from "@ngrx/store";
import { Actions, Effect,  ofType } from "@ngrx/effects";
import * as LoadSelectionActions from "./load-selection.actions";
import { RequestService } from "../../../../Swagger-Gen-V2/api/request.service";
@Injectable()
export class LoadSelectionEffects {
  loadSelectionService: RequestService = this.injector.get(RequestService);

  constructor(private injector: Injector, private actions$: Actions) {}
  @Effect()
  getLoadSelectionList$: Observable<Action> = this.actions$.pipe(
    ofType(LoadSelectionActions.LoadSelectionActionTypes.GetLoadSelectionList),
    mergeMap((action) => {
      return this.loadSelectionService.loadsGet
          (
          
          (action as LoadSelectionActions.GetLoadSelectionList).stackholderId
        )
        .pipe(
          map(
            (jobList) =>
              new LoadSelectionActions.GetLoadSelectionListSuccess(jobList)
          ),
          catchError((err) => of(new LoadSelectionActions.GetLoadSelectionListFail(err)))
        );
    })
  );
  
  @Effect()
  postSelectedLoad$: Observable<Action> = this.actions$.pipe(
    ofType(LoadSelectionActions.LoadSelectionActionTypes.PostSelectedLoad),
    mergeMap((action) => {
      return this.loadSelectionService.loadsPost
        (
          (action as LoadSelectionActions.PostSelectedLoad).jobSelected
        )
        .pipe(
          map(
            (response) =>
              new LoadSelectionActions.PostSelectedLoadSuccess(response)
          ),
          catchError((err) => of(new LoadSelectionActions.PostSelectedLoadFail(err)))
        );
    })
  );



}
