<ion-app [ngStyle]="edgeConnectStyle()">
    <ion-split-pane contentId="main-content" when="false">
          <ion-menu content-id="main-content" menuId='sideMenu' (ionDidOpen)="getCount()">
            <ion-content>
              <div>
                <app-hamburger-menu [jobsCount]="sideMenu?.noPaperSubmittedJobsCount" [benefitsCount]="sideMenu?.benefitsAnnouncementsUnreadCount" *ngIf="sideMenu?.showHeader || sideMenu?.pageUrl == '/preview-home'" [userImage]="userImage"></app-hamburger-menu>
              </div>                
            </ion-content>
          </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>    
      </ion-split-pane>    
</ion-app>