import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { UserDisplayVM } from 'src/app/Swagger-Gen';
import { CommonPhone, Profile, ProfileEmail, ProfileUser, RequestService } from 'src/app/Swagger-Gen-V2';
import { StorageConstants } from './storage-constants';
@Injectable()
export class ProfileUtility {
    userProfile: UserDisplayVM = {};
    userFullName: string = "";
    phoneNumbers?: Array<CommonPhone>;
    emails?: Array<ProfileEmail>;
    profile: Profile;

    constructor(private requestService: RequestService) { }

    public getUserFullName(profile: UserDisplayVM): string {
        if (profile.firstName && profile.lastName) {
            this.userFullName = profile.firstName + ' ' + profile.lastName;
        }
        if (profile.nickName && profile.firstName && profile.lastName) {
            this.userFullName = profile.firstName + ` '` + profile.nickName + `' ` + profile.lastName;
        }
        return this.userFullName;
    }

    public getUserEmail(profile: Profile): string {
        let userEmail = "";
        if (profile && profile.emails && profile.emails.length > 0) {
            this.emails = profile.emails.filter(address => address.type === 'HOME');
            userEmail = this.emails.length > 0 ? this.emails[0].address : "";
        }
        return userEmail;
    }

    public getUserPhoneNumber(profile: Profile): string {
        let userPhoneNumber = "";
        if (profile && profile.phoneNumbers && profile.phoneNumbers.length > 0) {
            this.phoneNumbers = profile.phoneNumbers.filter(address => address.type === 'HOME');
            userPhoneNumber = (this.phoneNumbers.length > 0) ? (this.phoneNumbers[0].countryCode + this.phoneNumbers[0].areaCode + this.phoneNumbers[0].number) : "";
        }
        return userPhoneNumber;
    }

    public getV2UserFullName(profile: ProfileUser): string {
        let username;
        if (profile.firstName && profile.lastName) {
            username = profile.firstName + ' ' + profile.lastName;
        }
        if (profile.nickname && profile.firstName && profile.lastName) {
            username = profile.firstName + ` '` + profile.nickname + `' ` + profile.lastName;
        }
        return username;
    }

    public getV2UserName(profile: ProfileUser): string {
        let username;
        if (profile.firstName && profile.lastName) {
            username = profile.firstName + ' ' + profile.lastName;
        }
        return username;
    }    

    public getFleetManagerPhoneNumber(profile: Profile): string {
        let phoneNumber = "";
        if (profile && profile.fleetManagers && profile.fleetManagers.length > 0) {
            for (let fleetManager of profile.fleetManagers) {
                if (fleetManager.user && fleetManager.user.role === "Primary Transportation Manager" && fleetManager.phone && fleetManager.phone.number) {
                    phoneNumber = fleetManager.phone.number;
                }
            }
        }
        return phoneNumber;
    }

    async getProfilePictureFromApi(): Promise<string> {
        try {
            const pictureValue = await Preferences.get({ key: StorageConstants.PROFILE_PICTURE_BASE64 });
            
            if (pictureValue?.value) {
                return `data:image/jpeg;base64,${pictureValue.value}`;
            }
    
            const response = await this.requestService.profileImageGet().toPromise();
    
            if (response?.image) {
                const userPictureUrl = `data:image/jpeg;base64,${response.image}`;
                await Preferences.set({
                    key: StorageConstants.PROFILE_PICTURE_BASE64,
                    value: response.image,
                });
                return userPictureUrl;
            }
    
            return "";
        } catch (error) {
            console.error('Failed to get profile picture:', error);
            throw error;  // Rethrowing the error after logging it
        }
    }
    


    async getProfilePicture(profile : Profile) {
        let userPictureUrl = "";
        const pictureValue = await Preferences.get({
            key: StorageConstants.PROFILE_PICTURE_BASE64,
        });
        if (pictureValue && pictureValue.value) {
            userPictureUrl = "data:image/jpeg;base64," + pictureValue.value;
        } else {
            if (profile && profile.user && profile.user.picture) {
                userPictureUrl = "data:image/jpeg;base64," + profile.user.picture;
                await Preferences.set({
                    key: StorageConstants.PROFILE_PICTURE_BASE64,
                    value: profile.user.picture,
                });
            }
        }
        return userPictureUrl;
    }

    getEmployeeNumber (profile : Profile) {
        let employeeNumber = "";
        if(profile && profile.user && profile.user.employeeNumber) {
            employeeNumber = profile.user.employeeNumber;
        }
        return employeeNumber;
    }

    getStackholderId (profile : Profile) {
        let stackholderId = "";
        if(profile?.user?.stakeholderId) {
            stackholderId = profile?.user?.stakeholderId;
        }
        return parseInt(stackholderId);
    }
}
