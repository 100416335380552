import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadStatusSummary } from '../../../Swagger-Gen';

@Component({
  selector: 'job-details',
  templateUrl: './my-load-details.component.html',
  styleUrls: ['./my-load-details.component.scss'],
})
export class MyLoadDetailsComponent implements OnInit {
  @Input() loadStatusSummary: LoadStatusSummary; // Stores summary information on load status
  @Input() origin = {
    city: '',
    state: '',
    isTrailerReservation: false
  };
  @Input() destination = {
    city: 'her',
    state: ''
  };
  @Input()
  jobInformation = {
    id: '',
    truckNumber: '',
    trailerNumber: '',
    departureTime: null,
    fleetManagerName: '',
    type: 'solo'
  };
  locationAddress = '';

  constructor(private router: Router) { }

  ngOnInit() {
  }

  getAddress() {
    let address1 = '';
    let address2 = '';
    if (this.loadStatusSummary && this.loadStatusSummary.location) {
      if (this.loadStatusSummary.location.street1) {
        address1 = this.loadStatusSummary.location.street1;
      }
      if (this.loadStatusSummary.location.street2) {
        if (address1) {
          address1 += ', ';
        }
        address1 += this.loadStatusSummary.location.street2;
      }
      if (this.loadStatusSummary.location.city) {
        address2 = this.loadStatusSummary.location.city;
      }
      if (this.loadStatusSummary.location.state) {
        if (address2) {
          address2 += ', ';
        }
        address2 += this.loadStatusSummary.location.state;
      }
      if (this.loadStatusSummary.location.postalCode) {
        if (address2) {
          address2 += ', ';
        }
        address2 += this.loadStatusSummary.location.postalCode;
      }
    }
    return address1 + (address2 ? (address1 ? '<br />' : '') + address2 : '');
  }

  async showTrailerReservationMap() {
    await this.router.navigate(['/loads/view-trailer-reservation-notice']);
  }
}
