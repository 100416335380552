import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { NavController } from "@ionic/angular";
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: "root",
})
export class QaAuthService  {
  constructor(
    private navCtrl: NavController
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async (resolve) => {
      // For production build , dont allow user to access the pages in testing purposes
      if (environment.production === true) {
          this.navCtrl.navigateRoot("/");
      } else {
        // Allow tester and developer to access these pages , which are under devlopment
            resolve(true);
      }
    });
  }
}
