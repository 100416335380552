import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToasterUtility {
    constructor(private toastController: ToastController) { }
    async showToaster(header, msg, type) {
        const toast = await this.toastController.create({
            header: header,
            message: msg,
            color: type,
            duration: 5000,
            cssClass: 'custom-toast',
        });
        return toast.present();
    }
}
