<ion-card *ngIf="card.type != 'Carousel'">
  <div class="flex-me">
    <div class="edge-value">
      <div class="title">
        {{card.title | titlecase }}
      </div>
      <div class="description">
        {{ card.description }}
      </div>
    </div>
    <div class="werner-button primary" (click)="add(card)">
      Add
    </div>
  </div>      
</ion-card>