<div class="background">
  <div class="modal">
    <ion-row>

      <ion-item class="title-row" lines="full">
        <ion-label class="title-label">{{title}}</ion-label>
        <div style="float:right !important;">
          <img src="../../../../assets/Images/cross.png" (click)="tappedCloseButtonClickHandler()" />
        </div>

      </ion-item>

    </ion-row>
    <ion-row>
      <ion-item  lines="full">
        <div class="subtitle-row">
          <p>
            {{firstContent}}
          </p>
          <p style="font-weight: bold !important;" class="squeezed-paragraph">
            {{secondContent}}
          </p>
          <p class="squeezed-paragraph">
            {{thirdContent}}
          </p>

        </div>

      </ion-item>
    </ion-row>

    <ion-button  (click)="tappedActionButtonClickHandler()">{{buttonText}}</ion-button>
  </div>
</div>
