<ion-card>
  <card-header cardTitle="Message"></card-header>
  <ion-row class="ion-padding-top ion-padding">
      <ion-text class="ion-text-center">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
        standard
        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
        specimen
        book.
      </ion-text>
  </ion-row>
</ion-card>