import { Action } from "@ngrx/store";
import { UserProfile, Awards, UserEditVM } from "src/app/Swagger-Gen";
import { WorkdayDisplayVM } from "src/app/Swagger-Gen/model/workdayDisplayVM";

import { UserDisplayVM } from "../../../Swagger-Gen/model/userDisplayVM";

export enum ProfileActionTypes {
  GetProfileInfo = "Get user profile info",
  GetProfileInfoSuccess = "Get user profile info Success",
  GetProfileInfoFail = "Get user profile info Fail",
  GetWorkDayProfileInfo = "Get profile info from Workday",
  GetWorkDayProfileInfoSuccess = "Get profile info Workday Success",
  GetWorkDayProfileInfoFail = "Get profile info WorkDay Fail",
  GetProfileAwards = "Get user profile awards",
  GetProfileAwardsSuccess = "Get user profile awards Success",
  GetProfileAwardsFail = "Get user profile awards Fail",
  SetIdentityKey = "Set the identity key for user",
  UpdateProfileInfoSuccess = "Update Profile Information",
  UpdateProfile = "Update profile",
}

export class GetProfileInfo implements Action {
  readonly type = ProfileActionTypes.GetProfileInfo;
  IdentityKey = "";
  constructor(public identityKey: string) {
    this.IdentityKey = identityKey;
  }
}

export class GetProfileInfoSuccess implements Action {
  readonly type = ProfileActionTypes.GetProfileInfoSuccess;
  constructor(public payload: UserDisplayVM) {
  }
}

export class GetWorkDayProfileInfo implements Action {
  readonly type = ProfileActionTypes.GetWorkDayProfileInfo;
  IdentityKey = "";
  constructor(public identityKey: string) {
    this.IdentityKey = identityKey;
  }
}

export class GetWorkDayProfileInfoSuccess implements Action {
  readonly type = ProfileActionTypes.GetWorkDayProfileInfoSuccess;
  constructor(public payload: WorkdayDisplayVM) {
    if (this.payload != null) {
    }
  }
}
export class UpdateProfileInfoSuccess implements Action {
  readonly type = ProfileActionTypes.UpdateProfileInfoSuccess;
  constructor(public payload: number) {
    if (this.payload != null) {
    }
  }
}
export class UpdateProfileInfoFail implements Action {
  readonly type = ProfileActionTypes.UpdateProfileInfoSuccess;
  constructor(public payload: number) {
    if (this.payload != null) {
    }
  }
}

export class GetProfileInfoFail implements Action {
  readonly type = ProfileActionTypes.GetProfileInfoFail;
  constructor(public payload: string) { }
}

export class GetWorkDayProfileInfoFail implements Action {
  readonly type = ProfileActionTypes.GetWorkDayProfileInfoFail;
  constructor(public payload: string) { }
}

export class GetProfileAwards implements Action {
  readonly type = ProfileActionTypes.GetProfileAwards;
  IdentityKey = "";
  constructor(public identityKey: string) {
    this.IdentityKey = identityKey;
  }
}

export class GetProfileAwardsSuccess implements Action {
  readonly type = ProfileActionTypes.GetProfileAwardsSuccess;
  constructor(public payload: Awards) { }
}

export class GetProfileAwardsFail implements Action {
  readonly type = ProfileActionTypes.GetProfileAwardsFail;
  constructor(public payload: string) { }
}
export class UpdateProfile implements Action {
  readonly type = ProfileActionTypes.UpdateProfile;
  updateProfile: UserEditVM;
  constructor(public userEditInput: UserEditVM) {
    this.updateProfile = userEditInput;
  }
}

export class SetIdentityKey implements Action {
  readonly type = ProfileActionTypes.SetIdentityKey;
  constructor(public identityKey: string) {
  }
}

export type GetProfileActions =
  | GetProfileInfo
  | GetProfileInfoSuccess
  | GetProfileInfoFail
  | GetWorkDayProfileInfo
  | GetWorkDayProfileInfoSuccess
  | GetWorkDayProfileInfoFail
  | GetProfileAwardsFail
  | GetProfileAwardsSuccess
  | GetProfileAwards
  | UpdateProfile
  | SetIdentityKey;
