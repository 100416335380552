/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Attachment } from './attachment';

export interface Message { 
    id?: string;
    senderName?: string;
    content?: string;
    wasRead?: boolean;
    creatorName?: string;
    createdDate?: Date;
    action?: string;
    attachments?: Array<Attachment>;
}