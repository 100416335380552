/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PhoneType = 'Unknown' | 'ShortDial' | 'LocalNumber' | 'BusinessPager' | 'HomeNumber' | 'FaxNumber' | 'BusinessMobile' | 'ACDLine' | 'PersonalPager' | 'PersonalMobile' | 'DirectLine' | 'OfficeDeskNumber' | 'EmergencyContact' | 'Number800';

export const PhoneType = {
    Unknown: 'Unknown' as PhoneType,
    ShortDial: 'ShortDial' as PhoneType,
    LocalNumber: 'LocalNumber' as PhoneType,
    BusinessPager: 'BusinessPager' as PhoneType,
    HomeNumber: 'HomeNumber' as PhoneType,
    FaxNumber: 'FaxNumber' as PhoneType,
    BusinessMobile: 'BusinessMobile' as PhoneType,
    ACDLine: 'ACDLine' as PhoneType,
    PersonalPager: 'PersonalPager' as PhoneType,
    PersonalMobile: 'PersonalMobile' as PhoneType,
    DirectLine: 'DirectLine' as PhoneType,
    OfficeDeskNumber: 'OfficeDeskNumber' as PhoneType,
    EmergencyContact: 'EmergencyContact' as PhoneType,
    Number800: 'Number800' as PhoneType
};