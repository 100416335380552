/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Coordinates } from "./coordinates";

export interface Address {
    street1?: string;
    street2?: string;
    postalCode?: string;
    streetAddress1?: string;
    streetAddress2?: string;
    city?: string;
    state?: string;
    zip?: string;
    coordinates? : Coordinates;
    longitude?: number;
    latitude?: number;
    country?: string;
    addressTypeId?: number;
    addressTypeDescription?: string;
    id?: string;
    lastUpdatedDateTime?: string;
}
