
<ion-card class="edge-card vertical-card">
  <card-overlay 
    [card]="card"
    (didTapCard)="tapCard()"
    (didTapResize)="resizeCard()"
    (didTapChange)="changeCard()"
    (didTapRemove)="removeCard()"
  ></card-overlay>
  <div class="img-div">
    <img src="../../../../../assets/Images/Message-Icon.svg" alt="Message">
  </div>
</ion-card>