<div [ngSwitch]="card.type">
  <preassigned-job-card *ngSwitchCase="'Preassigned Job'"
    class="pointer"
    [card]="card" 
    (resize)="resize(card)" 
    (change)="change(card)" 
    (remove)="remove(card)">
  </preassigned-job-card>
  <previous-job-card *ngSwitchCase="'Previous Job'"
    class="pointer"
    [card]="card" 
    (resize)="resize(card)" 
    (change)="change(card)" 
    (remove)="remove(card)">
  </previous-job-card>

  <news-card *ngSwitchCase="'News'"
    class="pointer"
    [card]="card" 
    (resize)="resize(card)" 
    (change)="change(card)" 
    (remove)="remove(card)">
  </news-card>  
  <messages-card *ngSwitchCase="'Message'"
    class="pointer"
    [card]="card" 
    (resize)="resize(card)" 
    (change)="change(card)" 
    (remove)="remove(card)">
  </messages-card>    
    <load-status-card *ngSwitchCase="'Load Status'"
    class="pointer"
    [card]="card" 
    (resize)="resize(card)" 
    (change)="change(card)" 
    (remove)="remove(card)">
  </load-status-card>
  <pay-card *ngSwitchCase="'Pay'"
    class="pointer" 
    [card]="card" 
    (resize)="resize(card)" 
    (change)="change(card)" 
    (remove)="remove(card)">
  </pay-card>

  <blue-coin-card *ngSwitchCase="'BlueCoin'" 
    class="pointer" 
    [card]="card" 
    (resize)="resize(card)" 
    (change)="change(card)"
    (remove)="remove(card)">
  </blue-coin-card>

<opportunities-card  *ngSwitchCase="'Opportunities'"
  class="pointer"
  [card]="card" 
  (resize)="resize(card)" 
  (change)="change(card)" 
  (remove)="remove(card)">
</opportunities-card>
<workday-card *ngSwitchCase="'Workday'"
  class="pointer"
  [card]="card" 
  (resize)="resize(card)" 
  (change)="change(card)" 
  (remove)="remove(card)">
</workday-card>
  <div *ngSwitchDefault> </div>
</div>