import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NewsService } from '../../../Swagger-Gen';
import * as NewsActions from './news.actions';

@Injectable()
export class GetNewsEffects {
    news: NewsService = this.injector.get(NewsService);
    constructor(private injector: Injector, private actions$: Actions) {
    }

    @Effect()
    newsItems$: Observable<Action> = this.actions$.pipe(
        ofType(NewsActions.NewsActionTypes.GetAllNews),
        mergeMap(action =>
            this.news.apiNewsGet().pipe(
                map(allNews => (new NewsActions.GetAllNewsSuccess(allNews))),
                catchError(err => of(new NewsActions.GetAllNewsFail(err)))
            )
        )
    );
}
