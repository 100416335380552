/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { TripPakCustomer } from './tripPakCustomer';
import { TripPakExpense } from './tripPakExpense';
import { TripPakStop } from './tripPakStop';
import { TripPakTripType } from './tripPakTripType';

export interface TripPakEnvelopeRequest { 
    identityKey?: string;
    driverName?: string;
    fleetManager?: string;
    tractorNumber?: number;
    trailerNumber?: string;
    tripId?: string;
    loadDate?: Date;
    tripType?: TripPakTripType;
    isSplitTrip?: boolean;
    shipper?: TripPakCustomer;
    receiver?: TripPakCustomer;
    extraPickupDrops?: Array<TripPakStop>;
    deadhead?: Array<TripPakStop>;
    splitOrigin?: Address;
    splitDestination?: Address;
    tripExpense?: Array<TripPakExpense>;
    isCashFuel?: boolean;
}