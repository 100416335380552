/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Awards } from './awards';
import { EmailAddressVm } from './emailAddressVm';
import { EmergencyContact } from './emergencyContact';
import { Employee } from './employee';
import { PhoneNumber } from './phoneNumber';
import { Preferences } from './preferences';
import { UserType } from './userType';

export interface UserProfile { 
    identityId?: string;
    employeeNumber?: number;
    firstName?: string;
    lastName?: string;
    username?: string;
    emailAddresses?: Array<EmailAddressVm>;
    phones?: Array<PhoneNumber>;
    birthDate?: Date;
    birthDay?: Date;
    userType?: UserType;
    title?: string;
    hireDate?: Date;
    fleetManager?: Employee;
    fleetManagers?: Array<Employee>;
    trainees?: Array<Employee>;
    trainers?: Array<Employee>;
    emergencyContacts?: Array<EmergencyContact>;
    addresses?: Array<Address>;
    award?: Awards;
    awards?: Awards;
    profilePictureUrl?: string;
    driverTypes?: Array<string>;
    pictureBase64?: string;
    permissions?: Array<string>;
    preferences?: Preferences;
}