/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DriversEdgeModelsApiPaymentsPayStubSource } from '../model/driversEdgeModelsApiPaymentsPayStubSource';
import { DriversEdgeModelsPaymentsPayCheck } from '../model/driversEdgeModelsPaymentsPayCheck';
import { DriversEdgeModelsPaymentsPayCheckSummary } from '../model/driversEdgeModelsPaymentsPayCheckSummary';
import { PayIncreaseResponse } from '../model/PayIncreaseResponse';
import { PayIncreaseVideoResponse } from '../model/PayIncreaseVideoResponse';
import { DriversEdgeModelsPaymentsPayVideoResponse } from '../model/driversEdgeModelsPaymentsPayVideoResponse';
import { PaymentSummary } from '../model/paymentSummary';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PaymentsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get payment video for driver
     * 
     * @param name 
     * @param currentDateTime 
     * @param driverIdentityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsGetpaymentvideoDriverIdentityKeyNameCurrentDateTimeGet(name: string, currentDateTime: string, driverIdentityKey: string, observe?: 'body', reportProgress?: boolean): Observable<DriversEdgeModelsPaymentsPayVideoResponse>;
    public apiPaymentsGetpaymentvideoDriverIdentityKeyNameCurrentDateTimeGet(name: string, currentDateTime: string, driverIdentityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DriversEdgeModelsPaymentsPayVideoResponse>>;
    public apiPaymentsGetpaymentvideoDriverIdentityKeyNameCurrentDateTimeGet(name: string, currentDateTime: string, driverIdentityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DriversEdgeModelsPaymentsPayVideoResponse>>;
    public apiPaymentsGetpaymentvideoDriverIdentityKeyNameCurrentDateTimeGet(name: string, currentDateTime: string, driverIdentityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling apiPaymentsGetpaymentvideoDriverIdentityKeyNameCurrentDateTimeGet.');
        }

        if (currentDateTime === null || currentDateTime === undefined) {
            throw new Error('Required parameter currentDateTime was null or undefined when calling apiPaymentsGetpaymentvideoDriverIdentityKeyNameCurrentDateTimeGet.');
        }

        if (driverIdentityKey === null || driverIdentityKey === undefined) {
            throw new Error('Required parameter driverIdentityKey was null or undefined when calling apiPaymentsGetpaymentvideoDriverIdentityKeyNameCurrentDateTimeGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DriversEdgeModelsPaymentsPayVideoResponse>('get',`${this.basePath}/api/payments/getpaymentvideo/${encodeURIComponent(String(driverIdentityKey))}/${encodeURIComponent(String(name))}/${encodeURIComponent(String(currentDateTime))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param firstName 
     * @param driverId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPayIncreaseGet(firstName?: string, driverId?: string, observe?: 'body', reportProgress?: boolean): Observable<PayIncreaseResponse>;
    public apiPaymentsPayIncreaseGet(firstName?: string, driverId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayIncreaseResponse>>;
    public apiPaymentsPayIncreaseGet(firstName?: string, driverId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayIncreaseResponse>>;
    public apiPaymentsPayIncreaseGet(firstName?: string, driverId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (firstName !== undefined && firstName !== null) {
            queryParameters = queryParameters.set('firstName', <any>firstName);
        }
        if (driverId !== undefined && driverId !== null) {
            queryParameters = queryParameters.set('driverId', <any>driverId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PayIncreaseResponse>('get',`${this.basePath}/api/payments/PayIncrease`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param name 
     * @param currentRateOfPay 
     * @param increasedRateOfPay 
     * @param dateOfIncrease 
     * @param seniorityDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPayIncreaseVideoPost(name?: string, currentRateOfPay?: string, increasedRateOfPay?: string, dateOfIncrease?: string, seniorityDate?: string, observe?: 'body', reportProgress?: boolean): Observable<PayIncreaseVideoResponse>;
    public apiPaymentsPayIncreaseVideoPost(name?: string, currentRateOfPay?: string, increasedRateOfPay?: string, dateOfIncrease?: string, seniorityDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayIncreaseVideoResponse>>;
    public apiPaymentsPayIncreaseVideoPost(name?: string, currentRateOfPay?: string, increasedRateOfPay?: string, dateOfIncrease?: string, seniorityDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayIncreaseVideoResponse>>;
    public apiPaymentsPayIncreaseVideoPost(name?: string, currentRateOfPay?: string, increasedRateOfPay?: string, dateOfIncrease?: string, seniorityDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (currentRateOfPay !== undefined && currentRateOfPay !== null) {
            queryParameters = queryParameters.set('currentRateOfPay', <any>currentRateOfPay);
        }
        if (increasedRateOfPay !== undefined && increasedRateOfPay !== null) {
            queryParameters = queryParameters.set('increasedRateOfPay', <any>increasedRateOfPay);
        }
        if (dateOfIncrease !== undefined && dateOfIncrease !== null) {
            queryParameters = queryParameters.set('dateOfIncrease', <any>dateOfIncrease);
        }
        if (seniorityDate !== undefined && seniorityDate !== null) {
            queryParameters = queryParameters.set('seniorityDate', <any>seniorityDate);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PayIncreaseVideoResponse>('post',`${this.basePath}/api/payments/PayIncreaseVideo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

   /**
     * 
     * 
     * @param identityKey 
     * @param paySource 
     * @param payStubId 
     * @param runNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPayStubDetailGet(identityKey?: string, paySource?: DriversEdgeModelsApiPaymentsPayStubSource, payStubId?: string, runNumber?: number, paymentDate?: string, observe?: 'body', reportProgress?: boolean): Observable<DriversEdgeModelsPaymentsPayCheck>;
    public apiPaymentsPayStubDetailGet(identityKey?: string, paySource?: DriversEdgeModelsApiPaymentsPayStubSource, payStubId?: string, runNumber?: number, paymentDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DriversEdgeModelsPaymentsPayCheck>>;
    public apiPaymentsPayStubDetailGet(identityKey?: string, paySource?: DriversEdgeModelsApiPaymentsPayStubSource, payStubId?: string, runNumber?: number, paymentDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DriversEdgeModelsPaymentsPayCheck>>;
    public apiPaymentsPayStubDetailGet(identityKey?: string, paySource?: DriversEdgeModelsApiPaymentsPayStubSource, payStubId?: string, runNumber?: number, paymentDate?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (identityKey !== undefined && identityKey !== null) {
            queryParameters = queryParameters.set('identityKey', <any>identityKey);
        }
        if (paySource !== undefined && paySource !== null) {
            queryParameters = queryParameters.set('paySource', <any>paySource);
        }
        if (payStubId !== undefined && payStubId !== null) {
            queryParameters = queryParameters.set('payStubId', <any>payStubId);
        }
        if (runNumber !== undefined && runNumber !== null) {
            queryParameters = queryParameters.set('runNumber', <any>runNumber);
        }
        if (paymentDate !== undefined && paymentDate !== null) {
            queryParameters = queryParameters.set('paymentDate', <any>paymentDate);
        }
        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DriversEdgeModelsPaymentsPayCheck>('get', `${this.basePath}/api/payments/PayStubDetail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPayStubsMetadataGet(identityKey?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DriversEdgeModelsPaymentsPayCheckSummary>>;
    public apiPaymentsPayStubsMetadataGet(identityKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DriversEdgeModelsPaymentsPayCheckSummary>>>;
    public apiPaymentsPayStubsMetadataGet(identityKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DriversEdgeModelsPaymentsPayCheckSummary>>>;
    public apiPaymentsPayStubsMetadataGet(identityKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (identityKey !== undefined && identityKey !== null) {
            queryParameters = queryParameters.set('identityKey', <any>identityKey);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DriversEdgeModelsPaymentsPayCheckSummary>>('get',`${this.basePath}/api/payments/PayStubsMetadata`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves data on the details of a given pay check
     * 
     * @param driverIdentityKey 
     * @param checkId 
     * @param runNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     
    public apiPaymentsPaycheckDetailsDriverIdentityKeyCheckIdRunNumberGet(driverIdentityKey: string, checkId: number, runNumber: number, paymentDate: string, observe?: 'body', reportProgress?: boolean): Observable<DriversEdgeModelsPaymentsPayCheck>;
    public apiPaymentsPaycheckDetailsDriverIdentityKeyCheckIdRunNumberGet(driverIdentityKey: string, checkId: number, runNumber: number, paymentDate: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DriversEdgeModelsPaymentsPayCheck>>;
    public apiPaymentsPaycheckDetailsDriverIdentityKeyCheckIdRunNumberGet(driverIdentityKey: string, checkId: number, runNumber: number, paymentDate: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DriversEdgeModelsPaymentsPayCheck>>;
    public apiPaymentsPaycheckDetailsDriverIdentityKeyCheckIdRunNumberGet(driverIdentityKey: string, checkId: number, runNumber: number, paymentDate: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (driverIdentityKey === null || driverIdentityKey === undefined) {
            throw new Error('Required parameter driverIdentityKey was null or undefined when calling apiPaymentsPaycheckDetailsDriverIdentityKeyCheckIdRunNumberGet.');
        }

        if (checkId === null || checkId === undefined) {
            throw new Error('Required parameter checkId was null or undefined when calling apiPaymentsPaycheckDetailsDriverIdentityKeyCheckIdRunNumberGet.');
        }

        if (runNumber === null || runNumber === undefined) {
            throw new Error('Required parameter runNumber was null or undefined when calling apiPaymentsPaycheckDetailsDriverIdentityKeyCheckIdRunNumberGet.');
        }

        if (runNumber === null || runNumber === undefined) {
            throw new Error('Required parameter runNumber was null or undefined when calling apiPaymentsPaycheckDetailsDriverIdentityKeyCheckIdRunNumberGet.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DriversEdgeModelsPaymentsPayCheck>('get',`${this.basePath}/api/payments/paycheck/details/${encodeURIComponent(String(driverIdentityKey))}/${encodeURIComponent(String(checkId))}/${encodeURIComponent(String(runNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
*/

    /**
     * Retrieves data on a paycheck with a given id
     * 
     * @param driverIdentityKey 
     * @param checkId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPaycheckDriverIdentityKeyCheckIdGet(driverIdentityKey: string, checkId: number, observe?: 'body', reportProgress?: boolean): Observable<DriversEdgeModelsPaymentsPayCheck>;
    public apiPaymentsPaycheckDriverIdentityKeyCheckIdGet(driverIdentityKey: string, checkId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DriversEdgeModelsPaymentsPayCheck>>;
    public apiPaymentsPaycheckDriverIdentityKeyCheckIdGet(driverIdentityKey: string, checkId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DriversEdgeModelsPaymentsPayCheck>>;
    public apiPaymentsPaycheckDriverIdentityKeyCheckIdGet(driverIdentityKey: string, checkId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (driverIdentityKey === null || driverIdentityKey === undefined) {
            throw new Error('Required parameter driverIdentityKey was null or undefined when calling apiPaymentsPaycheckDriverIdentityKeyCheckIdGet.');
        }

        if (checkId === null || checkId === undefined) {
            throw new Error('Required parameter checkId was null or undefined when calling apiPaymentsPaycheckDriverIdentityKeyCheckIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DriversEdgeModelsPaymentsPayCheck>('get',`${this.basePath}/api/payments/paycheck/${encodeURIComponent(String(driverIdentityKey))}/${encodeURIComponent(String(checkId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves data on the most recent n pay checks that the driver received
     * 
     * @param driverIdentityKey 
     * @param checksCount 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPaychecksLastDriverIdentityKeyChecksCountGet(driverIdentityKey: string, checksCount: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DriversEdgeModelsPaymentsPayCheckSummary>>;
    public apiPaymentsPaychecksLastDriverIdentityKeyChecksCountGet(driverIdentityKey: string, checksCount: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DriversEdgeModelsPaymentsPayCheckSummary>>>;
    public apiPaymentsPaychecksLastDriverIdentityKeyChecksCountGet(driverIdentityKey: string, checksCount: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DriversEdgeModelsPaymentsPayCheckSummary>>>;
    public apiPaymentsPaychecksLastDriverIdentityKeyChecksCountGet(driverIdentityKey: string, checksCount: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (driverIdentityKey === null || driverIdentityKey === undefined) {
            throw new Error('Required parameter driverIdentityKey was null or undefined when calling apiPaymentsPaychecksLastDriverIdentityKeyChecksCountGet.');
        }

        if (checksCount === null || checksCount === undefined) {
            throw new Error('Required parameter checksCount was null or undefined when calling apiPaymentsPaychecksLastDriverIdentityKeyChecksCountGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DriversEdgeModelsPaymentsPayCheckSummary>>('get',`${this.basePath}/api/payments/paychecks/last/${encodeURIComponent(String(driverIdentityKey))}/${encodeURIComponent(String(checksCount))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves data on the last three pay checks that the driver received
     * 
     * @param driverIdentityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPaychecksLasthreeDriverIdentityKeyGet(driverIdentityKey: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DriversEdgeModelsPaymentsPayCheckSummary>>;
    public apiPaymentsPaychecksLasthreeDriverIdentityKeyGet(driverIdentityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DriversEdgeModelsPaymentsPayCheckSummary>>>;
    public apiPaymentsPaychecksLasthreeDriverIdentityKeyGet(driverIdentityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DriversEdgeModelsPaymentsPayCheckSummary>>>;
    public apiPaymentsPaychecksLasthreeDriverIdentityKeyGet(driverIdentityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (driverIdentityKey === null || driverIdentityKey === undefined) {
            throw new Error('Required parameter driverIdentityKey was null or undefined when calling apiPaymentsPaychecksLasthreeDriverIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DriversEdgeModelsPaymentsPayCheckSummary>>('get',`${this.basePath}/api/payments/paychecks/lasthree/${encodeURIComponent(String(driverIdentityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves driver payment summary information of a given driver
     * 
     * @param driverIdentityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsSummaryDriverIdentityKeyGet(driverIdentityKey: string, observe?: 'body', reportProgress?: boolean): Observable<PaymentSummary>;
    public apiPaymentsSummaryDriverIdentityKeyGet(driverIdentityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentSummary>>;
    public apiPaymentsSummaryDriverIdentityKeyGet(driverIdentityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentSummary>>;
    public apiPaymentsSummaryDriverIdentityKeyGet(driverIdentityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (driverIdentityKey === null || driverIdentityKey === undefined) {
            throw new Error('Required parameter driverIdentityKey was null or undefined when calling apiPaymentsSummaryDriverIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaymentSummary>('get',`${this.basePath}/api/payments/summary/${encodeURIComponent(String(driverIdentityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
