import { Component, OnDestroy, OnInit } from "@angular/core";

import { ToastController } from "@ionic/angular";
import { SubSink } from "subsink";
import { LoadingUtility } from "../../utilities/LoadingUtility";
import { AuthService } from "src/app/auth/auth.service";
import { Profile, RequestService } from "src/app/Swagger-Gen-V2";
import { DrivingOptionsQuestionnaire } from "src/app/Swagger-Gen-V2/model/drivingOptionsQuestionnaire";
import { DrivingOptionsSaveDrivingOptionCriteria } from "src/app/Swagger-Gen-V2/model/drivingOptionsSaveDrivingOptionCriteria";
import { ProfileInfoService } from "src/app/modules/profile-v2/service/profileInfo.service";
@Component({
  selector: "app-driving-options",
  templateUrl: "./driving-options.component.html",
  styleUrls: ["./driving-options.component.scss"],
})
export class DrivingOptionsComponent implements OnInit, OnDestroy {

  showGoBackButton: boolean = true;
  driverId: string;
  jsonFormData: DrivingOptionsQuestionnaire;
  subscriptions$ = new SubSink();
  userProfile: Profile;
  constructor(
    private toastController: ToastController,
    private loadingUtility: LoadingUtility,
    private authService : AuthService,
    private requestService: RequestService,
    private profileInfoService: ProfileInfoService
  ) { }
  
  getIntialQuestion(status : string) {
    this.subscriptions$.sink = this.requestService.profileDrivingOptionsGet(this.driverId).subscribe((response) => {
      this.loadingUtility.hideLoader();
      if(status === "fromSecondPage"){
        this.presentToast('Your answers were successfully updated! 🎉', 'primary');
      }
      this.jsonFormData = response;
    },
      (error) => {
        this.presentToast('An error occurred. Please try after some time', 'danger');
        this.loadingUtility.hideLoader();
    });
  }

  getNextQuestion() {
    this.subscriptions$.sink = this.requestService.profileDrivingOptionsGet(this.driverId, this.jsonFormData.questionGroup.questionGroupID).subscribe((response) => {
      this.loadingUtility.hideLoader();
      this.presentToast('Your answers were successfully updated! 🎉', 'primary');
      this.jsonFormData = response;
    },
      (error) => {
        this.presentToast('An error occurred. Please try after some time', 'danger');
        this.loadingUtility.hideLoader();
      });
  }

  async ngOnInit() {
    this.driverId = await this.authService.getUserIdentitityKey();
    this.getIntialQuestion(null);
  }
  async onSubmitOptions() {
    this.userProfile = await this.profileInfoService.getUserProfileInfo();
    let displayOrder = this.jsonFormData.questionGroup.displayOrder;
    const payload: DrivingOptionsSaveDrivingOptionCriteria = {
      identityKey: this.driverId,
      stakeholderId: this.userProfile.user.stakeholderId,
      questionnaire: this.jsonFormData
    };    
    if (displayOrder === 1) {
      this.loadingUtility.showLoader('Please wait..');
      this.subscriptions$.sink = this.requestService.profileDrivingOptionsPost(payload).subscribe((response) => {
        if (this.jsonFormData.questionGroup.displayOrder === 1) {
          this.getNextQuestion();
        } else {
          this.getIntialQuestion("fromSecondPage");
        }
      },
        (error) => {
          this.presentToast('An error occurred. Please try after some time', 'danger');
          this.loadingUtility.hideLoader();
      }
      );
    } else {
      this.loadingUtility.showLoader('Please wait..');
      this.subscriptions$.sink = this.requestService.profileDrivingOptionsPost(payload).subscribe((response) => {
        this.loadingUtility.hideLoader();
        this.presentToast('Your answers were successfully updated! 🎉', 'primary');
      },
        (error) => {
          this.presentToast('An error occurred. Please try after some time', 'danger');
          this.loadingUtility.hideLoader();
      });
    }
  }

  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message, 
      color: color, 
      duration: 2000,
      cssClass: "ion-text-center",
    });
    toast.present();
  }

  goBack() {
    this.loadingUtility.showLoader('Please wait..');
    this.getIntialQuestion(null);
  }

  async ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }  
}
