<ion-list class="ion-safe-area-top">
  <div class="menu-header flex-me">

    <ion-avatar *ngIf="userImage">
      <img alt="driver profile" [src]="userImage" />
    </ion-avatar>

    <ion-thumbnail slot="start" *ngIf="!userImage">
      <ion-skeleton-text [animated]="true"></ion-skeleton-text>
    </ion-thumbnail>
    
  
    <div class="flex-me col menu-header-user-info">
      <p style="width: 100%;" *ngIf="!userFullName"> <ion-skeleton-text [animated]="true" style="width: 80%;" ></ion-skeleton-text> </p>
      <ion-label class="edge-value user-name-label" data-test-id="hamburger-user-fullname" *ngIf="userFullName">{{ userFullName }}</ion-label>
      <ion-label *ngIf="!viewProfileDisable" class="user-view-profile-label pointer"
        (click)="routeToScreen('/driver-profile?tab=myInformation')">View Profile</ion-label>
      <ion-label *ngIf="viewProfileDisable" class="user-view-profile-workday-down-label"><strong>Workday is currently
          unavailable.</strong> <br> View Profile - Not able to view at this time. </ion-label>
    </div>
  </div>

  <div *ngFor="let currentMenuItem of menuItems">
    <ion-menu-toggle>
      <ion-item routerLink="/{{currentMenuItem.url}}" routerLinkActive="active" routerDirection="root" detail="false"  class="pointer"  lines="full" [ngClass]="currentMenuItem.cls"
        (click)="menuIetmClicked(currentMenuItem.url, currentMenuItem.label)" mode='md' attr.data-test-id="hamburger-{{currentMenuItem.cls}}">
        <ion-grid [ngClass]="{'disabled': ((currentMenuItem.label.toLowerCase() === 'pay' && isWorkdayDown &&  workdayPachecks)
        || (currentMenuItem.label.toLowerCase() === 'workday learning' && isWorkdayDown)), 
        'two-lines': (isWorkdayDown && currentMenuItem.label.toLowerCase() === 'workday learning')}">
          <ion-row>
            <ion-col class="icon-col">
              <ion-icon class="icon-color" name="{{ currentMenuItem.icon }}" *ngIf="
                  currentMenuItem.iconType != null &&
                  currentMenuItem.iconType.toLowerCase() === 'ionic' &&
                  currentMenuItem.label.toLowerCase() !== 'dark theme'
                "></ion-icon>
              <img class="icon-color" *ngIf="
              currentMenuItem.iconType !== null &&
              currentMenuItem.iconType.toLowerCase() === 'image' &&
              currentMenuItem.label.toLowerCase() !== 'dark theme'
            " src="{{ currentMenuItem.icon }}" />
              <fa-icon [icon]="['fal', currentMenuItem.icon]" *ngIf="
                  currentMenuItem.iconType !== null &&
                  currentMenuItem.iconType.toLowerCase() === 'fontawesome' &&
                  currentMenuItem.label.toLowerCase() !== 'dark theme'
                " class="ionic-icon icon-color"></fa-icon>
            </ion-col>
            <ion-col [class]="(currentMenuItem.label.toLowerCase() === 'pay' && isWorkdayDown &&  workdayPachecks)
            || (currentMenuItem.label.toLowerCase() === 'workday learning' && isWorkdayDown) ? 'workday-unavailable-width md hydrated' : 'md hydrated'">
              <ion-label (click)="showOpportunitiesScreen();" class="edge-value list-item-label"
                *ngIf="currentMenuItem.label.toLowerCase() === 'career opportunities'">
                {{ currentMenuItem.label }}
              </ion-label>
              <ion-label (click)="showSafetyScreen()" class="edge-value list-item-label"
                *ngIf="currentMenuItem.label.toLowerCase() === 'safety'">
                {{ currentMenuItem.label }}
              </ion-label>
              <ion-label (click)="showContactsScreen()" class="edge-value list-item-label"
                *ngIf="currentMenuItem.label.toLowerCase() === CONTACTS_PAGE_KEY">
                {{ currentMenuItem.label }}
              </ion-label>

              <ion-row *ngIf="currentMenuItem.label.toLowerCase() === BENEFITS_PAGE_KEY">
                <ion-col size="12" class="ion-no-padding" *ngIf="benefitsCount === 0">
                  <ion-label (click)="showBenefitsScreen()" class="edge-value list-item-label">
                    {{ currentMenuItem.label }}
                  </ion-label>
                </ion-col>
                <ion-col size="10" class="ion-no-padding" *ngIf="benefitsCount > 0">
                  <ion-label (click)="showBenefitsScreen()" class="edge-value list-item-label">
                    {{ currentMenuItem.label }}
                  </ion-label>
                </ion-col>
                <ion-col size="2" class="ion-no-padding"  *ngIf="benefitsCount > 0">
                  <ion-badge class="jobs-badge ion-float-right">
                    {{benefitsCount}}
                  </ion-badge>
                </ion-col>
              </ion-row>

              <ion-label class="edge-value list-item-label" *ngIf="currentMenuItem.label.toLowerCase() !== 'dark theme' &&
                currentMenuItem.label.toLowerCase() !== 'career opportunities' && currentMenuItem.label.toLowerCase() !== 'safety' && currentMenuItem.label.toLowerCase() !== CONTACTS_PAGE_KEY && currentMenuItem.label.toLowerCase() !== BENEFITS_PAGE_KEY">
                {{ currentMenuItem.label }}

                <ion-badge *ngIf="currentMenuItem.label.toLowerCase() === 'messages' && unreadMessagesCount > 0"
                  class="messages-badge">
                  {{unreadMessagesCount}}
                </ion-badge>
                <ion-badge *ngIf="currentMenuItem.label.toLowerCase() === 'jobs' && jobsCount > 0"
                  class="jobs-badge ion-float-right">
                  {{jobsCount}}
                </ion-badge>
              </ion-label>
            </ion-col>
            <ion-col class="workday-unavailable"
              *ngIf="(currentMenuItem.label.toLowerCase() === 'pay' && isWorkdayDown &&  workdayPachecks)
                      || (currentMenuItem.label.toLowerCase() === 'workday learning' && isWorkdayDown)">

              <ion-label>Not able to view at this time.</ion-label>

            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-menu-toggle>
  </div>

  <ion-item 
  lines="full" 
  class="admin-item pointer" 
  (click)="subMenuItemHandler()"
  *ngIf="isProduction == false"
  >
    <ion-grid>
      <ion-row>
        <ion-col class="icon-col">
          <fa-icon [icon]="['fal', 'user-cog']" class="ionic-icon icon-color"></fa-icon>
        </ion-col>
        <ion-col>
          <ion-label class="edge-value list-item-label sub-menu-label">
            Administration
          </ion-label>
          <ion-icon class="sub-menu-icon" [name]="showSubmenu ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
  
  <ion-menu-toggle>
      <div *ngIf="!showSubmenu">
      <ion-item 
      routerLink="/account" routerLinkActive="active" routerDirection="root" detail="false" 
      lines="full"
       class="messages-item pointer" 
       *ngFor = "let subMenuItem of adminSubMenuItems"       
       (click)="menuIetmClicked(subMenuItem.url,subMenuItem.label)">
        <ion-grid>
          <ion-row>
            <ion-col class="sub-menu icon-col">
              <fa-icon
                  [icon]="['fal', subMenuItem.icon]"
                  class="ionic-icon icon-color"
                ></fa-icon>
            </ion-col>
            <ion-col>
              <ion-label class="edge-value list-item-label pointer">
               {{subMenuItem.label}}
              </ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </div>
  </ion-menu-toggle>

  <ion-menu-toggle>
    <ion-item routerLink="/" routerLinkActive="active" routerDirection="root" detail="false"  lines="full" class="logout-item" (click)="logOut()">
      <ion-grid>
        <ion-row>
          <ion-col class="icon-col">
            <ion-icon name="log-out-outline" class="icon-color"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-label class="edge-value list-item-label pointer">
              Sign out
            </ion-label>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
</ion-menu-toggle>
</ion-list>