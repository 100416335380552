/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BusTicket } from './busTicket';
import { MotelVoucher } from './motelVoucher';
import { OrientationTerminal } from './orientationTerminal';

export interface OrientationDetails { 
    caseId?: number;
    identityKey?: string;
    orientationDate?: Date;
    terminal?: OrientationTerminal;
    motelVoucher?: MotelVoucher;
    busTicket?: BusTicket;
    attendanceStatus?: number;
    externalReferenceId?: number;
    smokingPreference?: number;
    isDriverConfirmed?: boolean;
    confirmedBy?: string;
    confirmationDate?: Date;
}