/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Inspection } from './inspection';

export interface InspectionsListViewItem { 
    points?: number;
    date?: Date;
    dateText?: string;
    inspectionId?: string;
    comments?: string;
    inspectionDetails?: Inspection;
}