<ion-toolbar class="ion-safe-area-top" mode="ios">
  <ion-buttons class="pointer" slot="start" (click)="goBack()">
    <ion-icon name="chevron-back-outline" size="large" color="primary"></ion-icon>
  </ion-buttons>
  <ion-title class="ion-text-center">Jobs</ion-title>
  <ion-button class="find-jobs-button" slot="end"  expand="block" *ngIf="isShowFindJobsBtn" (click)="onClickFindJobs()">Find Jobs</ion-button>
</ion-toolbar>
<scrolling-header
  [subPages]="screenSubPages"
  [tabIndex]="defaultSelectedIndex"
  (selectedTab)="onSubpageChange($event)"
  [selectedIndex]="defaultSelectedIndex"
  [theValue]="tab"
></scrolling-header>

<ion-content>
  <ion-refresher *ngIf="platform.is('mobile') && !platform.is('mobileweb')" slot="fixed" (ionRefresh)="loadJobsData($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <div [ngSwitch]="tab">
    <app-previous-trips-loads
      [trips] = "previousTrips"
      [closeBottomDrawer] = "closeBottomDrawer"
      [isPreviousJobLoading]="isPreviousJobLoading"
      (getPreviousJobs)="getPreviousJobs()"
      *ngSwitchCase="'Previous'"
    ></app-previous-trips-loads>
    <current-job 
    [trip] = "currentTrip"
    [closeBottomDrawer] = "closeBottomDrawer"
    [isCurrentJobLoading]="isCurrentJobLoading"
    (getCurrentJob)="getCurrentJob()"
    *ngSwitchCase="'Current'">
    </current-job>
    <app-pre-assigned-trips-loads
      *ngSwitchCase="'Pre-Assigned'"
      [loadTrip] = "loadPreassignedJob"
      (stopIonRefresher)="stopIonRefresher($event)"
    ></app-pre-assigned-trips-loads>
  </div>
</ion-content>