/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserStory { 
    id?: string;
    userId?: string;
    userName?: string;
    userContactInfos?: string;
    thumbnailBytes?: string;
    thumbnailUrl?: string;
    title?: string;
    content?: string;
    approved?: boolean;
    published?: boolean;
    createdDate?: Date;
    modifiedDate?: Date;
    publishedDate?: Date;
    approvedDate?: Date;
}