<ion-card-header *ngIf="title">
  <ion-card-title class="edge-value ion-text-center">{{title}}</ion-card-title>
</ion-card-header>
<ion-card-content *ngIf="employees && employees.length > 0">
  <div class="content">
  <div class="contact pointer" *ngFor="let fm of employees" (click)="showContactDetails(fm)">
    <div class="flex-me">
      <div class="flex-me col">
        <ion-label class="edge-value name">
          <span *ngIf="fm.fullName">{{ fm?.fullName }}</span>
          <span *ngIf="!fm.fullName">{{ fm?.firstName }} {{fm?.lastName}}</span>
        </ion-label>
        <ion-text class="edge-value">{{ fm.role }}</ion-text>
      </div>
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </div>
  </div>
</div>
</ion-card-content>
