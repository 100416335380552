/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type UserType = 'Unknown' | 'Driver' | 'Onboarding' | 'ExWernerDriver' | 'Employee' | 'System' | 'Public' | 'ExWernerEmployee';

export const UserType = {
    Unknown: 'Unknown' as UserType,
    Driver: 'Driver' as UserType,
    Onboarding: 'Onboarding' as UserType,
    ExWernerDriver: 'ExWernerDriver' as UserType,
    Employee: 'Employee' as UserType,
    System: 'System' as UserType,
    Public: 'Public' as UserType,
    ExWernerEmployee: 'ExWernerEmployee' as UserType
};