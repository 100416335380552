
<ion-card class="edge-card" [ngClass]="{'static-vertical-card': !isMobile || isTab, 'vertical-card': isMobile}" *ngIf="card">
  <card-overlay 
    [card]="card"
    (didTapCard)="tapCard()"
    (didTapResize)="resizeCard()"
    (didTapChange)="changeCard()"
    (didTapRemove)="removeCard()"
  ></card-overlay>
  <div class="img-div">
    <img src="../../../../../assets/Images/Refer-Driver-Icon.svg" alt="Driver Referal">
  </div>
</ion-card>