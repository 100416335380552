<ion-card #card (click)="arrowButtonClickHandler()" class="ion-padding-vertical">

  <ion-item lines="none">
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col size="6">
          <ion-text class="edge-value">{{title}}</ion-text>
        </ion-col>
        <ion-col size="6">
          <div class="edge-value date ion-float-right">
            <ion-text>{{secondSubTitle}}</ion-text>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <div class="edge-value ion-padding-top">{{firstSubTitle}}</div>
        </ion-col>
      </ion-row>
    
      <div #expandWrapper class='expand-wrapper' [class.collapsed]="!expanded">
        <ng-content ></ng-content>
      </div>
    </ion-grid>
   </ion-item>
    
  </ion-card>