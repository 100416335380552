/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */


import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DrivingOptions } from '../model/drivingOptions';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable()

export class DrivingOptionsService { 

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }   
    
    /**
     * Save questions for first page
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDrivingOptionsSaveQuestions(identityKey,body?: DrivingOptions, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiDrivingOptionsSaveQuestions(identityKey,body?: DrivingOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiDrivingOptionsSaveQuestions(identityKey,body?: DrivingOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiDrivingOptionsSaveQuestions(identityKey,body?: DrivingOptions, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post', `${this.basePath}/api/DrivingOptions/${encodeURIComponent(String(identityKey))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }    


    /**
     * Save questions for first page
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDrivingOptionsSaveBaseQuestions(identityKey, body?: DrivingOptions, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiDrivingOptionsSaveBaseQuestions(identityKey, body?: DrivingOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiDrivingOptionsSaveBaseQuestions(identityKey, body?: DrivingOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiDrivingOptionsSaveBaseQuestions(identityKey, body?: DrivingOptions, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post', `${this.basePath}/api/BaseQuestions/${encodeURIComponent(String(identityKey))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }   


    /**
     * Fetch questions for first page
     * 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDrivingOptionsGet(identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<DrivingOptions>;
    public apiDrivingOptionsGet(identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DrivingOptions>>;
    public apiDrivingOptionsGet(identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DrivingOptions>>;
    public apiDrivingOptionsGet(identityKey: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiPinUserIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DrivingOptions>('get', `${this.basePath}/api/DrivingOptions/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }    

    /**
     * Fetch questions for second page
     * 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDrivingOptionsNextQuestionsGet(identityKey: string, currentQuestionGroupId: string, observe?: 'body', reportProgress?: boolean): Observable<DrivingOptions>;
    public apiDrivingOptionsNextQuestionsGet(identityKey: string, currentQuestionGroupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DrivingOptions>>;
    public apiDrivingOptionsNextQuestionsGet(identityKey: string, currentQuestionGroupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DrivingOptions>>;
    public apiDrivingOptionsNextQuestionsGet(identityKey: string, currentQuestionGroupId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiPinUserIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        return this.httpClient.request<DrivingOptions>('get', `${this.basePath}/api/DrivingOptions/${encodeURIComponent(String(identityKey))}/${encodeURIComponent(String(currentQuestionGroupId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }        

}