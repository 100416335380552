import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { AuthService } from 'src/app/auth/auth.service';
import { RequestService } from 'src/app/Swagger-Gen-V2';
import { SubSink } from 'subsink';
@Component({
  selector: 'app-legacy-banner-card',
  templateUrl: './legacy-banner-card.component.html',
  styleUrls: ['./legacy-banner-card.component.scss'],
})
export class LegacyBannerCardComponent implements OnInit, OnDestroy {

  identityKey = '';
  message = '';
  resourceUrl = '';

  appVersion = '';
  platformName = '';
  platformVersion = '';
  deviceId = '';
  buildNumber = '';

  deviceInfo = null;
  subscriptions$ = new SubSink();
  @Input('loadBannerApi') loadBannerApi: boolean;
  @Output('hideIonRefresher') hideIonRefresher = new EventEmitter;
  // instantiates component
  constructor(
              private iab: InAppBrowser, 
              private platform: Platform,
              private authService : AuthService,
              private requestService: RequestService
              ) {
      this.authService.getUserIdentitityKey().then((identityKey) => {
        this.identityKey = identityKey;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const loadBannerApiChange = changes['loadBannerApi'];
    if (loadBannerApiChange && loadBannerApiChange.currentValue) {
      this.getLegacyMessageBannerCard();
    }
  }

  // handler called when all the component have finished loading
  async ngOnInit() {

    this.deviceInfo = await Device.getInfo();

    this.appVersion = '2.0.0';
    this.platformName = 'iOS';
    this.platformVersion = '12.0';
    this.deviceId = '567568546';
    this.buildNumber = '2000';

    if (this.deviceInfo !== null && this.deviceInfo !== undefined) {
      const isWebApp = (this.platform.is("desktop") || this.platform.is("mobileweb"));
      const appInfo =  (!isWebApp)?await App.getInfo():undefined;
      const deviceIdInfo = await Device.getId();
      this.appVersion = (appInfo && appInfo.version)?appInfo.version.toString():this.appVersion;
      this.deviceId = deviceIdInfo.uuid.toString();
      this.buildNumber = (appInfo && appInfo.build)?appInfo.build.toString():this.buildNumber;
      this.platformName = this.deviceInfo.platform.toString();
      this.platformVersion = this.deviceInfo.osVersion.toString();

      if (this.buildNumber.length === 0) {
        this.buildNumber = '2000';
      }

      if (this.appVersion.length === 0) {
        this.appVersion = '1.0.35';
      } else if (isNaN(+this.appVersion) === true) {
        // Determine the correct platform format. Backend expects x.x.xx (where xx can be maximum of 2 characters)
         const indexOfSecondPeriod = this.getPosition(this.appVersion, '.', 2);

         let firstPartOfVersionNumber = this.appVersion.substr(0, indexOfSecondPeriod);

         // add period
         firstPartOfVersionNumber += '.';

         const totalCharsAfterDecimalToEndOfString =
         this.appVersion.substr((indexOfSecondPeriod + 1), (this.appVersion.length - indexOfSecondPeriod));

         let secondPartOfVersionNumber = '';

         if (totalCharsAfterDecimalToEndOfString.length >=  2) {
          secondPartOfVersionNumber = totalCharsAfterDecimalToEndOfString.substr(0, 2);
         } else {
          secondPartOfVersionNumber = totalCharsAfterDecimalToEndOfString.substr(0, 1);
         }
         this.appVersion = firstPartOfVersionNumber + secondPartOfVersionNumber;
       }

      if (this.platformName.length === 0 ||
        (this.platformName.toString().toLowerCase() !== 'ios'
          && this.platformName.toString().toLowerCase() !== 'android')) {
        this.platformName = 'iOS';
      }

      if (this.platformName === 'ios') {
        this.platformName = 'iOS'; // expected by the backend API RegEx
      }

      if (this.platformName === 'android') {
        this.platformName = 'Android'; // expected by the backend API RegEx
      }


      if (this.platformVersion === 'Android 5.0') {
        this.platformVersion = '11.1';
      }

      if ((this.platform.is("desktop") || this.platform.is("mobileweb"))) {
        this.platformName = "iOS";
        this.platformVersion = "12.0";
      }
    }

    if (this.identityKey !== null
        && this.identityKey !== undefined
          && this.identityKey.length === 0) {
      this.identityKey = await this.authService.getUserIdentitityKey();
    } else {
           await this.getLegacyMessageBannerCard();
    }

  }

  // helplink: https://stackoverflow.com/questions/14480345/how-to-get-the-nth-occurrence-in-a-string
  getPosition(input, subString, index) {
    return input.split(subString, index).join(subString).length;
  }

  // handler called when the user taps on the open link
  openLinkButtonClickHandler() {
    if (this.resourceUrl !== null && this.resourceUrl !== undefined && this.resourceUrl.length > 0) {
      let isPDF = this.resourceUrl.includes('.pdf');
      let isAndroid = this.platform.is("android");
      let target = (isAndroid && isPDF) ? '_system' : '_blank';
  
      this.iab.create(this.resourceUrl, target, {
        location: 'no', // hide the location bar
        hidenavigationbuttons: 'yes' // hide the navigation buttons
      });
    }
  }

 // retrieves message information used to populate this card
  async  getLegacyMessageBannerCard() {
    this.subscriptions$.sink = this.requestService.messagingLegacymessagebannercardGet(this.identityKey).subscribe(t => {
      if (t !== null && t !== undefined) {
        this.hideIonRefresher.emit(true);
        this.message = t.message;
        this.resourceUrl = t.redirectUrl;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();  
  }

}
