/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SafetyCsaBasicCategory } from './wernerDwpContractsLogicSafetyCsaBasicCategory';

export interface SafetyCsaInspection { 
    reportNumber?: string;
    reportIssuedState?: string;
    inspectionDate?: Date;
    inspectionValue?: number;
    csaBasicCategory?: SafetyCsaBasicCategory;
    violationSection?: string;
    riskIndicator?: string;
    sectionDescription?: string;
    timeWeight?: number;
    severityWeight?: number;
    totalSeverityPoints?: number;
    outOfService?: string;
    isInspection?: boolean;
}