<app-expandable-card expandHeight="700px" [expanded]="expanded" [title]="title" [firstSubTitle]="firstSubTitle"
  [secondSubTitle]="secondSubTitle" [showConnectorDot]="showConnectorDot" [showConnectorLine]="false" [isLast]="isLast"
  [connectorsColor]="connectorsColor" [showExpandableArrow]="showExpandableArrow" [cardType]="cardType"
  [allowCardToExpand]="showExpandableArrow">

    <ion-row>
      <ion-col size="12">
        <ion-label class="edge-value">{{pickUpAddress}}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col >
        <div class="ion-padding-top number">
          <ion-label>  Trailer #: {{trailerNumber}} </ion-label>
        </div>
      </ion-col>
      <ion-col size = "trailerColumn"  *ngIf="pickUpNumber">
        <div class="ion-float-right ion-padding-top number">
          <ion-label>  Pickup #: {{pickUpNumber}} </ion-label>
        </div>
     </ion-col>
    </ion-row>
  
    <!-- we show the Start Paperwork button when we are on a consignee and not on MVP -->
    <div class="ion-text-center ion-margin-top" *ngIf="isLastRecord && isScanPaperworkEnable">
      <ion-button expand="block" size="default" (click)='startPaperWork()'>
        Paperwork
      </ion-button>
      <ion-button fill="clear" class="ion-text-wrap" (click)="onClickUploadProcess()"> How to submit paperwork </ion-button>
    </div>
</app-expandable-card>

