/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ReferenceLibraryListViewSection } from '../model/referenceLibraryListViewSection';
import { ReferenceLibraryView } from '../model/referenceLibraryView';
import { SectionView } from '../model/sectionView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ReferenceLibraryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Retrieves all the necessary data to create model that will be bound to the reference library screen during a seach.  The function returns a section with a collection of items
     * 
     * @param identityKey 
     * @param keyword 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReferencelibrarySearchSectionIdentityKeyKeywordGet(identityKey: string, keyword: string, observe?: 'body', reportProgress?: boolean): Observable<ReferenceLibraryListViewSection>;
    public apiReferencelibrarySearchSectionIdentityKeyKeywordGet(identityKey: string, keyword: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReferenceLibraryListViewSection>>;
    public apiReferencelibrarySearchSectionIdentityKeyKeywordGet(identityKey: string, keyword: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReferenceLibraryListViewSection>>;
    public apiReferencelibrarySearchSectionIdentityKeyKeywordGet(identityKey: string, keyword: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiReferencelibrarySearchSectionIdentityKeyKeywordGet.');
        }

        if (keyword === null || keyword === undefined) {
            throw new Error('Required parameter keyword was null or undefined when calling apiReferencelibrarySearchSectionIdentityKeyKeywordGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ReferenceLibraryListViewSection>('get',`${this.basePath}/api/referencelibrary/search/section/${encodeURIComponent(String(identityKey))}/${encodeURIComponent(String(keyword))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves all the necessary data to create model that will be bound to the reference library screen during a seach
     * 
     * @param identityKey 
     * @param keyword 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReferencelibrarySearchViewIdentityKeyKeywordGet(identityKey: string, keyword: string, observe?: 'body', reportProgress?: boolean): Observable<ReferenceLibraryView>;
    public apiReferencelibrarySearchViewIdentityKeyKeywordGet(identityKey: string, keyword: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReferenceLibraryView>>;
    public apiReferencelibrarySearchViewIdentityKeyKeywordGet(identityKey: string, keyword: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReferenceLibraryView>>;
    public apiReferencelibrarySearchViewIdentityKeyKeywordGet(identityKey: string, keyword: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiReferencelibrarySearchViewIdentityKeyKeywordGet.');
        }

        if (keyword === null || keyword === undefined) {
            throw new Error('Required parameter keyword was null or undefined when calling apiReferencelibrarySearchViewIdentityKeyKeywordGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ReferenceLibraryView>('get',`${this.basePath}/api/referencelibrary/search/view/${encodeURIComponent(String(identityKey))}/${encodeURIComponent(String(keyword))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves all the necessary data to createa model that will be bound to the reference library screen
     * 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReferencelibraryViewIdentityKeyGet(identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<ReferenceLibraryView>;
    public apiReferencelibraryViewIdentityKeyGet(identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReferenceLibraryView>>;
    public apiReferencelibraryViewIdentityKeyGet(identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReferenceLibraryView>>;
    public apiReferencelibraryViewIdentityKeyGet(identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiReferencelibraryViewIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ReferenceLibraryView>('get',`${this.basePath}/api/referencelibrary/view/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves all the necessary data to create model that will bound to the section
     * 
     * @param sectionId 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiReferencelibraryViewSectionSectionIdIdentityKeyGet(sectionId: string, identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<SectionView>;
    public apiReferencelibraryViewSectionSectionIdIdentityKeyGet(sectionId: string, identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SectionView>>;
    public apiReferencelibraryViewSectionSectionIdIdentityKeyGet(sectionId: string, identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SectionView>>;
    public apiReferencelibraryViewSectionSectionIdIdentityKeyGet(sectionId: string, identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sectionId === null || sectionId === undefined) {
            throw new Error('Required parameter sectionId was null or undefined when calling apiReferencelibraryViewSectionSectionIdIdentityKeyGet.');
        }

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiReferencelibraryViewSectionSectionIdIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SectionView>('get',`${this.basePath}/api/referencelibrary/view/section/${encodeURIComponent(String(sectionId))}/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
