import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AuthService } from 'src/app/auth/auth.service';
import { MessageThreadDetailResponse } from 'src/app/Swagger-Gen-V2/model/messageThreadDetailResponse';

@Component({
  selector: 'messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent {
  
  SELECTED_MESSAGE_KEY = 'selected-message';
  MESSAGE_DETAILS_VIEW_KEY = 'message-details';
  SELECTED_THREAD_ID_KEY = 'selected-thread-id';
  SELECTED_THREAD_KEY = 'selected-thread';
  @Input() messages;
  filteredMessages: Array<MessageThreadDetailResponse> = []; 
  identityKey = '';
  threadBroadCast = 'Broadcast';
  threadGroupDriver = 'GroupDriver';

  constructor(
    private authService: AuthService,
    private navCtrl: NavController
  ) { 
    this.authService.getUserIdentitityKey().then((identityKey) => {
      this.identityKey = identityKey;
    });
  }

  async ngOnChanges() {
    this.filteredMessages = [];
    if (this.messages) {
      this.messages.threads.forEach(async message => {
        if (message.threadType !== this.threadBroadCast && message.threadType !== this.threadGroupDriver) {
          this.filteredMessages.push(message);
        }
      });
    }
  }

  // opens message details and shows it
  async showMessage(messageItem: MessageThreadDetailResponse) {
    if(messageItem.threadType === this.threadBroadCast || messageItem.threadType === this.threadGroupDriver) {
      this.navCtrl.navigateForward([`broadcast-message-details/${messageItem.threadId}`]);
    }  else {
      this.navCtrl.navigateForward([`personal-message-details/${messageItem.threadId}`]);
    }
  }
}
