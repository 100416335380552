<ion-card class="edge-value poi-map-details">
  <ion-card-header>
    <ion-card-title>Important</ion-card-title>
  </ion-card-header>
  <ion-card-content>
  <div class="message">
    The following site does not provide Class 8 routing.
  </div>
  <div class="flex-me">
    <ion-button fill='clear' (click)="dismiss()">
      Cancel
    </ion-button>
    <ion-button (click)="directionsTo()">
      Continue
    </ion-button>
</div>
  </ion-card-content>
</ion-card>
