import {Injectable, OnInit} from '@angular/core';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {AppVersionsService} from '../../Swagger-Gen/api/appVersions.service';
import {VersionUpdateDto} from '../../Swagger-Gen/model/versionUpdateDto';
import {Platform} from '@ionic/angular';


@Injectable({
    providedIn: 'root'
})
export class AppVersionService implements OnInit {
    versionUpdate: VersionUpdateDto;

    constructor(private appVersion: AppVersion,
                private appVersionsService: AppVersionsService,
                public platform: Platform) {

    }

    async ngOnInit() {
       await this.getUpdateObject();
    }
    async getUpdateObject() {
        const versionNumber = await this.appVersion.getVersionNumber();
        this.appVersionsService.apiAppVersionsAppVersionGet(versionNumber).subscribe((versionDto: VersionUpdateDto) => {
            this.versionUpdate = versionDto;
        });
    }

    forcedUpdate(): boolean {
      if (this.versionUpdate !== undefined && this.versionUpdate.isForcedUpdate) {
        return this.versionUpdate.isForcedUpdate;
      }
    }

    updateAvailable(): boolean {
        return this.versionUpdate.isUpdateAvailable;
    }

    updateApp() {
      if (this.platform.is('ios')) {
        window.open('Https://testflight.apple.com/v1/app/1511202045', '_system');
      }
      if (this.platform.is('android')){
        window.open('https://install.appcenter.ms/orgs/Werner-Enterprises/apps/DriversEdge-Droid', '_system');
      }
    }

}
