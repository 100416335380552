import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LoadsPageRoutingModule } from './loads-routing.module';

import { LoadsPage } from './loads.page';
import {MyLoadStatusCardComponent} from '../../Core/components/my-load-status-card/my-load-status-card.component';
import {SharedComponentsModule} from '../../shared/shared-components.module';
import {CurrentJobComponent } from './trip-load-infos/current-job.component';
import {PreviousTripsLoadsComponent} from './previous-trips-loads/previous-trips-loads.component';
import {TripsListComponent} from './trips-list/trips-list.component';
import {Store, StoreModule} from '@ngrx/store';
import {previousTripsReducer} from './previous-trips-loads/state/previous-trips-loads.reducer';
import {EffectsModule} from '@ngrx/effects';
import {GetPreviousTripsEffects} from './previous-trips-loads/state/previous-trips-loads.effects';
import {PreAssignedTripsLoadsComponent} from './pre-assigned-trips-loads/pre-assigned-trips-loads.component';
import {PreAssignedTripCardComponent} from '../../Core/components/pre-assigned-trip-card/pre-assigned-trip-card.component';
import {GetPreAssignedTripEffects} from '../../Core/components/pre-assigned-trip-card/state/pre-assigned-trip-card.effects';
import {TripStepCardComponent} from '../../Core/components/trip-step-card/trip-step-card.component';
import {CriticalLoadNotesCardComponent} from '../../Core/components/critical-load-notes-card/critical-load-notes-card.component';
import {GetCurrentTripEffects} from './trip-load-infos/state/trip-load-infos.effects';
import {tripLoadInfosReducer} from './trip-load-infos/state/trip-load-infos.reducer';
import {preAssignedTripCardReducer} from '../../Core/components/pre-assigned-trip-card/state/pre-assigned-trip-card-reducer';
import { LoadSelectionComponent } from './load-selection/load-selection.component';
import { loadSelectionReducer } from './load-selection/state/load-selection.reducer';
import { LoadSelectionEffects } from './load-selection/state/load-selection.effects';
import {StopOptionsModalComponent} from "./stop-options-modal/stop-options-modal.component";
import {LoadSelectionDetailsComponent} from "./load-selection/load-selection-details/load-selection-details.component";
import {EquipmentLocationMapComponent} from "./trip-load-infos/equipment-location-map/equipment-location-map.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        LoadsPageRoutingModule,
        SharedComponentsModule,
        FontAwesomeModule,
        StoreModule.forFeature('previousTrips', previousTripsReducer),
        StoreModule.forFeature('currentTrip', tripLoadInfosReducer),
        StoreModule.forFeature('preAssignedTrip', preAssignedTripCardReducer),
        StoreModule.forFeature('loadSelection', loadSelectionReducer),
        EffectsModule.forFeature([GetPreviousTripsEffects, GetPreAssignedTripEffects, GetCurrentTripEffects, LoadSelectionEffects])
    ],
    declarations: [LoadsPage, MyLoadStatusCardComponent, CurrentJobComponent, PreviousTripsLoadsComponent,
        TripsListComponent, PreAssignedTripsLoadsComponent, PreAssignedTripCardComponent, TripStepCardComponent,
        CriticalLoadNotesCardComponent, LoadSelectionComponent,
        CriticalLoadNotesCardComponent, StopOptionsModalComponent, LoadSelectionDetailsComponent, EquipmentLocationMapComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class LoadsPageModule {}
