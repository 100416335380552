import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy
} from "@angular/core";
import { CupertinoPane } from "cupertino-pane";
import { LoadService } from "src/app/modules/loads/services/load.service";
import { SubSink } from 'subsink';
@Component({
  selector: "bottom-drawer",
  templateUrl: "./bottom-drawer.component.html",
  styleUrls: ["./bottom-drawer.component.scss"],
})
export class BottomDrawerComponent implements OnInit, OnDestroy {
  @Input("title") title = "Row Title";
  @Input("isShowBtns") isShowBtns = true;
  
  // Passing any value to the bottomDrawer hides it
  //
  @Input() set bottomDrawerShouldHide(hide: any) {
    if (this.bottomDrawer && hide) {
      this.bottomDrawer.moveToBreak("bottom");
    } else if (this.bottomDrawer) {
      this.bottomDrawer.moveToBreak("middle");
    }
  }

  @Input("backdrop") backdrop = false;
  @Input("topOffset") topOffset = window.screen.height - 300 * 0.4;
  @Input("middleOffset") middleOffset = window.screen.height / 3;
  @Input("bottomOffset") bottomOffset = 300;
  @Input("actionSheetRows") actionSheetRows: Array<ActionSheetRow>;
  @Output("closedDrawer") closedDrawer = new EventEmitter();
  subscriptions$ = new SubSink();
  bottomDrawer: CupertinoPane;

  constructor(private loadService: LoadService){}

  ngOnInit() {
    this.bottomDrawer = new CupertinoPane(
      ".cupertino-pane", // Container selector
      {
        parentElement: 'app-loads', // Parent container, uncomment to position above tab bar
        breaks: {
          top: { enabled: true, height: this.topOffset },
          middle: { enabled: true, height: this.middleOffset },
          bottom: { enabled: true, height: this.bottomOffset },
        },
        backdrop: this.backdrop,
        buttonDestroy : false
       }
    );
    this.bottomDrawer.on('onBackdropTap', () => {
      this.backdropTapped();
    });
    this.bottomDrawer.present({ animate: true });

    document.addEventListener("closeDrawer", () => this.closeDrawer());

    this.subscriptions$.sink = this.loadService.closeBottomDrawer.subscribe( response => {
      if(response == true){
        this.closeDrawer();
      }
    })
  }

  async closeDrawer() {
    await this.bottomDrawer.destroy({ animate: true });
  }
  

  backdropTapped() {
    if (this.backdrop) {
      this.closeDrawer().then(() => {
        // Delay to avoid flicker when closing drawer with backdrop
        //

        setTimeout(() => {
          this.closedDrawer.emit(true);
        }, 100);
      });
    }
  }

  tappedCancel() {
    // Cancel either dismisses or minimizes bottom drawer depending on it's row content
    //
    this.actionSheetRows != undefined
      ? this.backdropTapped()
      : this.bottomDrawer.moveToBreak("bottom");
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

}

export class ActionSheetRow {
  title: string;
  route: string;
}
