<ion-card class="ion-margin-top">
  <ion-card-header>
      <div class="flex-me">
        <ion-card-title class="edge-value">Driving Options</ion-card-title>
      </div>
  </ion-card-header>
  <ion-card-content padding class="ion-padding-bottom">
    <div class="ion-padding-bottom">
      <ion-label class="ion-text-center">Please let us know about your interest on the different driving options
        Werner offers:</ion-label>
    </div>
    <app-json-form [jsonFormData]="jsonFormData" (submitOptions)="onSubmitOptions()" (backToHome)="goBack()"
      [showGoBack]="showGoBackButton"></app-json-form>
  </ion-card-content>
</ion-card>