import { Action } from '@ngrx/store';
import { ReferDriverInputs, RequestResponse } from 'src/app/Swagger-Gen';

export enum DriverReferralActionTypes {
  PostDriverreferralData = 'post driver referral data',
  PostDriverReferralSuccess = 'post driver referral data success',
  PostDriverReferralFail = ' data fail',
  UpdateDriverReferralResponse = 'update driver referral'
}

export class PostDriverreferralData implements Action {
  readonly type = DriverReferralActionTypes.PostDriverreferralData;
  DriverRefferalData: ReferDriverInputs;
  constructor(public driverReferralData: ReferDriverInputs) { 
      this.DriverRefferalData = driverReferralData;
  }
}
export class PostDriverReferralSuccess implements Action {
    readonly type = DriverReferralActionTypes.PostDriverReferralSuccess;
    constructor(public payload: RequestResponse) {}
  }
  
  export class PostDriverReferralFail implements Action {
    readonly type = DriverReferralActionTypes.PostDriverReferralFail;
    constructor(public payload: string) {}
  }

  export class UpdateDriverReferralResponse implements Action {
    readonly type = DriverReferralActionTypes.UpdateDriverReferralResponse;
    constructor(public payload: RequestResponse) {}
  }
  
  

export type DriverReferralAction = PostDriverreferralData | PostDriverReferralSuccess | PostDriverReferralFail |UpdateDriverReferralResponse;