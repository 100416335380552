import {Injectable, Injector} from '@angular/core';
import {  ToastController } from '@ionic/angular';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
@Injectable()
export class PlayerUtilities {
    toastController: ToastController;
    streamingMedia: StreamingMedia;
    constructor(
        private injector: Injector,
      ) { 
        this.streamingMedia = this.injector.get<StreamingMedia>(StreamingMedia);
      }   
      
      async startVideoStream(streamUrl: string, type) {
        // helplink: https://ionicframework.com/docs/native/streaming-media
        const options: StreamingVideoOptions = {
          successCallback: async () => {
          },
          errorCallback: async () => {
            const toast = await this.toastController.create({
              message: `An error occured while playing video`,
              duration: 3000
            });
            toast.present();
          },
          orientation: 'landscape',
          shouldAutoClose: true,
          controls: true
        };
        if(type == 'audio')
            this.streamingMedia.playAudio(streamUrl, options)
        else
            this.streamingMedia.playVideo(streamUrl, options);
      }        
}
