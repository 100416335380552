<ion-item lines="full" class="ion-no-padding  toolbar" id="main-content" [ngClass]="{'ion-safe-area-top':router.url != '/preview-home' }">
  <!-- Menu Button -->
  <ion-col size="2" class="ion-no-padding">
    <div class="menu-icon">
      <ion-buttons>
        <ion-menu-button (click)="menuButtonTapped()" slot="start" autoHide="false" data-test-id="toolbar-hamburger-button"></ion-menu-button>
      </ion-buttons>
    </div>
  </ion-col>
  
  <!-- Logo Image -->
  <ion-col size="8" class="ion-no-padding">
    <div class="logo-icon">
      <img class="pointer" (click)="goToHome()" src="{{ logoSrc }}" />
    </div>
  </ion-col>
  <!-- Bot -->
  <ion-col size="2" class="ion-no-padding">
  
  </ion-col>
</ion-item>
