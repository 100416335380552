<ion-toolbar class="ion-safe-area-top" mode="ios">
  <ion-buttons class="pointer" slot="start" (click)="goBack()">
    <ion-icon name="chevron-back-outline" size="large" color="primary"></ion-icon>
  </ion-buttons>
  <ion-title class="ion-text-center">Job Search</ion-title>
</ion-toolbar>

<ion-content *ngIf="!(filteredRecomendedLoads && filteredRecomendedLoads.length > 0)">
  <div class="no-jobs edge-value">No Available Jobs</div>
</ion-content>

<ion-content *ngIf="(filteredRecomendedLoads && filteredRecomendedLoads.length > 0)" >
  <ion-card *ngFor="let rl of filteredRecomendedLoads;">
    <ion-card-content>
      
      <ion-item class="list-item" lines="full" *ngIf="rl.loadStops && rl.loadStops.length > 1">
        <ion-grid>
          <ion-row class="row-item">
            <ion-col size="5">
              <div>
                <ion-label class="edge-value" text-wrap>{{rl.loadStops[0]?.address?.city}}, {{rl.loadStops[0]?.address?.state}}</ion-label>
              </div>

               <ion-text class="edge-label">{{rl.loadStops[0].openTimeStamp | date: 'MMM d, HH:mm'}} 123</ion-text>
            </ion-col>
            <ion-col size="1" class="ion-col-2">
              <ion-icon class="connector" name="arrow-forward-outline"></ion-icon>
            </ion-col>
            <ion-col size="6" class="ion-col-3">
              <div>
                <ion-label class="edge-value" text-wrap>{{rl.loadStops[rl.loadStops.length -1]?.address?.city}}, {{rl.loadStops[rl.loadStops.length -1]?.address?.state}}
                </ion-label>
              </div>
              <ion-text class="edge-label">{{rl.loadStops[rl.loadStops.length -1].openTimeStamp | date: 'MMM d, HH:mm'}}</ion-text> 
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <div *ngIf="rl.loadStops && rl.loadStops.length > 2">
          <ion-item class="list-item" lines="full">
            <ion-grid class="ion-grid-stop">
              <ion-row class="row-item">
                <ion-col class="ion-stop-col">STOP</ion-col>
                <ion-col class="ion-arrow-col">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </ion-col>
                <ion-col class="ion-address-col">
                  <div>
                    <ion-label>{{rl.loadStops[1].city}},
                      {{rl.loadStops[1].state}}
                    </ion-label>
                  </div>
                  
                  <ion-text>{{rl.loadStops[1].targetStamp | date: 'MMM d, HH:mm'}}</ion-text>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-label class="ion-load-more" slot="end" (click)="showDetails(rl)" ><a>LEARN MORE</a></ion-label>
          </ion-item>
      </div>
      <ion-item class="list-item" lines="none">
        <ion-grid>
          <ion-row>
            <ion-col size="6">
              <ion-label class="load-miles">
                {{rl.lineHaulMiles}}
                Load Miles
              </ion-label>
            </ion-col>
            <ion-col size="6">
              <div class="ion-float-right">
                <ion-button fill="clear" (click)="showDetails(rl)"> VIEW DETAILS <ion-icon class="detail-icon" name="chevron-forward-outline"></ion-icon></ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-card-content>
  </ion-card>

</ion-content>
