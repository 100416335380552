/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { NewsArticle } from '../model/newsArticle';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class NewsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Retrieves data on all the news articles that are in the system
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiNewsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<NewsArticle>>;
    public apiNewsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NewsArticle>>>;
    public apiNewsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NewsArticle>>>;
    public apiNewsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<NewsArticle>>('get',`${this.basePath}/api/News`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves data on all the news articles that are in the system based on pagenumber and limit
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public apiNewsArticlesWithLimitPagenumberGet(limit?: number , pageNumber?: number , observe?: 'body', reportProgress?: boolean): Observable<Array<NewsArticle>>;
     public apiNewsArticlesWithLimitPagenumberGet(limit?: number , pageNumber?: number , observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NewsArticle>>>;
     public apiNewsArticlesWithLimitPagenumberGet(limit?: number , pageNumber?: number , observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NewsArticle>>>;
     public apiNewsArticlesWithLimitPagenumberGet(limit?: number , pageNumber?: number , observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;

         if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling apiNewsArticlesWithLimitPagenumberGet.');
         }
         if (pageNumber === null || pageNumber === undefined) {
            throw new Error('Required parameter pageNumber was null or undefined when calling apiNewsArticlesWithLimitPagenumberGet.');
         }
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<NewsArticle>>('get',`${this.basePath}/api/news/articles/${encodeURIComponent(String(limit))}/${encodeURIComponent(String(pageNumber))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
     * Retrieves data on the news article with given article id
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
      public apiNewsArticleByArticleIdGet(articleId?: string , observe?: 'body', reportProgress?: boolean): Observable<NewsArticle>;
      public apiNewsArticleByArticleIdGet(articleId?: string , observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NewsArticle>>;
      public apiNewsArticleByArticleIdGet(articleId?: string , observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NewsArticle>>;
      public apiNewsArticleByArticleIdGet(articleId?: string , observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
  
          let headers = this.defaultHeaders;
 
          if (articleId === null || articleId === undefined) {
             throw new Error('Required parameter articleId was null or undefined when calling apiNewsArticleByArticleIdGet.');
          }
          
          // authentication (oauth2) required
          if (this.configuration.accessToken) {
              const accessToken = typeof this.configuration.accessToken === 'function'
                  ? this.configuration.accessToken()
                  : this.configuration.accessToken;
              headers = headers.set('Authorization', 'Bearer ' + accessToken);
          }
  
          // to determine the Accept header
          let httpHeaderAccepts: string[] = [
              'application/json'
          ];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
              headers = headers.set('Accept', httpHeaderAcceptSelected);
          }
  
          // to determine the Content-Type header
          const consumes: string[] = [
          ];
  
          return this.httpClient.request<NewsArticle>('get',`${this.basePath}/api/news/article?articleId=${encodeURIComponent(String(articleId))}`,
              {
                  withCredentials: this.configuration.withCredentials,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress
              }
          );
      }
 

}
