<app-badge-status-card *ngIf="preAssignedTrip && preAssignedTrip.length > 0"
  [messageText]="badgeMessage" [iconName]="badgeIcon" [textColor]="badgeTextColor"></app-badge-status-card>
<ion-card>
  <ion-card-content>
    <ion-row class="ion-padding-bottom">
      <ion-col size="6">
        <ion-text class="label">
          My Load Status
        </ion-text>
      </ion-col>
      <ion-col size="6">
        <div>
          <ion-button *ngIf="!preAssignedTrip || preAssignedTrip.length <= 0" color="medium" size="small" disabled
            class="ion-float-right ion-text-capitalize">
            <ion-text class="no-pre-trip-button-text">
              Unassigned
            </ion-text>
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
    <div *ngFor="let trip of preAssignedTrip" >
      <ion-row>
        <ion-col size="4">
          <ion-label class="label">Job ID</ion-label>
        </ion-col>
        <ion-col size="8" class="value">
          <ion-text class="value">{{trip?.id}}</ion-text>
        </ion-col>
      </ion-row>
      
      <ion-row>
        <ion-col size="4">
          <ion-label class="label">Origin</ion-label>
        </ion-col>
        <ion-col size="8" class="value">
          <div *ngIf="trip?.companyName">
            <ion-text class="value" >
              {{ trip?.companyName ? ' ' : trip?.companyName }}
            </ion-text>
          </div>
          <div >
            <ion-text class="value" *ngIf="getLocation(trip,'departure')">
              {{getLocation(trip,'departure')}}
            </ion-text>
          </div>
        </ion-col>
      </ion-row>
      
      <ion-row>
        <ion-col size="4">
          <ion-label class="label">Destination</ion-label>
        </ion-col>
        <ion-col size="8" class="value">
          <ion-text class="value">{{getLocation(trip,'arrive')}}</ion-text>
        </ion-col>
      </ion-row>
      
      <ion-row>
        <ion-col size="4">
          <ion-label class="label">Pickup</ion-label>
        </ion-col>
        <ion-col size="8" class="value">
          <ion-text class="value">{{trip?.id}}</ion-text>
        </ion-col>
      </ion-row>
    </div>
    <ion-row class="ion-padding-vertical" *ngIf="!preAssignedTrip || preAssignedTrip.length <= 0">
      <ion-col size="8" push="2">
        <div class="ion-text-center">
          <ion-text class="value no-pre-trip">
            You are not pre-assigned any jobs at this time.
          </ion-text>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="ion-padding-vertical" >
      <ion-col>
        <div class="ion-text-center" *ngIf="!isWebApp && fleetManagerPhoneNumber">
          <ion-button (click)='callFleetManager()' [href]="'tel:'+fleetManagerPhoneNumber">
            <fa-icon [icon]="['fas', 'phone']" class="icon"></fa-icon>
            <ion-label class="ion-padding-start">
              Call Transportation Manager
            </ion-label>
          </ion-button>ˀ
        </div>
      </ion-col>
    </ion-row>
  </ion-card-content>
</ion-card>
