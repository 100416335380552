
import {createFeatureSelector, createSelector} from '@ngrx/store';
import { LoadAssignmentResponse } from '../../../../Swagger-Gen';
import {LoadSelectionActionTypes, LoadSelectionActions} from './load-selection.actions';
import * as fromRoot from '../../../../app.state';
import { JobLoad } from 'src/app/Swagger-Gen-V2/model/jobLoad';


export interface State extends fromRoot.State {
    loadSelection: LoadSelectionState;
    
}

export interface LoadSelectionState {
    jobSelectionList: Array<JobLoad>;
    loadSelectionResponse: LoadAssignmentResponse;
    error: string;
}

const initialState: LoadSelectionState = {
    jobSelectionList: null,
    loadSelectionResponse: null,
    error: ''
};

const getLoadSelectionFeatureState = createFeatureSelector<LoadSelectionState>('loadSelection');

export const getJobSelectionList = createSelector(
    getLoadSelectionFeatureState ,
    state =>  (state != null) ? state.jobSelectionList : null
);

export const getJobSelectionResponse = createSelector(
    getLoadSelectionFeatureState ,
    state =>  (state != null) ? state.loadSelectionResponse : null
);


export const getError = createSelector(
    getLoadSelectionFeatureState,
    state => state.error
);



export function loadSelectionReducer(state= initialState, action: LoadSelectionActions): LoadSelectionState {
    switch (action.type) {
        case LoadSelectionActionTypes.GetLoadSelectionListSuccess:
            return {
                ...state,
                jobSelectionList: action.payload,
                error: ''
            };
        case LoadSelectionActionTypes.GetLoadSelectionListFail:
            return {
                ...state,
                jobSelectionList: null,
                error: action.payload
            };
            case LoadSelectionActionTypes.PostSelectedLoadSuccess:
                return {
                    ...state,
                    loadSelectionResponse: action.payload,
                    error: ''
                };
            case LoadSelectionActionTypes.PostSelectedLoadFail:
                return {
                    ...state,
                    loadSelectionResponse: null,
                    error: action.payload
                };

                case LoadSelectionActionTypes.ResetLoadSelectionMessage:
                return {
                    ...state,
                    loadSelectionResponse: null
                };
             
        default:
            return state;
    }
}
