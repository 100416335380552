<ion-card class="edge-card vertical-card safety-card" *ngIf="card">
  <card-overlay 
    [card]="card"
    (didTapCard)="tapCard()"
    (didTapResize)="resizeCard()"
    (didTapChange)="changeCard()"
    (didTapRemove)="removeCard()"
  ></card-overlay>
  <div *ngIf="zeroMiles">
    <ion-row>
      <ion-label class="edge-value zero-title-label">
        Thank you for<br>your safe driving.
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label class="edge-value zero-label">
        Your profile has<br>not yet reached the<br>key metrics to<br>display a graph.
      </ion-label>
    </ion-row>
  </div>
  <div *ngIf="card.size == 6 && !zeroMiles">
    <div [ngSwitch]="tab">
      <div *ngSwitchCase="'Safe Miles'">
        <circle-progress
          [title]="miles"
          [radius]="65"
          [titleFontSize]='16'
          [subtitle]='["miles"]'     
          [subtitleFontSize]='12'
          [percent]="milesPercent"
          [maxPercent]="maxPercent"
          [radius]='radius'
        ></circle-progress>
       
      </div>
      <div *ngSwitchCase="'Safe Stops'">
        <circle-progress
          [radius]="30"
          [title]="stops"
          [titleFontSize]='16'
          [subtitle]='["stops"]'
          [subtitleFontSize]='12'     
          [percent]="stopsPercent"
          [maxPercent]="maxPercent"
          [radius]='radius'
        ></circle-progress>
        <ion-row>
          <ion-label class="edge-label update-label">
            Safe Stops are updated on the 11th of each month.
          </ion-label>
        </ion-row>
      </div>
    </div>
  </div>
  <div *ngIf="card.size == 12 && !zeroMiles">
    <ion-row>
      <ion-col size='6'>
        <ion-row>
          <ion-label class="edge-value circle-label">
            Safe Miles
          </ion-label>
        </ion-row>
        <ion-row>
          <circle-progress
            [title]="miles"
            [titleFontSize]='16'
            [subtitle]='["miles"]'  
            [subtitleFontSize]='12'   
            [percent]="milesPercent"
            [maxPercent]="maxPercent"
            [radius]='radius'
          ></circle-progress>
        </ion-row>
        <ion-row>
          <ion-label class="edge-value milestone-label">
            Next Milestone: {{ nextMilestone || "1,000,000" }} miles
          </ion-label>
        </ion-row>
        <ion-row>
          <ion-label class="edge-label update-label">
            Safe Miles are updated on the 11th of each month.
          </ion-label>
        </ion-row>
      </ion-col>
      <ion-col size='6'>
        <ion-row>
          <ion-label class="edge-value circle-label">
            Safe Stops
          </ion-label>
        </ion-row>
        <ion-row>
          <circle-progress
            [title]="stops"
            [titleFontSize]='16'
            [subtitle]='["stops"]' 
            [subtitleFontSize]='12'    
            [percent]="stopsPercent"
            [maxPercent]="maxPercent"
            [radius]='radius'
          ></circle-progress>
        </ion-row>
        <ion-row>
          <ion-label class="milestone-label">
            <br>
          </ion-label>
        </ion-row>
        <ion-row>
          <ion-label class="edge-label update-label">
            Safe Stops are updated on the 11th of each month.
          </ion-label>
        </ion-row>
      </ion-col>
    </ion-row>
  </div>
</ion-card>