import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from "@angular/core";
import { NavController } from "@ionic/angular";

import { PayCheck } from "../../../../Swagger-Gen";
import { Observable } from "rxjs";

import { EdgeCard } from 'src/app/shared/models/edge-card';
import { SubSink } from 'subsink';
import { AdministrationFeatureFlags } from "src/app/Swagger-Gen-V2/model/administrationFeatureFlags";
import { DispatchService } from "src/app/shared/services/dispatchers/dispatch.service";


@Component({
  selector: "pay-card",
  templateUrl: "./pay-card.component.html",
  styleUrls: ["./pay-card.component.scss"],
})
export class PayCardComponent implements OnInit, OnDestroy {
  componentActive = true;
  driverId = "";
  driverPaymentSummary: Observable<any>;
  errorMessage$: Observable<string>;
  @Input("card") card: EdgeCard;
  @Output("resize") resize = new EventEmitter();
  @Output("change") change = new EventEmitter();
  @Output("remove") remove = new EventEmitter();
  RECENT_PAY_KEY = 'recentPayKey';
  PAST_PAYCHECK_KEY = "pastPayCheckKey";
  pastPaychecks: Observable<any>;
  private subscriptions$ = new SubSink();
  paychecks: Array<PayCheck>;
  isWorkdayDown: boolean = false;
  workdayPachecks: boolean = false;
  featureFlags: AdministrationFeatureFlags;

  constructor(
    private navCtrl: NavController,
    private dispatchService : DispatchService
  ) { }

  async ngOnInit() {
    this.featureFlags = await this.dispatchService.getFeatureFlags();
    
    this.isWorkdayDown = this.featureFlags
      && this.featureFlags.flags
      && this.featureFlags.flags.IsWorkdayDown !== undefined ? this.featureFlags.flags.IsWorkdayDown : null;
    this.workdayPachecks = this.featureFlags
      && this.featureFlags.flags
      && this.featureFlags.flags.WorkdayPayChecks !== undefined ? this.featureFlags.flags.WorkdayPayChecks : null;

  }

  // Handler called when the component is discarded from memory
  ngOnDestroy(): void {
    this.componentActive = false;
    this.subscriptions$.unsubscribe();
  }

  tapCard() {
    if (!(this.isWorkdayDown && this.workdayPachecks)) {
      this.card.showSizePositionControl || this.card.preview ?
        !this.card.showOverlay :
        this.navCtrl.navigateRoot("/pin", { queryParams: { page: "Pay" } });
    }
  }

  resizeCard() {
    this.resize.emit(this.card);
  }

  changeCard() {
    this.change.emit(this.card);
  }

  removeCard() {
    this.remove.emit(this.card)
  }
}
