/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ExpenseType = 'Tolls' | 'Scale' | 'Permit' | 'Maintenance' | 'Parts' | 'EmergencyFuel' | 'Fee' | 'Shag' | 'Other';

export const ExpenseType = {
    Tolls: 'Tolls' as ExpenseType,
    Scale: 'Scale' as ExpenseType,
    Permit: 'Permit' as ExpenseType,
    Maintenance: 'Maintenance' as ExpenseType,
    Parts: 'Parts' as ExpenseType,
    EmergencyFuel: 'EmergencyFuel' as ExpenseType,
    Fee: 'Fee' as ExpenseType,
    Shag: 'Shag' as ExpenseType,
    Other: 'Other' as ExpenseType
};