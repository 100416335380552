<app-sub-header [title]="'Trip Details'" [url]="'/loads'"></app-sub-header>

<ion-content>
  <ion-card class="ion-padding">
    <ion-card-content>
      <ion-item lines="none">
        <ion-grid>
          <ion-row>
            <ion-col size="5">
              <div>
                
                <ion-label *ngIf="beginningStop?.address" class="edge-value" text-wrap>{{beginningStop?.address?.city}}, {{beginningStop?.address?.state}}</ion-label>
              </div>
              <ion-text class="edge-label">{{beginningStop?.targetTimeStamp | date: 'MMM d, HH:mm'}}</ion-text>
            </ion-col>
            <ion-col size="2" class="ion-col-2">
              <ion-icon class="connector" name="arrow-forward-outline"></ion-icon>
            </ion-col>
            <ion-col size="5" class="end-stop-text">
              <div>
                <ion-label class="edge-value" text-wrap>{{endStop?.address?.city}}, {{endStop?.address?.state}}</ion-label>
              </div>
              <ion-text class="edge-label">{{endStop?.targetTimeStamp | date: 'MMM d, HH:mm'}}</ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-item lines='none'>
        <ion-grid>
          <ion-row>
            <ion-col size="6">
              <ion-chip *ngIf="selectedLoad?.preLoad">Pre-Load</ion-chip>
              <ion-chip *ngIf="selectedLoad?.liveLoad">Live Load</ion-chip>
              <ion-chip *ngIf="selectedLoad?.trailerType && selectedLoad.trailerType?.length > 0">{{selectedLoad.trailerType}}</ion-chip>
            </ion-col>
                  
            <ion-col size="6">
              <div class="ion-float-right">
                <ion-chip *ngIf="selectedLoad?.liveUnLoad" >Live Unload</ion-chip>
                <ion-chip *ngIf="selectedLoad?.dropTrailer">Drop Trailer</ion-chip>
                <ion-chip *ngIf="selectedLoad?.trailerTypeDesc && selectedLoad.trailerTypeDesc.length > 0">{{selectedLoad.trailerTypeDesc}}</ion-chip>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
              <ion-col size="6">
                <div class="edge-label">TRIP ID</div>
              </ion-col>
              <ion-col size="6">
                <div class="edge-value ion-float-right">
                  {{selectedLoad?.tripId}}
                  </div>
              </ion-col>
              <ion-col size="6">
                <div class="edge-label">Load Miles</div>
              </ion-col>
              <ion-col size="6">
                <div class="edge-value ion-float-right">
                  {{selectedLoad?.lineHaulMiles}} miles
                  </div>
              </ion-col>
              <ion-col size="6">
                <div class="edge-label">Weight</div>
              </ion-col>
              <ion-col size="6">
                <div class="edge-value ion-float-right">
                  {{selectedLoad?.weight}} lbs 
                  </div>
              </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <ion-card class="ion-padding">
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-label class="edge-label">
            Pickup
          </ion-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item lines="none">
            <ion-icon slot="start" class="trip-detail-icons" name="location-outline"></ion-icon>
             <div>
              <ion-label class="ion-text-wrap">{{beginningStop?.name}}</ion-label>
              <ion-text class="ion-text-wrap">{{beginningStop?.address?.city}}, {{beginningStop?.address?.state}}</ion-text>
            </div>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item lines="none">
            <ion-icon slot="start" name="time-outline"></ion-icon>
             <div>
              <ion-label>{{beginningStop?.openTimeStamp | date: 'MMM d, HH:mm'}} - {{beginningStop?.targetTimeStamp | date: 'MMM d, HH:mm'}}</ion-label>
            </div>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>

  <ion-card *ngFor="let ms of midStops" class="ion-padding">
    <ion-card-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-label>
              Stop #{{ms?.sequence}}
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item lines="none">
              <ion-icon slot="start" class="trip-detail-icons" name="location-outline"></ion-icon>
               <div>
                <ion-label class="ion-text-wrap">{{ms?.name}}</ion-label>
                <ion-text class="ion-text-wrap">{{ms?.city}}, {{ms?.state}}</ion-text>
              </div>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item lines="none">
              <ion-icon slot="start" name="time-outline"></ion-icon>
               <div>
                <ion-label>{{ms?.openTimeStamp | date: 'MMM d, HH:mm'}} - {{ms?.targetTimeStamp | date: 'MMM d, HH:mm'}}</ion-label>
              </div>
            </ion-item>
          </ion-col>
        </ion-row>
     </ion-grid>
    </ion-card-content>
  </ion-card>
  <ion-card class="ion-padding">
    <ion-card-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-label class="edge-label">
              Full Load
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item lines="none">
              <ion-icon slot="start" class="trip-detail-icons" name="location-outline"></ion-icon>
               <div>
                <ion-label>{{endStop?.name}}</ion-label>
                <ion-text class="ion-text-wrap">{{endStop?.address?.city}}, {{endStop?.address?.state}}</ion-text>
              </div>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item lines="none">
              <ion-icon slot="start" name="time-outline"></ion-icon>
               <div>
                <ion-label>{{endStop?.openTimeStamp | date: 'MMM d, HH:mm'}} - {{endStop?.targetTimeStamp | date: 'MMM d, HH:mm'}}</ion-label>
              </div>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
  <div class="ion-padding-horizontal ion-padding-bottom">
    <ion-button size="large" expand="block" (click)="showLoadActions()">
      SELECT FULL LOAD
    </ion-button>
  </div>
 </ion-content>

