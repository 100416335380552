import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'bottom-drawer-row',
  templateUrl: './bottom-drawer-row.component.html',
  styleUrls: ['./bottom-drawer-row.component.scss'],
})
export class BottomDrawerRowComponent implements OnInit {

  @Input('title') title = 'Set Action Title'
  @Input('route') route = '/home'
  @Input('handler') handler = null;

  constructor(private router: Router) { }

  ngOnInit() {}

  selectedRow() {
    if(this.handler) {
      this.handler();
    } else {
      this.router.navigate([this.route]);
    }
  }
}
