import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EdgeCard } from 'src/app/shared/models/edge-card';
import { SubSink } from 'subsink';
import { NavController } from "@ionic/angular";
import { Router } from "@angular/router";
import { AdministrationFeatureFlags } from 'src/app/Swagger-Gen-V2/model/administrationFeatureFlags';
import { DispatchService } from 'src/app/shared/services/dispatchers/dispatch.service';
@Component({
  selector: 'workday-card',
  templateUrl: './workday-card.component.html',
  styleUrls: ['./workday-card.component.scss'],
})
export class WorkdayCardComponent implements OnInit, OnDestroy {

  @Input() card: EdgeCard;
  @Output() resize = new EventEmitter();
  @Output() change = new EventEmitter();
  @Output() remove = new EventEmitter();
  subscriptions$ = new SubSink();
  isWorkdayDown: boolean = false;
  featureFlags: AdministrationFeatureFlags;

  constructor(
    private navCtrl: NavController,
    public router: Router,
    private dispatchService : DispatchService
  ) { }

  async ngOnInit() {
    this.featureFlags = await this.dispatchService.getFeatureFlags();
    this.isWorkdayDown = this.featureFlags && this.featureFlags.flags && this.featureFlags.flags.IsWorkdayDown !== undefined ? this.featureFlags.flags.IsWorkdayDown : null;
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  tapCard() {
    if (!this.isWorkdayDown) {
      this.card.showSizePositionControl || this.card.preview ? !this.card.showOverlay :
        this.navCtrl.navigateRoot("/pin", { queryParams: { page: "WorkdayLearning" } });

    }
  }

  resizeCard() {
    this.resize.emit(this.card);
  }

  changeCard() {
    this.change.emit(this.card);
  }

  removeCard() {
    this.remove.emit(this.card);
  }
}
