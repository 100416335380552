import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pay-card-row',
  templateUrl: './pay-card-row.component.html',
  styleUrls: ['./pay-card-row.component.scss'],
})
export class PayCardRowComponent {

  @Input('amount') amount

  constructor() { }
}
