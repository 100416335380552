<ion-card class="security-card">
  <ion-card-header>
    <div class="flex-me">
      <ion-card-title class="edge-value">Challenge Questions</ion-card-title>
    </div>
  </ion-card-header>
  <ion-card-content>
    <div>
      <ion-item lines="none">
        <ion-label> Set Challenge Question </ion-label>
      </ion-item>
      <ion-item class="challenge-select">
        <ion-select mode="md"
          (ionChange)="updateChallengeQuestion($event)"
          interface="popover"
          [interfaceOptions]="{cssClass: 'custom-pop-over-text security-question-popover',showBackdrop : false,size: 'cover'}"
          placeholder="Select Challenge Question"
          [value] = "selectedQuestion.questionId"
          [(ngModel)]="selectedQuestion.questionId"
          >
          <ion-select-option *ngFor="let data of questions" [value]="data.questionId">
            {{ data.question }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-input [(ngModel)]="answer" (ngModelChange)="setInput($event)" (keydown.enter)="submitAnswer()"
          placeholder="Answer"></ion-input>
      </ion-item>
      <div class="ion-padding-vertical">
       <ion-row class="ion-align-items-center">
        <ion-col size="12" class="ion-text-center">
          <!--
             keydown attribute added for sonar fixes
          https://sonarsource.atlassian.net/browse/RSPEC-1082?_gl=1*z3rx6h*_gcl_au*NjY5NzE5OTEzLjE3MTUzMzQ0MTI.*_ga*MTIxMzMxODc0My4xNzE1MzM0NDEz*_ga_9JZ0GZ5TC6*MTcxNTMzNDQxMi4xLjEuMTcxNTMzNDQ5OS42MC4wLjA.
          -->
          <ion-button (click)="submitAnswer()" onKeyDown="doSomething();" tabindex="0" >
            Save
          </ion-button>
        </ion-col>
       </ion-row>
      </div> 
    </div>
  </ion-card-content>
</ion-card>
