<ion-card>
    <ion-card-content>
            <div class="card-row">
                <div class="data-label">Trailer Reservation Notice</div>
                <div class="data-value"><span class="trailer-active">Active</span></div>
            </div>

            <div class="card-row">
                <div class="data-label">Truck #</div>
                <div class="data-value">{{ loadStatusSummary.truckNumber }}</div>
            </div>
            <div class="card-row">
                <div class="data-label">Trailer #</div>
                <div class="data-value">{{ loadStatusSummary.trailerNumber }}</div>
            </div>


            <div class="card-row">
                <div class="data-label">
                    Origin
                </div>
                <div class="data-label">
                    Destination
                </div>
            </div>
            <div class="card-row">
                <div class="data-value">{{ origin.city }}<br>{{ origin.state }}</div>
                <div class="route-icon">
                    <ion-icon name="caret-forward"></ion-icon>
                </div>
                <div class="data-value">{{ destination.city }}<br>{{ destination.state }}</div>
            </div>

            <div *ngIf="getAddress().length > 0">               
                <div class="card-row">
                    <div class="data-label">Address</div>
                </div>
                <div class="card-row">
                    <div class="data-value" [innerHTML]="getAddress()"></div>
                </div>
            </div>

            <div class="card-row">
                <div class="data-label">Driver Notes</div>
            </div>
            <div class="card-row left-align">
                <div class="data-value">{{ loadStatusSummary.driverNotes }}</div>
            </div>

            
                <ion-row class="card-row">
                    <ion-col>
                        <a class="trailer-reservation-action pointer" (click)="showTrailerReservationMap()">VIEW ON MAP</a>
                    </ion-col>
                </ion-row>
          
    </ion-card-content>
</ion-card>
