import {Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProfileService, TripStep} from '../../../../Swagger-Gen';
import {MapService} from '../../../../shared/components/map/service/map.service';
import {GeoJSONProperty} from '../../../../shared/components/map/service/geo-json.service';
import * as TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { select, Store } from '@ngrx/store';
import * as fromCurrentTrip from '../state/trip-load-infos.reducer';
import { LoadingUtility } from 'src/app/shared/utilities/LoadingUtility';
import { SubSink } from 'subsink';
import { CommonAddress } from 'src/app/Swagger-Gen-V2';
import { WernerDwpContractsLogicJobEquipment as equipment} from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobEquipment';
import { Preferences } from '@capacitor/preferences';
import { AuthService } from 'src/app/auth/auth.service';
import { takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-equipment-location-map',
  templateUrl: './equipment-location-map.component.html',
  styleUrls: ['./equipment-location-map.component.scss'],
})
export class EquipmentLocationMapComponent implements OnInit, OnDestroy {
  currentTripStep: TripStep;
  selectedPoiProperties: GeoJSONProperty = new GeoJSONProperty();
  private locationMarker: TrimbleMaps.Marker = new TrimbleMaps.Marker({ anchor: 'center' });
  @ViewChild('equipmentMap', { static: true }) mapElement: ElementRef;
  trailerLocation: CommonAddress;
  trailerLocationUpdatedDate: string;
  driverId = '';
  componentActive = true;
  trailerListDetails: Array<equipment> = null;
  approximateLocationWarning = false;
  equipmentLocationAvailable = false;
  subscriptions$ = new SubSink();
  profileService: ProfileService;
  constructor(private mapService: MapService,
    private store: Store<fromCurrentTrip.State>,
    private loadingUtility: LoadingUtility,
    private injector: Injector,
    private authService : AuthService,
    private router: Router
  ) {
    this.profileService = injector.get<ProfileService>(ProfileService);
  }

  async ngOnInit() {
    const currentTripStepString = await Preferences.get({key: 'trip-step-on-map'});
    if (currentTripStepString !== null) {
      this.currentTripStep = JSON.parse(currentTripStepString.value);

      this.driverId = await this.authService.getUserIdentitityKey();
     
      
      this.subscriptions$.sink = this.store.pipe(select(fromCurrentTrip.isTrailersLoading), 
        takeWhile(() => this.componentActive)).subscribe((isTrailerLoading: boolean) => {
            if(isTrailerLoading) {
              this.loadingUtility.showLoader('Getting Equipment Location. Please wait..');  
            }else {
              this.loadingUtility.hideLoader();
            }
        });

      this.subscriptions$.sink = this.store.pipe(select(fromCurrentTrip.getTrailers))
        .subscribe(async (TrailersListResponse: Array<equipment>) => {
          if (TrailersListResponse)
          {
            this.trailerListDetails = TrailersListResponse;
            this.mapStepEquipment(this.trailerListDetails);
          }
        });

      const mapOptions = {
        container: this.mapElement.nativeElement,
        zoom: 13
      };

      this.mapService.initMap(mapOptions).then((map: TrimbleMaps.Map) => {
        map.setStyle(TrimbleMaps.Common.Style.SATELLITE);

        this.showCurrentTripStepOnMap(map);
      });
    }
  }
  
  async cancelJobEnvelop() {
    let previousUrl = await Preferences.get({ key: 'job-page-url' });
    const previousUrlObj = JSON.parse(previousUrl.value);
    if(previousUrlObj){      
      this.router.navigate([previousUrlObj?.url],{queryParams: {page: previousUrlObj?.tab} });
    }else{
      this.router.navigate(['/loads']);
    }    
  }

  mapStepEquipment(trailerListDetails: Array<equipment>) {
    let currentTripStepEquipment = trailerListDetails.filter(trailer => trailer.number === this.currentTripStep.trailerNumber)
    if (currentTripStepEquipment) {      
      this.currentTripStep.equipments = currentTripStepEquipment;
      if (currentTripStepEquipment.length === 0 || currentTripStepEquipment[0].location === null )
      {
        const equip = { ...this.currentTripStep.equipments[0] };
        equip.location = this.currentTripStep.location;
        this.currentTripStep.equipments[0] = equip;
      }
    }
  }

  showCurrentTripStepOnMap(map: TrimbleMaps.Map) {
    if (this.currentTripStep.equipments && this.currentTripStep.equipments.length > 0
      && this.currentTripStep.equipments[0].location !== null) {
      this.trailerLocation = this.currentTripStep.equipments[0].location;
      this.trailerLocationUpdatedDate = this.trailerLocation.lastUpdatedDateTime;
      this.equipmentLocationAvailable = true;
    } else {
      // Equipment location is not available - use "Stop" locaiton for map.
      this.trailerLocation = this.currentTripStep.location;
      this.trailerLocationUpdatedDate = 'N/A';
      this.approximateLocationWarning = true;
    }

    map.on('load', () => {
      let popupLocations: TrimbleMaps.LngLatLike = { lat: null, lon: null };

      let LatLong = {
        'lat': this.trailerLocation.coordinates.latitude,
        'lon': this.trailerLocation.coordinates.longitude,
      }
      if (this.equipmentLocationAvailable === true) {
        this.locationMarker.setLngLat(LatLong).addTo(map);
        map.setCenter(LatLong);
        popupLocations["lat"] = this.trailerLocation.coordinates.latitude;
        popupLocations["lon"] = this.trailerLocation.coordinates.longitude;
      } else {
        /*
          When we get data that originates from AS400 for locaiton, the Latitude And Longitude are reversed by server
          for the purpose of dealing with an external vendor. Thus, when we take the parent Stop coordinates instead
          of equipment location coordinates, we need to flip the Longitude and Latitude values. 
          (Do this in other pages also as applicable)
        */


        this.locationMarker.setLngLat(LatLong).addTo(map);
        map.setCenter(LatLong);
        popupLocations["lat"] = this.trailerLocation.coordinates.latitude;
        popupLocations["lon"] = this.trailerLocation.coordinates.longitude;
      }

      let popupConent =
        `<b>Equipment #${this.currentTripStep.trailerNumber}</b> </br>` +
        `${this.trailerLocation.streetAddress1} </br>
       ${this.trailerLocation.city},  ${this.trailerLocation.state} ${this.trailerLocation.zip}` +
        `</br>(Updated: ${this.trailerLocationUpdatedDate})`;

      if (this.approximateLocationWarning === true) {
        popupConent += `</br><span style='color:red' class='approximate-location-warning'>Location is approximate</span>`;
      }

      // The offset parameter puts the HTML popup window above the map marker by specified number
      new TrimbleMaps.Popup({ offset: 35, closeButton: false, closeOnClick: false })
        .setLngLat(popupLocations)
        .setHTML(popupConent)
        .addTo(map);
    });
    // Resize window after map and location information is shown
    window.dispatchEvent(new Event('resize'));
  }

  ionViewDidEnter() {
    window.dispatchEvent(new Event('resize'));
  }

  async ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
