import { Action } from "@ngrx/store";
import { PayCheck } from "src/app/Swagger-Gen";

export enum PaycheckDetailActionTypes {
  GetPaycheckDetail = "Get Paycheck Detail",
  GetPaycheckDetailSuccess = "Get Past Paychecks Details Success",
  GetPaycheckDetailFail = "Get Past Paychecks Details Fail",
}

export class GetPaycheckDetail implements Action {
  readonly type = PaycheckDetailActionTypes.GetPaycheckDetail;
  driverId = "";
  paymentDate: string;
  payStubId:string;
  constructor(
    public id: string,
    public payId: string,
    public date: string    
  ) {
    this.driverId = id;
    this.payStubId = payId;
    this.paymentDate = date;
  }
}

export class GetPaycheckDetailSuccess implements Action {
  readonly type = PaycheckDetailActionTypes.GetPaycheckDetailSuccess;
  constructor(public payload: PayCheck) {
    if (this.payload != null) {
      localStorage.setItem(
        "driver-paycheck-detail",
        JSON.stringify(this.payload)
      );
    }
  }
}

export class GetPaycheckDetailFail implements Action {
  readonly type = PaycheckDetailActionTypes.GetPaycheckDetailFail;
  constructor(public payload: string) { }
}

export type PaycheckDetailActions =
  | GetPaycheckDetail
  | GetPaycheckDetailSuccess
  | GetPaycheckDetailFail;
