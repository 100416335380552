import { Action } from "@ngrx/store";
import { SafetyStats } from "src/app/Swagger-Gen-V2";
import { Preferences } from '@capacitor/preferences';

export enum SafetyStatsActionTypes {
    GetStatsView = "Get Stats View",
    GetStatsViewSuccess = "Get Stats View Success",
    GetStatsViewFail = "Get Stats View Fail",
}

export class GetStatsView implements Action {
  readonly type = SafetyStatsActionTypes.GetStatsView;
  driverId = "";
  employeeNumber;
  stakeholderId;
  constructor(
    public id: string,
    public empNumber,
    public stackId
  ) {
    this.driverId = id;
    this.employeeNumber = empNumber;
    this.stakeholderId = stackId;
  }
}

export class GetStatsViewSuccess implements Action {
  readonly type = SafetyStatsActionTypes.GetStatsViewSuccess;
  constructor(public payload: SafetyStats) {
    if (this.payload != null) {
      Preferences.set({ key : "driver-safety-stats", value :  JSON.stringify(this.payload)}
      );
    }
  }
}

export class GetStatsViewFail implements Action {
  readonly type = SafetyStatsActionTypes.GetStatsViewFail;
  constructor(public payload: string) {}
}

export type SafetyStatsViewActions = 
    | GetStatsView
    | GetStatsViewSuccess
    | GetStatsViewFail
