import { Action } from '@ngrx/store';
import { Profile } from 'src/app/Swagger-Gen-V2';
import { ProfileDivisionInfoResponse } from 'src/app/Swagger-Gen-V2/model/ProfileDivisionInfoResponse';
import { ProfileFleetManagersResponse } from 'src/app/Swagger-Gen-V2/model/ProfileFleetManagersResponse';
import { ProfileIdsResponse } from 'src/app/Swagger-Gen-V2/model/ProfileIdsResponse';
import { ProfileImageResponse } from 'src/app/Swagger-Gen-V2/model/ProfileImageResponse';
import { ProfileInfo } from 'src/app/Swagger-Gen-V2/model/ProfileInfo';
import { ProfilePermissionResponse } from 'src/app/Swagger-Gen-V2/model/ProfilePermissionResponse';

export enum UserProfileActionTypes {
    UserProfile = 'Get User Data',
    UserProfileSuccess = 'Get User Data Success',
    UserProfileFail = 'Get User Data Fail'
}

export enum UserProfileBasicInfoActionTypes {
    UserBasicInfo = 'Get User Basic Data',
    UserBasicInfoSuccess = 'Get User Basic Data Success',
    UserBasicInfoFail = 'Get User Basic Data Fail'
}

export enum UserProfileFleetManagerActionTypes {
    UserProfileFleetManager = 'Get User Fleet Manager Data',
    UserProfileFleetManagerSuccess = 'Get User Fleet Manager Data Success',
    UserProfileFleetManagerFail = 'Get User Fleet Manager Data Fail'
}

export enum UserDivisonInfoActionTypes {
    UserDivisonInfo = 'Get User Divison Info Data',
    UserDivisonInfoSuccess = 'Get User Divison Info Data Success',
    UserDivisonInfoFail = 'Get User Divison Info Data Fail'
}

export enum UserIdsActionTypes {
     UserIds = 'Get User Ids Data',
     UserIdsSuccess = 'Get User Ids Data Success',
     UserIdsFail = 'Get User Ids Data Fail'
}

export enum UserPictureActionTypes {
    UserPicture = 'Get User Picture Data',
    UserPictureSuccess = 'Get User Picture Data Success',
    UserPictureFail = 'Get User Picture Data Fail'
}

export enum UserPermissionsActionTypes {
    UserPermissions = 'Get User Permission Data',
    UserPermissionsSuccess = 'Get User Permission Data Success',
    UserPermissionsFail = 'Get User Permission Data Fail'
}

export class UserProfile implements Action {
    readonly type = UserProfileActionTypes.UserProfile;
    constructor(public identityKey: string) {}
}

export class UserProfileSuccess implements Action {
    readonly type = UserProfileActionTypes.UserProfileSuccess;
    constructor(public payload: Profile) {}
}

export class UserProfileFail implements Action {
    readonly type = UserProfileActionTypes.UserProfileFail;
    constructor(public payload: string) {}
}

export class UserProfileBasicInfo implements Action {
    readonly type = UserProfileBasicInfoActionTypes.UserBasicInfo;
    constructor(public workdayId: string) {
        console.log('Action triggered for UserProfileBasicInfo');
    }
}

export class UserProfileBasicInfoSuccess implements Action {
    readonly type = UserProfileBasicInfoActionTypes.UserBasicInfoSuccess;
    constructor(public payload: ProfileInfo) {}
}

export class UserProfileBasicInfoFail implements Action {
    readonly type = UserProfileBasicInfoActionTypes.UserBasicInfoFail;
    constructor(public payload: string) {}
}

export class UserProfileFleetManager implements Action {
    readonly type = UserProfileFleetManagerActionTypes.UserProfileFleetManager;
    constructor(public stakeHolderId: string) {}
}

export class UserProfileFleetManagerSuccess implements Action {
    readonly type = UserProfileFleetManagerActionTypes.UserProfileFleetManagerSuccess;
    constructor(public payload: ProfileFleetManagersResponse) {}
}

export class UserProfileFleetManagerFail implements Action {
    readonly type = UserProfileFleetManagerActionTypes.UserProfileFleetManagerFail;
    constructor(public payload: string) {}
}


export class UserDivisonInfo implements Action {
    readonly type = UserDivisonInfoActionTypes.UserDivisonInfo;
    constructor(public stakeHolderId: string) {
    }
}

export class UserDivisonInfoSuccess implements Action {
    readonly type = UserDivisonInfoActionTypes.UserDivisonInfoSuccess;
    constructor(public payload: ProfileDivisionInfoResponse) {}
}

export class UserDivisonInfoFail implements Action {
    readonly type = UserDivisonInfoActionTypes.UserDivisonInfoFail;
    constructor(public payload: string) {}
}

export class UserIds implements Action {
    readonly type = UserIdsActionTypes.UserIds;
}

export class UserIdsSuccess implements Action {
    readonly type = UserIdsActionTypes.UserIdsSuccess;
    constructor(public payload: ProfileIdsResponse) {}
}

export class UserIdsFail implements Action {
    readonly type = UserIdsActionTypes.UserIdsFail;
    constructor(public payload: string) {}
}


export class UserPicture implements Action {
    readonly type = UserPictureActionTypes.UserPicture;
}

export class UserPictureSuccess implements Action {
    readonly type = UserPictureActionTypes.UserPictureSuccess;
    constructor(public payload: ProfileImageResponse) {}
}

export class UserPictureFail implements Action {
    readonly type = UserPictureActionTypes.UserPictureFail;
    constructor(public payload: string) {}
}


export class UserPermissions implements Action {
    readonly type = UserPermissionsActionTypes.UserPermissions;
    constructor(public stakeHolderId: string) {
    }
}

export class UserPermissionsSuccess implements Action {
    readonly type = UserPermissionsActionTypes.UserPermissionsSuccess;
    constructor(public payload: ProfilePermissionResponse) {}
}

export class UserPermissionsFail implements Action {
    readonly type = UserPermissionsActionTypes.UserPermissionsFail;
    constructor(public payload: string) {}
}


export type UserProfileActions =
    | UserProfile
    | UserProfileSuccess
    | UserProfileFail
    | UserProfileBasicInfo
    | UserProfileBasicInfoSuccess
    | UserProfileBasicInfoFail
    | UserProfileFleetManager
    | UserProfileFleetManagerSuccess
    | UserProfileFleetManagerFail
    | UserDivisonInfo
    | UserDivisonInfoSuccess
    | UserDivisonInfoFail
    | UserIds
    | UserIdsSuccess
    | UserIdsFail
    | UserPicture
    | UserPictureSuccess
    | UserPictureFail
    | UserPermissions
    | UserPermissionsSuccess
    | UserPermissionsFail;
