<ion-card *ngIf="loadStatusSummary !== null && loadStatusSummary !== undefined">
  <ion-card-content>




    <div class="card-top">
      <div class="card-row">
        <div class="data-label">Trailer Reservation Notice</div>
        <div class="data-value"><span class="active">VALUE</span></div>
      </div>
    </div>
    <div class="card-middle">
      <div class="card-row">
        <div class="data-label">Trip ID</div>
        <div class="data-value">VALUE</div>
      </div>
      <div class="card-row">
        <div class="data-label">Truck #</div>
        <div class="data-value">VALUE</div>
      </div>
      <div class="card-row">
        <div class="data-label">Trailer #</div>
        <div class="data-value">VALUE</div>
      </div>
    </div>
      <div class="card-middle">
        <div class="card-row">
          <div class="data-label">LOCATION ICON</div>
          <div class="data-value">VALUE</div>
        </div>
      </div>
    <div class="card-bottom">
      <div class="card-row">
        <div class="data-label">Driver Notes</div>
        <div class="data-value">VALUE</div>
      </div>
    </div>




    <ion-grid>

      <ion-row>
      <ion-col size="8">
        <ion-text>
          My Load Status Summary
        </ion-text>
      </ion-col>

      <ion-col size="4">
        <ion-button
          class="slim-green-button"
          expand="full">
            {{statusDefinition}}
        </ion-button>
      </ion-col>
    </ion-row>


      <!-- <app-my-load-details [loadStatusSummary]="loadStatusSummary"></app-my-load-details> -->


      <ion-row>

        <ion-col size="6" >
          <ion-button class="gray-action-button"  (click)="viewCurrentJobOnMap()"  >
            <ion-icon name="navigate-outline"></ion-icon>
             <ion-label class="blue-action-button-label">View on map</ion-label>
          </ion-button>
        </ion-col>



        <ion-col size="6" *ngIf="loadStatusSummary !== null && loadStatusSummary !== undefined &&
          loadStatusSummary.fleetManagerPhoneNumber !== null && loadStatusSummary.fleetManagerPhoneNumber !== undefined &&
           loadStatusSummary.fleetManagerPhoneNumber.length > 0 else callbutton" >
          <a href="tel:{{loadStatusSummary.fleetManagerPhoneNumber}}">
            <ion-button class="gray-action-button" >
              <ion-icon name="call-outline"></ion-icon>
               <ion-label class="blue-action-button-label">Call Manager</ion-label>
            </ion-button>
          </a>

        </ion-col>

        <ng-template #callbutton>
          <ion-col size="6" >

            <ion-button color="primary" class="blue-action-button" (click)="showInvalidPhoneNumberMessage()" >
              <ion-icon name="call-outline"></ion-icon>
               <ion-label class="blue-action-button-label">Call Transportation Manager</ion-label>
            </ion-button>


          </ion-col>
        </ng-template>


      </ion-row>
      <ion-row *ngIf="!showCompletePaperWorkButton">
        <ion-col size="12">
          <ion-button color="primary" class="blue-action-button" (click)="completePaperWork()" >
            <ion-icon name="documents-outline"></ion-icon>
             <ion-label style="font-size: 12px !important;">Complete Paperwork</ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>


    <div *ngIf="showBottomActionBar">
      <hr class="gray-separator-line" />

      <ion-grid class="bottom-action-button-grid">
        <ion-row>
          <ion-col size="2">

          </ion-col>
          <ion-col size="8">
            <ion-button color="primary" (click)="startPaperwork()" class="blue-action-button">
               <ion-label class="blue-action-button-label">Start your paperwork</ion-label>
            </ion-button>
          </ion-col>
          <ion-col size="2">

          </ion-col>
        </ion-row>
      </ion-grid>
    </div>


  </ion-card-content>
</ion-card>



<ion-card *ngIf="loadStatusSummary === null || loadStatusSummary === undefined">

  <ion-card-content>

    <div class="no-trips-panel-content"  >
      <ion-text style="text-align: center;" >
        <h1 class="no-trips-assigned-label">
          You are not currently on a trip
        </h1>

      </ion-text>
    </div>



  </ion-card-content>


</ion-card>
