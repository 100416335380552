import {Component, OnInit, Input} from "@angular/core";
import {Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import * as fromNews from "../../../../../modules/news/state/news.reducer";
import {NewsArticle} from "../../../../../Swagger-Gen";
import { Preferences } from '@capacitor/preferences';

@Component({
    selector: "carousel-news-card",
    templateUrl: "./carousel-news-card.component.html",
    styleUrls: ["./carousel-news-card.component.scss"],
})
export class CarouselNewsCardComponent implements OnInit {
    @Input('preview') preview = false;
    imageUrl: string;
    article: NewsArticle;
    LOCAL_NEWS_KEY = 'localNewsKey';

    constructor(private newsStore: Store<fromNews.State>, private route: Router) {

    }

    selectedArticle() {
        if (!this.preview) {
            this.route.navigate(["/news/article", this.article]);
        }
    }

    async ngOnInit() {
        const news = await Preferences.get({key: this.LOCAL_NEWS_KEY});
        const cachedNews = JSON.parse(news.value);

        if (cachedNews != null) {
            this.imageUrl = cachedNews.teaserImageUrl;
            this.article = cachedNews;
        }
        this.newsStore.pipe(select(fromNews.getNewsItems)).subscribe(
            async (latestNews) => {

                if (latestNews && latestNews.length > 0) {
                    const newsObject = latestNews[0];
                    this.imageUrl = newsObject.teaserImageUrl;
                    this.article = newsObject;
                }
            },
            (error) => {
            }
        );
    }
}
