import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { MapService } from './service/map.service';
import * as TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { GeoJSONService, GeoJSONProperty } from './service/geo-json.service';
import { ModalController, IonRouterOutlet, ToastController, Platform } from '@ionic/angular';
import { ImportantDialogComponent } from '../important-dialog/important-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';
import { LoadingUtility } from '../../utilities/LoadingUtility';
import { BottomModalComponent } from "../bottom-modal/bottom-modal.component";
import { RequestService, PointOfInterestResponse } from 'src/app/Swagger-Gen-V2';
import { AuthService } from 'src/app/auth/auth.service';
import { MapFeedback } from 'src/app/Swagger-Gen-V2/model/mapFeedback';
import { GeneralFeedbackType, MapFeedbackType } from 'src/app/Swagger-Gen-V2/model/mapFeedbackType';
@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit, OnDestroy, AfterViewInit {
  mapPopup: any;
  poi: PointOfInterestResponse = {};
  identityKey = '';
  @Input() poiFilter = [];
  map;
  BottomSheetState: Boolean = false;
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  @Input('showReportMissingLocationPin') set showReportMissingLocationPin(showPin: boolean) {
    // do not remove this, This is need for sonarcloud
  }
  @Input() userInteractionEnabled = false;
  @Input() showPOI = false;
  @Input() showLocateButton = true;
  @Input() showSearchHereButton = false;
  @Input() showPOIModal = false;
  @Input() shouldLocateUser = false;
  @Output() closePOIModal = new EventEmitter();
  private locationMarker: TrimbleMaps.Marker = new TrimbleMaps.Marker({
    anchor: 'center'
  });

  selectedPoiProperties: GeoJSONProperty = new GeoJSONProperty();
  selectedPOIImage = '';
  isLoading = false;
  onMoveTime = 0;
  initialLoad = true;
  showFeedbackModal = false;
  feedbackType: MapFeedbackType;
  generalFeedback: MapFeedback;
  subscriptions$ = new SubSink();
  mapFilterPoiKey = 'map-filter-poi-';
  longitude = -95.928291; //Omaha
  latitude = 41.259892;
  isSatelliteEnabled:boolean = false;
  constructor(
    private mapService: MapService,
    public modalController: ModalController,
    private geoJSON: GeoJSONService,
    private routerOutlet: IonRouterOutlet,
    private activatedRoute: ActivatedRoute,
    private requestService: RequestService,
    private loadingUtility: LoadingUtility,
    private toastController: ToastController,
    private platform: Platform,
    private authService : AuthService
  ) {
    this.subscriptions$.sink = this.platform.keyboardDidHide.subscribe(() => this.keyboardChanged());
    this.subscriptions$.sink = this.platform.keyboardDidShow.subscribe(() => this.keyboardChanged());
  }

  async keyboardChanged() {
    // when feedback window opens, keyboard is activated. On close, this event resizes the map.
    window.dispatchEvent(new Event('resize'));
  }

  async ngOnInit() {
    this.loadingUtility.hideLoader();
    this.subscriptions$.sink = this.activatedRoute.params.subscribe(() => {
      if (this.shouldLocateUser) {
        this.locateUser(9);
      }
    });

    this.identityKey = await this.authService.getUserIdentitityKey();

    // Check for previous lat/lng
    const mapOptions = {
      container: this.mapElement.nativeElement,
      center: [this.longitude, this.latitude ]
    };

    this.mapService.initMap(mapOptions).then((map: TrimbleMaps.Map) => {
      this.showSearchHereButton = false;
      this.map = map;
      if (this.platform.is("desktop") || this.platform.is("mobileweb")) {
        setTimeout(function () {
          let mapCanvas = map.getCanvas()
          mapCanvas.style.height = mapOptions.container.offsetHeight + "px";
          mapCanvas.style.width = mapOptions.container.offsetWidth + "px";
          map.resize();
        }, 1000);
        map.addControl(new TrimbleMaps.NavigationControl());
      }
      map.on('load', () => {
        this.loadPOI();
      });

      map.on('moveend', (event) => {
        // Check time stamp on 'moveend' event to determine
        // if the previous move was too recent to make another
        const newMoveTime = event.originalEvent ? event.originalEvent.timeStamp : null;
        const time = 750; // milliseconds
        const moveTimeThreshold = this.onMoveTime + time;
        let makeNewRequest = false;

        if (newMoveTime > moveTimeThreshold) {
          this.onMoveTime = newMoveTime;
          makeNewRequest = true;
        }
        if (this.initialLoad) {
          this.initialLoad = false;
          return;
        }
        this.showSearchHereButton = true;
      });
    });
  }

  toggle_map() {
    this.mapService.toggleStyle();
    this.isSatelliteEnabled = !this.isSatelliteEnabled;
    this.loadPOI();
  }

  loadPOI() {
    this.isLoading = true;
    this.showSearchHereButton = false;

    this.mapService.getLocalPoiWith(this.identityKey).then(async (localPOI) => {
        if (localPOI) {
          this.isLoading = false;
          this.poi = localPOI;
          this.addOrRemoveAllSourceToPOIMAP();
        } else {
          const toast = await this.toastController.create({
            message: 'Could not find POIs in this area',
            color: 'warning',
            duration: 3000,
            cssClass: 'ion-text-center'
          });
          toast.present();
          window.dispatchEvent(new Event('resize'));
          this.isLoading = false;
        }
    });
  }

  closeDrawer(target) {
    target.dispatchEvent(new Event('closeDrawer', { bubbles: true }));
  }

  displayFeedbackModal() {
    this.showLocateButton = false;
    this.showFeedbackModal = true;
    this.feedbackType = GeneralFeedbackType.MapPOI;
    this.BottomSheetState = false;
  }

  cancelFeedbackSubmission() {
    this.showFeedbackModal = false;
    this.showLocateButton = true;
    window.dispatchEvent(new Event('resize'));
  }

  async submitFeedback(message: string, selectedPoiProperties: GeoJSONProperty) {
    const locationAddress = this.getAddressFromGeoJSONProperty(selectedPoiProperties.location);
    const locationDetails =   '<br><br>' + 'Location Name: ' + selectedPoiProperties.name  + '<br>' +
                    'Location Address: ' + locationAddress  + '<br>' +
                     'Location Coordinates: ' + selectedPoiProperties.location.coordinates.latitude + ',' +
                      selectedPoiProperties.location.coordinates.longitude;

    message = message + locationDetails;
    this.loadingUtility.showLoader('Sending Feedback.. Please wait');
    const genFeedback: MapFeedback = { message, feedbackType: GeneralFeedbackType.MapPOI };
    this.subscriptions$.sink = this.requestService
      .mapFeedbackPost(genFeedback)
      .subscribe(        
        async (response) => {
        this.loadingUtility.hideLoader();
          this.showFeedbackModal = false;
          this.showLocateButton = true;
          const toast = await this.toastController.create({
            message: 'Your Feedback has been sent. Thank you',
            color: 'primary',
            duration: 3000,
            cssClass: 'ion-text-center'
          });
          toast.present();
          window.dispatchEvent(new Event('resize'));
      },
      async (error) => {
        this.BottomSheetState = false;
        const toast = await this.toastController.create({
          message: 'An error occurred while submitting your Feedback. Please try again',
          color: 'primary',
          duration: 3000,
          cssClass: 'ion-text-center'
        });
        toast.present();
        setTimeout(() => {
          this.loadingUtility.hideLoader();
        }, 3000);
      }      
    );
  }

  addOrRemoveAllSourceToPOIMAP() {
    this.poiFilter.forEach((item) => {
      this.addDataSourceTOPOIMap(item, item.isChecked, this.poi.proximityZipCode);
    });
  }

  addDataSourceTOPOIMap(poi, isFilterActive?: boolean, zipcode?: string) {
    // assign this to self so we can call the bottom drawer function from within the map.on event()
    const self = this;
    const map = this.mapService.getMap();
    map.off('click', 'poi-' + poi.name + '-points', function(evt) {
      if (this.mapPopup) {
        this.mapPopup.remove();
      }
      this.BottomSheetState = false;
      self.openBottomSheet(evt.features[0].properties, poi.icon);
    });
    // isFilterActive toggles between showing and hiding the POI layer
    if (isFilterActive) {
      
      // Creates a new source for the map using POI name and current zipcode if it doesn't already exist
      // It will skip this step when using the filter because the source will already exist
      if (!map.getSource(`${poi.name}-${zipcode}`)) {
        const source = this.geoJSON.convertPOIs(this.poi[poi.name], `${poi.name}-${zipcode}`);
        source.cluster = false;
        map.addSource(`${poi.name}-${zipcode}`, source);
      }
      // Load pin images
      this.mapService.map.loadImage('../../../../assets/Images/pins/' + poi.icon + '.png', (error, image) => {
        if (error) {
          throw error;
        }

        if (poi.isChecked === true) {
          // Remove layer if it has already been added before by checking for image
          // Otherwise, add image
          if (map.hasImage('poi-' + poi.name + '-marker')) {
            map.removeLayer('poi-' + poi.name + '-points');
          } else {
            map.addImage('poi-' + poi.name + '-marker', image);
          }

          // Add the layer to th e map with the points, source, and image
          map.addLayer({
            id: 'poi-' + poi.name + '-points',
            type: 'symbol',
            source: `${poi.name}-${zipcode}`,
            layout: {
              'icon-image': 'poi-' + poi.name + '-marker',
              'icon-size': 0.2,
              'icon-allow-overlap': true
            }
          });
        }
        // Listen for clicks on the hqPoints layer
        map.on('click', 'poi-' + poi.name + '-points', function (evt) { 
          if (this.mapPopup) {
            this.mapPopup.remove();
          }
          this.BottomSheetState = false;
          self.openBottomSheet(evt.features[0].properties, poi.icon);
        });
      });
    } else if (this.mapService.hasImage('poi-' + poi.name + '-marker')) {
      // Remove layer because the filter is inactive
      this.mapService.removeImage('poi-' + poi.name + '-marker');
      this.mapService.removeLayer('poi-' + poi.name + '-points');
    }
  }

  async openBottomSheet(properties, name: string) {
    if (typeof properties.location === 'string') {
      properties.location = JSON.parse(properties.location);
    }
    const inferredPOI = name;
    this.selectedPoiProperties = properties;
    this.selectedPOIImage = `../../../../assets/Images/poi/${inferredPOI}.png`;
    this.selectedPoiProperties.title = name;

    // show modal , if it's not opened
    let customClass : string;
    if (this.selectedPoiProperties.title === "fuelStops") {
      customClass = "fuel-station" ;
    } else if (this.selectedPoiProperties.title === "restAreas") {
      customClass = "rest-area" ;
    } else if (this.selectedPoiProperties.title === "truckWashes") {
      customClass = "truck-washes" ;
    } else {
      customClass = "other-station" ;
    }
    if (this.BottomSheetState === false) {
      this.BottomSheetState = true;
      const options = {
        component: BottomModalComponent,
        cssClass: "map-modal poi-station " + customClass,
        swipeToClose: true, //ios only
        componentProps: {
          selectedPoiProperties: this.selectedPoiProperties,
          selectedPOIImage: this.selectedPOIImage,
        },
      };
      const modal = await this.modalController.create(options);
      await modal.present();
      // trigger when modal closes
      modal.onDidDismiss().then((data) => {
        this.BottomSheetState = false;
        if(data && data.data && data.data.data) {
          let showFeedbackModal = data.data.data.showFeedback;
          if (showFeedbackModal == true) {
            this.displayFeedbackModal();
          }
        }
      });
    }
  }

  async openWarningModal() {
    const fullAddress = this.getAddressFromGeoJSONProperty(this.selectedPoiProperties.location);

    const modal = await this.modalController.create({
      component: ImportantDialogComponent,
      componentProps: {
        address: fullAddress
      },
      cssClass: 'ion-custom-model',
      presentingElement: this.routerOutlet.nativeEl
    });
    modal.onDidDismiss().then(() => { });
    return await modal.present();
  }

  getAddressFromGeoJSONProperty(address) {
    let fullAddress: string = '';
    if (address.streetAddress1) {
      fullAddress = address.streetAddress1 + ' ';
    }
    if (address.streetAddress2) {
      fullAddress = fullAddress + address.streetAddress2 + ' ';
    }
    if (address.city) {
      fullAddress = fullAddress + address.city + ', ';
    }
    if (address.state) {
      fullAddress = fullAddress + address.state;
    }
    if (address.zip) {
      fullAddress = fullAddress + ' ' + address.zip;
    }
    return fullAddress;
  }

  closeBottomSheet() {
    this.BottomSheetState = false;
  }

  locateUser(zoom: number = 12) {
    this.mapService.locateUser(zoom).then((val) => {
      if (val !== null && val !== undefined) {
        this.locationMarker.setLngLat(val);
        this.locationMarker.addTo(this.mapService.map);
        this.latitude = val.lat;
        this.longitude = val.lng;
      } 
    });
  }

  selectedPOICarouselItem(event) {
    
    this.poiFilter.forEach((item, i) => {
      if (item.name === event.name) {
        this.poiFilter[i].isChecked = event.isChecked;
      }
    });
    // set storage of the filters.
    localStorage.removeItem(this.mapFilterPoiKey + this.identityKey);
    localStorage.setItem(this.mapFilterPoiKey + this.identityKey, JSON.stringify(this.poiFilter));
    this.addOrRemoveAllSourceToPOIMAP();
  }

  closeModal() {
    this.showPOIModal = !this.showPOIModal;
    this.closePOIModal.emit(this.showPOIModal);
  }

  ngAfterViewInit() {
    this.loadingUtility.hideLoader();
  }

  ionViewDidEnter() {
    this.loadingUtility.hideLoader();
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}