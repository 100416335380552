import {
  Component,
  Input,
  ViewChild,
  OnInit,
  ElementRef,
  Renderer2,
  AfterViewInit
} from '@angular/core';

import {DomController, Platform} from '@ionic/angular';

@Component({
  selector: 'app-expandable-card',
  templateUrl: './expandable-card.component.html',
  styleUrls: ['./expandable-card.component.scss'],
})
export class ExpandableCardComponent implements AfterViewInit {

  arrowClassName = 'arrow-down';
  connectorMaxHeight = 786;
  connectorMinHeight = 100;
  nodeColor = '';
  edgeColor = '';

  @Input('expanded') expanded = false;
  @Input('title') title = '';
  @Input('firstSubTitle') firstSubTitle = '';
  @Input('secondSubTitle') secondSubTitle = '';
  @Input('expandHeight') expandHeight = '150px';
  @Input('showConnectorDot') showConnectorDot = false;
  @Input('showConnectorLine') showConnectorLine = false;
  @Input('connectorsColor') connectorsColor = '';
  @Input('isLast') isLast = false;
  @Input('showExpandableArrow') showExpandableArrow = true;
  @Input('cardType') cardType = '';
  @Input('allowCardToExpand') allowCardToExpand = true;

  @ViewChild('card', { read: ElementRef }) card: ElementRef;
  @ViewChild('expandWrapper', { read: ElementRef }) expandWrapper: ElementRef;
  @ViewChild('connectorLine', { read: ElementRef }) connectorLine: ElementRef;



  // Instantiates an expandable card component
  constructor(public renderer: Renderer2, public domController: DomController, public platform: Platform) { }


  // Handler called after the component has been initialized
  async ngAfterViewInit() {
    await this.card.nativeElement.componentOnReady();
    this.domController.read(() => {
      if (this.connectorLine !== undefined && this.card !== undefined) {
        this.renderer.setStyle(this.connectorLine.nativeElement, 'min-height',
            '' + this.card.nativeElement.offsetHeight + 'px');

        let maxHeight = 0;

        if (this.platform.is("android")) {
          maxHeight = this.card.nativeElement.offsetHeight;
        }
        if (this.platform.is("ios")) {
          maxHeight = this.card.nativeElement.offsetHeight + 20;
        } else {
          maxHeight = this.card.nativeElement.offsetHeight;
        }

        this.connectorMaxHeight = maxHeight;



        this.renderer.setStyle(this.connectorLine.nativeElement, 'max-height',
            '' + maxHeight + 'px');
      }});




    if (this.expandWrapper !== null && this.expandWrapper !== undefined) {
      this.renderer.setStyle(this.expandWrapper.nativeElement, 'max-height', this.expandHeight);
      this.changeArrowButton(this.expanded);
    }
  }
  // Handler called when the user clicks on the arrow button
  arrowButtonClickHandler() {

    if(this.allowCardToExpand === true) {
      this.expanded = !this.expanded;
      /*if (this.expanded === true) {
        this.setConnectorsDimensions(this.cardType);
      }*/
      this.changeArrowButton(this.expanded);
    }

  }

  // This function changes the image that is shown on the arrow button based on if the div is expanded or not
  changeArrowButton(expanded: boolean) {
    if (expanded === true) {
      this.arrowClassName = 'chevron-down';
      if (this.connectorLine !== null && this.connectorLine !== undefined) {
        this.renderer.setStyle(this.connectorLine.nativeElement, 'height', this.connectorMaxHeight + 'px');
      }

    } else {
      this.arrowClassName = 'chevron-forward';
      if (this.connectorLine !== null && this.connectorLine !== undefined) {
        this.renderer.setStyle(this.connectorLine.nativeElement, 'height', this.connectorMinHeight + 'px');
      }
    }
  }

  // Computes the dimensions that the connector line between two cards should have
  getConnectorDimensions(cardType: string): number {
    let connectorDimensions: number = 180;

    if(cardType !== null && cardType.trim().length > 0) {
      switch(cardType.trim().toLowerCase()) {
        case 'previous-step-card-summary':
          connectorDimensions = this.getConnectorDimensionsForPreviousStepCardSummary();
          break;
        case 'current-step-card-full':
          connectorDimensions = this.getConnectorDimensionsForCurrentStepCardFullSized();
          break;
      }
    }

    return(connectorDimensions);
  }

  // retrieves the connector dimensions for the previous step cards
  getConnectorDimensionsForPreviousStepCardSummary(): number {
    let connectorDimensions: number = 180;
    const screenWidth = window.innerWidth;

    if(screenWidth <= 375) {
      connectorDimensions = 242;
    }
    if(screenWidth > 375 && screenWidth <= 412) {
      connectorDimensions = 180;
    }
    if(screenWidth > 412 && screenWidth <= 476) {
      connectorDimensions = 242;
    } else if(screenWidth > 476) {
      connectorDimensions = 180;
    }


    return(connectorDimensions);
  }

  // retrieves the connector dimensions for the full sized version of the current step card
  getConnectorDimensionsForCurrentStepCardFullSized(): number {
    let connectorDimensions: number = 180;

    return(connectorDimensions);
  }

  // This function sets the dimensions of the connectors based on the screen size
  setConnectorsDimensions(cardType: string) {
    this.connectorMaxHeight = this.getConnectorDimensions(cardType);
    this.expandHeight = this.connectorMaxHeight + 'px';
  }





}
