import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EdgeCard } from 'src/app/shared/models/edge-card';

@Component({
  selector: 'modal-card',
  templateUrl: './modal-card.component.html',
  styleUrls: ['./modal-card.component.scss'],
})
export class ModalCardComponent implements OnInit {

  @Input('card') card: EdgeCard;
  @Output('addCard') addCard = new EventEmitter();
  imageSrc: string;

  constructor() { }

  ngOnInit() { 
    this.imageSrc = "assets/Images/" + this.card.icon + ".png";
  }

  add(card) {
    this.addCard.emit(card);
  }
}
