import {Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Coordinates, ProfileService, Trip, TripStep} from '../../../../Swagger-Gen';
import {MapService} from '../../../../shared/components/map/service/map.service';
import {GeoJSONProperty} from '../../../../shared/components/map/service/geo-json.service';
import * as TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { select, Store } from '@ngrx/store';
import * as fromCurrentTrip from '../../../../modules/loads/trip-load-infos/state/trip-load-infos.reducer';
import { takeWhile } from 'rxjs/operators';
import { LoadingUtility } from 'src/app/shared/utilities/LoadingUtility';
import { SubSink } from 'subsink';
import { WernerDwpContractsLogicJobTrailerReservation as trailerReservation } from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobTrailerReservation';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'trailer-reservation-notice-map',
  templateUrl: './trailer-reservation-notice-map.component.html',
  styleUrls: ['./trailer-reservation-notice-map.component.scss'],
})
export class TrailerReservationNoticeMapComponent implements OnInit, OnDestroy {

  currentTripStep: TripStep;
  selectedPoiProperties: GeoJSONProperty = new GeoJSONProperty();
  private trailerMarker: TrimbleMaps.Marker = new TrimbleMaps.Marker({anchor: 'center'});
  private gateMarker: TrimbleMaps.Marker = new TrimbleMaps.Marker({anchor: 'center'});
  @ViewChild('trailerReservationNoticeMap', { static: true }) mapElement: ElementRef;
  trailerCoordinates: Coordinates;
  gateCoordinates: Coordinates;
  trailerLocationUpdatedDate: string;
  driverId = '';
  componentActive = true;
  approximateLocationWarning = false;
  subscriptions$ = new SubSink();
  constructor(private mapService: MapService,
              private store: Store<fromCurrentTrip.State>,
              private loadingUtility: LoadingUtility,
              private injector: Injector,
              private authService : AuthService
              ) {}

  async ngOnInit() {
    
    this.driverId = await this.authService.getUserIdentitityKey();

    this.subscriptions$.sink = this.store.pipe(select(fromCurrentTrip.isTrailerReservationLoading), 
    takeWhile(() => this.componentActive)).subscribe((isLoading: boolean) => {
        if(isLoading) {
          this.loadingUtility.showLoader('Getting Trailer Reservation Notice. Please wait..');
        } else {
          this.loadingUtility.hideLoader();
        }
    });

    this.subscriptions$.sink = this.store.pipe(select(fromCurrentTrip.getTrailerReservation),
      takeWhile(() => this.componentActive)).subscribe(async (trailerReservation: trailerReservation) => {
        if (trailerReservation !== null) {
          this.trailerCoordinates = trailerReservation.trailerCoordinates;
          this.gateCoordinates = trailerReservation.gateCoordinates;
        }
      });

    const mapOptions = {
      container: this.mapElement.nativeElement,
      zoom: 15
    };

    this.mapService.initMap(mapOptions).then((map: TrimbleMaps.Map) => {
      map.setStyle(TrimbleMaps.Common.Style.SATELLITE);
      this.showTrailerReservationNoticeOnMap(map);
      // this code is for first time user install the app, map not loaded correctly
      setTimeout(() => {
        map.resize();
      },1000);
    });
  }

  showTrailerReservationNoticeOnMap(map: TrimbleMaps.Map) {
    map.on('load', () => {
    this.trailerMarker.setLngLat([this.trailerCoordinates.longitude, this.trailerCoordinates.latitude]).addTo(map);
    this.gateMarker.setLngLat([this.gateCoordinates.longitude, this.gateCoordinates.latitude]).addTo(map);

    const averageLongitude = (this.trailerCoordinates.longitude + this.gateCoordinates.longitude) / 2;
    const averageLatitude = (this.trailerCoordinates.latitude + this.gateCoordinates.latitude) / 2;
    map.setCenter([averageLongitude, averageLatitude]);

      let trailerCoordinatesArray: TrimbleMaps.LngLatLike= {lat:null,lng:null};
      // Please note: Latitude and Longitudes are reversed. So keep it this way. It works.
      trailerCoordinatesArray["lat"] = this.trailerCoordinates.latitude;
      trailerCoordinatesArray["lng"] = this.trailerCoordinates.longitude;
    const trailerPopupConent =
    `
    <ion-row>
    <ion-col size="4">
      <img id="image-icon" src="../../../../../assets/Images/icTrailer.png" alt="Trailer">
    </ion-col>
    <ion-col size="8">
      <span id="image-description">
        Trailer
      </span>
    </ion-col>
  </ion-row>
    `;
    // The offset parameter puts the HTML popup window above the map marker by specified number
    new TrimbleMaps.Popup({offset: 35, closeButton: false, closeOnClick: false})
        .setLngLat(trailerCoordinatesArray)
        .setHTML(trailerPopupConent)
        .addTo(map);

      let gateCoordinatesArray: TrimbleMaps.LngLatLike = {lat:null,lon:null};
    // Please note: Latitude and Longitudes are reversed. So keep it this way. It works.
    gateCoordinatesArray["lat"] = this.gateCoordinates.latitude;
    gateCoordinatesArray["lon"] = this.gateCoordinates.longitude;

    const gatePopupContent =
    `
      <ion-row>
        <ion-col size="4">
          <img id="image-icon" src="../../../../../assets/Images/guardShack.png" alt="Gate">
        </ion-col>
        <ion-col size="8">
          <span id="image-description">
            Gate
          </span>
         </ion-col>
      </ion-row>
    `;

    // The offset parameter puts the HTML popup window above the map marker by specified number
    new TrimbleMaps.Popup({offset: 35, closeButton: false, closeOnClick: false})
            .setLngLat(gateCoordinatesArray)
            .setHTML(gatePopupContent)
            .addTo(map);
    });
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
