import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromLoadSelectionReducer from "./state/load-selection.reducer";
import * as loadSelectionActions from "./state/load-selection.actions";
import {
  Recommendedload,
} from 'src/app/Swagger-Gen';
import {AnalyticsService} from "../../../shared/services/analytics.service";
import { TelemetryUtility } from 'src/app/shared/utilities/telemetry-utilities';
import { TelemetryConstants } from 'src/app/shared/utilities/telemetry-constants';
import { JobLoad } from 'src/app/Swagger-Gen-V2/model/jobLoad';
import { Preferences } from '@capacitor/preferences';
import { DispatchService } from 'src/app/shared/services/dispatchers/dispatch.service';

@Component({
  selector: 'app-load-selection',
  templateUrl: './load-selection.component.html',
  styleUrls: ['./load-selection.component.scss'],
})
export class LoadSelectionComponent implements OnInit, OnDestroy {
  subscription: Array<Subscription> = [];
  stackholderId: number;
  filteredRecomendedLoads: Array<JobLoad> = [];

  constructor(private loadSelectionStore: Store<fromLoadSelectionReducer.State>, private router: Router,
              private analytics: AnalyticsService,
              private telemetryUtility: TelemetryUtility, private dispatchService:DispatchService) { }

  async ngOnInit() {
    this.stackholderId = await this.dispatchService.getStackholderId();
    this.loadSelectionStore.dispatch(new loadSelectionActions.GetLoadSelectionList(this.stackholderId));

    this.subscription.push(this.loadSelectionStore
      .pipe(select(fromLoadSelectionReducer.getJobSelectionList))
      .subscribe((jobList) => {
        let recomendedLoads = jobList || [];
        recomendedLoads.forEach(r => {

          if (r && r.loadStops && r.loadStops.length > 3) {
            let loadStops = JSON.parse(JSON.stringify(r.loadStops));
            loadStops= loadStops.slice(1,loadStops.length -1);
            loadStops.sort((a, b) => b.openStamp - a.openStamp);
            let index = 1;
            loadStops.forEach(ls => {
              r.loadStops[index] = ls;
              index++;
            });
          }
        });
        this.filteredRecomendedLoads = recomendedLoads;
      }));
     
  }

  async goBack() {
    let previousUrl = await Preferences.get({ key: 'job-page-url' });
    const previousUrlObj = JSON.parse(previousUrl.value);
    if(previousUrlObj){      
      this.router.navigate([previousUrlObj?.url],{queryParams: {page: previousUrlObj?.tab} });
    }else{
      this.router.navigate(['/loads']);
    }    
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.forEach(s => {
        s.unsubscribe();
      });
    }
  }

  showDetails(rl: Recommendedload) {

    this.analytics.trackEvent('User', 'Clicked A Job On Job Search Screen',
        'Clicked-A-Job-On-Job-Search-Screen');

    this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_JOB_SEARCH);
    localStorage.setItem('load-details', JSON.stringify(rl));
    this.router.navigate(['/loads/load-details']);
  }


  
 
}