import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Store, select} from '@ngrx/store';
import * as fromPreAssignedTripCard from './state/pre-assigned-trip-card-reducer';
import {Observable} from 'rxjs';
import {takeWhile} from 'rxjs/operators';
import {LoadingUtility} from "../../../shared/utilities/LoadingUtility";
import {AnalyticsService} from "../../../shared/services/analytics.service";
import { Preferences } from '@capacitor/preferences';
import { Platform } from '@ionic/angular';
import { JobTrip } from 'src/app/Swagger-Gen-V2/model/jobTrip';
import * as tripActions from './state/pre-assigned-trip-card.actions';
import { SubSink } from 'subsink';
import { AuthService } from 'src/app/auth/auth.service';
import { TelemetryUtility } from 'src/app/shared/utilities/telemetry-utilities';
import { TelemetryConstants } from 'src/app/shared/utilities/telemetry-constants';
import { ProfileInfoService } from 'src/app/modules/profile-v2/service/profileInfo.service';
import { ProfileUtility } from 'src/app/shared/utilities/profile-utility';
import { Profile } from 'src/app/Swagger-Gen-V2';
import { DispatchService } from 'src/app/shared/services/dispatchers/dispatch.service';
@Component({
  selector: 'app-pre-assigned-trip-card',
  templateUrl: './pre-assigned-trip-card.component.html',
  styleUrls: ['./pre-assigned-trip-card.component.scss'],
})
export class PreAssignedTripCardComponent implements OnInit, OnDestroy {
  tripId = '';
  customerName = '';
  location = '';
  arrivalLocation: string;
  pickUpDate = '';
  componentActive = true;

  preAssignedTripCard: Observable<any>;
  errorMessage$: Observable<string>;
  preAssignedTrip: Array<JobTrip> = null;

  badgeMessage = 'Respond via your EDGE Connect tablet as details of your next job may change.'; 
  badgeIcon = 'alert-circle-outline';
  badgeBackgroundColor = '#E05353';
  badgeTextColor = '#FFFFFF';
  fleetManagerPhoneNumber = '';
  isWebApp: boolean = false;
  @Input('driverId') driverId = '';
  @Input('loadTrip') loadTrip: boolean = false;
  @Output('hideIonRefresher') hideIonRefresher = new EventEmitter;
  LOCAL_PREASSIGNED_TRIP_KEY = 'local-preassigned-trip-key';
  LOCAL_PREASSIGNED_TRIP_DRIVER_KEY = 'local-preassigned-trip-driver-key';
  subscriptions$ = new SubSink();
  employeeNumber : string;
  userInfo : Profile;

  constructor(private store: Store<fromPreAssignedTripCard.State>, private loadingUtility: LoadingUtility, private analytics: AnalyticsService, platform : Platform, private authService : AuthService,private telemetryUtility:TelemetryUtility, private profileInfoService: ProfileInfoService, private profileUtility: ProfileUtility, private dispatchService: DispatchService) {
    this.isWebApp = (platform.is("desktop") || platform.is("mobileweb"));
  }

  ngOnChanges(changes: SimpleChanges) {
    const loadTripChange = changes['loadTrip'];
    if (loadTripChange && loadTripChange.currentValue) {
      this.store.dispatch(new tripActions.GetPreAssignedTrip(this.driverId,this.employeeNumber));
    }
  }

  async ngOnInit() {
    if(this.loadingUtility.loader === null)
      await this.loadingUtility.showLoader('Loading preassigned jobs...');
    setTimeout(()=> {
      this.loadingUtility.hideLoader();
    }, 5000);
    this.loadPreassignedTrip();
  }

  async loadPreassignedTrip() {
    this.driverId = await this.authService.getUserIdentitityKey();
    await this.getProfileInformation();
    this.loadPreAssignedTripFromLocalStorage();
    this.preAssignedTripCard = this.store.pipe(select(fromPreAssignedTripCard.getPreAssignedTripCard)) as Observable<any>;
    this.subscriptions$.sink = this.store.pipe(select(fromPreAssignedTripCard.getError)).subscribe(() => {
      this.hideIonRefresher.emit(true);
      this.loadingUtility.hideLoader();
    });

    this.subscriptions$.sink = this.store.pipe(select(fromPreAssignedTripCard.getPreAssignedTripCard),
        takeWhile(() => this.componentActive)).subscribe(
        preAssignedTrip => {
            this.loadingUtility.hideLoader();
            this.hideIonRefresher.emit(true);
            if(preAssignedTrip){
              Preferences.remove({key: this.LOCAL_PREASSIGNED_TRIP_KEY});
              Preferences.remove({key: this.LOCAL_PREASSIGNED_TRIP_DRIVER_KEY});
              Preferences.set({key: this.LOCAL_PREASSIGNED_TRIP_KEY, value: JSON.stringify(preAssignedTrip)});
              Preferences.set({key: this.LOCAL_PREASSIGNED_TRIP_DRIVER_KEY, value: this.driverId});
              this.preAssignedTrip = preAssignedTrip;
            }else{
              this.store.dispatch(new tripActions.GetPreAssignedTrip(this.driverId,this.employeeNumber));
            }
        }
    );
  }
   
  isValidDate(targetDate): boolean {
    if (targetDate) {
        if (isNaN(new Date(targetDate).getTime())) {
            return false;
        } else {
            return (new Date(targetDate).getTime()) > 0;
        }
    } else {
        return false;
    }
  }


    async getProfileInformation () {
      this.userInfo = await this.profileInfoService.getUserProfileInfo();
      this.employeeNumber = await this.dispatchService.getEmployeeNumber();
      this.fleetManagerPhoneNumber = this.profileUtility.getFleetManagerPhoneNumber(this.userInfo);
    }

    async loadPreAssignedTripFromLocalStorage() {
        const localDriverId = await Preferences.get({key: this.LOCAL_PREASSIGNED_TRIP_DRIVER_KEY});
        const cachedDriverId = localDriverId.value;
        
        // We only pull from the cached data if it belongs to the current driver. This is an edge case but need to
        // do it for the sue case when we have user with two or more logins
        if(cachedDriverId !== null && cachedDriverId !== undefined && cachedDriverId.length > 0 &&
            cachedDriverId === this.driverId) {

            const localPreAssignedTrip = await Preferences.get({key: this.LOCAL_PREASSIGNED_TRIP_KEY});
            const cachedPreAssignedTrip = JSON.parse(localPreAssignedTrip.value) as Array<JobTrip>;

            if (cachedPreAssignedTrip !== null) {
              this.preAssignedTrip = cachedPreAssignedTrip;
            }
        }

    }


  // Handler called when the component is discarded from memory
  ngOnDestroy(): void {
    this.componentActive = false;
    this.subscriptions$.unsubscribe();
  }

  getLocation(trip:JobTrip, type:string){
    if(type == "arrive"){
      return this.getTextIfNull(trip.arrivalLocation.city) + ', ' +
         this.getTextIfNull(trip.arrivalLocation.state) + ' ' +
         this.getTextIfNull(trip.arrivalLocation.zip); 
    }else{
      return this.getTextIfNull(trip.departureLocation.city) + ', ' +
      this.getTextIfNull(trip.departureLocation.state) + ' ' +
      this.getTextIfNull(trip.departureLocation.zip);
    }
  }

  // Checks text, if not undefined or null returns its value. Otherwise empty string is sent back
  getTextIfNull(text: string): string {
    let theText = '';
    if (text !== null && text !== undefined) {
      theText = text;
    }
    return theText;
  }

  async callFleetManager() {
    this.analytics.trackEvent('User', 'Clicked Call Transportation Manager Button On Pre-Assigned Screen',
          'Clicked-Call-Fleet-Manager-Button-On-Pre-Assigned-Screen');
    await this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_PREASSIGNED_SCREEN);
  }
  
}
