import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LoadStatus } from '../../../../../Swagger-Gen';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { IAuthAction } from 'ionic-appauth';
import { LoadingController, NavController } from '@ionic/angular';
import * as fromCurrentTrip from "../../../../../modules/loads/trip-load-infos/state/trip-load-infos.reducer";
import { EdgeCard } from 'src/app/shared/models/edge-card';
// adding brackets causes map to crash
import * as TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { MapService } from "../../../map/service/map.service";
import { SubSink } from 'subsink';
import * as tripActions from '../../../../../modules/loads/trip-load-infos/state/trip-load-infos.actions';
import { StorageConstants } from 'src/app/shared/utilities/storage-constants';
import { WernerDwpContractsLogicJobTrip as trip} from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobTrip';
import { WernerDwpContractsLogicJobStep } from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobStep';
import { Preferences } from '@capacitor/preferences';
import { Profile } from 'src/app/Swagger-Gen-V2';
import { DispatchService } from 'src/app/shared/services/dispatchers/dispatch.service';

@Component({
  selector: 'load-status-card',
  templateUrl: './load-status-card.component.html',
  styleUrls: ['./load-status-card.component.scss'],
})

export class LoadStatusCardComponent implements OnInit, OnDestroy {
  statusLoaded = false;
  action: IAuthAction;
  currentTripObservable: Observable<trip>;
  loadStatus: LoadStatus;
  errorMessage$: Observable<string>;
  showStatusBadge = true;
  @Input("card") card: EdgeCard;
  @Output("resize") resize = new EventEmitter();
  @Output("change") change = new EventEmitter();
  @Output("remove") remove = new EventEmitter();
  currentStatus = 'Assigned';
  currentLoadStatus: LoadStatus = null;
  loadDetailsScreenRoute = '/loads';
  componentActive = true;
  driverId = '';
  LOAD_STATUS_KEY = 'loadStatusKey';
  LOAD_IDENTITY_KEY = 'global-identity-key';
  LOAD_SAVED_IDENTITY_KEY = 'load-saved-identity-key';
  defaultMapLatitude = 41.259892; // Omaha latitude
  defaultMapLongitude = -95.928291; // Omaha longitude
  @ViewChild('jobMap', { static: true }) mapElement: ElementRef;
  private subscriptions$ = new SubSink();
  private locationMarker: TrimbleMaps.Marker = new TrimbleMaps.Marker({
    anchor: "center"
  });
  currentTrip: trip = null;
  currentJobStep : WernerDwpContractsLogicJobStep = null;
  profile : Profile;
  employeeNumber : string;

  constructor(
    private currentTripStore: Store<fromCurrentTrip.State>,
    public loadingController: LoadingController,
    private navCtrl: NavController,
    private mapService: MapService,
    private dispatchService: DispatchService
  ) {
    this.loadCurrentJob(); 
  }

  async ngOnInit() {
    const identityKeyCheck = await Preferences.get({ key: StorageConstants.USER_IDENTITY_KEY });
    this.driverId = identityKeyCheck.value;
    this.currentTripObservable = this.currentTripStore.pipe(
      select(fromCurrentTrip.getCurrentTrip)
    ) as Observable<trip>;

    this.subscriptions$.sink = this.currentTripObservable
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(async (currentTrip: trip) => {
        if (currentTrip) {
          this.currentTrip = currentTrip
        }
        await this.getPointforMap();
      });
  }

  async loadCurrentJob(){
    this.employeeNumber = await this.dispatchService.getEmployeeNumber();
    this.currentTripStore.dispatch(new tripActions.GetCurrentTrip(this.employeeNumber));
  }

  async getPointforMap() {
    let longitude: number;
    let latitude: number;

    if (this.currentTrip && this.currentTrip.steps && this.currentTrip.steps.length > 0) {
      for (let i = 0; i < this.currentTrip.steps.length; i++) {
        if (this.currentTrip.steps[i].isNext === true) {
          this.currentJobStep = this.currentTrip.steps[i];
          longitude = this.currentTrip.steps[i].location.coordinates.longitude;
          latitude = this.currentTrip.steps[i].location.coordinates.latitude;
          break;
        }
      }
    }
    else {
      await this.mapService.locateUser(9, true).then((location) => {
        if (location) {
          longitude = location.lng;
          latitude = location.lat;
        } else {
          longitude = this.defaultMapLongitude;
          latitude = this.defaultMapLatitude;
        }
      });
    }

    await this.ShowPointOnMap(latitude, longitude);
  }

  async ShowPointOnMap(latitude: number, longitude: number) {
    if (latitude
      && longitude) {
      const mapOptions = {
        container: this.mapElement.nativeElement,
        center: {
          lng: longitude,
          lat: latitude
        },
        zoom: 10
      };

      await this.mapService.initMap(mapOptions).then((map: TrimbleMaps.Map) => {
        map.on("load", () => {
          this.locationMarker.setLngLat([longitude, latitude])
            .addTo(map);
          map.setCenter([longitude, latitude]);
          // Force the resize to reset the style (height)
          window.dispatchEvent(new Event('resize'));
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.componentActive = false;
    this.subscriptions$.unsubscribe();
  }

  async presentLoadingWithOptions() {
    const loading = await this.loadingController.create({
      spinner: 'bubbles',
      duration: 5000,
      message: 'Loading',
      translucent: true,
      cssClass: 'custom-class custom-loading',
      backdropDismiss: true,
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
  }

  tapCard() {
    this.card.showSizePositionControl || this.card.preview ?
      !this.card.showOverlay : (this.currentJobStep && this.currentJobStep.location && this.currentJobStep.location.state && this.currentJobStep.targetDate) ?
        this.gotoCurrentJob() : null;
  }

  gotoCurrentJob() {
    this.navCtrl.navigateRoot("/loads?page=Current"); 
  }

  resizeCard() {
    this.resize.emit(this.card);
  }

  changeCard() {
    this.change.emit(this.card);
  }

  removeCard() {
    this.remove.emit(this.card)
  }
}
