<div class="background">
  <div class="modal">
    <ion-item class="title-row ion-no-padding" lines="full">
      <ion-label class="edge-label ">Map Filter</ion-label>
      <button class="close-btn" (click)="close()">
        <img src="assets/cross.svg" alt="">
      </button>
    </ion-item>
    <div class="cta werner-button text" (click)="applyfilters()"> Apply Filters </div>
    <div class="modal-content">
      <ion-item *ngFor="let item of poiInfo; let i = index;" lines='none'>
        <div class="filter-icon flex-me">
          <img class="poi-icons" src="../../../../assets/Images/poi/{{item.icon}}.png" alt="">
        </div>
        <ion-label>{{ item.title }}</ion-label>
        <ion-checkbox slot='end' color="primary" [(ngModel)]="item.isChecked"></ion-checkbox>
      </ion-item>
    </div>
    
  </div>
</div>