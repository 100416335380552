/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type JobTripStepType = 'SHIPPER' | 'STOP' | 'ARRIVAL' | 'CONSIGNEE' | 'SPLIT' | 'UNKNOWN';

export const JobTripStepType = {
    SHIPPER: 'SHIPPER' as JobTripStepType,
    STOP: 'STOP' as JobTripStepType,
    ARRIVAL: 'ARRIVAL' as JobTripStepType,
    CONSIGNEE: 'CONSIGNEE' as JobTripStepType,
    SPLIT: 'SPLIT' as JobTripStepType,
    UNKNOWN: 'UNKNOWN' as JobTripStepType
};