<ion-card *ngIf="card">
  <card-overlay
    [card]="card"
    (didTapCard)="tapCard()"
    (didTapResize)="resizeCard()"
    (didTapChange)="changeCard()"
    (didTapRemove)="removeCard()"
  ></card-overlay>

  <input type="file" accept="image/*" (change)="selectBannerImage($event)" #fileInput class="ion-hide">  
  <ion-img [src]="coverPictureUrl" height="120" width="180"></ion-img>
  <ion-item lines="none">
    <img [src]="profileUrl" alt="" />
    <ion-label class="edge-value">{{ greeting }} {{ displayName }}</ion-label>
  </ion-item>
</ion-card>
