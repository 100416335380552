import { Action } from '@ngrx/store';
import { JobLoad } from 'src/app/Swagger-Gen-V2/model/jobLoad';
import { JobLoadAssignment } from 'src/app/Swagger-Gen-V2/model/jobLoadAssignment';

export enum LoadSelectionActionTypes {
    GetLoadSelectionList = 'Get Load selection List',
    PostSelectedLoad = 'Post Selected Load',
    GetLoadSelectionListSuccess = 'Get Load selection List Success',
    GetLoadSelectionListFail = 'Get Load selection List Fail',
    PostSelectedLoadSuccess = 'Post Selected Load  Success',
    PostSelectedLoadFail = 'Post Selected Load Fail',
    ResetLoadSelectionMessage = 'Reset load selection message to null',

}


export class GetLoadSelectionList implements Action {
    readonly type = LoadSelectionActionTypes.GetLoadSelectionList;
    stackholderId = 0;
    constructor(public stackId: number) {
        this.stackholderId = stackId;
    }
}

export class PostSelectedLoad implements Action {
    readonly type = LoadSelectionActionTypes.PostSelectedLoad;
    jobSelected: JobLoadAssignment
    constructor(public job: JobLoadAssignment) {
        this.jobSelected = job
    }
}

export class GetLoadSelectionListSuccess implements Action {
    readonly type = LoadSelectionActionTypes.GetLoadSelectionListSuccess;
    constructor(public payload: Array<JobLoad>) {

    }
}

export class GetLoadSelectionListFail implements Action {
    readonly type = LoadSelectionActionTypes.GetLoadSelectionListFail;
    constructor(public payload: string) {

    }
}

export class PostSelectedLoadSuccess implements Action {
    readonly type = LoadSelectionActionTypes.PostSelectedLoadSuccess;
    constructor(public payload) {

    }
}

export class PostSelectedLoadFail implements Action {
    readonly type = LoadSelectionActionTypes.PostSelectedLoadFail;
    constructor(public payload: string) {

    }
}

export class ResetLoadSelectionMessage implements Action {
    readonly type = LoadSelectionActionTypes.ResetLoadSelectionMessage;
    constructor() {

    }
}

export type LoadSelectionActions = GetLoadSelectionList
    | PostSelectedLoad
    | GetLoadSelectionListSuccess
    | GetLoadSelectionListFail
    | PostSelectedLoadSuccess
    | PostSelectedLoadFail
    | ResetLoadSelectionMessage


