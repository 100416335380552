import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { EdgeCard } from 'src/app/shared/models/edge-card';

@Component({
  selector: 'card-overlay',
  templateUrl: './card-overlay.component.html',
  styleUrls: ['./card-overlay.component.scss'],
})
export class CardOverlayComponent implements OnInit {
  @Input('card') card: EdgeCard;
  @Output('didTapResize') didTapResize = new EventEmitter();
  @Output('didTapCard') didTapCard = new EventEmitter();
  @Output('didTapChange') didTapChange = new EventEmitter();
  @Output('didTapRemove') didTapRemove = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  tappedCard() {
    this.didTapCard.emit(this.card);
    
    if (this.card.showSizePositionControl) {
      this.card.showOverlay = true
    }
  }

  tappedOverlay() {
    this.card.showOverlay = false
  }

  tappedResize() {
    this.didTapResize.emit(this.card)
  }

  tappedChangeTile() {
    this.didTapChange.emit(this.card)
  }

  tappedRemoveTile() {
    this.didTapRemove.emit(this.card);
  }

  overlayStyle() {

    let transform = this.card.size == 12 ? 'translate(-33%, -50%)' : 'translate(-50%, -50%)'; 
    let style = {
      'position': 'absolute',
      'top': '50%',
      'left': '50%',
      'transform': transform,

      'z-index': '5' 
    }

    return style;
  }

  showHeader(card) {
    return card.showHeaderBar;
  }

  showTitleInBody(card) {
    return card.showTitleInBody;
  }
}
