import {createFeatureSelector, createSelector} from '@ngrx/store';
import {PreviousTripsActions, PreviousTripsActionTypes} from './previous-trips-loads.actions';
import * as fromRoot from '../../../../app.state';

export interface State extends fromRoot.State {
    previousTrips: PreviousTripsState;
}

export interface PreviousTripsState {
    previousTrips: Array<any>;
    error: string;
    previousTripIdInContext: string;
    isPreviousJobsLoading: boolean
}

const initialState: PreviousTripsState = {
    previousTrips: null,
    previousTripIdInContext: '',
    error: '',
    isPreviousJobsLoading: false
};

const getPreviousTripsFeatureState = createFeatureSelector<PreviousTripsState>('previousTrips');

export const getDriverPreviousTrips = createSelector(
    getPreviousTripsFeatureState ,
    state => state.previousTrips
);

export const getDriverPreviousTripsByTripId = createSelector(
    getPreviousTripsFeatureState ,
    state => state.previousTrips
);

export const getError = createSelector(
    getPreviousTripsFeatureState,
    state => state.error
);

export const isPreviousJobsLoading = createSelector(
    getPreviousTripsFeatureState ,
    state =>  (state !== null) ? state.isPreviousJobsLoading : false
);

export function previousTripsReducer(state= initialState, action: PreviousTripsActions): PreviousTripsState {
    switch (action.type) {
        case PreviousTripsActionTypes.GetPreviousTripsSuccess:
            return {
                ...state,
                previousTrips: action.payload,
                error: '',
                isPreviousJobsLoading: false
            };
        case PreviousTripsActionTypes.GetPreviousTrips:
            return {
                ...state,
                isPreviousJobsLoading: true,
                previousTrips: null,
                error: ''
            };

        case PreviousTripsActionTypes.PreviousTripIdInContext:
            return {
                ...state,
                previousTripIdInContext: action.tripIp,
                
            };
    
        case PreviousTripsActionTypes.GetPreviousTripsFail:
            return {
                ...state,
                previousTrips: null,
                error: action.payload,
                isPreviousJobsLoading: false
            };
        default:
            return state;
    }
}
