/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ProfileDriverEthnicity } from "./profileDriverEthnicity";

export interface ProfileUser { 
    workdayId?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    nickname?: string;
    initials?: string;
    dateOfBirth?: Date;
    maritalStatus?: string;
    stakeholderId?: string;
    identityKey?: string;
    picture?: string;
    hireDate?: Date;
    employeeNumber?: string;
    role?: string;
    ethnicities?: Array<ProfileDriverEthnicity>;
    hispanicOrLatino?: boolean;
}
