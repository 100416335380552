<ion-header>
  <ion-grid fixed style="padding-bottom: 0px;">
    <ion-row>
      <ion-col size="12">
        <ion-segment class="hide-scrollbar" scrollable [(ngModel)]="theValue" (ionChange)="onSubpageChange($event)"
          mode="md">
          <ion-segment-button *ngFor="let page of subPages" value={{page.title}} layout={{page.layout}}>
            <ion-icon *ngIf="page.icon !== null && page.icon.length" name="{{page.icon}}"></ion-icon>
            
            <ion-label class="edge-value">
              <fa-icon *ngIf="page.fa_icon" class="faf-icon" [icon]="[page.fa_icon_style, page.fa_icon]"></fa-icon>
              {{page.title}}
            </ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-header>