<ion-item lines="none" *ngIf="jsonFormData?.questionGroup?.description">
  <ion-title class="ion-no-padding"> {{jsonFormData.questionGroup.description}} </ion-title>
</ion-item>

<form [formGroup]="questionAnswerForm" (ngSubmit)="onSubmit()" *ngIf="jsonFormData">
  <ion-item *ngFor="let question of jsonFormData?.questionGroup?.questions;let questionIndex=index;" lines="none"
    id="question_wrapper_{{ question?.questionID }}"
    [ngClass]="questionsToHide.indexOf(question?.questionID)  > -1 ? 'hide-block' : 'show-block'">
    <div id="question_{{ question?.questionID }}" class="size">
      <!-- <div>Not Required Element</div> -->
      <ion-label class="ion-text-wrap" [innerHTML]="question.questionText">
      </ion-label>
      <div *ngIf="
         _array.isArray(question?.answerOptions);
                then AnswerOptionIsArrayBlock;
                else AnswerOptionIsNotArrayBlock
              "></div>
      <ng-template #AnswerOptionIsArrayBlock>

        <div *ngFor="let answerOption of question?.answerOptions;let answerOptionIndex=index;">
         
        <div *ngIf="answerOption.fieldType === 'CheckBox'">
            <ion-item lines="none">
              <ion-checkbox (ionChange)="
                      setCheckboxValue(
                        $event,
                        answerOption?.questionAnswerOptionID,
                        questionIndex,
                        answerOptionIndex                    
                      )" formControlName="{{ answerOption?.questionAnswerOptionID }}" [value]="answerOption?.userAnswer?.userValue"
                [ngModel]="setIntialValue(
                      answerOption?.questionAnswerOptionID,
                      questionIndex,
                      answerOptionIndex)" id="{{ answerOption.questionAnswerOptionID }}"> </ion-checkbox>
              <ion-label  [innerHTML]="answerOption.text" class="ion-padding-start ion-text-wrap">  
              </ion-label>
            </ion-item>
          </div>
        <ion-label class="ion-text-wrap" [ngClass]="{'driving-label':answerOption.fieldType  === 'TextBox' || answerOption.fieldType  == 'TextBoxSmall' }" *ngIf="answerOption.text && answerOption.fieldType  != '19' && jsonFormData.questionGroup.displayOrder == 1"
          [innerHTML]="answerOption.text">
        </ion-label>
        <div *ngIf="answerOption?.fieldType === 'RadioButton'">
          <ion-radio-group id="{{ answerOption.questionAnswerOptionID }}" (ionChange)="
                    changeInRadioField(
                      $event,
                      answerOption?.questionAnswerOptionID,
                      questionIndex,
                      answerOptionIndex                    
                    )
                  " [ngModel]="setIntialValue(
                      answerOption?.questionAnswerOptionID,
                      questionIndex,
                      answerOptionIndex)" formControlName="{{ answerOption?.questionAnswerOptionID }}">
            <ion-item *ngFor="
                      let answerOptionItem of answerOption.answerOptionItems;let answerOptionItemIndex=index;
                    " lines="none">
              <ion-radio [value]="answerOptionItem.value"></ion-radio>
              <ion-label class="ion-padding-start ion-text-wrap">{{
                answerOptionItem.text
                }}</ion-label>
            </ion-item>

          </ion-radio-group>
        </div>

        <div *ngIf="answerOption.fieldType === 'TextBox'" class="ion-padding-vertical">
        <div *ngIf="questionAnswerForm.get('4135').invalid && questionAnswerForm.get('4135').touched" class="showValidationMessagelabel">
            Date*
            </div>
        <ion-item>
          <ion-input *ngIf="answerOption.questionAnswerOptionID == '4135'" id="{{ answerOption.questionAnswerOptionID }}" formControlName="{{ answerOption?.questionAnswerOptionID }}" [type]="'text'" [ngModel]="setIntialValue(
                              answerOption?.questionAnswerOptionID,
                              questionIndex,
                              answerOptionIndex)" 
            [maskito] = "dateMaskOptions"
            [maskitoElement]="maskPredicate"
            [attr.questionAnswerOptionID]="answerOption?.questionAnswerOptionID"
             class="input-box"
            (ionInput)="setValue($event,answerOption?.questionAnswerOptionID,questionIndex,answerOptionIndex)">
          </ion-input>

          <ion-input *ngIf="answerOption.questionAnswerOptionID != '4135'" id="{{ answerOption.questionAnswerOptionID }}" formControlName="{{ answerOption?.questionAnswerOptionID }}" [type]="'text'" [ngModel]="setIntialValue(
                                        answerOption?.questionAnswerOptionID,
                                        questionIndex,
                                        answerOptionIndex)" 
            [attr.questionAnswerOptionID]="answerOption?.questionAnswerOptionID"
             class="input-box"
            (ionInput)="setValue($event,answerOption?.questionAnswerOptionID,questionIndex,answerOptionIndex)">
          </ion-input>
        </ion-item>
        <br>
        <div *ngIf="questionAnswerForm.get('4135').invalid && questionAnswerForm.get('4135').touched" class="showValidationMessage">
        Date Required
        </div>
        </div>

        <div *ngIf="answerOption.fieldType === 'TextBoxSmall'" class="ion-padding-vertical">
          <ion-item>
          <ion-input id="{{ answerOption.questionAnswerOptionID }}"
            *ngIf="answerOption.questionAnswerOptionID != '4139' && answerOption.questionAnswerOptionID != '4136'"
             class="input-box"
            formControlName="{{ answerOption?.questionAnswerOptionID }}" [type]="'text'"
            (keypress)="numberOnlyValidation($event,answerOption?.questionAnswerOptionID,answerOption.fieldType)" [ngModel]="setIntialValue(
                              answerOption?.questionAnswerOptionID,
                              questionIndex,
                              answerOptionIndex)" 
            [attr.questionAnswerOptionID]="answerOption?.questionAnswerOptionID"
            (ionInput)="setValue($event,answerOption?.questionAnswerOptionID,questionIndex,answerOptionIndex)">
          </ion-input>
            <ion-input id="{{ answerOption.questionAnswerOptionID }}" 
            maxlength ="18"
          *ngIf="answerOption.questionAnswerOptionID == '4139' || answerOption.questionAnswerOptionID == '4136'"
            class="input-box"
          formControlName="{{ answerOption?.questionAnswerOptionID }}" 
          [type]="'text'"
          (keypress)="numberOnlyValidation($event,answerOption?.questionAnswerOptionID,answerOption.fieldType)"
          
          [ngModel]="setIntialValue(
                  answerOption?.questionAnswerOptionID,
                  questionIndex,
                  answerOptionIndex) | phoneFormat" 
          [attr.questionAnswerOptionID]="answerOption?.questionAnswerOptionID"
          (ionInput)="setValue($event,answerOption?.questionAnswerOptionID,questionIndex,answerOptionIndex)">
        </ion-input>
         </ion-item>
        
  
        </div>
        <div *ngIf="answerOption?.fieldType == '19'">
            <ion-row class="ion-no-padding">
              <ion-col size-lg="5" size-xl="5" size-md="5" size-sm="8"  size-xs="8" size="6">
                <ion-label *ngIf="answerOption.text" [innerHTML]="answerOption.text" class="ion-text-wrap ion-padding-top"></ion-label>
              </ion-col>
              <ion-col size-lg="1" size-xl="1" size-md="1" size-sm="3" size-xs="3" size="6">
              <ion-item>
                <ion-input maxlength ="1" id="{{ answerOption?.questionAnswerOptionID }}" formControlName="{{ answerOption?.questionAnswerOptionID }}"
                  (keypress)="numberOnlyValidation($event,answerOption?.questionAnswerOptionID,answerOption?.fieldType)"
                  [type]="'numeric'" [ngModel]="setIntialValue(answerOption?.questionAnswerOptionID,questionIndex,answerOptionIndex)"
                 [attr.questionAnswerOptionID]="answerOption?.questionAnswerOptionID"
                  (ionInput)="setValue($event,answerOption?.questionAnswerOptionID,questionIndex,answerOptionIndex)">
                </ion-input>
              </ion-item>
              </ion-col>
            </ion-row>
        </div>

        </div>
      </ng-template>
      <ng-template #AnswerOptionIsNotArrayBlock>
        <div *ngIf="question?.answerOptions?.fieldType === 'RadioButton'">
          <ion-radio-group id="{{ question?.answerOptions?.questionAnswerOptionID }}" (ionChange)="
                    changeInRadioField(
                      $event,
                      question?.answerOptions?.questionAnswerOptionID,
                      questionIndex,
                      ''                  
                    )
                  " [ngModel]="setIntialValue(
                      question?.answerOptions?.questionAnswerOptionID,
                      questionIndex,
                      '')" formControlName="{{ question?.answerOptions?.questionAnswerOptionID }}">
            <ion-item *ngFor="
                      let answerOptionItem of question?.answerOptions
                        ?.AnswerOptionItems?.AnswerOptionItem;let answerOptionItemIndex=index;
                    " lines="none">
              <ion-radio [value]="answerOptionItem.value"></ion-radio>
              <ion-label class="ion-padding-start ion-text-wrap">{{
                answerOptionItem.Text
                }}</ion-label>
            </ion-item>
          </ion-radio-group>
        </div>
   
        <div class="ion-padding-vertical" *ngIf="question?.answerOptions?.fieldType === 'TextBox'">
        <ion-item>
          <ion-input id="{{ question?.answerOptions?.questionAnswerOptionID }}" 
            formControlName="{{  question?.answerOptions?.questionAnswerOptionID }}" type="text" [ngModel]="setIntialValue(
                    question?.answerOptions?.questionAnswerOptionID,
                    questionIndex,
                    '')" 
             [attr.questionAnswerOptionID]="question?.answerOptions?.questionAnswerOptionID"
            (ionInput)="setValue($event,question?.answerOptions?.questionAnswerOptionID,questionIndex,'')">
          </ion-input>
        </ion-item>
        </div>

        <div *ngIf="question?.answerOptions?.fieldType === 'TextBoxSmall'" class="ion-padding-vertical">
          <ion-item>
          <ion-input id="{{ question?.answerOptions?.questionAnswerOptionID }}" formControlName="{{
                    question?.answerOptions?.questionAnswerOptionID
                  }}" [type]="'text'" [ngModel]="setIntialValue(
                    question?.answerOptions?.questionAnswerOptionID,
                    questionIndex,
                    '')"  [attr.questionAnswerOptionID]="question?.answerOptions?.questionAnswerOptionID"
            (ionInput)="setValue($event,question?.answerOptions?.questionAnswerOptionID,questionIndex,'')">
          </ion-input>
          ></ion-item>
        </div>


      </ng-template>
    </div>


  </ion-item>
  <ion-row class="ion-align-items-center">
    <ion-col size="12" class="ion-text-center">
      <ion-button type="button" *ngIf="jsonFormData.questionGroup.displayOrder > 1" (click)="goBack()">Go Back</ion-button>
      <ion-button type="submit">Save options</ion-button>
    </ion-col>
  </ion-row>
  
</form>