import { NgModule } from '@angular/core';
import {IonicModule} from '@ionic/angular';
import { CommonModule } from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {loadStatusReducer} from './load-status/state/load-status.reducer';
import {EffectsModule} from '@ngrx/effects';
import {LoadStatusEffects} from './load-status/state/load-status.effects';


@NgModule({
  declarations: [],
  exports: [
  ],
  imports: [
    CommonModule,
    // SharedComponentsModule,
    IonicModule,
      StoreModule.forFeature('loadStatus', loadStatusReducer),
      EffectsModule.forFeature([LoadStatusEffects])
  ],
  providers: [ ]
})
export class LoadStatusModule { }
