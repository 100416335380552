<div class="background">
  <div class="modal">
    <div class="title-row">
      <ion-label class="edge-label">Add A Tile</ion-label>
      <button (click)="closeModal()">X</button>
    </div>
    <br>
    <br>
    <div>
      <ion-row>
        <div class="edge-value" *ngIf="allCardsActive" style="text-align: center;">
          All cards have already been placed on the Home Page
        </div>
        <ion-col *ngFor="let card of inactiveCards" size="12" [ngStyle]="setStyle(card.isActive)">
          <modal-card [card]="card" (addCard)="add(card)" ></modal-card>
        </ion-col>
      </ion-row>
    </div>
  </div>
</div>