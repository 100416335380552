<ion-item lines="full" detail="true">
  <div class="flex-full">
    <div class="flex-full col">
      <ion-label class="edge-label">
        {{paycheck.checkDate | date : 'MMMM d, y':'UTC'}}
      </ion-label>
    </div>
    <div class="flex-auto">
      <ion-label class="edge-value amount">
        {{paycheck.grossPay | currency}}
      </ion-label>
    </div>
  </div>
</ion-item>