import { Action } from '@ngrx/store';
import { LoadStatus } from '../../../../../../Swagger-Gen';

export enum LoadStatusActionTypes {
    GetLoadStatus = '[LoadStatus] Get Load Status',
    LoadSuccess = '[LoadStatus] Load Success',
    LoadFail = '[LoadStatus] Load Fail'
}

export class GetLoadStatus implements Action {
    driverId = '';
    readonly type = LoadStatusActionTypes.GetLoadStatus;
    constructor(public id: string) {
        this.driverId = id;
    }
}
export class LoadSuccess implements Action {
    readonly type = LoadStatusActionTypes.LoadSuccess;
    constructor(public payload: LoadStatus) {
    }
}
export class LoadFail implements Action {
    readonly type = LoadStatusActionTypes.LoadFail;

    constructor(public payload: string) { }
}

export type LoadStatusActions = GetLoadStatus | LoadSuccess | LoadFail;
