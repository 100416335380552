<ion-card>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col size="1">
          <ion-icon name="{{iconName}}" [style.color]="textColor" [style.font-size.px]="24"></ion-icon>
        </ion-col>
        <ion-col size="11">
          <ion-text  [style.color]="textColor"  class="edge-value">{{message}}</ion-text>
        </ion-col>
      </ion-row>

    </ion-grid>


  </ion-card-content>
</ion-card>