import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'scrolling-header',
  templateUrl: './scrolling-header.component.html',
  styleUrls: ['./scrolling-header.component.scss'],
})
export class ScrollingHeaderComponent implements OnInit {

  @Input('subPages') subPages = [{title: '', icon: ''}];
  @Input('selectedIndex') selectedIndex = 0;
  @Input('theValue') theValue = '';

  @Output() selectedTab = new EventEmitter();
  selectedValue = '';

  constructor() { }

  ngOnInit() {
    this.updateTab();
  }


  // Method used to update the tab that is selected in the component
  updateTab() {
    if(this.subPages !== null && this.subPages !== undefined && this.subPages.length > 0) {
      this.theValue = this.subPages[this.selectedIndex].title;
    }
  }
  
  onSubpageChange(event) {
      this.selectedTab.emit(event.detail.value);
  }
}
