import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
Keyboard.setResizeMode({mode:KeyboardResize.Ionic});
@Component({
  selector: 'sticky-input',
  templateUrl: './sticky-input.component.html',
  styleUrls: ['./sticky-input.component.scss']
})
export class StickyInputComponent implements OnInit, OnChanges {

  @ViewChild('input') input;
  @Output('stickyInputText') stickyInputText  = new EventEmitter();
  @Output('sendText') sendText = new EventEmitter();
  @Output('micClick') micClick = new EventEmitter();
  inputText: string;
  isNativeApp: boolean = this.platform.is('mobile') && !this.platform.is('mobileweb');
  @Input('isApiLoading') isApiLoading: boolean;
  isLoading: boolean = false;
  constructor(private platform: Platform) {

    window.addEventListener('keyboardDidShow', () => {
      this.hidePaddedInput(true);
      // Set focus to the unpadded input when keyboardDidShow
      this.input.setFocus();
      window.dispatchEvent(new Event('resize'));
    });

    window.addEventListener('keyboardDidHide', () => {
      this.hidePaddedInput(false);
      window.dispatchEvent(new Event('resize'));
    });

    window.addEventListener('keyup', (event: any) => {
      if (event.keyCode === 13 && this.inputText !== '') {
        this.inputText = '';
      }
    }, false);

  }

  ngOnChanges(changes: SimpleChanges): void {
    const isLoading = changes['isApiLoading'];
    if (isLoading?.currentValue !== null) {
      this.isLoading = isLoading.currentValue;
    }
  }

  ngOnInit() {
    // Hide the padded input if isNativeApp
    this.hidePaddedInput(!this.isNativeApp);
  }

  hidePaddedInput(isHidden) {
    // Hide/show the correct input
    document.getElementById('paddedInput').hidden = isHidden;
    document.getElementById('input').hidden = !isHidden;
  }

  setBottom() {
    // iPhone X+ height - 896
    // iPhone 8+ height - 736
    // iPhone 8 height - 667
    const iPhone = this.platform.is('mobile') && !this.platform.is('mobileweb') && this.platform.is('ios');
    const tallTabBar = this.platform.height() > 800;
    const android = this.platform.is('mobile') && !this.platform.is('mobileweb') && this.platform.is('android');
    let bottom = 0;

    if (iPhone) {
      // Account for taller tab bar on iPhone X and newer phones
      bottom = tallTabBar ? 84 : 50;
      
    } else if (android) {
      bottom = 0;
    }

    return  { 'bottom': bottom + 'px' };
  }

  setInput(value) {
    if(this.isLoading === false){
      this.stickyInputText.emit(value);
    }   
  }

  sendButtonClicked() {
      this.sendText.emit();
      this.inputText = '';
  }

  micButtonClicked() {
    this.micClick.emit();
  }
}
