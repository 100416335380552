import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { SubSink } from 'subsink';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import {
  PeriodTripsList,
  Trip,
  TripStep,
} from '../../../Swagger-Gen';
import * as paperWorkActions from '../../paperwork/state/paperwork.actions';
import * as fromPaperWorkReducer from '../../paperwork/state/paperwork.reducer';
import { StopOptionsModalComponent } from '../stop-options-modal/stop-options-modal.component';
import { environment } from 'src/environments/environment';
import { Preferences } from '@capacitor/preferences';
import nativePlugin from 'src/plugins/nativePlugin';
import { AuthService } from 'src/app/auth/auth.service';
import { ScannedPaperwork } from 'src/app/Swagger-Gen-V2/model/ScannedPaperwork';
import { TelemetryUtility } from 'src/app/shared/utilities/telemetry-utilities';
import { TelemetryConstants } from 'src/app/shared/utilities/telemetry-constants';
import { RequestService } from 'src/app/Swagger-Gen-V2';
@Component({
  selector: 'app-trips-list',
  templateUrl: './trips-list.component.html',
  styleUrls: ['./trips-list.component.scss'],
})
export class TripsListComponent implements OnInit, OnDestroy {
  @Output() showPaperworkDrawer = new EventEmitter<Trip>();
  @Input('periodTrips') periodTrips: PeriodTripsList; // Stores data on all the trips that occurred during a given period
  trips: Array<Trip> = null; // Stores data on collection of trips
  panelsHidden: boolean[] = [];
  showStopOptionsModal = false;
  identityKey: string;
  SELECTED_STOP_KEY = 'selected_stop';
  CLICKED_TRIP_KEY = 'clicked_trip';
  paperWorkConfirmation: ScannedPaperwork[] = [];
  subscriptions$ = new SubSink();
  isWebApp: boolean = false;
  isProduction : boolean = environment.production;
  paperworkConfirmationUrl = '';
  paperworkSubmissionVideoUrl : string;

  constructor(
    private paperWorkStore: Store<fromPaperWorkReducer.State>,
    private analytics: AnalyticsService,
    private iab: InAppBrowser,
    private telemetryUtility: TelemetryUtility,
    public modalController: ModalController,
    platform: Platform,
    private requestService : RequestService,
    private authService : AuthService
  ) {
    this.isWebApp = (platform.is("desktop") || platform.is("mobileweb"));
  }

  async ngOnInit() {
    this.trips = this.periodTrips.trips;
    //this.store.dispatch(
    // new previousTripsActions.PreviousTripIdInContext(this.trips.));
    this.panelsHidden = new Array<boolean>(this.trips.length);
    let i = 0;
    for (i; i < this.trips.length; i++) {
      this.panelsHidden[i] = true;
    }
    this.identityKey = await this.authService.getUserIdentitityKey();
    this.subscriptions$.sink = this.requestService.linksGet().subscribe(async (response) => {
      if (response.paperworkConfirmation) {
        this.paperworkConfirmationUrl = response.paperworkConfirmation.url;
      }
      if (response.paperworkUploadProcess) {
        this.paperworkSubmissionVideoUrl = response.paperworkUploadProcess.url;
      }
    });
  }

  // How or hide details information on a trip that the user selected
  showOrHideTrip(currentTrip, tripIndex: number) {
    this.closeAllPanelsExcept(tripIndex);
    if (currentTrip != null) {
      this.panelsHidden[tripIndex] = !this.panelsHidden[tripIndex];
      if (this.panelsHidden[tripIndex] === false)
        this.GetPaperworkConfirmationList(currentTrip);
      let panelIsOpened: boolean = !this.panelsHidden[tripIndex];
      if (panelIsOpened == true) {
        this.analytics.trackEvent('User', 'Expanded Previous Job',
          'Expanded-Previous-Job');

        this.telemetryUtility.recordTelemetry(TelemetryConstants.EXPAND_PREVIOUS_JOB);
      }
    }
  }
  private GetPaperworkConfirmationList(currentTrip: any) {

    this.paperWorkStore.dispatch(
      new paperWorkActions.SelectedJob(currentTrip));
    this.paperWorkStore.dispatch(
      new paperWorkActions.GetPaperWorkConfirmation(this.identityKey, currentTrip.id));
    this.subscriptions$.sink = this.paperWorkStore.pipe(select(fromPaperWorkReducer.getPaperWorkConfirmation)).subscribe(
      (confirmationCodesList) => {
        this.paperWorkConfirmation = confirmationCodesList ? confirmationCodesList : [];
      });
  }

  // We close all the panels
  closeAllPanelsExcept(tripIndex: number) {
    if (this.panelsHidden !== null && this.panelsHidden.length > 0) {
      let i = 0;
      for (i; i < this.panelsHidden.length; i++) {

        if (i !== tripIndex) {
          this.panelsHidden[i] = true;
        }

      }
    }
  }

  // Checks if the trip step card is the last one
  isBeforeLastTripStepCard(tripStepIndex: number, currentTrip: any): boolean {
    let isLast = false;

    // We only show a connector line , if the step is not the last one
    if (tripStepIndex === currentTrip.details.steps.length - 2) {
      isLast = true;
    }

    return isLast;
  }

  // This function determines if we should show a connector line from a step to another
  connectorLineIsVisible(tripStepIndex: number, currentTrip: any): boolean {
    let isVisible = true;

    // We only show a connector line , if the step is not the last one
    if (tripStepIndex === currentTrip.details.steps.length - 1) {
      isVisible = false;
    }

    return isVisible;
  }

  formatDateTimeToSmallDate(date: Date): string {
    return formatDate(date, 'MM/dd', 'en');
  }

  showPaperworkDrawerEvent(trip: Trip) {
    this.showPaperworkDrawer.emit(trip);
  }

  // Renders stop options pop
  async showStopOptions(event?: any, step?: TripStep, currentTrip?: Trip) {
    let eventText = event && event.target && event.target.innerText ? event.target.innerText.trim() : '';
    if (!(eventText && eventText.toUpperCase() === "PAPERWORK") && !(eventText && eventText.toUpperCase() === "HOW TO SUBMIT PAPERWORK")) {

      const options = {
        component: StopOptionsModalComponent,
        cssClass: "job-modal",
        swipeToClose: true, //ios only
        componentProps: {},
      };
      const modal = await this.modalController.create(options);
      await modal.present();

      Preferences.remove({ key: this.SELECTED_STOP_KEY });
      Preferences.set({ key: this.SELECTED_STOP_KEY, value: JSON.stringify(step, this.replaceSlashes) });

      Preferences.remove({ key: this.CLICKED_TRIP_KEY });
      Preferences.set({ key: this.CLICKED_TRIP_KEY, value: JSON.stringify(currentTrip, this.replaceSlashes) });
    }
  }

  replaceSlashes(key, value) {
    if (typeof value == "string") {
      value = value.replace(/\//g, "\\/");
    }
    return value;
  }

  // Handler called when the user taps on x or another button to close the options modal pop up
  stopOptionsModalCloseButtonClickHandler() {
    this.showStopOptionsModal = false;
  }

  async onClickPaperWorkConfirmation(paperWorkConfirmation: ScannedPaperwork) {
   
    let data = this.paperworkConfirmationUrl.concat(paperWorkConfirmation.label.code);
    if (data) {
      this.iab.create(data, '_blank');
    }
  }

  async ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

}
