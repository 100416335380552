/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Coordinates } from './coordinates';

export interface LoadStatusSummary { 
    tripId?: string;
    trailerNumber?: string;
    truckNumber?: string;
    status?: string;
    fleetManagerName?: string;
    fleetManagerEmailAddress?: string;
    fleetManagerPhoneNumber?: string;
    fleetManagerPhoneExtension?: string;
    location?: Address;
    gateCoordinates?: Coordinates;
    trailerCoordinates?: Coordinates;
    trailerTracking?: boolean;
    driverNotes?: string;
    isTrailerReservation?: boolean;
}
