import { Component, ChangeDetectorRef, AfterViewInit, OnDestroy, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { Employee } from "src/app/Swagger-Gen";
import { Store, select } from "@ngrx/store";
import { SubSink } from "subsink";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import * as UserDataInfo from '../../../modules/profile-v2/state/user-profile.reducer';
import * as UserDataActions from '../../../modules/profile-v2/state/user-profile.actions';
import { DispatchService } from "../../services/dispatchers/dispatch.service";
import { ProfileFleetManagersResponse } from "src/app/Swagger-Gen-V2/model/ProfileFleetManagersResponse";

export interface LeaderModel {
  user : User;
  email : Email;
  phone : Phone;
}

export interface User {
  firstName?: string;
  lastName?: string;
  role?: string;
  homeTown?: string;
  picture?: string;
  placementDriversTrained?: string;
}

export interface Email {
  address?: string;
}

export interface Phone {
  number?: string;
}

@Component({
  selector: "app-account-contacts",
  templateUrl: "./account-contacts.component.html",
  styleUrls: ["./account-contacts.component.scss"],
})
export class AccountContactsComponent implements AfterViewInit, OnDestroy {
  componentActive = true;
  showContactDetails = false;
  employeeInfo: Employee;
  formattedPhoneNumber = "";
  fleetManagers: ProfileFleetManagersResponse;
  leaderModel : LeaderModel = {
    user : {
      firstName: "Oliver",
      lastName: "Smith",
      role: "Driver Leadership",
      picture : "../../../../../assets/Images/user.png",
      homeTown: "New York,NY",
      placementDriversTrained: "200+"
    },
    email : {
      address : "redwards@gmail.com"
    },
    phone : {
      number : "1-555-555-5555"
    }
  };

  placementDriverModel : LeaderModel = {
    user : {
      firstName: "Rachel",
      lastName: "Edwards",
      picture : "../../../../../assets/Images/user.png",
      homeTown: "New York,NY",
    },
    email : {
      address : "redwards@gmail.com"
    },
    phone : {
      number : "1-555-555-5555"
    }
  };
  
  private subscriptions$ = new SubSink();
  isProduction: boolean = environment.production;
  @Input('isLoadData') isLoadData: boolean;
  @Output() stopIonRefresher = new EventEmitter;
  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private userStore: Store<UserDataInfo.State>,
    private dispatchService: DispatchService,
  ) {
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url === "/contact" || event.url === "/profile") {
          this.showContactDetails = false;
        }
      }
    });    
  }

async ngOnChanges(changes: SimpleChanges) {
  if (changes['isLoadData']?.currentValue) {
    const stakeHolderId = await this.dispatchService.getStackholderId();
    this.userStore.dispatch(new UserDataActions.UserProfileFleetManager(stakeHolderId.toString()));
    this.stopIonRefresher.emit(true);
  }
}

  async ngAfterViewInit() {
    localStorage.setItem("contact-selected-tab", "0");
    await this.getUserFleetManager();
    this.cdRef.detectChanges();
  }

  async getUserFleetManager() {
    const stakeHolderId = await this.dispatchService.getStackholderId();
    this.subscriptions$.sink = this.userStore
    .pipe(select(UserDataInfo.getUserFleetManager))
    .subscribe(
      (response) => {
        if(response){
          this.fleetManagers = response;
        }else{
          this.userStore.dispatch(new UserDataActions.UserProfileFleetManager(stakeHolderId.toString()));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  showAccountContactDetails(employee: Employee) {
    this.showContactDetails = true;
    this.employeeInfo = employee;
  }

  back() {
    this.showContactDetails = false;
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
