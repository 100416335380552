import { Injectable, OnDestroy } from '@angular/core';
import { EdgeCard } from '../models/edge-card';
import { StorageConstants } from '../utilities/storage-constants';
import { SubSink } from 'subsink';
import { Preferences, RequestService } from 'src/app/Swagger-Gen-V2';
import { LoadingUtility } from 'src/app/shared/utilities/LoadingUtility';
import { Preferences as Storage } from '@capacitor/preferences';
import { AuthService } from 'src/app/auth/auth.service';
import { LaunchDarklyRoutes } from '../utilities/launch-darkly-constants';
let CARDS_KEY = StorageConstants.CARDS_KEY;
let CARDS_CHANGED_KEY = StorageConstants.CARDS_CHANGED_KEY;
let CARDS_HISTORY_KEY = StorageConstants.CARDS_HISTORY_KEY;
@Injectable({
  providedIn: 'root'
})
export class EdgeCardService implements OnDestroy {
  identityKey = '';
  subscriptions$ = new SubSink();
  preferenceData:Preferences = {};
  launchDarklyRoutes = LaunchDarklyRoutes.ROUTES;
  public async getIdentityKey(): Promise<void> {
    this.identityKey = await this.authService.getUserIdentitityKey();
    await this.generateUniqueCardsKey();
  }

  constructor(private apiService:RequestService,private loadingUtility: LoadingUtility,private authService : AuthService
      ) {
     this.getIdentityKey();
  }

  async generateUniqueCardsKey () {
    CARDS_KEY = StorageConstants.CARDS_KEY + '_' + this.identityKey ;
  }

  async resetHome(): Promise<boolean> {
    await Storage.set({ key: CARDS_KEY, value: JSON.stringify(this.getDefaultHomeCards()) });
    
    return true;
  }

  async setCardsDidChange(cardsDidChange: boolean) {
    await Storage.set({ key: CARDS_CHANGED_KEY, value: JSON.stringify(cardsDidChange) });
  }

  async cardsDidChange(): Promise<boolean> {
    const cardsChanged = await Storage.get({ key: CARDS_CHANGED_KEY });

    if (cardsChanged.value !== null && cardsChanged.value !== "undefined") {
      return JSON.parse(cardsChanged.value);
    } else {
      return false;
    }
  }

  async setCardsHistory(cardsHistory: Array<string>) {
    await Storage.set({ key: CARDS_HISTORY_KEY, value: JSON.stringify(cardsHistory) });
  }

  async getCardsHistory(): Promise<Array<string>> {
    const cardsHistory = await Storage.get({ key: CARDS_HISTORY_KEY });
    const cards = await Storage.get({ key: CARDS_KEY });

    if (cardsHistory.value !== null && cardsHistory.value !== "undefined") {
      return JSON.parse(cardsHistory.value);
    } else {
      const defaultHomeCards = this.getDefaultHomeCards();

      defaultHomeCards.forEach(async card => {
        card.showSizePositionControl = true;
      });

      const defaultCards = JSON.stringify(defaultHomeCards);
      const currentCards = cards.value;

      // If currentCards, then navigating back from preview - append current custom cards
      // else, navigating from home - return only default cards for an "empty" history
      return currentCards != null 
        ? [defaultCards, currentCards] 
        : [defaultCards, defaultCards];
    } 
  }

  async removeCardHistory() {
    await Storage.remove({ key: CARDS_HISTORY_KEY });
  }

  async setCurrentCards(cards: Array<EdgeCard>,deviceId) { 
    await this.getIdentityKey();
    this.loadingUtility.showLoader('Please wait..');
    this.preferenceData = {
      userId:this.identityKey,
      deviceId:deviceId,
      updatedDate:new Date(),
      value:JSON.stringify(cards)
    };
    this.subscriptions$.sink = this.apiService.preferencesPost(this.preferenceData).subscribe(
      async (response) => {
        this.loadingUtility.hideLoader();
        await Storage.set({ key: CARDS_KEY, value: JSON.stringify(cards) });
      },
      (error) => {
        this.loadingUtility.hideLoader();
      }
     );
  }

  getCardByTitle(title){
    let card:EdgeCard;
    switch (title) {
      case 'BlueCoin':
        card = this.getBlueCoinCard();
        break;
    }
    return card;
  }


  async updateHomePageCards(cards:Array<EdgeCard>,flags): Promise<Array<EdgeCard>> {
    for(let flagKey in flags){
      let flagValue = flags[flagKey];
      const route = this.launchDarklyRoutes.find((route) =>  route.key === flagKey );
      // break the loop , if flags not related to home page tile
      if(route === undefined ||  route?.isHomeCardAvailable === false) continue;
      const cardIndex = (route)?cards.findIndex( card => card.title === route.homeCardTitle ):-1;
      flagValue = (route !== undefined && typeof flagValue === 'object')?flagValue.current:flagValue;
      if(flagValue === true && cardIndex <= -1){
        cards.push(this.getCardByTitle(route.homeCardTitle));
      }else if(flagValue === false && cardIndex >= 0){
        cards.splice(cardIndex,1);
      }      
    }
    return cards;
  }  

  async getCurrentCards(): Promise<Array<EdgeCard>> {
    await this.getIdentityKey();    
    const currentCards = await Storage.get({ key: CARDS_KEY });
    if (currentCards.value !== null && currentCards.value !== "undefined") {
      const cards = JSON.parse(currentCards.value);
      cards.forEach(card => {
        card.showSizePositionControl = false;
      });
      return cards;
    } else {
      return this.getDefaultHomeCards();
    } 
  }

  canShowWorkdayTile (featureFlagIinfo) {
  let isWorkdayEnabled = featureFlagIinfo
                          && featureFlagIinfo.flags
                          && featureFlagIinfo.flags.IsWorkdayEnabled !== undefined ? featureFlagIinfo.flags.IsWorkdayEnabled : null;

    if(isWorkdayEnabled === false) {
      let isWorkdayEarlyAccessEnabled = featureFlagIinfo
          && featureFlagIinfo.flags
          && featureFlagIinfo.flags.IsWorkdayEarlyAccessEnabled !== undefined ? featureFlagIinfo.flags.IsWorkdayEarlyAccessEnabled : null;
      
      if(isWorkdayEarlyAccessEnabled === true) {
        let isWorkdayEarlyAccessEnabledForUser = featureFlagIinfo
                  && featureFlagIinfo.flags
                  && featureFlagIinfo.flags.IsWorkdayEarlyAccessEnabledForUser !== undefined ? featureFlagIinfo.flags.IsWorkdayEarlyAccessEnabledForUser : null;

        return isWorkdayEarlyAccessEnabledForUser; 
      }
    } else {
      return true; // Workday is enabled for all the drivers
    }
    return false; // Workday is not enabled for the driver
  }

  getDefaultHomeCards(): Array<EdgeCard> {
    return [
      this.getJobCard(),
      this.getPreviousJobCard(),
      this.getPreassignedJobCard(),
      this.getPayCard(),
      this.getOpportunitiesCard(),
      this.getMessageCard(),
      this.getWorkdayCard(),
      this.getBlueCoinCard()
    ];
  }
  private getJobCard(): EdgeCard {
    return {
      type: 'Load Status',
      position: 0,
      size: 12,
      showHeaderBar: true,
      title: 'Current Job',
      description: "Quickly Access Route Information",
      icon: "current-job",
      showSizePositionControl: false,
      isActive: true
    };
  }
  private getPreviousJobCard(): EdgeCard {
    return {
      type: 'Previous Job',
      position: 1,
      size: 6,
      showHeaderBar: false,
      title: 'Previous Job',
      description: "View Previous Job Details",
      showSizePositionControl: false,
      isActive: true,
      showTitleInBody: true
    };    
  }

  private getPreassignedJobCard(): EdgeCard {
    return {
      type: 'Preassigned Job',
      position: 2,
      size: 6,
      showHeaderBar: false,
      title: 'Preassigned Job',
      description: "View Preassigned Job Details",
      showSizePositionControl: false,
      isActive: true,
      showTitleInBody: true
    };    
  }


  private getPayCard(): EdgeCard {
    return {
      type: 'Pay',
      position: 3,
      size: 6,
      showHeaderBar: false,
      title: 'Pay',
      description: "View Payroll Details",
      icon: "payroll",
      showSizePositionControl: false,
      isActive: true,
      showTitleInBody: true
    };
  }

  private getOpportunitiesCard(): EdgeCard {
    return {
      type: 'Opportunities',
      position: 4,
      size: 6,
      showHeaderBar: false,
      title: 'Career Opportunities',
      description: "Quickly Access Career Opportunities List",
      icon: "opportunities",
      showSizePositionControl: false,
      isActive: true,
      showTitleInBody: true
    };
  }


  private getMessageCard(): EdgeCard {
    return {
      type: 'Message',
      position: 5,
      size: 6,
      showHeaderBar: false,
      title: 'Message',
      description: "View Message Details",
      showSizePositionControl: false,
      isActive: true,
      showTitleInBody: true
    };    
  }   

  private getWorkdayCard(): EdgeCard {
    return {
      type: 'Workday',
      position: 6,
      size: 6,
      title: 'Workday',
      description: "Go To Your Workday Page",
      showSizePositionControl: false,
      showTitleInBody: true,
      isActive: true
    };
  }

  public getSafetyCard(): EdgeCard {
    return {
      type: 'Safety',
      position: 7,
      size: 6,
      showHeaderBar: false,
      title: 'Safety',
      description: "Easily Monitor Safety Stats And Goals",
      icon: "safety",
      showSizePositionControl: false,
      isActive: true,
      showTitleInBody: true
    };
  }  

  public getReferalCard(): EdgeCard {
    return {
      type: 'Refer Driver',
      position: 8,
      size: 6,
      showHeaderBar: false,
      title: 'Refer Driver',
      description: "View Refer Driver Details",
      showSizePositionControl: false,
      isActive: true,
      showTitleInBody: true
    };    
  }    

  private getNewsCard(): EdgeCard {
    return {
      type: 'News',
      position: 9,
      size: 6,
      showHeaderBar: true,
      title: 'News',
      description: "Keep Up On The Latest News At Werner",
      icon: "fitness-outline",
      showSizePositionControl: false,
      isActive: true
    };
  }

  
  public getBlueCoinCard(): EdgeCard {
    return {
      type: 'BlueCoin',
      position: 7,
      size: 6,
      showOverlay: false,
      showHeaderBar: false,
      title: 'BlueCoin',
      description: "BlueCoin details",
      showSizePositionControl: false,
      isActive: true,
      showTitleInBody: true
    };     
  } 

  getCarouselCard(): EdgeCard {
    return {
      type: 'Carousel',
      size: 12,
      isActive: true
    };
  }

  getAddCard(): EdgeCard {
    return {
      type: 'Add',
      size: 6
    };
  }

  public getProfileCard(): EdgeCard {
    return {
      type: 'Profile',
      position: 11,
      size: 12,
      title: "Photo Greeting",
      description: "See a Personalized Photo Greeting",
      icon: "photo-greeting",
      showSizePositionControl: false,
      isActive: true
    };
  }
  
  public getUrgentCard(): EdgeCard {
    return {
      type: 'Urgent Care',
      position: 9,
      size: 6,
      showHeaderBar: false,
      title: 'Benefits',
      description: "Find Urgent Care Near You",
      icon: "fitness-outline",
      showSizePositionControl: false,
      isActive: true,
      showTitleInBody: true
    };
  }

  async ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
