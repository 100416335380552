import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';

@Component({
  selector: 'app-confirmation-action-modal',
  templateUrl: './confirmation-action-modal.component.html',
  styleUrls: ['./confirmation-action-modal.component.scss'],
})
export class ConfirmationActionModalComponent implements OnInit {
  @Input('title') title = '';
  @Input('firstContent') firstContent = '';
  @Input('secondContent') secondContent = '';
  @Input('thirdContent') thirdContent = '';
  @Input('buttonText') buttonText = '';
  @Output("tappedActionButton") tappedActionButton = new EventEmitter;
  @Output("tappedCloseButton") tappedCloseButton = new EventEmitter;



  constructor() { }

  ngOnInit() {}

  tappedActionButtonClickHandler() {
    this.tappedActionButton.emit();
  }

  tappedCloseButtonClickHandler() {
    this.tappedCloseButton.emit();
  }

}
