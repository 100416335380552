/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Answer } from './answer';
import { Image } from './image';

export interface UserLocationFeedback { 
    tripId?: string;
    locationCode?: string;
    splitId?: string;
    locationProfileCode?: string;
    comment?: string;
    images?: Array<Image>;
    answers?: Array<Answer>;
    overallRating?: number;
    categoryIdentifier?: string;
}