import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { EdgeCard } from 'src/app/shared/models/edge-card';

@Component({
  selector: "referal-driver-card",
  templateUrl: "./referal-driver-card.component.html",
  styleUrls: ["./referal-driver-card.component.scss"],
})
export class ReferalDriverCardComponent {
  @Input("card") card: EdgeCard;
  @Output("resize") resize = new EventEmitter();
  @Output("change") change = new EventEmitter();
  @Output("remove") remove = new EventEmitter();
  isMobile:boolean;
  isTab:boolean;
  constructor(
    private navCtrl: NavController,
    private platform:Platform
  ) { 
    this.isMobile = platform.is('mobile') || platform.is('mobileweb');
    this.isTab = platform.is('tablet')
  }

  tapCard() {
    this.navCtrl.navigateRoot("/driver-referral");
  }

  resizeCard() {
    this.resize.emit(this.card);
  }

  changeCard() {
    this.change.emit(this.card);
  }

  removeCard() {
    this.remove.emit(this.card);
  }
}
