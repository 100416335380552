import { Component } from '@angular/core';

@Component({
  selector: 'app-incomplete-document-message-card',
  templateUrl: './incomplete-document-message-card.component.html',
  styleUrls: ['./incomplete-document-message-card.component.scss'],
})
export class IncompleteDocumentMessageCardComponent {

}
