<div class="background">
  <div class="modal">
    <ion-row>
      <ion-item class="title-row" lines="none">
        <ion-label class="title-label">Feedback for <br>
          <p>{{selectedPOIName}}</p>
         <i> {{selectedPOIAddress}}</i>
        </ion-label> 
      </ion-item>
    </ion-row>
    <ion-row>
      <ion-item lines="none">       
         <ion-textarea class="text-area" #feedbackTextArea placeholder="Please enter your Feedback here" [(ngModel)]="message">
         </ion-textarea>
      </ion-item>
    </ion-row>
    <ion-row >
      <ion-col size="6">
        <ion-button class="ion-color ion-color-medium cancel-button" (click)="cancelFeedbackSubmission()" expand="block">
           Cancel
        </ion-button>    
      </ion-col>
      <ion-col size="6">
        <ion-button class="primary"  (click)="submitFeedback()" expand="block">
           <ion-label>Submit</ion-label>
        </ion-button>    
      </ion-col>
    </ion-row>
    <ion-row >
      <ion-col>
        <span class="feedback-disclaimer">
          Comments will be reviewed by Werner and passed to location representatives if appropriate.
        </span>      
      </ion-col>      
    </ion-row>
  </div>
</div>