import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input("title") title: string = "Set a title";
  @Input("subtitle") subtitle: string = "Set a subtitle";
  @Input("status") status: string = "";
  @Output("didTapClose") didTapClose = new EventEmitter;

  close() {
    this.didTapClose.emit();
  }
}
