<ion-header translucent class="ion-safe-area-top">
  <ion-toolbar>
    <div class="werner-button text" (click)="back()">Back</div>
  </ion-toolbar>
</ion-header>
<ion-card>
  <ion-card-header>
    <ion-card-title class="edge-value">{{ employee?.fullName }}</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-item lines="none">
      <ion-label class="edge-label">Phone</ion-label>
      <a class="edge-value" href="tel:{{ employee?.phoneNumber }}"
        ><ion-text>{{ this.formattedPhoneNumber | phoneFormat }}</ion-text></a
      >
    </ion-item>
    <ion-item lines="none">
      <ion-label class="edge-label">Email</ion-label>
      <ion-text class="edge-value">{{ employee?.emailAddress }}</ion-text>
    </ion-item>
  </ion-card-content>
</ion-card>
