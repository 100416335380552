import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { ConfigurationV2 } from './configurationV2';
import { HttpClient } from '@angular/common/http';


import { RequestService } from './api/request.service';
@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    RequestService ]
})
export class ApiV2Module {
    public static forRoot(configurationFactory: () => ConfigurationV2): ModuleWithProviders<ApiV2Module> {
        return {
            ngModule: ApiV2Module,
            providers: [ { provide: ConfigurationV2, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiV2Module,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
