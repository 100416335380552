import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EdgeCard } from '../../models/edge-card';

@Component({
  selector: 'we-card',
  templateUrl: './we-card.component.html',
  styleUrls: ['./we-card.component.scss'],
})
export class WeCardComponent implements OnInit {
  @Input('card') card: EdgeCard;
  @Output('didTapResize') didTapResize = new EventEmitter();
  @Output('didTapChange') didTapChange = new EventEmitter();
  @Output('didTapRemove') didTapRemove = new EventEmitter();
  
  constructor() { }

  ngOnInit() {}

  resize(card: EdgeCard) {
    this.didTapResize.emit(card);
  }

  change(card: EdgeCard) {
    this.didTapChange.emit()
  }

  remove(card: EdgeCard) {
    this.didTapRemove.emit()
  }
}
