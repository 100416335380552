import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'map-carousel-item',
  templateUrl: './map-carousel-item.component.html',
  styleUrls: ['./map-carousel-item.component.scss'],
})
export class MapCarouselItemComponent implements OnInit {

  @Input('item') item: any

  private isSelected = false
  constructor() { }

  ngOnInit() { }

  selectedCard() {
    this.isSelected = !this.isSelected
  }

  labelStyle() {
    return this.isSelected ? { 'color': '#000000' } : { 'color': '#a0a0a0' }
  }

  cardStyle() {
    return this.isSelected ? { 'background': '#d6e1f0' } : { 'background': '#ffffff' }
  }
}
