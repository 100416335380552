import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-yes-no-action-modal',
  templateUrl: './yes-no-action-modal.component.html',
  styleUrls: ['./yes-no-action-modal.component.scss'],
})
export class YesNoActionModalComponent implements OnInit {
  @Input('title') title = '';
  @Input('content') content = '';
  @Input('yesButtonText') yesButtonText = '';
  @Input('noButtonText') noButtonText = '';
  @Output("tappedCloseButton") tappedCloseButton = new EventEmitter;
  @Output("yesButton") yesButton = new EventEmitter;
  @Output("noButton") noButton = new EventEmitter;

  constructor() { }

  ngOnInit() {}


  // Handler called when the user clicks on the close button
  tappedCloseButtonClickHandler() {
    this.tappedCloseButton.emit();
  }

  // Handler called when the user clicks on the yes button
  yesButtonClickHandler() {
    this.yesButton.emit();
  }

  // Handler called when the user clicks on the no button
  noButtonClickHandler() {
    this.noButton.emit();
  }

}
