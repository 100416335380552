<ion-card style="height:140px !important;" *ngIf="filteredMessages.length <= 0">
  <ion-card-content>
    <div>
      You have no Messages
    </div>
  </ion-card-content>
</ion-card>



  <ion-list class="list" *ngIf="filteredMessages !== null && filteredMessages !== undefined">

    <ion-item 
	  class="pointer"
      lines="full"
      detail="true"
      *ngFor="let messageItem of filteredMessages"
      (click)="showMessage(messageItem)" [class.unread]="messageItem?.isParticipantRead === false">
       <ion-label>
        <div class="edge-label">  {{messageItem.createdDate | date:'MMM dd, HH:mm'}} </div>
        <ion-item-sliding>
          <span>
            <div class="unreadSubject" *ngIf="messageItem?.isParticipantRead === false">
              <b> {{ messageItem.subject }}</b>
             </div>
           <div *ngIf="messageItem?.isParticipantRead === true">
            {{ messageItem.subject }}
           </div>
          </span>
        </ion-item-sliding>
      </ion-label>
    </ion-item>

  </ion-list>
