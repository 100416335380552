import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NavController } from "@ionic/angular";
import { EdgeCard } from 'src/app/shared/models/edge-card';

@Component({
  selector: "previous-job-card",
  templateUrl: "./previous-job-card.component.html",
  styleUrls: ["./previous-job-card.component.scss"],
})
export class PreviousJobComponent {
  @Input("card") card: EdgeCard;
  @Output("resize") resize = new EventEmitter();
  @Output("change") change = new EventEmitter();
  @Output("remove") remove = new EventEmitter(); 
  
  constructor(
    private navCtrl: NavController
  ) { }

  tapCard() {
    // to prevent sonar error , we replaced short ifs
    if(this.card.showSizePositionControl || this.card.preview){
      this.card.showOverlay = !this.card.showOverlay;
    }    
    else {
       this.navCtrl.navigateRoot("/loads", { queryParams: { page: "Previous" } }); 
    }
  }

  resizeCard() {
    this.resize.emit(this.card);
  }

  changeCard() {
    this.change.emit(this.card);
  }

  removeCard() {
    this.remove.emit(this.card);
  }
}
