/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AverageMilesByDriverType } from './averageMilesByDriverType';
import { AveragePayByDriverType } from './averagePayByDriverType';
import { Shift } from './shift';

export interface JobOpportunity {
    accountCode?: string;
    name?: string;
    description?: string;
    averageMiles?: string;
    home?: string;
    averageStops?: string;
    visibleToDriver?: boolean;
    hotsheet?: boolean;
    pay?: string;
    operation?: string;
    other?: string;
    updatedDate?: Date;
    regional?: boolean;
    dedicated?: boolean;
    zone?: boolean;
    teams?: boolean;
    ownerOps?: boolean;
    student?: boolean;
    trainer?: boolean;
    billTo?: string;
    division?: string;
    cityState?: string;
    tempInfo?: string;
    groupId?: number;
    groupName?: string;
    shifts?: Array<Shift>;
    averageMilesByDriverTypes?: Array<AverageMilesByDriverType>;
    referral?: string;
    relocation?: string;
    signOnBonus?: string;
    averagePayByDriverType?: Array<AveragePayByDriverType>;
}
