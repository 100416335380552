
<ion-card class="edge-card">
  <card-overlay 
    [card]="card"
    (didTapCard)="tapCard()"
    (didTapResize)="resizeCard()"
    (didTapChange)="changeCard()"
    (didTapRemove)="removeCard()"
  ></card-overlay>
    <img src="{{imageUrl}}">
    <div>
      <div class="edge-value news-card-title">{{articleTitle}}</div>
      <div class="edge-value news-card-subtitle">{{articleReleaseDate | date : 'EEEE, MMM d, y'}}</div>
    </div>
</ion-card>