/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WernerDwpContractsLogicFeedbackFeedbackImageDetails } from './wernerDwpContractsLogicFeedbackFeedbackImageDetails';
import { WernerDwpContractsLogicFeedbackFeedbackResponse } from './wernerDwpContractsLogicFeedbackFeedbackResponse';

export interface FeedbackDetails { 
    userIdentityKey?: string;
    tripId?: string;
    splitId?: string;
    locationProfileCode?: string;
    locationId?: string;
    comment?: string;
    images?: Array<WernerDwpContractsLogicFeedbackFeedbackImageDetails>;
    answers?: Array<WernerDwpContractsLogicFeedbackFeedbackResponse>;
    overallRating?: number;
    categoryIdentifier?: string;
}