
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DriverReferralAction,DriverReferralActionTypes } from './driver-referral.actions';
import * as fromRoot from '../../../app.state';
import { RequestResponse } from 'src/app/Swagger-Gen';



export interface State extends fromRoot.State {
    driverReferral: DriverReferralState;
    
}

export interface DriverReferralState {
    driverReferralResponse: RequestResponse;
    error: any;
}

const initialState: DriverReferralState = {
    driverReferralResponse: null,
    error: ''
};

const getDriverReferralFeatureState = createFeatureSelector<DriverReferralState>('driverReferral');

export const getDriiverReferralResponse = createSelector(
    getDriverReferralFeatureState ,
    state =>  (state != null) ? state.driverReferralResponse : null
);


export const getError = createSelector(
    getDriverReferralFeatureState,
    state => state.error
);


export function driverReferralReducer(state= initialState, action: DriverReferralAction): DriverReferralState {
    switch (action.type) {
        case DriverReferralActionTypes.PostDriverReferralSuccess:
            return {
                ...state,
                driverReferralResponse: action.payload,
                error: ''
            };
        case DriverReferralActionTypes.PostDriverReferralFail:
            return {
                ...state,
                driverReferralResponse: null,
                error: action.payload
            };
        case DriverReferralActionTypes.UpdateDriverReferralResponse:
                return {
                    ...state,
                    driverReferralResponse: action.payload,
                    error: null
                };
        default:
            return state;
    }
}
