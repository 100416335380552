/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SafetyCsaInspection } from './SafetyCsaInspection';

export interface SafetyCsaDetails { 
    totalPoints?: string;
    projectedPoints?: string;
    csaInspections?: Array<SafetyCsaInspection>;
    items?:Array<any>;
}