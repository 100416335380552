import { Injectable } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';

@Injectable()
export class UrgentCareUtility {
    private geolocation = new Geolocation();

    public async  getUrgentCareUrl(url): Promise<string> {
        try {
            const response = await this.geolocation.getCurrentPosition();
            if (response) {
                const latitude = response.coords.latitude;
                const longitude = response.coords.longitude;

                const latLong = latitude + ',' + longitude;

                return url+latLong;
            }else{
                return url;
            }
        } catch (error) {
            return url;
        }
    }
}
