<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-back-button class="back-btn" [defaultHref]="url" text=""></ion-back-button>
    </ion-buttons>
    <ion-title class="edge-value ion-text-center">
      <div class="ion-text-wrap sub-header-title">
        {{title}}
      </div>
      </ion-title>
  </ion-toolbar>
</ion-header>