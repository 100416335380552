/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Fulfillment } from './fulfillment';
import { Metadata } from './metadata';
import { Parameters } from './parameters';

export interface Result { 
    source?: string;
    resolvedQuery?: string;
    action?: string;
    actionIncomplete?: boolean;
    score?: number;
    parameters?: Parameters;
    contexts?: Array<any>;
    metadata?: Metadata;
    fulfillment?: Fulfillment;
}
