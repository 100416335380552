/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Message } from './message';

export interface Thread { 
    id?: number;
    routingType?: number;
    senderId?: string;
    author?: string;
    authorId?: string;
    employeeNumber?: number;
    tractorNumber?: number;
    subject?: string;
    status?: number;
    parentThreadId?: number;
    createdBy?: string;
    createdDate?: Date;
    messages?: Array<Message>;
    watchThread?: boolean;
    updatedDate?: Date;
    attachmentsCount?: number;
    initiatorId?: string;
    threadType?: number;
    canReply?: boolean;
    readByDriver?: boolean;
    participantsIds?: Array<string>;
}