<ion-content>
  <!-- Template for other POI types-->
  <div *ngIf="selectedPoiProperties.title !== 'fuelStops' && selectedPoiProperties.title !== 'restAreas' && selectedPoiProperties.title !== 'truckWashes'">
    <ion-card class="poi-map-details">
      <ion-grid fixed>
        <ion-row>
          <ion-col size="12">
            <ion-header class="ion-no-border">
              <ion-button fill="clear" expand="full" class="ion-text-center">
                <div class="drawer-pull" (click)="closeModal(false)"></div>
              </ion-button>
            </ion-header>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="ion-text-center sub-header">
            <img class="drawer-image" src="{{ selectedPOIImage }}" alt="" />
            <ion-text class="drawer-title ion-text-center">
              {{ selectedPoiProperties.name }}
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <div>
                <ion-label class="address-text">{{ selectedPoiProperties.name }}</ion-label>
              </div>
              <div>
                <ion-text class="direction-text">{{
                  selectedPoiProperties.location.streetAddress1
                  }}</ion-text>
              </div>
              <div *ngIf="selectedPoiProperties.location.streetAddress2">
                <ion-text class="direction-text">{{
                  selectedPoiProperties.location.streetAddress2
                  }}</ion-text>
              </div>
              <ion-text class="direction-text">{{ selectedPoiProperties.location.city }}
                {{ selectedPoiProperties.location.state }}
                {{ selectedPoiProperties.location.zip }}</ion-text>
            </ion-col>
            <ion-col class="buttons" *ngIf="selectedPoiProperties.phoneVoice">
              <a href="tel:{{ selectedPoiProperties.phoneVoice }}">
                <ion-button>
                  <ion-icon color="primary" name="call"></ion-icon>
                </ion-button>
              </a>
            </ion-col>
            <ion-col class="buttons" *ngIf="selectedPoiProperties.title == 'dropyards' && selectedPoiProperties.location.city ==  'Cheyenne' && selectedPoiProperties.location.state ==  'WY'">
              <ion-button (click)="openPDFLink()">
                <span class="fa fa-p"></span>
              </ion-button>
            </ion-col>
            <ion-col class="buttons">
              <ion-button (click)="closeModal(true)">
                <ion-icon name="star" color="primary"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-button expand="full" color="primary" class="blue-action-button" (click)="closeModal(false)">
          <ion-label class="blue-action-button-label">Close</ion-label>
        </ion-button>
      </ion-card-content>
    </ion-card>
  </div>

  <!-- Template for Fuel Stops and Rest Areas-->
  <div *ngIf="selectedPoiProperties.title === 'fuelStops' || selectedPoiProperties.title === 'restAreas' || selectedPoiProperties.title === 'truckWashes'">
    <ion-card>
      <ion-grid fixed>
        <ion-row>
          <ion-col size="12">
            <ion-header class="ion-no-border">
              <ion-button fill="clear" expand="full" class="ion-text-center">
                <div class="drawer-pull" (click)="closeModal(false)"></div>
              </ion-button>
            </ion-header>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="ion-text-center sub-header">
            <ion-text class="drawer-title ion-text-center">
              {{ selectedPoiProperties.name }}
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row class="header-divider ion-padding-bottom">
        </ion-row>
      </ion-grid>
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <div>
                <ion-text class="address-text">{{
                  selectedPoiProperties.location.streetAddress1
                  }}</ion-text>
              </div>
              <div *ngIf="selectedPoiProperties.location.streetAddress2">
                <ion-text class="address-text">{{
                  selectedPoiProperties.location.streetAddress2
                  }}</ion-text>
              </div>
              <ion-text class="address-text">
                {{ selectedPoiProperties.location.city }},
                {{ selectedPoiProperties.location.state }}
                {{ selectedPoiProperties.location.zip }}
              </ion-text>
            </ion-col>
            <ion-col class="buttons" *ngIf="selectedPoiProperties.title === 'fuelStops' && selectedPoiProperties.phoneVoice">
              <a href="tel:{{ selectedPoiProperties.phoneVoice }}">
                <ion-button>
                  <ion-icon color="primary" name="call"></ion-icon>
                </ion-button>
              </a>
            </ion-col>
            <ion-col class="buttons" *ngIf="selectedPoiProperties.title === 'truckWashes' && selectedPoiProperties.phone">
              <a href="tel:{{ selectedPoiProperties.phone }}">
                <ion-button>
                  <ion-icon color="primary" name="call"></ion-icon>
                </ion-button>
              </a>
            </ion-col>
            <ion-col class="buttons">
              <ion-button (click)="closeModal(true)">
                <ion-icon name="star" color="primary"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="directions" *ngIf="selectedPoiProperties.title === 'fuelStops' && 
              selectedPoiProperties.directions !== null &&
              selectedPoiProperties.directions.length > 0  
            ">
            <ion-col size="10">
              <ion-text class="direction-text">
                {{ selectedPoiProperties.directions }}
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="directions" *ngIf="selectedPoiProperties.title === 'truckWashes' && selectedPoiProperties.direction">
            <ion-col size="10">
              <ion-text class="direction-text">
                {{ selectedPoiProperties.direction }}
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="selectedPoiProperties.title === 'fuelStops'">
            <ion-col size="6">
              <ion-item class="align-left no-border" *ngIf="selectedPoiProperties.parkingStalls !== null">
                <fa-icon [icon]="['fal', 'parking']" class="icon"></fa-icon>
                <ion-text class="text">{{ selectedPoiProperties.parkingStalls }} Parking
                  Stalls</ion-text>
              </ion-item>
            </ion-col>
            <ion-col size="6">
              <ion-item class="no-border" *ngIf="selectedPoiProperties.showerStalls !== null">
                <fa-icon [icon]="['fal', 'raindrops']" class="icon"></fa-icon>
                <ion-text class="text shower-stalls-text">{{ selectedPoiProperties.showerStalls }} Shower Stalls
                </ion-text>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="selectedPoiProperties.title === 'fuelStops'">
            <ion-col>
              <ion-item class="align-left no-border negative-top-margin" *ngIf="
                  selectedPoiProperties.brand !== null &&
                  selectedPoiProperties.brand.length > -1
                ">
                <fa-icon [icon]="['fal', 'wrench']" class="icon"></fa-icon>
                <ion-text class="text">{{
                  selectedPoiProperties.brand
                  }}</ion-text>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="selectedPoiProperties.title === 'restAreas'">
            <ion-col size="7">
              <ion-item class="align-left no-border" *ngIf="selectedPoiProperties.availableParkingSpots != null && selectedPoiProperties.totalParkingSpots != null">
                <fa-icon [icon]="['fal', 'parking']" class="icon"></fa-icon>
                <ion-text class="text">{{ selectedPoiProperties.availableParkingSpots }} of {{ selectedPoiProperties.totalParkingSpots }} 
                  stalls available</ion-text>
              </ion-item>
            </ion-col>
            <ion-col size="5">
              <ion-item class="no-border" *ngIf="selectedPoiProperties.open !== null">
                <fa-icon [icon]="['fal', 'question-circle']" class="icon"></fa-icon>
                <ion-text class="text" *ngIf="selectedPoiProperties.open"> Open </ion-text>
                <ion-text class="text" *ngIf="!selectedPoiProperties.open"> Closed </ion-text>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="selectedPoiProperties.title === 'restAreas'">
            <ion-col>
              <ion-item class="align-left no-border negative-top-margin" *ngIf="
                  selectedPoiProperties.lastUpdatedDatetime !== null
                ">
                <fa-icon [icon]="['fal', 'sync-alt']" class="icon"></fa-icon>
                <ion-text class="text">Last updated at {{
                  selectedPoiProperties.lastUpdatedDatetime | date : 'yyyy-MM-dd HH:mm:ss Z'
                  }}</ion-text>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-button expand="full" color="primary" class="blue-action-button" (click)="closeModal(false)">
          <ion-label class="blue-action-button-label">Close</ion-label>
        </ion-button>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>