import {Injectable, Injector} from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';


import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as CurrentTripActions from './trip-load-infos.actions';
import {GetCurrentTrip, GetTrailerReservation} from './trip-load-infos.actions';
import { RequestService } from 'src/app/Swagger-Gen-V2';


@Injectable()
export class GetCurrentTripEffects {
    tripsService: RequestService = this.injector.get(RequestService);
    constructor(private injector: Injector, private actions$: Actions) {
    }
    @Effect()
    loadLoadStatus$: Observable<Action> = this.actions$.pipe(
        ofType(CurrentTripActions.CurrentTripActionTypes.GetCurrentTrip),
        mergeMap(action =>
            this.tripsService.tripsCurrentGet((action as GetCurrentTrip).employeeNumber).pipe(
                map(currentTrip => ( new CurrentTripActions.GetCurrentTripSuccess(currentTrip))),
                catchError(err => of(new CurrentTripActions.GetCurrentTripFail(err)))
            )
        )
    );
    
    @Effect()
    loadTrailerReservation$: Observable<Action> = this.actions$.pipe(
        ofType(CurrentTripActions.CurrentTripActionTypes.GetTrailerReservation),
        mergeMap(action =>
            this.tripsService.trailerreservationGet((action as GetTrailerReservation).employeeNumber).pipe(
                map(trailerReservation => ( new CurrentTripActions.GetTrailerReservationSuccess(trailerReservation))),
                catchError(err => of(new CurrentTripActions.GetTrailerReservationFail(err)))
            )
        )
    );
    
    @Effect()                
    loadTrailers$: Observable<Action> = this.actions$.pipe(
        ofType(CurrentTripActions.CurrentTripActionTypes.GetTrailers),
        mergeMap(action =>
            this.tripsService.trailersGet((action as CurrentTripActions.GetTrailers).trailerNumbers).pipe(
                map(trailers => ( new CurrentTripActions.GetTrailersSuccess(trailers))),
                catchError(err => of(new CurrentTripActions.GetTrailersFail(err)))
            )
        )
    );
    
    @Effect()
    loadSiteSafetyalertUrl$: Observable<Action> = this.actions$.pipe(
        ofType(CurrentTripActions.CurrentTripActionTypes.GetSiteSafetyalertUrl),
        mergeMap(action =>
            this.tripsService.sitesafetyalerturlGet((action as CurrentTripActions.GetSiteSafetyalertUrl).locationCode).pipe(
                map(url => ( new CurrentTripActions.GetSiteSafetyalertUrlSuccess(url))),
                catchError(err => of(new CurrentTripActions.GetSiteSafetyalertUrlFail(err)))
            )
        )
    );
}
