import {Injectable, OnInit} from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, Photo, CameraSource} from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { Platform, ToastController } from '@ionic/angular';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { NgxImageCompressService } from 'ngx-image-compress';
interface IPhoto {
  filepath: string;
  webviewPath: string;
  base64?: string;
  filename?: string;
}
@Injectable({
  providedIn: 'root'
})
export class CameraService {

  public photo: IPhoto;
  public selectedFileName = '';
  public theCapturedPhoto: Photo;
  private PHOTO_STORAGE  = 'photo';
  private platform: Platform;
  public base64ImageString : string;

  constructor(platform: Platform, private toastController: ToastController,private chooser:Chooser,private imageCompress: NgxImageCompressService) {
    this.platform = platform;
  }

  // retrieves image from gallery
  public async getImageFromGallery() {
    const capturedPhoto = await this.chooser.getFile('image/*');
    const dataURI = await this.compressImage(capturedPhoto.dataURI);
      
    this.base64ImageString = dataURI.split("base64,")[1];
    this.selectedFileName = capturedPhoto.name;
 }

  public async imageFromPhotoGallery() {
    const capturedPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        quality: 100
      });

    this.theCapturedPhoto = capturedPhoto;

    await this.savePicture(capturedPhoto);

    await this.storePhoto();

  }

  private async storePhoto() {
    await Preferences.remove({key: this.PHOTO_STORAGE});
    await Preferences.set({
      key: this.PHOTO_STORAGE,
      value: JSON.stringify(this.photo)
    });
  }

  public async loadSaved() {
    // Retrieve cached photo array data
    const initPhoto: IPhoto = { filepath: '', webviewPath: ''};
    const photo = await Preferences.get({ key: this.PHOTO_STORAGE });
    this.photo = JSON.parse(photo.value) || initPhoto;
  }

  // retrieves image from camera using a given quality ration
  public async getImageFromCamera(imageQuality: number) {
    if (imageQuality >= 1 && imageQuality <= 100) {
      const capturedPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Base64,
        source: CameraSource.Camera,
        width : 720,
        quality: imageQuality
      });
      this.theCapturedPhoto = capturedPhoto;
      this.base64ImageString = await this.compressImage('data:image/jpeg;base64,'+capturedPhoto.base64String);
      this.base64ImageString = this.base64ImageString.split("base64,")[1];
      this.selectedFileName = new Date().getTime() + '.' + capturedPhoto.format;  
    }
  }

  public async imageFromCamera() {
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100
    });
    this.photo.webviewPath = capturedPhoto.webPath;

    this.theCapturedPhoto = capturedPhoto;
    await this.savePicture(capturedPhoto);
    await this.storePhoto();
  }

  private async savePicture(cameraPhoto: Photo) {
    const base64Data = await this.readAsBase64(cameraPhoto);

    // Write the file to the data directory
    const fileName = new Date().getTime() + '.jpeg';
    this.selectedFileName = fileName;
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data
    });

    if (this.platform.is('hybrid')) {
        // Display the new image by rewriting the 'file://' path to HTTP
        // Details: https://ionicframework.com/docs/building/webview#file-protocol
        this.photo.filepath = savedFile.uri;
        this.photo.webviewPath = Capacitor.convertFileSrc(savedFile.uri);
    } else {
        // Use webPath to display the new image instead of base64 since it's
        // already loaded into memory
        this.photo.filepath = fileName;        
        this.photo.webviewPath = cameraPhoto.webPath;
    }
  }

  public async readAsBase64(cameraPhoto: Photo) {
    if (this.platform.is('hybrid')) {
      // Read the file into base64 format
      const file = await Filesystem.readFile({
        path: cameraPhoto.path
      });

      return file.data;
    } else {
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(cameraPhoto.webPath);
      const blob = await response.blob();

      return await this.convertBlobToBase64(blob) as string;
    }
  }
  // comprees image for share a story mail template
  async compressImage(image){
    return await this.imageCompress
    .compressFile(image, 1, 50, 50)
  }  

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  })

  async storeFileInBrowserStorage(file, filename) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          try {
            await Preferences.remove({ key: this.PHOTO_STORAGE });
            let latestPhoto = { "filepath": reader.result.toString(), "webviewPath": reader.result.toString(), "filename": filename }
            this.photo = latestPhoto
            await Preferences.set({
              key: this.PHOTO_STORAGE,
              value: JSON.stringify(latestPhoto)
            });
            await this.loadSaved()
          } catch (error) {
              const toast = await this.toastController.create({
                message: 'Image too big. Please try other image...',
                color: 'danger',
                duration: 2000,
                cssClass: "ion-text-center",
              });
              toast.present();
              reject(error)
          }
          resolve(reader.result.toString())
        };
      } catch (error) {
        reject(error)
      }

    })
  }

  async getImageUrl(input) {
    const file = input.target.files[0];
    const filename = input.target.files[0].name
    await this.storeFileInBrowserStorage(file, filename)
  }
}
