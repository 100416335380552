import {Injectable, Injector} from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';


/* NgRx */
import { Action } from '@ngrx/store';
import { Actions,  Effect,  ofType } from '@ngrx/effects';
import * as PreAssignedTripCardActions from './pre-assigned-trip-card.actions';
import {GetPreAssignedTrip} from './pre-assigned-trip-card.actions';
import { RequestService } from 'src/app/Swagger-Gen-V2';



@Injectable()
export class GetPreAssignedTripEffects {
    preAssignedTripsService: RequestService = this.injector.get(RequestService);
    constructor(private injector: Injector, private actions$: Actions) {
    }
    @Effect()
    loadLoadStatus$: Observable<Action> = this.actions$.pipe(
        ofType(PreAssignedTripCardActions.PreAssignedTripCardActionTypes.GetPreAssignedTrip),
        mergeMap(action =>
            this.preAssignedTripsService.tripsGet('NEXT',(action as GetPreAssignedTrip).employeeNumber).pipe(
                map(preAssignedTrip => ( new PreAssignedTripCardActions.GetPreAssignedTripSuccess(preAssignedTrip))),
                catchError(err => of(new PreAssignedTripCardActions.GetPreAssignedTripFail(err)))
            )
        )
    );

}


