import { Component, OnInit, Input } from "@angular/core";
import { NavController } from "@ionic/angular";
import { EdgeCard } from 'src/app/shared/models/edge-card';
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "carousel-card",
  templateUrl: "./carousel-card.component.html",
  styleUrls: ["./carousel-card.component.scss"],
})
export class CarouselCardComponent implements OnInit {
  @Input('card') card: EdgeCard;
  driverId = "";

  carouselItems = [
    {
      title: "Provide Feedback",
      image: "assets/Images/positive-vote.png",
      style: "default",
    },
  ];

  sliderConfig = {
    slidesPerView: 1.6,
    spaceBetween: 3,
  };

  constructor(
    private navCtrl: NavController,
    private authService : AuthService
  ) { }

  async ngOnInit() {
    this.driverId = await this.authService.getUserIdentitityKey();
  }

  navigateTo(path) {
    if (!this.card.preview) {
      if (path) {
        this.navCtrl.navigateForward(path);
      } else {
        let mailTo = "mailto:dw2feedback@werner.com?subject=Drive%20Werner%202.0%20Feedback&body=My%20feedback%20is%3A";
        window.open(mailTo, '_system')
      }
    }
  }
}
