/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Loadstop { 
    sequence?: number;
    name?: string;
    address1?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    phone?: string;
    latitude?: string;
    longitude?: string;
    openTimeStamp?: string;
    targetTimeStamp?: string;
    openStamp?: Date;
    targetStamp?: Date;
}