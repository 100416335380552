import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { ActionSheetController, AlertController } from "@ionic/angular";
import * as fromLoadSelectionReducer from "./../state/load-selection.reducer";
import * as loadSelectionActions from "./../state/load-selection.actions";
import { select, Store } from '@ngrx/store';
import { CallNumber } from '@ionic-native/call-number/ngx';
import {AnalyticsService} from "../../../../shared/services/analytics.service";
import { AuthService } from 'src/app/auth/auth.service';
import { Profile } from 'src/app/Swagger-Gen-V2';
import { ProfileInfoService } from 'src/app/modules/profile-v2/service/profileInfo.service';
import { ProfileUtility } from 'src/app/shared/utilities/profile-utility';
import { TelemetryUtility } from 'src/app/shared/utilities/telemetry-utilities';
import { TelemetryConstants } from 'src/app/shared/utilities/telemetry-constants';
import { JobLoadAssignment } from 'src/app/Swagger-Gen-V2/model/jobLoadAssignment';
import { JobLoad } from 'src/app/Swagger-Gen-V2/model/jobLoad';
import { JobStop } from 'src/app/Swagger-Gen-V2/model/JobStop';
import { forkJoin } from 'rxjs';
import { DispatchService } from 'src/app/shared/services/dispatchers/dispatch.service';
@Component({
  selector: 'app-load-selection-details',
  templateUrl: './load-selection-details.component.html',
  styleUrls: ['../load-selection.component.scss'],
})
export class LoadSelectionDetailsComponent implements OnInit, OnDestroy {
  selectedLoad: JobLoad;
  recommendedLoad$ = new Observable<object>();
  beginningStop: JobStop;
  endStop: JobStop;
  midStops: Array<JobStop>;
  fleetManagerNumber: string;
  userProfile : Profile;
  stakeholderId: number;

  constructor(public activatedRoute: ActivatedRoute, private alertController: AlertController, private loadSelectionStore: Store<fromLoadSelectionReducer.State>
    , public actionSheetController: ActionSheetController,
    private callNumber: CallNumber, private analytics: AnalyticsService, private telemetryUtility:TelemetryUtility, private authService : AuthService, private profileService : ProfileInfoService, private profileUtility : ProfileUtility, private dispatchService:DispatchService) { }

  subscription: Array<Subscription> = [];
  identityKey: string;
  async ngOnInit() {
    this.selectedLoad = JSON.parse(localStorage.getItem("load-details"));
    const arraylength = this.selectedLoad.loadStops.length;
    this.beginningStop = this.selectedLoad.loadStops[0];
    this.endStop = this.selectedLoad.loadStops[arraylength - 1];
    this.midStops = this.selectedLoad.loadStops.filter(_ => _.sequence > this.beginningStop.sequence && _.sequence < this.endStop.sequence);
  
    // using forkJoin to get multiple observables instead of promise.all. 
    // This will ensure that the observables are subscribed to at the same time and the results are returned together.
    this.subscription.push(
      forkJoin({
        identityKey: this.authService.getUserIdentitityKey(),
        userProfile: this.profileService.getUserProfileInfo()
      }).subscribe(async ({ identityKey, userProfile }) => {
        this.identityKey = identityKey;
        this.userProfile = userProfile;
    
        this.fleetManagerNumber = this.profileUtility.getFleetManagerPhoneNumber(this.userProfile);
        this.stakeholderId = await this.dispatchService.getStackholderId();
      })
    );
  
    this.subscription.push(this.loadSelectionStore
      .pipe(select(fromLoadSelectionReducer.getJobSelectionResponse))
      .subscribe((loadSelectionResponse) => {
        if (loadSelectionResponse && loadSelectionResponse.message) {
          this.alertDialogBox(loadSelectionResponse.message);
        }
      }));
  }

  async showLoadActions() {
    const alert = await this.alertController.create({
      cssClass: 'ion-confirm-alert',
      backdropDismiss: true,
      message:
        "Confirm you are selecting the full load for Trip ID " + this.selectedLoad.tripId + ".",
      buttons: [
        {
          text: "Confirm",
          handler: () => {

            this.analytics.trackEvent('User', 'Clicked Confirm Button On Load Selection Details Pop Up',
                'Clicked-Confirm-Button-On-Load-Selection-Details-Pop-Up');

            this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_CONFIRM_LOAD_SELECTION);

            let loadAssignmentReguest: JobLoadAssignment = {
              "stakeholderId": this.stakeholderId,
              "tripId": this.selectedLoad.tripId,
              "splitNumber": this.selectedLoad.splitNumber,
              "useRecommendedSplit": "yes"
            }
            this.loadSelectionStore.dispatch(
              new loadSelectionActions.PostSelectedLoad(loadAssignmentReguest));

          }
        },
        {
          text: "Call Transportation Manager",
          handler: () => {
            if (this.fleetManagerNumber) {

              this.analytics.trackEvent('User',
                  'Clicked Call Transportation Manager Button On Load Selection Details Pop Up',
                  'Clicked-Call-Transportation-Manager-Button-On-Load-Selection-Details-Pop-Up');

              this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_CALL_TRANPORTATION_MANAGER);


              this.callNumber.callNumber(this.fleetManagerNumber, true)
              .catch(err => {
                console.log('Error launching dialer', err);
                this.handleError(err);
              });
            }
          }
        }
      ],
    });

    await alert.present();
    const result = await alert.onDidDismiss();
  }

  async alertDialogBox(message: string) {
    const alert = await this.alertController.create({
      cssClass: 'ion-confirm-alert',
      backdropDismiss: false,
      message: message,
      buttons: [
        {
          text: "Ok",
          handler: () => {
            this.loadSelectionStore.dispatch(
              new loadSelectionActions.ResetLoadSelectionMessage());
          }
        }
      ],
    });

    await alert.present();
    const result = await alert.onDidDismiss();
  }

  async handleError(error: any) {
    const alert = await this.alertController.create({
      cssClass: 'ion-error-alert',
      backdropDismiss: false,
      message: 'An error occurred when launching the dialer',
      buttons: ['OK'],
    });
  
    await alert.present();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.forEach(s => {
        s.unsubscribe();
      });
    }
  }

}
