/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddressEditVM } from './addressEditVM';
import { IdentityAddressType } from './identityAddressType';
import {IAddressType} from "../../modules/profile/my-information/my-information.component";

export interface AddressesVM {
    addressTypes?: Array<IAddressType>;
    addresses?: Array<AddressEditVM>;
}
