/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PhoneNumber { 
    phoneId?: number;
    internationalCode?: number;
    areaCode?: number;
    number?: number;
    phoneType?: number;
    isPrimary?: boolean;
    isPrivate?: boolean;
    carrierId?: number;
    formattedNumber?: string;
    phoneTypeDescription?: string;
    isSubscribed?: boolean;
}