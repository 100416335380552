import { Component, EventEmitter, Input, Output} from "@angular/core";
import { Employee } from "src/app/Swagger-Gen";

@Component({
  selector: "app-account-contacts-card",
  templateUrl: "./account-contacts-card.component.html",
  styleUrls: ["./account-contacts-card.component.scss"],
})
export class AccountContactsCardComponent {
  @Input() title: string = "";
  @Input() employees: Employee[] = [];
  @Output() showDetails = new EventEmitter();

  showContactDetails(employee: Employee) {
    this.showDetails.emit(employee);  
  }
}
