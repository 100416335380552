<div class="map" id="map" #map></div>
<poi-modal
  *ngIf="showPOIModal"
  [poiFilter]="poiFilter"
  (selectedItem)="selectedPOICarouselItem($event)"
  (didTapClose)='closeModal()'
></poi-modal>

<div *ngIf="showLocateButton">
  <ion-button
    class="locate-control top"
    (click)="locateUser(9)"  >
    <div class="locate-background">
      <ion-icon class="locate" name="navigate"></ion-icon>
    </div>
  </ion-button>
</div>

<div *ngIf="showSearchHereButton" >
  <ion-button  

    class="search-here-control"
    color="primary"
    (click)="loadPOI()" >
    Search This Area
  </ion-button>
  </div>

  <!-- Show toggle button -->
  <div>
    <ion-button [ngClass]="{'satelite-fill':isSatelliteEnabled,'satelite-clear':!isSatelliteEnabled}" class="toggle-view-control top" (click)="toggle_map()" fill="clear">
      <div class="locate-background">
        <ion-icon class="compass-outline" name="compass-outline"></ion-icon>
      </div>
    </ion-button>
  </div>

<div *ngIf="isLoading" class="loading">
  <div class="flex-me col">
    Loading Pins
    <ion-spinner name="dots"></ion-spinner>
  </div>
</div>
<feedback-modal
  *ngIf="showFeedbackModal" 
  [selectedPOIName] = "selectedPoiProperties.name"
  [selectedPOIAddress]= "selectedPoiProperties.location.street1"
  (cancelFeedbackEvent)="cancelFeedbackSubmission()"
  (submitFeedbackEvent)="submitFeedback($event, selectedPoiProperties)"
></feedback-modal>
