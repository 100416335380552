/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Check } from './check';
import { CheckAdvance } from './checkAdvance';
import { CheckDeduction } from './checkDeduction';
import { CheckNote } from './checkNote';
import { CheckTripDetail } from './checkTripDetail';
import { Detail } from './detail';

export interface PayCheck { 
    employerAddress?: string;
    employerName?: string;
    employeeNumber?: number;
    employeeName?: string;
    taxState?: string;
    directDeposit?: string;
    fedWithholdingsStatus?: any;
    payCheckDate?: Date;
    checkNumber?: number;
    header?: any;
    detail?: Detail;
    checkGrossEarnings?: Array<Check>;
    grossEarningsTotal?: number;
    checkAdvances?: Array<CheckAdvance>;
    advanceTotal?: number;
    checkReimbursements?: Array<Check>;
    reimbursementsTotal?: number;
    checkDeductions?: Array<CheckDeduction>;
    deductionsTotalReimbursements?: number;
    checkTripDetails?: Array<CheckTripDetail>;
    deadHeadMilesTotal?: number;
    lineHaulMilesTotal?: number;
    tripMilesTotal?: number;
    tripPayTotal?: number;
    perDiemTotal?: number;
    perDiemDate?: string;
    checkNotes?: Array<CheckNote>;
    ratePerMile?: number;
    averageRatePerMile?: number;
    logHours?: number;
    beginDate?: Date;
    endDate?: Date;
    checkType?: string;
    trainerStudentIncentiveCheckDetails?: Array<any>;
    ssiDetailCheckTotal?: number;
    ssiCheckNotes?: Array<any>;
    potentialFutureTrainerStudentIncentiveCheckDetails?: Array<any>;
    pftsiCheckDetailTotal?: number;
}
