import {Component, EventEmitter,  Output} from '@angular/core';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-stop-options-modal',
  templateUrl: './stop-options-modal.component.html',
  styleUrls: ['./stop-options-modal.component.scss'],
})
export class StopOptionsModalComponent {

  @Output() tappedCloseButton = new EventEmitter();

  title = 'Choose An Option';
  SELECTED_STOP_KEY = 'selected_stop';
  CLICKED_TRIP_KEY = 'clicked_trip';
  siteSafetyAlertUrl = '';


  constructor(public route: Router, public modalController: ModalController) { }

  // Handler called when the user clicks on the close button
  tappedCloseButtonClickHandler() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  // Handler called when the user clicks on the location feedback button
  async locationFeedbackButtonClickHandler() {
    await Preferences.set({key: 'job-page-url', value: JSON.stringify({url:'/loads', tab:'Previous'})});
    this.route.navigate(['location-feedback']);
    this.tappedCloseButtonClickHandler();
  }
}
