import { Injectable } from "@angular/core";
import {  Platform } from "@ionic/angular";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { NavController } from "@ionic/angular";
import { PayAuthService } from "./pay-auth.service";
@Injectable({
  providedIn: "root",
})
export class PayAuthGuardService  {
  constructor(
    private payService: PayAuthService,
    private navCtrl: NavController,
    private platform: Platform
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async (resolve) => {
      // if user using mobiles , then allow them to access
      if (this.platform.is("mobile") && !this.platform.is("mobileweb")) {
        resolve(true);
      } else {
        // Allow users to request pin , each time user visit pay page
        this.payService.isUserAuthenticated.subscribe((authenticated) => {
          if (authenticated) {
            resolve(true);
          } else {
            this.navCtrl.navigateRoot("/pin");
          }
        });
      }
    });
  }
}
