import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { AnalyticsService } from '../../../services/analytics.service';
import { LoadingUtility } from 'src/app/shared/utilities/LoadingUtility';
import { SubSink } from 'subsink';
import { AuthService } from 'src/app/auth/auth.service';
import { TelemetryUtility } from 'src/app/shared/utilities/telemetry-utilities';
import { TelemetryConstants } from 'src/app/shared/utilities/telemetry-constants';
import { RequestService } from 'src/app/Swagger-Gen-V2';
import { ChallengeQuestion } from 'src/app/Swagger-Gen-V2/model/challengeQuestion';

@Component({
  selector: 'app-set-challenge-question',
  templateUrl: './set-challenge-question.component.html',
  styleUrls: ['./set-challenge-question.component.scss'],
})

export class SetChallengeQuestionComponent implements OnDestroy, OnInit {
  private subscriptions$ = new SubSink();
  driverId: string;
  answer = '';
  componentActive = true;
  questionId: number;
  questions: Array<ChallengeQuestion> = [];
  selectedQuestion: ChallengeQuestion = {};
  userEmail: string;

  constructor(
    private analytics: AnalyticsService,
    private telemetryUtility: TelemetryUtility,
    private loadingUtility: LoadingUtility,
    private toastController: ToastController,
    private authService: AuthService,
    private requestService: RequestService
  ) {
  }

  async ngOnInit() {
    await this.getQuestionData();
  }

  async getQuestionData() {
    this.analytics.trackEvent('User', 'Entered Challenge Question Screen', 'Entered-Challenge-Question-Screen');
    this.telemetryUtility.recordTelemetry(TelemetryConstants.ENTER_CHALLENGE_QUESTION);
    this.driverId = await this.authService.getUserIdentitityKey();
    await this.fetchAllQuestions();
    await this.fetchSelectedQuestion();
  }

  async fetchAllQuestions() {
    if (this.driverId) {
      this.subscriptions$.sink = this.requestService.challengeGet().subscribe(
        async (challengeQuestion) => {this.questions = challengeQuestion; },
        async () => { await this.showToaster('An error occurred. Please try after some time', 'danger'); });
    }
  }

  async fetchSelectedQuestion() {
    if (this.driverId) {
      this.subscriptions$.sink = this.requestService
        .challengeUserGet(this.driverId)
        .subscribe(async (challengeQuestion) => {
            this.selectedQuestion = challengeQuestion;
            this.questionId = parseFloat(challengeQuestion.questionId.toString());
          },
          async () => { await this.showToaster('An error occurred. Please try after some time', 'danger'); });
    }
  }

  setInput(value) {
    this.answer = value;
  }

  updateChallengeQuestion(event) {
    this.questionId = event.detail.value;
  }

  async showToaster(msg, type) {
    const toast = await this.toastController.create({
      message: msg,
      color: type,
      duration: 5000,
      cssClass: 'ion-text-center'
    });
    return toast.present();
  }

  async submitAnswer() {
    if (!this.questionId) {
      await this.showToaster('Please choose a question', 'danger');
      return false;
    } else if (!this.answer) {
      await this.showToaster('Please enter a answer', 'danger');
      return false;
    } else {
      this.analytics.trackEvent('User', 'Clicked Submit Button On Challenge Card', 'Clicked-Submit-Button-On-challenge-card');
      this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_SUBMIT_CHALLENGE_CARD);
      this.loadingUtility.showLoader('Please wait..');

      const request: ChallengeQuestion = {
        identityKey: this.driverId,
        questionId: Number(this.questionId),
        answer: this.answer
      };

      this.subscriptions$.sink = this.requestService
        .challengePost(request)
        .subscribe(async () => {
            this.loadingUtility.hideLoader();
            await this.showToaster('Challenge Question Saved Successfully', 'primary');
            this.answer = '';
          },
          async () => {
            this.loadingUtility.hideLoader();
            await this.showToaster('An error occurred. Please try after some time', 'danger');
          });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
