/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EventLocation } from './eventLocation';

export interface CriticalEventDetails { 
    tractorNumber?: number;
    tripid?: string;
    trailerNumber?: number;
    segmentOriginCity?: string;
    segmentOriginState?: string;
    segmentDestinationCity?: string;
    segmentDestinationState?: string;
    decelerationRate?: string;
    eventSpeed?: string;
    parkingBrakeStatus?: string;
    eventLocation?: EventLocation;
    eventLocationDateTime?: Date;
    rolloverStabilityCount?: number;
    driverName?: string;
    laneDepartureCount?: number;
    laneDepartureSeconds?: number;
    tractorStatus?: string;
    name?: string;
    eventDateTime?: Date;
    eventId?: number;
    eventCode?: string;
}