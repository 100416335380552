import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'paperwork-item',
  templateUrl: './paperwork-item.component.html',
  styleUrls: ['./paperwork-item.component.scss'],
})
export class PaperworkItemComponent implements OnInit {

  @Input('pay') pay
  color = 'blue'

  constructor(private navCtrl: NavController) { }

  ngOnInit() {}

  paperwork(type) : string {
    switch (type) {
      case "missing": {
        this.color = "#8A2423"
        return type + " Paperwork"
      }
      case "in-process": {
        this.color = "#9B6B00"
        return "Paperwork " + type
      }
      case "completed": {
        this.color = "green"
        return "Paperwork " + type
      }
    }
  }

  selected(route) {
    this.navCtrl.navigateForward('pay/' + route)
  }
}
