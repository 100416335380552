/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
export interface Detail {
    gross?: number;
    grossYtd?: number;
    perDiem?: number;
    perDiemYtd?: number;
    reimbursements?: number;
    reimbursementsYtd?: number;
    deductions?: number;
    deductionsYtd?: number;
    netPay?: number;
    netPayYtd?: number;
    socSecTax?: number;
    socSecTaxYtd?: number;
    federalTax?: number;
    federalTaxYtd?: number;
    stateTax?: number;
    stateTaxYtd?: number;
    medicare?: number;
    medicareYtd?: number;
    otherTax?: number;
    otherTaxYtd?: number;
    perDiemType?: string;
    netPayCheckDetail?: any;
}
