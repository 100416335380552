import { Component } from '@angular/core';
import {AnalyticsService} from "../../../shared/services/analytics.service";
import { MenuItemsService } from '../services/menu-items-service';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { TelemetryUtility } from 'src/app/shared/utilities/telemetry-utilities';
import { TelemetryConstants } from 'src/app/shared/utilities/telemetry-constants';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {

  logoSrc = 'assets/Images/Drive_Werner_Logo@3x.png';
  subscriptions$ = new SubSink();
 
  constructor(private analytics:AnalyticsService, 
    private telemetryUtility: TelemetryUtility,
    private menuItemsService: MenuItemsService,
    public router: Router
  ) {}

  // handler called when the user taps on the menu button
  async menuButtonTapped() {
    this.analytics.trackEvent('User', 'Opened Hamburger Menu', 'Opened-Hamburger-Menu');
    this.telemetryUtility.recordTelemetry(TelemetryConstants.OPEN_HAMBURGER);

    // Enable or disable items in the menu
    await this.menuItemsService.setupConfigurationAndMenuItems();
  }
  
  goToHome() {
    this.router.navigate(['/']);
  }
  
}