/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Attachment } from '../model/attachment';
import { DeleteMessagingThreadInputs } from '../model/deleteMessagingThreadInputs';
import { LegacyMessageBannerCard } from '../model/legacyMessageBannerCard';
import { MessageDetailsView } from '../model/messageDetailsView';
import { MessageThreadVM } from '../model/messageThreadVM';
import { MessagesThreadView } from '../model/messagesThreadView';
import { MessagesView } from '../model/messagesView';
import { NewMessageView } from '../model/newMessageView';
import { ReplyToMessageInputs } from '../model/replyToMessageInputs';
import { RoutingType } from '../model/routingType';
import { SendMessageInputs } from '../model/sendMessageInputs';
import { StandardMessagesRecipient } from '../model/standardMessagesRecipient';
import { Thread } from '../model/thread';
import { AdminMessageSendingDepartments } from '../model/adminMessageSendingDepartment';
import { AdminMessageRecipients } from '../model/adminMessageRecipients';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MessagingService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletes a given messaging thread
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingDeletemessagePost(body?: DeleteMessagingThreadInputs, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiMessagingDeletemessagePost(body?: DeleteMessagingThreadInputs, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiMessagingDeletemessagePost(body?: DeleteMessagingThreadInputs, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiMessagingDeletemessagePost(body?: DeleteMessagingThreadInputs, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/messaging/deletemessage`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the data necessary to construct the model for the message details view on the mobile application
     * 
     * @param id 
     * @param senderName 
     * @param content 
     * @param wasRead 
     * @param creatorName 
     * @param createdDate 
     * @param action 
     * @param attachments 
     * @param subject 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingMessagedetailsviewSimpleGet(id?: string, senderName?: string, content?: string, wasRead?: boolean, creatorName?: string, createdDate?: Date, action?: string, attachments?: Array<Attachment>, subject?: string, observe?: 'body', reportProgress?: boolean): Observable<MessageDetailsView>;
    public apiMessagingMessagedetailsviewSimpleGet(id?: string, senderName?: string, content?: string, wasRead?: boolean, creatorName?: string, createdDate?: Date, action?: string, attachments?: Array<Attachment>, subject?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageDetailsView>>;
    public apiMessagingMessagedetailsviewSimpleGet(id?: string, senderName?: string, content?: string, wasRead?: boolean, creatorName?: string, createdDate?: Date, action?: string, attachments?: Array<Attachment>, subject?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageDetailsView>>;
    public apiMessagingMessagedetailsviewSimpleGet(id?: string, senderName?: string, content?: string, wasRead?: boolean, creatorName?: string, createdDate?: Date, action?: string, attachments?: Array<Attachment>, subject?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (senderName !== undefined && senderName !== null) {
            queryParameters = queryParameters.set('senderName', <any>senderName);
        }
        if (content !== undefined && content !== null) {
            queryParameters = queryParameters.set('content', <any>content);
        }
        if (wasRead !== undefined && wasRead !== null) {
            queryParameters = queryParameters.set('wasRead', <any>wasRead);
        }
        if (creatorName !== undefined && creatorName !== null) {
            queryParameters = queryParameters.set('creatorName', <any>creatorName);
        }
        if (createdDate !== undefined && createdDate !== null) {
            queryParameters = queryParameters.set('createdDate', <any>createdDate.toISOString());
        }
        if (action !== undefined && action !== null) {
            queryParameters = queryParameters.set('action', <any>action);
        }
        if (attachments) {
            attachments.forEach((element) => {
                queryParameters = queryParameters.append('attachments', <any>element);
            })
        }
        if (subject !== undefined && subject !== null) {
            queryParameters = queryParameters.set('subject', <any>subject);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MessageDetailsView>('get',`${this.basePath}/api/messaging/messagedetailsview/simple`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrievesthe data neccessary to construct the model for the message details view on the mobile application
     * 
     * @param threadId 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingMessagedetailsviewThreadIdIdentityKeyGet(threadId: string, identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<MessageDetailsView>;
    public apiMessagingMessagedetailsviewThreadIdIdentityKeyGet(threadId: string, identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageDetailsView>>;
    public apiMessagingMessagedetailsviewThreadIdIdentityKeyGet(threadId: string, identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageDetailsView>>;
    public apiMessagingMessagedetailsviewThreadIdIdentityKeyGet(threadId: string, identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (threadId === null || threadId === undefined) {
            throw new Error('Required parameter threadId was null or undefined when calling apiMessagingMessagedetailsviewThreadIdIdentityKeyGet.');
        }

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiMessagingMessagedetailsviewThreadIdIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MessageDetailsView>('get',`${this.basePath}/api/messaging/messagedetailsview/${encodeURIComponent(String(threadId))}/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Computes the total number of messages that the user hasd not yet read in his (her) ibox
     * 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingMessagesCountunreadIdentityKeyGet(identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public apiMessagingMessagesCountunreadIdentityKeyGet(identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public apiMessagingMessagesCountunreadIdentityKeyGet(identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public apiMessagingMessagesCountunreadIdentityKeyGet(identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiMessagingMessagesCountunreadIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('get',`${this.basePath}/api/messaging/messages/countunread/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves data that are needed to model and populate the legacy message banner card
     * 
     * @param userIdentityKey 
     * @param appVersion 
     * @param platformName 
     * @param platformVersion 
     * @param deviceId 
     * @param buildNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingMessagesLegacymessagebannercardGet(userIdentityKey?: string, appVersion?: string, platformName?: string, platformVersion?: string, deviceId?: string, buildNumber?: string, observe?: 'body', reportProgress?: boolean): Observable<LegacyMessageBannerCard>;
    public apiMessagingMessagesLegacymessagebannercardGet(userIdentityKey?: string, appVersion?: string, platformName?: string, platformVersion?: string, deviceId?: string, buildNumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LegacyMessageBannerCard>>;
    public apiMessagingMessagesLegacymessagebannercardGet(userIdentityKey?: string, appVersion?: string, platformName?: string, platformVersion?: string, deviceId?: string, buildNumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LegacyMessageBannerCard>>;
    public apiMessagingMessagesLegacymessagebannercardGet(userIdentityKey?: string, appVersion?: string, platformName?: string, platformVersion?: string, deviceId?: string, buildNumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userIdentityKey !== undefined && userIdentityKey !== null) {
            queryParameters = queryParameters.set('userIdentityKey', <any>userIdentityKey);
        }
        if (appVersion !== undefined && appVersion !== null) {
            queryParameters = queryParameters.set('appVersion', <any>appVersion);
        }
        if (platformName !== undefined && platformName !== null) {
            queryParameters = queryParameters.set('platformName', <any>platformName);
        }
        if (platformVersion !== undefined && platformVersion !== null) {
            queryParameters = queryParameters.set('platformVersion', <any>platformVersion);
        }
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('deviceId', <any>deviceId);
        }
        if (buildNumber !== undefined && buildNumber !== null) {
            queryParameters = queryParameters.set('buildNumber', <any>buildNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LegacyMessageBannerCard>('get',`${this.basePath}/api/messaging/messages/legacymessagebannercard`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves ata on all the standard recipients to whom users can send messages to
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingMessagesRecipientsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<StandardMessagesRecipient>>;
    public apiMessagingMessagesRecipientsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StandardMessagesRecipient>>>;
    public apiMessagingMessagesRecipientsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StandardMessagesRecipient>>>;
    public apiMessagingMessagesRecipientsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StandardMessagesRecipient>>('get',`${this.basePath}/api/messaging/messages/recipients`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the data necessary to construct the model for the messages thread view on the mobile application
     * 
     * @param threadId 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingMessagesthreadviewThreadIdIdentityKeyGet(threadId: string, identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<MessagesThreadView>;
    public apiMessagingMessagesthreadviewThreadIdIdentityKeyGet(threadId: string, identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessagesThreadView>>;
    public apiMessagingMessagesthreadviewThreadIdIdentityKeyGet(threadId: string, identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessagesThreadView>>;
    public apiMessagingMessagesthreadviewThreadIdIdentityKeyGet(threadId: string, identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (threadId === null || threadId === undefined) {
            throw new Error('Required parameter threadId was null or undefined when calling apiMessagingMessagesthreadviewThreadIdIdentityKeyGet.');
        }

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiMessagingMessagesthreadviewThreadIdIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MessagesThreadView>('get',`${this.basePath}/api/messaging/messagesthreadview/${encodeURIComponent(String(threadId))}/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves all the data necessary to construct the model for a messages view on the mobile application
     * 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingMessagesviewIdentityKeyGet(identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<MessagesView>;
    public apiMessagingMessagesviewIdentityKeyGet(identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessagesView>>;
    public apiMessagingMessagesviewIdentityKeyGet(identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessagesView>>;
    public apiMessagingMessagesviewIdentityKeyGet(identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiMessagingMessagesviewIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MessagesView>('get',`${this.basePath}/api/messaging/messagesview/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves data on a given routing type using its number
     * 
     * @param threadId 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingMessagethreadvmThreadIdIdentityKeyGet(threadId: string, identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<MessageThreadVM>;
    public apiMessagingMessagethreadvmThreadIdIdentityKeyGet(threadId: string, identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageThreadVM>>;
    public apiMessagingMessagethreadvmThreadIdIdentityKeyGet(threadId: string, identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageThreadVM>>;
    public apiMessagingMessagethreadvmThreadIdIdentityKeyGet(threadId: string, identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (threadId === null || threadId === undefined) {
            throw new Error('Required parameter threadId was null or undefined when calling apiMessagingMessagethreadvmThreadIdIdentityKeyGet.');
        }

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiMessagingMessagethreadvmThreadIdIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MessageThreadVM>('get',`${this.basePath}/api/messaging/messagethreadvm/${encodeURIComponent(String(threadId))}/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the data necessary to construct the model for the new message (compose message) view on the mobile application
     * 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingNewmessageviewIdentityKeyGet(identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<NewMessageView>;
    public apiMessagingNewmessageviewIdentityKeyGet(identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NewMessageView>>;
    public apiMessagingNewmessageviewIdentityKeyGet(identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NewMessageView>>;
    public apiMessagingNewmessageviewIdentityKeyGet(identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiMessagingNewmessageviewIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<NewMessageView>('get',`${this.basePath}/api/messaging/newmessageview/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Enables a user to send a reply to message that was sent to him (Iher) within a thread
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingReplytomessagePost(body?: ReplyToMessageInputs, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiMessagingReplytomessagePost(body?: ReplyToMessageInputs, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiMessagingReplytomessagePost(body?: ReplyToMessageInputs, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiMessagingReplytomessagePost(body?: ReplyToMessageInputs, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/api/messaging/replytomessage`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves data on a given routing type using its number
     * 
     * @param routingTypeNumber 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingRoutingtypeRoutingTypeNumberIdentityKeyGet(routingTypeNumber: number, identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<RoutingType>;
    public apiMessagingRoutingtypeRoutingTypeNumberIdentityKeyGet(routingTypeNumber: number, identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoutingType>>;
    public apiMessagingRoutingtypeRoutingTypeNumberIdentityKeyGet(routingTypeNumber: number, identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoutingType>>;
    public apiMessagingRoutingtypeRoutingTypeNumberIdentityKeyGet(routingTypeNumber: number, identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (routingTypeNumber === null || routingTypeNumber === undefined) {
            throw new Error('Required parameter routingTypeNumber was null or undefined when calling apiMessagingRoutingtypeRoutingTypeNumberIdentityKeyGet.');
        }

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiMessagingRoutingtypeRoutingTypeNumberIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RoutingType>('get',`${this.basePath}/api/messaging/routingtype/${encodeURIComponent(String(routingTypeNumber))}/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Enables us to send a message on behalf of a user to a given department. the routing type comes from the RoutingTypeEnum
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMessagingSendmessagePost(body?: SendMessageInputs, observe?: 'body', reportProgress?: boolean): Observable<Thread>;
    public apiMessagingSendmessagePost(body?: SendMessageInputs, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Thread>>;
    public apiMessagingSendmessagePost(body?: SendMessageInputs, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Thread>>;
    public apiMessagingSendmessagePost(body?: SendMessageInputs, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Thread>('post',`${this.basePath}/api/messaging/sendmessage`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves dropdown data for admin message recipients
     * 
     * @param routingTypeNumber 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGetMessageRecipientsGroups( observe?: 'body', reportProgress?: boolean): Observable<AdminMessageRecipients>;
    public apiGetMessageRecipientsGroups( observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdminMessageRecipients>>;
    public apiGetMessageRecipientsGroups( observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdminMessageRecipients>>;
    public apiGetMessageRecipientsGroups( observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.request('get', `${this.basePath}/api/messaging/recipientgroups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }    

    /**
     * Retrieves dropdown data for admin message recipients
     * 
     * @param routingTypeNumber 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGetMessageSendingDepartments(identityKey, observe?: 'body', reportProgress?: boolean): Observable<AdminMessageSendingDepartments>;
    public apiGetMessageSendingDepartments(identityKey, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdminMessageSendingDepartments>>;
    public apiGetMessageSendingDepartments(identityKey, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdminMessageSendingDepartments>>;
    public apiGetMessageSendingDepartments(identityKey, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.request<AdminMessageSendingDepartments>('get', `${this.basePath}/api/messaging/sendingdepartments/${identityKey}?includeInactive=true`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }        

}
