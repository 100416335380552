import {Component, Input, OnInit} from '@angular/core';
import { EdgeCard } from 'src/app/shared/models/edge-card';

@Component({
  selector: 'button-card',
  templateUrl: './button-card.component.html',
  styleUrls: ['./button-card.component.scss'],
})
export class ButtonCardComponent implements OnInit {

  @Input('cardTitle') cardTitle: string;
  @Input('card') card: EdgeCard;

  constructor() { }

  ngOnInit() {}

}
