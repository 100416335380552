import {createFeatureSelector, createSelector} from '@ngrx/store';
import {Trip} from '../../../../Swagger-Gen';
import {PreAssignedTripCardActions, PreAssignedTripCardActionTypes} from './pre-assigned-trip-card.actions';
import * as fromRoot from '../../../../app.state';
import { JobTrip } from 'src/app/Swagger-Gen-V2/model/jobTrip';


export interface State extends fromRoot.State {
    preAssignedTrip: PreAssignedTripCardState;
}

export interface PreAssignedTripCardState {
    preAssignedTrip: Array<JobTrip>;
    error: string;
}

const initialState: PreAssignedTripCardState = {
    preAssignedTrip: null,
    error: ''
};

const getPreAssignedTripCardFeatureState = createFeatureSelector<PreAssignedTripCardState>('preAssignedTrip');

export const getPreAssignedTripCard = createSelector(
    getPreAssignedTripCardFeatureState ,
    state =>  (state != null) ? state.preAssignedTrip : null
);

export const getError = createSelector(
    getPreAssignedTripCardFeatureState,
    state => state.error
);


export function preAssignedTripCardReducer(state= initialState, action: PreAssignedTripCardActions): PreAssignedTripCardState {
    switch (action.type) {
        case PreAssignedTripCardActionTypes.GetPreAssignedTripSuccess:
            return {
                ...state,
                preAssignedTrip: action.payload,
                error: ''
            };

        case PreAssignedTripCardActionTypes.GetPreAssignedTripFail:
            return {
                ...state,
                preAssignedTrip: null,
                error: action.payload
            };
        default:
            return state;
    }
}
