export class TelemetryConstants {
    static CLICK_PREASSIGNED_SCREEN = 'Clicked Call Transportation Manager Button On Pre-Assigned Screen';
    static CLICK_PREVIOUS_SCREEN = 'Clicked Start Paperwork Button From Previous Job';
    static LOGIN_FIRST_APP_OPEN = 'First Application Open';
    static LOGIN_INITIAL_APP_OPEN = 'Initial Application Open';
    static CLICKED_LOGIN_BUTTON = 'Clicked Login Button';
    static ENTER_CASS_CHAT = 'Entered CASS Screen';
    static CLICK_CONTACT_CASS_CHAT = 'Clicked CASS Chat Tab On Contact Screen';
    static CLICK_CONTACT_DEPARTMENT = 'Clicked Departments Tab On Contact Screen';
    static CLICK_CONTACT_TERMINAL = 'Clicked Terminals Tab On Contact Screen';
    static CLICK_CONTACT_ACCOUNT_CONTACTS = 'Clicked Account Contacts Tab On Contact Screen';
    static CLICK_DEPARTMENT_ITEM = 'Clicked Department Contact Item On Departments Screen';
    static CLICK_TERMINAL_ITEM = 'Clicked Terminal ${0} On Terminals Screen';
    static ENTER_CHALLENGE_QUESTION = 'Entered Challenge Question Screen';
    static SUBMIT_CHALLENGE_BUTTON = 'Clicked Submit Button On Challenge Card';
    static ARRIVE_FROM_LOGIN = 'Arrived From Login';
    static CLICK_CASS_FROM_HOME_SCREEN = 'Clicked CASS On Home Screen';
    static CLICK_HOME_SCREEN = 'Clicked Home Screen Tile ${0}';
    static CLICK_SIGN_OUT = 'Clicked Sign Out On Menu';
    static CLICK_WELCOME_MENU = 'Clicked Welcome On Menu';
    static CLICK_JOBS_MENU = 'Clicked Jobs On Menu';
    static CLICK_PAY_MENU = 'Clicked Pay On Menu';
    static CLICK_CONTACT_MENU = 'Clicked Contacts On Menu';
    static CLICK_REFERENCE_LIBRARY_MENU = 'Clicked Reference Library On Menu';
    static CLICK_BLUE_COIN_MENU = 'Clicked Blue Coin On Menu';
    static CLICK_OPPURTUNITY_MENU = 'Clicked Career Opportunities On Menu';
    static CLICK_MAP_MENU = 'Clicked Map On Menu';
    static CLICK_NEWS_MENU = 'Clicked Werner News On Menu';
    static CLICK_SAFETY_MENU = 'Clicked Safety On Menu';
    static CLICK_CUSTOMIZE_MENU = 'Clicked Customize Your Home Screen On Menu';
    static CLICK_PROFILE_MENU = 'Clicked Profile On Menu';
    static CLICK_SETTINGS_MENU = 'Clicked Settings On Menu';
    static CLICK_MESSAGES_MENU = 'Clicked Messages On Menu';
    static CLICK_BENEFITS_MENU = 'Clicked Benefits On Menu';
    static OPEN_HAMBURGER = 'Opened Hamburger Menu';
    static FIND_JOB_BUTTON = 'Find Jobs Button Is Shown On Jobs Screen';
    static CLICK_CURRENT_TAB = 'Clicked Current Tab On Jobs Screen';
    static CLICK_PRE_ASSIGNED = 'Clicked Pre-Assigned Tab On Jobs Screen';
    static CLICK_FIND_JOBS = 'Clicked Find Jobs Button On Jobs Screen';
    static CLICK_JOB_SEARCH = 'Clicked A Job On Job Search Screen';
    static CLICK_CALL_TRANPORTATION_MANAGER = 'Clicked Call Transportation Manager Button On Load Selection Details Pop Up';
    static CLICK_CONFIRM_LOAD_SELECTION = 'Clicked Confirm Button On Load Selection Details Pop Up';
    static CLICK_PREVIOUS_TAB_JOB_SCREEN = 'Clicked Previous Tab On Jobs Screen';
    static CLICK_EXISTING_PAPERWORK = 'Clicked View Existing Paper Work Button On Current Job Screen';
    static CLICK_SCAN_PAPERWORK = 'Clicked Scan Paperwork Only Button On Current Job Screen';
    static CLICK_GENERATE_JOB = 'Clicked Generate Job Envelope And Scan Button On Current Job Screen';
    static CLICK_JOB_GENERATE_CANCEL = 'Clicked Cancel Button After Starting Paper Work On Current Job Screen';
    static CLICK_START_PAPERWORK_CURRENT_JOB = 'Clicked Start Paperwork Button On Current Job Screen';
    static EXPAND_PREVIOUS_JOB = 'Expanded Previous Job';
    static ENTER_FORGOT_PIN = 'Entered Forgot Pin Screen';
    static CLICK_CALL_SERVICE = 'Clicked Call Service Desk Button On Forgot Pin Screen';
    static CLICK_SUBMIT_FORGOT_PIN = 'Clicked Submit Button On Forgot Pin Screen';
    static SELECT_STATEMENT_PAY_SCREEN = 'Selected Statement On Pay Screen';
    static SELECT_TRIP_DETAIL = 'Selected Trip Detail On Pay Screen';
    static EXPAND_PAY_DETAIL = 'Expanded Pay Detail On Pay Screen';
    static CLICK_FORGOT_PIN = 'Clicked Forgot Pin Button On Pin Screen';
    static ENTER_PIN_SCREEN = 'Entered Pin Screen';
    static CLICK_SUBMIT_CHALLENGE_CARD = 'Clicked Submit Button On Challenge Card';
}