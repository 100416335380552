<ion-card class="edge-card" [ngClass]="{'home-page-card':router.url !== '/customize-home','customized-home-page-card':router.url === '/customize-home'}">
  <card-overlay 
    [card]="card"
    (didTapCard)="tapCard()"
    (didTapResize)="resizeCard()"
    (didTapChange)="changeCard()"
    (didTapRemove)="removeCard()"
  ></card-overlay>
    <div class="content" *ngIf='!isWorkdayDown'>
      <div class="edge-image">
        <img src='../../assets/Images/workday-Icon.svg'/> <br/>
      </div>
    </div>
    <div *ngIf='isWorkdayDown' class="rectangle-545 ion-text-center"> 
      <ion-label class="wd-down-label">Not able to view Workday tile at this time.
        <br> Workday is currently unavailable. </ion-label>
        </div>
      
</ion-card>
