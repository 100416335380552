import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";

import { Action } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import * as RootActions from "./root.actions";

import { OrientationService} from "src/app/Swagger-Gen";
import { RequestService } from "../Swagger-Gen-V2";

@Injectable()
export class GetRootEffects {
  orientationService: OrientationService = this.injector.get(OrientationService);
  requestService: RequestService = this.injector.get(RequestService);

  constructor(private injector: Injector, private actions$: Actions) { }

  @Effect()
  welcomeOrientationInfo$: Observable<Action> = this.actions$.pipe(
    ofType(RootActions.RootActionTypes.GetWelcomeOrientationInfo),
    mergeMap((action) => {
      return this.requestService.orientationGet(
        (action as RootActions.GetWelcomeOrientationInfo).IdentityKey
      )
        .pipe(
          map((orientationInfo) => (new RootActions.GetWelcomeOrientationInfoSuccess(orientationInfo))),
          catchError((err) => of(new RootActions.GetWelcomeOrientationInfoFail(err)))
        );
    })
  );

  @Effect()
  getFeatureFlagStatus$: Observable<Action> = this.actions$.pipe(
    ofType(RootActions.RootActionTypes.GetFeatrueFlagsStatus),
    mergeMap((action) => {
      return this.requestService.featureflagsGet()
    
        .pipe(
          map((featureflag) => (new RootActions.GetFeatrueFlagsStatusSuccess(featureflag))),
          catchError((err) => of(new RootActions.GetFeatrueFlagsStatusFail(err)))
        );
    })
  );


}
