import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EdgeCard } from 'src/app/shared/models/edge-card';

@Component({
  selector: 'card-modal',
  templateUrl: './card-modal.component.html',
  styleUrls: ['./card-modal.component.scss'],
})
export class CardModalComponent implements OnInit {

  @Input('cards') cards: Array<EdgeCard>
  @Input('changeCard') changeCard: EdgeCard;
  @Output('close') close = new EventEmitter();
  @Output('addCard') addCard = new EventEmitter();

  allCardsActive = true;
  inactiveCards = [];

  public previousCardWasHalfSized = false
  
  constructor() { }

  async ngOnInit() { 
    this.cards = [...new Set(this.cards)];

    this.cards.forEach((card, index) => {
      if (!card.isActive) {
        this.allCardsActive = false;
        this.inactiveCards.push(card);
      }
    });
  }

  setStyle(active: boolean) {
    return active ? 
    { 'opacity': 0.0 } : 
    { 'opacity': 1.0 };
  }

  closeModal() {
    this.close.emit();
  }
  
  add(card) {
    if (!card.isActive) {
      this.addCard.emit(card);
      this.closeModal();
    }
  }
}
