import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from "@angular/core";

import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
// import required model
import { DrivingOptions } from "src/app/Swagger-Gen/model/drivingOptions";
import { Question } from "src/app/Swagger-Gen/model/drivingOptionsQuestion";
import { UserAnswer } from "src/app/Swagger-Gen/model/drivingOptionsUserAnswer";
import { AnswerOption } from "src/app/Swagger-Gen/model/drivingOptionsAnswerOption";
import { ToastController } from "@ionic/angular";
import { AnswerOptionItem } from "src/app/Swagger-Gen/model/drivingOptionsAnswerOptionItem";
import { maskitoDateOptionsGenerator } from '@maskito/kit';
import { MaskitoElementPredicate, MaskitoOptions } from "@maskito/core";

export const dateMask = maskitoDateOptionsGenerator ({
  mode : 'mm/dd/yyyy',
  separator : '/'
});

@Component({
  selector: "app-json-form",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./json-form.component.html",
  styleUrls: ["./json-form.component.scss"],
})

export class JsonFormComponent implements OnInit, OnChanges {
  @Input() jsonFormData: DrivingOptions;
  @Input() showGoBack: boolean;
  @Output() submitOptions = new EventEmitter();
  @Output() backToHome = new EventEmitter();
  public questionAnswerForm: UntypedFormGroup = this.formBuilder.group({});
  questionGroup: Question;
  answerOptions: AnswerOption;
  answerOptionItems: AnswerOptionItem[];
  answerOption: AnswerOptionItem;
  questionText: string;
  isSubmitted: Boolean = false;
  errorMessage: string = "";
  questionsToHide: Array<number> = [4085, 4086, 4087, 4005, 4007];
  initialSetofQuestionsToShow: Array<number> = [4000, 4006, 4002, 4008, 4004];
  public _array = Array;
  readonly dateMaskOptions: MaskitoOptions = dateMask;
  readonly maskPredicate: MaskitoElementPredicate = async (el) =>
    (el as HTMLIonInputElement).getInputElement();
    
  constructor(private formBuilder: UntypedFormBuilder, private toastController: ToastController
    ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.jsonFormData && !changes.jsonFormData.firstChange) {
      this.setValidator();
      if (this.jsonFormData && this.jsonFormData.questionGroup && this.jsonFormData.questionGroup.displayOrder == 1) {
        this.showElementBasedOnSavedData();
      }
    }
  }

  ngOnInit() {
    this.initialFormGroup();  
  }

  initialFormGroup() {
    this.questionAnswerForm = this.formBuilder.group({
      "4006": ["", [Validators.required]],
      "4002": ["", [Validators.required]],
      "4000": ["", [Validators.required]],
      "4091": ["", [Validators.required]],
      "4134": [""],
      "4135": [""],
      "4136": [""],
      "4137": [""],
      "4140": [""],
      "4138": [""],
      "4139": [""],
      "4004": ["", [Validators.required]],
      "4005": [""],
      "4007": [""]
    });
    // Set vaidation when click go back button
    if (this.questionsToHide.indexOf(4086) === -1) {
      this.addOrRemoveValidation("4135", "add");
    } 
    if (this.questionsToHide.indexOf(4087) === -1) {
      this.addOrRemoveValidation("4136", "add");
      this.addOrRemoveValidation("4137", "add");
      this.addOrRemoveValidation("4140", "add");
      this.addOrRemoveValidation("4138", "add");
      this.addOrRemoveValidation("4139", "add");
    }
  }

  getMask(fieldId) {
    if (fieldId == "4139") {
      return '(999)-(999)-9999'
    } else if (fieldId == "4136") {
      return '(999)-(999)-9999'
    } else if (fieldId == "4135") {
      return '99/99/9999'
    } else {
      return '*'
    } 
  }

  setValidator() {
    if (this.jsonFormData.questionGroup.displayOrder == 2) {
      this.questionAnswerForm = this.formBuilder.group({
        "4135": [""],
        "4092": [""],
        "4093": [""],
        "4094": [""],
        "4095": [""],
        "4096": [""],
        "4099": [""],
        "4102": [""],
        "4104": [""],
        "4106": [""],
        "4097": ["", [Validators.max(3), Validators.min(1)]],
        "4107": ["", [Validators.max(3), Validators.min(1)]],
        "4108": ["", [Validators.max(3), Validators.min(1)]],
        "4109": ["", [Validators.max(3), Validators.min(1)]],
        "4110": ["", [Validators.max(3), Validators.min(1)]],
        
        "4098": ["", [Validators.max(5), Validators.min(1)]],
        "4111": ["", [Validators.max(5), Validators.min(1)]],
        "4112": ["", [Validators.max(5), Validators.min(1)]],
        "4113": ["", [Validators.max(5), Validators.min(1)]],
        "4114": ["", [Validators.max(5), Validators.min(1)]],
        
        "4100": ["", [Validators.max(3), Validators.min(1)]],
        "4115": ["", [Validators.max(3), Validators.min(1)]],
        "4116": ["", [Validators.max(3), Validators.min(1)]],
        "4117": ["", [Validators.max(3), Validators.min(1)]],
        "4118": ["", [Validators.max(3), Validators.min(1)]],
        "4119": ["", [Validators.max(3), Validators.min(1)]],
        "4120": ["", [Validators.max(3), Validators.min(1)]],
        "4121": ["", [Validators.max(3), Validators.min(1)]],

        "4101": ["", [Validators.max(3), Validators.min(1)]],
        "4122": ["", [Validators.max(3), Validators.min(1)]],
        "4123": ["", [Validators.max(3), Validators.min(1)]],
        "4124": ["", [Validators.max(3), Validators.min(1)]],
        "4125": ["", [Validators.max(3), Validators.min(1)]],

        "4103": ["", [Validators.max(3), Validators.min(1)]],
        "4126": ["", [Validators.max(3), Validators.min(1)]],
        "4127": ["", [Validators.max(3), Validators.min(1)]],
        "4128": ["", [Validators.max(3), Validators.min(1)]],
        "4129": ["", [Validators.max(3), Validators.min(1)]],

        "4105": ["", [Validators.max(3), Validators.min(1)]],
        "4130": ["", [Validators.max(3), Validators.min(1)]],
        "4131": ["", [Validators.max(3), Validators.min(1)]],
        "4132": ["", [Validators.max(3), Validators.min(1)]],
        "4133": ["", [Validators.max(3), Validators.min(1)]]        
      })
    } else if (this.jsonFormData.questionGroup.displayOrder == 3) {
      this.questionAnswerForm = this.formBuilder.group({
        "4051": [""],
        "4052": [""],
        "4053": [""],
        "4054": [""],
        "4055": [""],
        "4056": [""],
        "4057": [""],
        "4058": [""],
        "4066": [""],
        "4067": [""],
        "4068": [""],
        "4069": [""],
        "4070": [""],
        "4071": [""],
        "4072": [""],
        "4060": [""],
        "4061": [""],
        "4062": [""],
        "4073": [""],
        "4074": [""],
        "4075": [""],
        "4076": [""],
        "4077": [""],
        "4064": [""],
        "4078": [""],
        "4079": [""],
        "4080": [""],
        "4081": [""],
        "4082": [""],
        "4083": [""],
        "4084": [""],
        "4085": [""]
      })
    }
  }

  
  changeInRadioField(event: any, id: string, questionIndex: string, answerOptionIndex: string) {
    this.setValue(event, id, questionIndex, answerOptionIndex)
    if (event && event.detail) {
      this.showHideElement(id, event.detail.value);
    }
  }

  showHideElement(id: string, value: string) {
    switch (String(id)) {
      case "4000": {
        if (value == "1") {
          document.getElementById("question_wrapper_4085").classList.remove('hide-block');
          document.getElementById("question_wrapper_4085").classList.add('show-block');
          this.addOrRemoveValidation("4134", "add");
          this.addOrRemoveValidation("4135", "remove");
          document.getElementById("question_wrapper_4008").classList.remove('show-block');
          document.getElementById("question_wrapper_4008").classList.add('hide-block');
          this.addOrRemoveValidation("4091", "remove");
        } else {
          document.getElementById("question_wrapper_4085").classList.remove('show-block');
          document.getElementById("question_wrapper_4085").classList.add('hide-block');
          this.addOrRemoveValidation("4134", "remove");
          document.getElementById("question_wrapper_4008").classList.remove('hide-block');
          document.getElementById("question_wrapper_4008").classList.add('show-block');
          this.addOrRemoveValidation("4091", "add");
        }

        document.getElementById("question_wrapper_4086").classList.remove('show-block');
        document.getElementById("question_wrapper_4086").classList.add('hide-block');
        this.addOrRemoveValidation("4135", "remove");
        document.getElementById("question_wrapper_4087").classList.remove('show-block');
        document.getElementById("question_wrapper_4087").classList.add('hide-block');
        this.addOrRemoveValidation("4136", "remove");
        this.addOrRemoveValidation("4137", "remove");
        this.addOrRemoveValidation("4140", "remove");
        this.addOrRemoveValidation("4138", "remove");
        this.addOrRemoveValidation("4139", "remove");
        break;
      }
      case "4091": {
        if (value == "1") {
          document.getElementById("question_wrapper_4086").classList.remove('hide-block');
          document.getElementById("question_wrapper_4086").classList.add('show-block');
          this.addOrRemoveValidation("4135", "add");

        } else {
          document.getElementById("question_wrapper_4086").classList.remove('show-block');
          document.getElementById("question_wrapper_4086").classList.add('hide-block');
          this.addOrRemoveValidation("4135", "remove");
          this.TextBox_4135.setValue('');
        }
        break;
      }
      case "4134": {
        if (value == "1") {
          document.getElementById("question_wrapper_4087").classList.remove('hide-block');
          document.getElementById("question_wrapper_4087").classList.add('show-block');
          this.addOrRemoveValidation("4136", "add");
          this.addOrRemoveValidation("4137", "add");
          this.addOrRemoveValidation("4140", "add");
          this.addOrRemoveValidation("4138", "add");
          this.addOrRemoveValidation("4139", "add");
          document.getElementById("question_wrapper_4086").classList.remove('show-block');
          document.getElementById("question_wrapper_4086").classList.add('hide-block');
          this.addOrRemoveValidation("4135", "remove");
          this.TextBox_4135.setValue('');
        } else {
          document.getElementById("question_wrapper_4087").classList.remove('show-block')
          document.getElementById("question_wrapper_4087").classList.add('hide-block')
          this.addOrRemoveValidation("4136", "remove");
          this.addOrRemoveValidation("4137", "remove");
          this.addOrRemoveValidation("4140", "remove");
          this.addOrRemoveValidation("4138", "remove");
          this.addOrRemoveValidation("4139", "remove");
          document.getElementById("question_wrapper_4086").classList.remove('hide-block');
          document.getElementById("question_wrapper_4086").classList.add('show-block');
          this.addOrRemoveValidation("4135", "add");
        }
        break;
      }
      case "4004": {
        if (value == "1") {
          document.getElementById("question_wrapper_4005").classList.remove('hide-block');
          document.getElementById("question_wrapper_4005").classList.add('show-block');
          this.addOrRemoveValidation("4005", "add");
        } else {
          document.getElementById("question_wrapper_4005").classList.remove('show-block');
          document.getElementById("question_wrapper_4005").classList.add('hide-block');
          this.addOrRemoveValidation("4005", "remove");
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  addOrRemoveValidation(id: string, status: string) {
    switch (id) {
      case "4005": {
        if (status === "add") {
          // this.TextBoxSmall_4005.setValue("");
          this.TextBoxSmall_4005.setValidators([Validators.required, Validators.maxLength(50)]);
        }
        else {
          this.TextBoxSmall_4005.setValidators(null);
        }
        this.TextBoxSmall_4005.updateValueAndValidity();
        break;
      }
      case "4135": {
        if (status === "add") {
          this.TextBox_4135.setValidators([Validators.required, Validators.minLength(10),this.dateValidator]);

        }
        else {
          this.TextBox_4135.setValidators(null);
        }
        this.TextBox_4135.updateValueAndValidity();
        break;
      }
      case "4134": {
        if (status === "add") {
          this.RadioButton_4134.setValue("");
          this.RadioButton_4134.setValidators([Validators.required]);
        }
        else {
          this.RadioButton_4134.setValidators(null);
        }
        this.RadioButton_4134.updateValueAndValidity();
        break;
      }
      case "4091": {
        if (status === "add") {
          this.RadioButton_4091.setValue("");
          this.RadioButton_4091.setValidators([Validators.required]);
        }
        else {
          this.RadioButton_4091.setValidators(null);
        }
        this.RadioButton_4091.updateValueAndValidity();
        break;
      }
      case "4136": {
        if (status === "add") {
          this.TextBoxSmall_4136.setValue("");
          this.TextBoxSmall_4136.setValidators([Validators.required,  Validators.minLength(14), Validators.maxLength(18)]);
        }
        else {
          this.TextBoxSmall_4136.setValidators(null);
        }
        this.TextBoxSmall_4136.updateValueAndValidity();
        break;
      }
      case "4137": {
        if (status === "add") {
          this.TextBoxSmall_4137.setValue("");
          this.TextBoxSmall_4137.setValidators([Validators.required, Validators.maxLength(50)]);
        }
        else {
          this.TextBoxSmall_4137.setValidators(null);
        }
        this.TextBoxSmall_4137.updateValueAndValidity();
        break;
      }
      case "4140": {
        if (status === "add") {
          this.TextBox_4140.setValue("");
          this.TextBox_4140.setValidators([Validators.maxLength(50)]);
        }
        else {
          this.TextBox_4140.setValidators(null);
        }
        this.TextBox_4140.updateValueAndValidity();
        break;
      }
      case "4139": {
        if (status === "add") {
          this.TextBoxSmall_4139.setValue("");
          this.TextBoxSmall_4139.setValidators([Validators.minLength(14), Validators.maxLength(18)]);
        }
        else {
          this.TextBoxSmall_4139.setValidators(null);
        }
        this.TextBoxSmall_4139.updateValueAndValidity();
        break;
      }
      default: {
        break;
      }
    }
  }

  get TextBoxSmall_4005() {
    return this.questionAnswerForm.get('4005') as UntypedFormControl;
  }
  get TextBoxSmall_4137() {
    return this.questionAnswerForm.get('4137') as UntypedFormControl;
  }
  get TextBox_4140() {
    return this.questionAnswerForm.get('4140') as UntypedFormControl;
  }
  get TextBoxSmall_4138() {
    return this.questionAnswerForm.get('4138') as UntypedFormControl;
  }
  get TextBoxSmall_4139() {
    return this.questionAnswerForm.get('4139') as UntypedFormControl;
  }
  get TextBoxSmall_4136() {
    return this.questionAnswerForm.get('4136') as UntypedFormControl;
  }
  get RadioButton_4091() {
    return this.questionAnswerForm.get('4091') as UntypedFormControl;
  }
  get RadioButton_4134() {
    return this.questionAnswerForm.get('4134') as UntypedFormControl;
  }
  get TextBox_4135() {
    return this.questionAnswerForm.get('4135') as UntypedFormControl;
  }

  dateValidator(control: UntypedFormControl): { [s: string]: string } {

    if (control.value) {
      let date = control.value
      if (control.value.length == 10) {        
        date = date.replace(/\\|\//g, "")
        date = date.substring(0, 2) + "/" + date.substring(2, 4) + "/" + date.substring(4, 8)
      }

      var currentValue = new Date(date);
      var todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      var curYear = todayDate.getFullYear()
      const monthNames = ["01", "02", "03", "04", "05", "06","07", "08", "09", "10", "11", "12"];
      var curDate = String(todayDate.getDate());
      var curMonth = monthNames[todayDate.getMonth()]
      var nextYearDateString =  curMonth + "/" + curDate  + "/"+  (curYear + 1);
      var nextYearDate = new Date(nextYearDateString);
      nextYearDate.setHours(0, 0, 0, 0);
      if(!(currentValue.getTime() >= todayDate.getTime() && currentValue.getTime() <= nextYearDate.getTime())){
        return {"invalidDate": "Please enter a date which is between today's date and " + nextYearDateString + "."};
      } else {
        return null;
      }
    }
    return null;
  }

  showError(fieldId) {
    if (this.questionAnswerForm.controls[fieldId] && this.questionAnswerForm.controls[fieldId] != null) {
      if (this.isSubmitted && "touched" in this.questionAnswerForm.controls[fieldId] && this.questionAnswerForm.controls[fieldId].touched && this.questionAnswerForm.controls[fieldId].errors != null && this.questionAnswerForm.controls[fieldId].errors.required)
      return true
    } else {
      return false
    }
  }

  setCheckboxValue(event, fieldId, questionIndex, answerOptionIndex) {
    let value = event.detail.checked
    let ansOption = this.jsonFormData.questionGroup.questions[questionIndex].answerOptions[answerOptionIndex]
    let question = this.jsonFormData.questionGroup.questions[questionIndex]
    this.jsonFormData.questionGroup.questions[questionIndex].answerOptions[answerOptionIndex].userAnswer = this.getAnswer(value, ansOption)
  }

  setValue(event, fieldId, questionIndex, answerOptionIndex) {
    let value = event.target.value
    let ansOptions = this.jsonFormData.questionGroup.questions[questionIndex].answerOptions
    let question = this.jsonFormData.questionGroup.questions[questionIndex]

    if (Array.isArray(ansOptions)) {
      if (question.questionID == 4087 || ansOptions[answerOptionIndex].fieldType == 19) {
        let ansOption = this.jsonFormData.questionGroup.questions[questionIndex].answerOptions[answerOptionIndex]
        this.jsonFormData.questionGroup.questions[questionIndex].answerOptions[answerOptionIndex].userAnswer = this.getAnswer(value, ansOption)
      } else {
        for (var ansIndex in ansOptions) {
          let ansOption = this.jsonFormData.questionGroup.questions[questionIndex].answerOptions[ansIndex]
          // if (ansOption.fieldID.indexOf('RadioButton') > 0) {
          this.jsonFormData.questionGroup.questions[questionIndex].answerOptions[ansIndex].userAnswer = this.getAnswer(value, ansOption)
          // }
        }
      }

    }
  }

  numberOnlyValidation(event: any, fieldId: string, fieldType) {

    if (fieldType == '19' || fieldId == '4136' || fieldId == '4139') {

      const pattern = (fieldType == '19') ? /[1-9.,]/ : /[0-9.,]/; 
      let inputChar = String.fromCharCode(event.charCode);

      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }      
    }
  }  

  getAnswer = (userValue, ansOption): UserAnswer => {
    let userAnswerId = (ansOption.userAnswer != null && ansOption.userAnswer.userAnswerID != null) ? Number(ansOption.userAnswer.userAnswerID) : 0
    return {
      "active": true,
      "instanceID": 0,
      "questionAnswerOptionID": Number(ansOption.questionAnswerOptionID),
      "questionnaireID": Number(this.jsonFormData.questionGroup.questionGroupID),
      "userAnswerID": userAnswerId,
      "userID": "0",
      "userValue": userValue,
      "validationLogs": []
    }
  }

  showElementBasedOnSavedData() {
    let question4004 = this.getQuestionArr(4004);
    let question4008 = this.getQuestionArr(4008);
    let question4000 = this.getQuestionArr(4000);
    let question4085 = this.getQuestionArr(4085);

    if (question4004.answerOptions[0].userAnswer != null && question4004.answerOptions[0].userAnswer.userValue === "1") {
      if (this.questionsToHide.indexOf(4005) > -1) {
        this.questionsToHide.splice(this.questionsToHide.indexOf(4005), 1);
        this.addOrRemoveValidation("4005", "add");
      }
    } else {
      if (this.questionsToHide.indexOf(4005) == -1) {
        this.questionsToHide.push(4005);
      }
    }

    if (question4000.answerOptions[0].userAnswer != null && question4000.answerOptions[0].userAnswer.userValue === "1") {
      if (this.questionsToHide.indexOf(4008) == -1) {
        this.questionsToHide.push(4008);
        this.addOrRemoveValidation("4091", "remove");
      }
      if (this.questionsToHide.indexOf(4085) > -1) {
        this.questionsToHide.splice(this.questionsToHide.indexOf(4085), 1);
        this.addOrRemoveValidation("4134", "add");
      }
      if (question4085.answerOptions[0].userAnswer != null && question4085.answerOptions[0].userAnswer.userValue === "1") {
        if (this.questionsToHide.indexOf(4087) > -1) {
          this.questionsToHide.splice(this.questionsToHide.indexOf(4087), 1);
          this.addOrRemoveValidation("4136", "add");
          this.addOrRemoveValidation("4137", "add");
          this.addOrRemoveValidation("4140", "add");
          this.addOrRemoveValidation("4138", "add");
          this.addOrRemoveValidation("4139", "add");
        }
        if (this.questionsToHide.indexOf(4086) == -1) {
          this.questionsToHide.push(4086);
        }
      }
      else {
        if (this.questionsToHide.indexOf(4086) > -1) {
          this.questionsToHide.splice(this.questionsToHide.indexOf(4086), 1);
          this.addOrRemoveValidation("4135", "add");
        }
        if (this.questionsToHide.indexOf(4087) == -1) {
          this.questionsToHide.push(4087);
        }
      }
    }

    if (question4000.answerOptions[0].userAnswer != null && question4000.answerOptions[0].userAnswer.userValue === "0") {
      if (this.questionsToHide.indexOf(4008) > -1) {
        this.questionsToHide.splice(this.questionsToHide.indexOf(4008), 1);
      }
      if (this.questionsToHide.indexOf(4087) == -1) {
        this.questionsToHide.push(4087);
      }
      if (this.questionsToHide.indexOf(4085) == -1) {
        this.questionsToHide.push(4085);
      }
      if (question4008.answerOptions[0].userAnswer && question4008.answerOptions[0].userAnswer.userValue === "1") {
        if (this.questionsToHide.indexOf(4086) > -1) {
          this.questionsToHide.splice(this.questionsToHide.indexOf(4086), 1);
          this.addOrRemoveValidation("4135", "add");
        }
      } else {
        if (this.questionsToHide.indexOf(4086) == -1) {
          this.questionsToHide.push(4086);
        }
      }
    }
  }

  setIntialValue(fieldId, questionIndex, answerOptionIndex) {
    let ansOptions = this.jsonFormData.questionGroup.questions[questionIndex].answerOptions;
    let answer;
    if (Array.isArray(ansOptions)) {
      answer = this.jsonFormData.questionGroup.questions[questionIndex].answerOptions[answerOptionIndex].userAnswer;
    } else {
      answer = this.jsonFormData.questionGroup.questions[questionIndex].answerOptions.userAnswer;
    }

    if (answer != null && "userValue" in answer) return answer.userValue;
    return ""
  }

  setDefaultUserAnswerValues() {
    for (var questionIndex in this.jsonFormData.questionGroup.questions) {
      let question = this.jsonFormData.questionGroup.questions[questionIndex]
      let ansOptions = question.answerOptions

      for (var ansIndex in ansOptions) {
        let ansOption = this.jsonFormData.questionGroup.questions[questionIndex].answerOptions[ansIndex]
        if (ansOption.userAnswer == null) {
          this.jsonFormData.questionGroup.questions[questionIndex].answerOptions[ansIndex].userAnswer = this.getAnswer("", ansOption)
        }
      }
    }
  }

  onSubmit() {
     this.isSubmitted = true;
    if (this.questionAnswerForm && this.questionAnswerForm.valid) {
      this.setDefaultUserAnswerValues()
      this.submitOptions.emit();
    }
    else {
      const errorGroup = this.getFormErrorGroup(this.questionAnswerForm);
      this.errorMessage = "Please answer all the mandatory questions.";
        for (let key in errorGroup) {
        if(errorGroup[key].error === "invalidDate"){
          this.errorMessage = "Invalid date. " + errorGroup[key].value;
          break;
        }
              

        
        for (var questionIndex in this.jsonFormData.questionGroup.questions) {
          let question = this.jsonFormData.questionGroup.questions[questionIndex];
          let ansOptions = question.answerOptions;
          for (var ansIndex in ansOptions) {
            let ansOption = this.jsonFormData.questionGroup.questions[questionIndex].answerOptions[ansIndex]
            
            if (errorGroup[key].error === "min") {
              this.errorMessage += "Invalid date. " + errorGroup[key].value;
              break;
            }

            if (errorGroup[key].error === "max") {
              this.errorMessage += "Invalid date. " + errorGroup[key].value;
              this.errorMessage += "Rank value " + errorGroup[key].value.actual + " is Invalid for questions with only  " + errorGroup[key].value.max+" options." + "<br>Question" + question.questionText;
              break;
            }              
            if (ansOption.fieldID != null && errorGroup[key].control === ansOption.fieldID) {
              this.errorMessage += "Please answer all the mandatory questions." + "<br>" + question.questionText;
            }
          }
        }
      }
      this.presentToast();
    }
  }

  getFormErrorGroup(questionAnswerForm: UntypedFormGroup) {
    const result = [];
    Object.keys(questionAnswerForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = questionAnswerForm.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
    return result;
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: this.errorMessage,
      color: 'danger',
      duration: 2000,
      cssClass: "ion-text-center",
    });
    toast.present();
  }

  goBack() {
    this.initialFormGroup();
    this.backToHome.emit();
  }



  getQuestionArr(questionId: number) {
    let questions = this.jsonFormData.questionGroup.questions
    for (let question in questions) {
      if (questions[question].questionID == questionId) {
        return questions[question]
      }
    }
  }

}
