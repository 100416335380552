/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Document } from './document';
import { TripSummary } from './tripSummary';

export interface PaperWork { 
    id?: string;
    type?: string;
    title?: string;
    description?: string;
    grossAmountDueToDriver?: number;
    trip?: TripSummary;
    submittedDocuments?: Array<Document>;
    missingDocumentsTypes?: Array<string>;
}