import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkifyPipe } from './pipes/Linkify.pipe';
import { PhoneFormatPipe } from './pipes/PhoneFormat.pipe';

@NgModule({
 imports:      [ CommonModule ],
 declarations: [ LinkifyPipe, PhoneFormatPipe ],
 exports:      [ LinkifyPipe, PhoneFormatPipe ]
})

export class SharedPipesModule { }