
<ion-card class="edge-card vertical-card">
  <card-overlay 
    [card]="card"
    (didTapCard)="tapCard()"
    (didTapResize)="resizeCard()"
    (didTapChange)="changeCard()"
    (didTapRemove)="removeCard()"
  ></card-overlay>
  <div *ngIf='!(isWorkdayDown&& workdayPachecks)' class="img-div">
    <img src="../../../../../assets/Images/pay-Icon.svg" alt="Pay">
  </div>
  <div *ngIf='isWorkdayDown && workdayPachecks' class="rectangle-545">
    <ion-label class="wd-down-label">
      Not able to view at this time.
      <br /> Workday is currently unavailable.
    </ion-label>
  </div>
</ion-card>