import { Injectable, Injector } from '@angular/core';
import { IUserInfo } from 'src/app/auth/user-info.model';
import { AuthService } from 'src/app/auth/auth.service';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { NavController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {AppVersion} from '@ionic-native/app-version/ngx';
import { LaunchDarklyConstants, LaunchDarklyRoutes } from '../utilities/launch-darkly-constants';
import { MenuItemsService } from 'src/app/modules/home/services/menu-items-service';
@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyService {
  userInfo: IUserInfo;
  authService: AuthService;
  ldClient: LDClient.LDClient;
  flags: LDClient.LDFlagSet;
  flagChange: BehaviorSubject<Object> = new BehaviorSubject<Object>({});
  launchDarklyRoutes = LaunchDarklyRoutes.ROUTES;
  constructor(
    private injector: Injector,
    private platform: Platform,
    private appVersion: AppVersion,
    private menuItemsService: MenuItemsService,
    private navCtrl: NavController,
  ) {
    this.authService = injector.get<AuthService>(AuthService);
    this.authService.getUserInfo().then(async userInfo => {
      const versionNumber = this.platform.is('mobile')? await this.appVersion.getVersionNumber(): 1;
      this.userInfo = userInfo;
      const context: LDClient.LDContext = {
        kind: 'multi',
        user: {
          key: 'user-key',
          userName: this.userInfo.preferred_username,
          identityKey: this.userInfo.identityKey
        },
        device: {
          key: 'device-type',
          type: this.getPlatform()
        },
        version: {
          key: 'app-version',
          value: versionNumber
        }
      };

      this.ldClient = LDClient.initialize(environment.launchDarkly.clientId, context, { streaming: true });

      this.ldClient.on('change', (flags) => {
        this.validateAndSetFlags(flags);
      });

      this.ldClient.on('ready', () => {
        this.setFlags();
      });
    });
  }

  getPlatform(){
    let platform = 'browser';
    if(this.platform.is('android')) platform = 'Android';
    if(this.platform.is('ios')) platform = 'ios';
    return platform;
  }

  validateAndSetFlags(flags) {
     const getAllflags = this.getAllFlags();
    if (JSON.stringify(flags) !== JSON.stringify(this.flags)) {
      this.updateHamburgerMenu(getAllflags);
      this.getGamificationFlag();
      const url = window.location.pathname;
      this.validatePageAccess(url);      
      this.flagChange.next(getAllflags);
    }
  }

  validatePageAccess(url){
    url   = url.replace('/', '');
    const flags = this.getAllFlags();
    const selectedFlag = this.launchDarklyRoutes.find((flag) => { return flag.url === url; });
    if(selectedFlag !== undefined && selectedFlag.key in flags && flags[selectedFlag.key] === false){
      this.navCtrl.navigateForward("/");
    }
  }

  setFlags() {
    const allFlags = this.ldClient.allFlags();
    this.validateAndSetFlags(allFlags);
  }

  getAllFlags(){
    return (this?.ldClient?.allFlags() && this?.ldClient?.allFlags() !== undefined)?this.ldClient.allFlags():[];
  }

  updateHamburgerMenu(flags){
    for(let flagKey in flags){
      const flagValue = flags[flagKey];
      const route = this.launchDarklyRoutes.find((route) => route.isMenuAvailable === true && route.key === flagKey );
      if(route !== undefined && typeof flagValue === 'object'){
        (flagValue.current === true)?this.menuItemsService.showMenuItemByLabel(route.menuLabel):this.menuItemsService.hideMenuItemByLabel(route.menuLabel);
      }else if(route !== undefined){
        (flagValue === true)?this.menuItemsService.showMenuItemByLabel(route.menuLabel):this.menuItemsService.hideMenuItemByLabel(route.menuLabel);
      }
    }
  }

  getGamificationFlag(flags = {}){
    if(!flags){ flags = this.getAllFlags(); }
    let isGamificationEnabled:boolean = false;
    if(flags !== undefined && typeof flags[LaunchDarklyConstants.DWP_GAMIFICATION_ENABLED] === 'object'){
      isGamificationEnabled = flags[LaunchDarklyConstants.DWP_GAMIFICATION_ENABLED].current;
    }
    else if (flags !== undefined && typeof flags[LaunchDarklyConstants.DWP_GAMIFICATION_ENABLED] === 'boolean'){
      isGamificationEnabled = flags[LaunchDarklyConstants.DWP_GAMIFICATION_ENABLED];
    }  
    return isGamificationEnabled;  
  }

  getCassChatFlag(flags = {}){
    let isCassChatEnabled: boolean = false;
    if(!flags){ flags = this.getAllFlags(); }

    if(flags !== undefined && typeof flags[LaunchDarklyConstants.DWP_CASSCHAT_ENABLED] === 'object'){
      isCassChatEnabled = flags[LaunchDarklyConstants.DWP_CASSCHAT_ENABLED].current;
    }
    else if (flags !== undefined && typeof flags[LaunchDarklyConstants.DWP_CASSCHAT_ENABLED] === 'boolean'){
      isCassChatEnabled = flags[LaunchDarklyConstants.DWP_CASSCHAT_ENABLED];
    }    
    return isCassChatEnabled;
  }  

  getFlagStatus(flags, flagKey: string){
    if(!flags){ flags = this.getAllFlags(); }
    let flagStatus: boolean = false;

    if(flags !== undefined && typeof flags[flagKey] === 'object'){
      flagStatus = flags[flagKey].current;
    }
    else if (flags !== undefined && typeof flags[flagKey] === 'boolean'){
      flagStatus = flags[flagKey];
    }    
    return flagStatus;    
  }


}
