/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PointsOfInterestAddress { 
    streetAddress1?: string;
    streetAddress2?: string;
    city?: string;
    state?: string;
    zip?: string;
    longitude?: number;
    latitude?: number;
    country?: string;
    addressTypeId?: number;
    addressTypeDescription?: string;
    id?: string;
    lastUpdatedDateTime?: string;
}