import { Action } from '@ngrx/store';
import { NewsArticle } from '../../../Swagger-Gen';

export enum NewsActionTypes {
    GetAllNews = 'Get All News',
    GetAllNewsSuccess = 'Get All News Success',
    GetAllNewsFail = 'Get All News Fail'
}

export class GetAllNews implements Action {
    readonly type = NewsActionTypes.GetAllNews;
    constructor() { }
}

export class GetAllNewsSuccess implements Action {
    readonly type = NewsActionTypes.GetAllNewsSuccess;
    constructor(public payload: Array<NewsArticle>) { }
}

export class GetAllNewsFail implements Action {
    readonly type = NewsActionTypes.GetAllNewsFail;
    constructor(public payload: string) { }
}

export type GetNewsActions = GetAllNews | GetAllNewsSuccess | GetAllNewsFail;
