/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DriverAddressType = 'Unknown' | 'CompanyMailing' | 'CompanyPhysical' | 'Home' | 'HomePayroll' | 'Logs';

export const DriverAddressType = {
    Unknown: 'Unknown' as DriverAddressType,
    CompanyMailing: 'CompanyMailing' as DriverAddressType,
    CompanyPhysical: 'CompanyPhysical' as DriverAddressType,
    Home: 'Home' as DriverAddressType,
    HomePayroll: 'HomePayroll' as DriverAddressType,
    Logs: 'Logs' as DriverAddressType
};