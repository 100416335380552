import {LoadStatus} from '../../../../../../Swagger-Gen';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {LoadStatusActions, LoadStatusActionTypes} from './load-status.actions';
import * as fromRoot from '../../../../../../app.state';

export interface State extends fromRoot.State {
    loadStatus: LoadStatusState;
}

export interface LoadStatusState {
    currentLoadStatus: LoadStatus;
    error: string;
}

const initialState: LoadStatusState = {
    currentLoadStatus: null,
    error: ''
};

const getLoadStatusFeatureState = createFeatureSelector<LoadStatusState>('loadStatus');

export const getCurrentLoadStatus = createSelector(
    getLoadStatusFeatureState,
    state => state.currentLoadStatus
);

export const getError = createSelector(
    getLoadStatusFeatureState,
    state => state.error
);

export function loadStatusReducer(state= initialState, action: LoadStatusActions): LoadStatusState {
    switch (action.type) {
        case LoadStatusActionTypes.LoadSuccess:
            return {
                ...state,
                currentLoadStatus: action.payload,
                error: ''
            };

        case LoadStatusActionTypes.LoadFail:
            return {
                ...state,
                currentLoadStatus: {},
                error: action.payload
            };

        default:
            return state;
    }
}
