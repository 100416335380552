/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PhoneEditVM } from './phoneEditVM';

export interface EmergencyContactEditVM {
    id?: string;
    userID?: string;
    firstName?: string;
    lastName?: string;
    phone?: PhoneEditVM;
    relationship?: string;
    relationshipTypeId?: string;
    isMarkedDelete?: boolean;
}
