<div class="background">
  <div class="modal">

    <ion-row>
      <ion-item class="title-row" lines="full">
        <ion-label class="title-label ion-text-left">{{title}}</ion-label>
        <div style="float:right !important;" class="pointer">
          <img src="../../../../assets/Images/cross.png" (click)="tappedCloseButtonClickHandler()" />
        </div>
      </ion-item>
    </ion-row>
    
    <ion-row class="location-row">
      <ion-item button lines="none" detail  (click)="locationFeedbackButtonClickHandler()">
          <ion-row>
            <ion-icon name="chatbox-outline" class="option-action-button-icon"></ion-icon>
            <ion-label class="option-action-button-label">Location Feedback</ion-label>
          </ion-row>
      </ion-item>

    </ion-row>
  </div>
</div>
