/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Equipment } from './equipment';
import { StopAmenities } from './stopAmenities';
import { TripStepType } from './tripStepType';

export interface TripStep { 
    id?: string;
    name?: string;
    loadNumber?: string;
    type?: TripStepType;
    location?: Address;
    departureDate?: Date;
    arrivalDate?: Date;
    targetDate?: Date;
    reviewsCount?: number;
    averageReview?: number;
    phoneNumber?: string;
    accessCode?: string;
    shipperReferenceNumber?: string;
    pickUpInstructions?: string;
    siteInstructions?: string;
    siteInstructionsImagesUrls?: Array<string>;
    amenities?: StopAmenities;
    trailerNumber?: string;
    pickUpNumber?: string;
    index?: number;
    equipments?: Array<Equipment>;
    siteSafetyAlertUrl?: string;
}
