import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "phoneFormat",
})
export class PhoneFormatPipe implements PipeTransform {
    transform(str: string): string {
        return this.phoneFormat(str);
    }

    private phoneFormat(value: string): string {
        if (value) {
            const phoneNumber = value.replace(/\D+/g, "");
            // Departments
            if (phoneNumber.length === 10) {
                return phoneNumber.replace(
                    /(\d{3})(\d{3})(\d{4}$)/gi,
                    "($1) $2-$3"
                );
            }
            // Profile and messages
            if (phoneNumber.length === 11 || phoneNumber.length === 12) {
                return phoneNumber.replace(
                    /(\d{0,2})(\d{3})(\d{3})(\d{4}$)/gi,
                    "+$1 ($2) $3-$4"
                );
            }
            // Terminals
            if (phoneNumber.length > 12) {
                const correctPhoneNumber = phoneNumber
                    .substring(0, 10)
                    .replace(/(\d{3})(\d{3})(\d{4}$)/gi, "($1) $2-$3");
                return value.replace(value.substring(0, 14), correctPhoneNumber);
            }
            return value;
        } else {
            return "";
        }
    }
}
