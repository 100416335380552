/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PointsOfInterests } from '../model/pointsOfInterests';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PointsOfInterestsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Retrieves data on points of interests near a zip code
     * 
     * @param userIdentityKey 
     * @param zipCode 
     * @param getFuelStops 
     * @param getTrucksWashes 
     * @param getRestAreas 
     * @param getTerminals 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPointsofinterestsUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGet(userIdentityKey: string, zipCode: string, getFuelStops: boolean, getTrucksWashes: boolean, getRestAreas: boolean, getTerminals: boolean, observe?: 'body', reportProgress?: boolean): Observable<PointsOfInterests>;
    public apiPointsofinterestsUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGet(userIdentityKey: string, zipCode: string, getFuelStops: boolean, getTrucksWashes: boolean, getRestAreas: boolean, getTerminals: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PointsOfInterests>>;
    public apiPointsofinterestsUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGet(userIdentityKey: string, zipCode: string, getFuelStops: boolean, getTrucksWashes: boolean, getRestAreas: boolean, getTerminals: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PointsOfInterests>>;
    public apiPointsofinterestsUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGet(userIdentityKey: string, zipCode: string, getFuelStops: boolean, getTrucksWashes: boolean, getRestAreas: boolean, getTerminals: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userIdentityKey === null || userIdentityKey === undefined) {
            throw new Error('Required parameter userIdentityKey was null or undefined when calling apiPointsofinterestsUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGet.');
        }

        if (zipCode === null || zipCode === undefined) {
            throw new Error('Required parameter zipCode was null or undefined when calling apiPointsofinterestsUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGet.');
        }

        if (getFuelStops === null || getFuelStops === undefined) {
            throw new Error('Required parameter getFuelStops was null or undefined when calling apiPointsofinterestsUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGet.');
        }

        if (getTrucksWashes === null || getTrucksWashes === undefined) {
            throw new Error('Required parameter getTrucksWashes was null or undefined when calling apiPointsofinterestsUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGet.');
        }

        if (getRestAreas === null || getRestAreas === undefined) {
            throw new Error('Required parameter getRestAreas was null or undefined when calling apiPointsofinterestsUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGet.');
        }

        if (getTerminals === null || getTerminals === undefined) {
            throw new Error('Required parameter getTerminals was null or undefined when calling apiPointsofinterestsUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PointsOfInterests>('get',`${this.basePath}/api/pointsofinterests/${encodeURIComponent(String(userIdentityKey))}/${encodeURIComponent(String(zipCode))}/${encodeURIComponent(String(getFuelStops))}/${encodeURIComponent(String(getTrucksWashes))}/${encodeURIComponent(String(getRestAreas))}/${encodeURIComponent(String(getTerminals))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves data on points of interests near a zip code
     * 
     * @param userIdentityKey 
     * @param zipCode 
     * @param getFuelStops 
     * @param getTrucksWashes 
     * @param getRestAreas 
     * @param getTerminals 
     * @param getChainBanks 
     * @param getScales 
     * @param getDropYards 
     * @param getTruckStops 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet(userIdentityKey: string, zipCode: string, getFuelStops: boolean, getTrucksWashes: boolean, getRestAreas: boolean, getTerminals: boolean, getChainBanks: boolean, getScales: boolean, getDropYards: boolean, getTruckStops: boolean, observe?: 'body', reportProgress?: boolean): Observable<PointsOfInterests>;
    public apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet(userIdentityKey: string, zipCode: string, getFuelStops: boolean, getTrucksWashes: boolean, getRestAreas: boolean, getTerminals: boolean, getChainBanks: boolean, getScales: boolean, getDropYards: boolean, getTruckStops: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PointsOfInterests>>;
    public apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet(userIdentityKey: string, zipCode: string, getFuelStops: boolean, getTrucksWashes: boolean, getRestAreas: boolean, getTerminals: boolean, getChainBanks: boolean, getScales: boolean, getDropYards: boolean, getTruckStops: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PointsOfInterests>>;
    public apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet(userIdentityKey: string, zipCode: string, getFuelStops: boolean, getTrucksWashes: boolean, getRestAreas: boolean, getTerminals: boolean, getChainBanks: boolean, getScales: boolean, getDropYards: boolean, getTruckStops: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userIdentityKey === null || userIdentityKey === undefined) {
            throw new Error('Required parameter userIdentityKey was null or undefined when calling apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet.');
        }

        if (zipCode === null || zipCode === undefined) {
            throw new Error('Required parameter zipCode was null or undefined when calling apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet.');
        }

        if (getFuelStops === null || getFuelStops === undefined) {
            throw new Error('Required parameter getFuelStops was null or undefined when calling apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet.');
        }

        if (getTrucksWashes === null || getTrucksWashes === undefined) {
            throw new Error('Required parameter getTrucksWashes was null or undefined when calling apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet.');
        }

        if (getRestAreas === null || getRestAreas === undefined) {
            throw new Error('Required parameter getRestAreas was null or undefined when calling apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet.');
        }

        if (getTerminals === null || getTerminals === undefined) {
            throw new Error('Required parameter getTerminals was null or undefined when calling apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet.');
        }

        if (getChainBanks === null || getChainBanks === undefined) {
            throw new Error('Required parameter getChainBanks was null or undefined when calling apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet.');
        }

        if (getScales === null || getScales === undefined) {
            throw new Error('Required parameter getScales was null or undefined when calling apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet.');
        }

        if (getDropYards === null || getDropYards === undefined) {
            throw new Error('Required parameter getDropYards was null or undefined when calling apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet.');
        }

        if (getTruckStops === null || getTruckStops === undefined) {
            throw new Error('Required parameter getTruckStops was null or undefined when calling apiPoisUserIdentityKeyZipCodeGetFuelStopsGetTrucksWashesGetRestAreasGetTerminalsGetChainBanksGetScalesGetDropYardsGetTruckStopsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PointsOfInterests>('get',`${this.basePath}/api/pois/${encodeURIComponent(String(userIdentityKey))}/${encodeURIComponent(String(zipCode))}/${encodeURIComponent(String(getFuelStops))}/${encodeURIComponent(String(getTrucksWashes))}/${encodeURIComponent(String(getRestAreas))}/${encodeURIComponent(String(getTerminals))}/${encodeURIComponent(String(getChainBanks))}/${encodeURIComponent(String(getScales))}/${encodeURIComponent(String(getDropYards))}/${encodeURIComponent(String(getTruckStops))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
