/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IdentityPhoneType } from './identityPhoneType';
import { PhoneEditVM } from './phoneEditVM';

export interface PhonesVM { 
    phoneTypes?: Array<IdentityPhoneType>;
    phones?: Array<PhoneEditVM>;
}