import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'save-modal',
  templateUrl: './save-modal.component.html',
  styleUrls: ['./save-modal.component.scss'],
})
export class SaveModalComponent implements OnInit {

  @Output("cancel") cancel = new EventEmitter;
  @Output("continue") continue = new EventEmitter;
  @Output("save") save = new EventEmitter;

  constructor() { }

  ngOnInit() {}

  didTapContinue() {
    this.continue.emit();
  }

  didTapSave() {
    this.save.emit();
  }

  closeModal() {
    this.cancel.emit();
  }
}
