import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AppVersionsService } from './api/appVersions.service';
import { AskTedService } from './api/askTed.service';
import { ChallengeQuestionsService } from './api/challengeQuestions.service';
import { ChecksService } from './api/checks.service';
import { DepartmentsService } from './api/departments.service';
import { EmergencyContactsService } from './api/emergencyContacts.service';
import { ExpensesService } from './api/expenses.service';
import { FeedbackService } from './api/feedback.service';
import { FilesService } from './api/files.service';
import { HomeService } from './api/home.service';
import { LoadSelectionsService } from './api/loadSelections.service';
import { LoadStatusService } from './api/loadStatus.service';
import { MessagingService } from './api/messaging.service';
import { NewsService } from './api/news.service';
import { OpportunitiesService } from './api/opportunities.service';
import { OrientationService } from './api/orientation.service';
import { PaperWorkService } from './api/paperWork.service';
import { PaymentsService } from './api/payments.service';
import { PinService } from './api/pin.service';
import { PointsOfInterestsService } from './api/pointsOfInterests.service';
import { ProfileService } from './api/profile.service';
import { ProfileInformationService } from './api/profileInformation.service';
import { ReferenceLibraryService } from './api/referenceLibrary.service';
import { ReferralsService } from './api/referrals.service';
import { SafetyService } from './api/safety.service';
import { StoriesService } from './api/stories.service';
import { TelemetryService } from './api/telemetry.service';
import { TerminalsService } from './api/terminals.service';
import { TripPaksService } from './api/tripPaks.service';
import { TripsService } from './api/trips.service';
import { UserAgreementVersionService } from './api/userAgreementVersion.service';
import {DriverAddressesService} from "./api/driverAddresses.service";
import {DriverEmailsService} from "./api/driverEmails.service";
import {DriverPhonesService} from "./api/driverPhones.service";
import { DriverMaritalStatusService } from './api/driverMaritalStatus.service';
import {AppFeaturesService} from './api/appFeatures.service';
import { WorkdayService } from './api/workday.service';
import { DrivingOptionsService } from './api/driving.options.service';
import { DriverUpdatesService } from './api/driverUpdates.service';
import { EmployeeResourcesService } from './api/employeeResources.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AppVersionsService,
    AskTedService,
    ChallengeQuestionsService,
    ChecksService,
    DepartmentsService,
    EmergencyContactsService,
    DriverAddressesService,
    DriverEmailsService,
    DriverUpdatesService,
    DriverPhonesService,
    DriverMaritalStatusService,
    ExpensesService,
    FeedbackService,
    FilesService,
    HomeService,
    LoadSelectionsService,
    LoadStatusService,
    MessagingService,
    NewsService,
    OpportunitiesService,
    OrientationService,
    PaperWorkService,
    PaymentsService,
    PinService,
    PointsOfInterestsService,
    ProfileService,
    ProfileInformationService,
    ReferenceLibraryService,
    ReferralsService,
    SafetyService,
    StoriesService,
    TelemetryService,
    TerminalsService,
    TripPaksService,
    TripsService,
    UserAgreementVersionService,
    AppFeaturesService,
    WorkdayService,
    DrivingOptionsService,
    EmployeeResourcesService
  ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
