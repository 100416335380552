<ion-card class="edge-card">
  <card-overlay 
    [card]="card"
    (didTapCard)="tapCard()"
    (didTapResize)="resizeCard()"
    (didTapChange)="changeCard()"
    (didTapRemove)="removeCard()"
  ></card-overlay>
  <ion-item lines="full">
    <div class="map" id="jobMap" #jobMap></div>
  </ion-item>
  <ion-row *ngIf ="currentJobStep && currentJobStep.location && currentJobStep.location.state && currentJobStep.targetDate; else no_jobs" class='location-arrival-wrapper'>
    <ion-col class="left-align" size="6">
      <ion-text class="edge-label small-card-text">NEXT LOCATION</ion-text>
      <ion-text class="edge-value bold-card-text">
        {{currentJobStep?.name}}, {{currentJobStep?.location?.state}}
      </ion-text>
    </ion-col>
    <ion-col class="right-align" size="6">
      <ion-text class="edge-label small-card-text">SCHEDULED ARRIVAL</ion-text>
      <ion-text class="edge-value bold-card-text">{{currentJobStep?.targetDate | date: 'MMM d'}} at {{currentJobStep?.targetDate | date: 'HH:mm'}}</ion-text>
    </ion-col>
  </ion-row>
  <ng-template #no_jobs >
    <div class='no-jobs-label'>No Jobs assigned.</div></ng-template>
</ion-card>