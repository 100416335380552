/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PointsOfInterestChainBank } from './pointsOfInterestChainBank';
import { PointsOfInterestDropYard } from './pointsOfInterestDropYard';
import { PointsOfInterestFuelStop } from './pointsOfInterestFuelStop';
import { PointsOfInterestRestArea } from './pointsOfInterestRestArea';
import { PointsOfInterestScale } from './pointsOfInterestScale';
import { PointsOfInterestTerminal } from './pointsOfInterestTerminal';
import { PointsOfInterestTruckStop } from './pointsOfInterestTruckStop';
import { PointsOfInterestTruckWash } from './pointsOfInterestTruckWash';

export interface PointOfInterestResponse { 
    proximityZipCode?: string;
    restAreas?: Array<PointsOfInterestRestArea>;
    fuelStops?: Array<PointsOfInterestFuelStop>;
    truckWashes?: Array<PointsOfInterestTruckWash>;
    terminals?: Array<PointsOfInterestTerminal>;
    chainBanks?: Array<PointsOfInterestChainBank>;
    scales?: Array<PointsOfInterestScale>;
    dropYards?: Array<PointsOfInterestDropYard>;
    truckStops?: Array<PointsOfInterestTruckStop>;
}