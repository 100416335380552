import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.scss'],
})
export class SecondaryButtonComponent implements OnInit {

  @Input('title') title = "Button"

  constructor() { }

  ngOnInit() {}

}
