<div class="account-contacts">

  <div *ngIf="showContactDetails">
    <app-employee-details
      [employee]="employeeInfo"
      (backEvent)="back()"
    ></app-employee-details>
  </div>
  <!-- Transportation Manager Card-->
  <app-transportation-manager-card *ngIf="fleetManagers" [fleetManagers]="fleetManagers?.fleetManagers"></app-transportation-manager-card>
  
  <!-- Leader Card-->
  <ion-card *ngIf="!isProduction">
    <ion-card-header>
      <ion-card-title class="edge-value">Leader</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col size-xs="3" size-sm="2" size-md="2" size-lg="2" size-xl="2">
            <ion-avatar>
              <ion-avatar>
                <img alt="user avatar" [src]="leaderModel?.user?.picture" />
              </ion-avatar>
            </ion-avatar>
          </ion-col>
          <ion-col
            size-xs="9"
            size-sm="10"
            size-md="10"
            size-lg="10"
            size-xl="10"
          >
            <ion-text class="edge-value leaderName">
              {{ leaderModel?.user?.firstName }}
              {{ leaderModel?.user?.lastName }}
            </ion-text>
            <ion-text class="edge-value leaderRole">
              {{ leaderModel?.user?.role }}
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-item lines="none" class="ion-no-padding">
        <ion-col size="6">
          <ion-label class="edge-label">Phone</ion-label>
        </ion-col>
        <ion-col size="6">
          <ion-text class="edge-value ion-float-right">
            <a href="tel:{{ leaderModel?.phone?.number }}">
              {{ leaderModel?.phone?.number }}
            </a>
          </ion-text>
        </ion-col>
      </ion-item>

      <ion-item lines="none" class="ion-no-padding">
        <ion-col size="6">
          <ion-label class="edge-label">HOMETOWN</ion-label>
        </ion-col>
        <ion-col size="6">
          <ion-text class="edge-value ion-float-right">
            {{ leaderModel?.user?.homeTown }}
          </ion-text>
        </ion-col>
      </ion-item>
      <ion-item lines="none" class="ion-no-padding">
        <ion-col size="6">
          <ion-label class="edge-label ion-text-wrap"
            >Placement drivers trained</ion-label
          >
        </ion-col>
        <ion-col size="6">
          <ion-text class="edge-value ion-float-right">
            {{ leaderModel?.user?.placementDriversTrained }}
          </ion-text>
        </ion-col>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <!-- Placement Driver Card-->
  <ion-card *ngIf="!isProduction">
    <ion-card-header>
      <ion-card-title class="edge-value">Placement Driver</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col size-xs="3" size-sm="2" size-md="2" size-lg="2" size-xl="2">
            <ion-avatar>
              <ion-avatar>
                <img
                  alt="user avatar"
                  [src]="placementDriverModel?.user?.picture"
                />
              </ion-avatar>
            </ion-avatar>
          </ion-col>
          <ion-col
            size-xs="9"
            size-sm="10"
            size-md="10"
            size-lg="10"
            size-xl="10"
          >
            <ion-text class="edge-value leaderName">
              {{ placementDriverModel?.user?.firstName }}
              {{ placementDriverModel?.user?.lastName }}
            </ion-text>
            <ion-text
              class="edge-value leaderRole"
              *ngIf="placementDriverModel?.user?.role"
            >
              {{ placementDriverModel?.user?.role }}
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-item lines="none" class="ion-no-padding">
        <ion-col size="6">
          <ion-label class="edge-label">Phone</ion-label>
        </ion-col>
        <ion-col size="6">
          <ion-text class="edge-value ion-float-right">
            <a href="tel:{{ placementDriverModel?.phone?.number }}">
              {{ placementDriverModel?.phone?.number }}
            </a>
          </ion-text>
        </ion-col>
      </ion-item>

      <ion-item lines="none" class="ion-no-padding">
        <ion-col size="6">
          <ion-label class="edge-label">HOMETOWN</ion-label>
        </ion-col>
        <ion-col size="6">
          <ion-text class="edge-value ion-float-right">
            {{ placementDriverModel?.user?.homeTown }}
          </ion-text>
        </ion-col>
      </ion-item>
      <ion-item lines="none" class="ion-no-padding">
        <ion-col size="12">
          <a> Baseline Evaluation </a>
        </ion-col>
      </ion-item>
    </ion-card-content>
  </ion-card>

    <!-- Mentor Profile Card-->
    <ion-card *ngIf="!isProduction">
      <ion-card-header>
        <ion-card-title class="edge-value">Mentor</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-grid>
          <ion-row class="ion-align-items-center">
            <ion-col size-xs="3" size-sm="2" size-md="2" size-lg="2" size-xl="2">
              <ion-avatar>
                <ion-avatar>
                  <img
                    alt="user avatar"
                    [src]="placementDriverModel?.user?.picture"
                  />
                </ion-avatar>
              </ion-avatar>
            </ion-col>
            <ion-col
              size-xs="9"
              size-sm="10"
              size-md="10"
              size-lg="10"
              size-xl="10"
            >
              <ion-text class="edge-value leaderName">Eric stein
              </ion-text>
              <ion-text
                class="edge-value leaderRole"
              > Mentor
              </ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-item lines="none" class="ion-no-padding">
          <ion-col size="6">
            <ion-label class="edge-label ion-text-wrap">ACCOUNT NAME</ion-label>
          </ion-col>
          <ion-col size="6">
            <ion-text class="edge-value ion-text-wrap ion-float-right">
              Family Dollar Rome, NY
            </ion-text>
          </ion-col>
        </ion-item>
  
        <ion-item lines="none" class="ion-no-padding">
          <ion-col size="6">
            <ion-label class="edge-label">PHONE</ion-label>
          </ion-col>
          <ion-col size="6">
            <ion-text class="edge-value ion-float-right">
             1234-5678
            </ion-text>
          </ion-col>
        </ion-item>

        <ion-item lines="none" class="ion-no-padding">
          <ion-col size="6">
            <ion-label class="edge-label">TENURE</ion-label>
          </ion-col>
          <ion-col size="6">
            <ion-text class="edge-value ion-text-wrap ion-float-right">
              5 years, 5 months
            </ion-text>
          </ion-col>
        </ion-item>

        <ion-item lines="none" class="ion-no-padding">
          <ion-col size="6">
            <ion-label class="edge-label">SAFE MILES</ion-label>
          </ion-col>
          <ion-col size="6">
            <ion-text class="edge-value ion-float-right">
              150,000
            </ion-text>
          </ion-col>
        </ion-item>

        <ion-item lines="none" class="ion-no-padding">
          <ion-col size="6">
            <ion-label class="edge-label">SAFE STOPS</ion-label>
          </ion-col>
          <ion-col size="6">
            <ion-text class="edge-value ion-float-right">
              4,000
            </ion-text>
          </ion-col>
        </ion-item>

      </ion-card-content>
    </ion-card>
</div>
