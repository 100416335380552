import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Employee } from "src/app/Swagger-Gen";

@Component({
  selector: "app-employee-details",
  templateUrl: "./employee-details.component.html",
  styleUrls: ["./employee-details.component.scss"],
})
export class EmployeeDetailsComponent implements OnInit {
  @Input() title: string = "";
  @Input() employee: Employee;
  @Output() backEvent = new EventEmitter();
  formattedPhoneNumber = "";

  async ngOnInit() {
    const splitNumber = this.employee.phoneNumber.match(/.{1,3}/g);
    this.formattedPhoneNumber = `(${splitNumber[0]}) ${splitNumber[1]}-${splitNumber[2]}${splitNumber[3]}`;
  }

  back() {
    this.backEvent.emit();
  }
}
