import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from "../../../app.state";
import { NewsItem } from "../../../shared/models/news-item";
import { GetAllNews, GetNewsActions, NewsActionTypes } from "./news.actions";
import { NewsArticle } from "../../../Swagger-Gen";

export interface State extends fromRoot.State {
    newsItemsState: NewsItemState;
}

export interface NewsItemState {
    newsItems: Array<NewsArticle>;
    error: string;
}

const initialState: NewsItemState = {
    newsItems: null,
    error: ''
};

const getNewsItemFeatureState = createFeatureSelector<NewsItemState>('newsItems');

export const getNewsItems = createSelector(
    getNewsItemFeatureState,
    state => (state != null) ? state.newsItems : null
);

export const getNewsItemError = createSelector(
    getNewsItemFeatureState,
    state => state.error
);

export function newsItemReducer(state = initialState, action: GetNewsActions): NewsItemState {
    switch (action.type) {
        case NewsActionTypes.GetAllNewsSuccess:
            return {
                ...state,
                newsItems: action.payload
            };
        case NewsActionTypes.GetAllNewsFail:
            return {
                ...state,
                newsItems: null,
                error: action.payload
            };
        default:
            return state;
    }
}
