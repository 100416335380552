<div class="background">
  <div class="modal">
    <ion-row>

      <ion-item class="title-row" lines="none">
        <ion-label class="title-label">{{title}}</ion-label>
        <div style="float:right !important;">
          <img src="../../../../assets/Images/cross.png" (click)="tappedCloseButtonClickHandler()" />
        </div>

      </ion-item>

    </ion-row>
    <ion-row>
      <ion-item lines="none">
        <div class="edge-value subtitle-row">
          <p>
            {{content}}
          </p>

        </div>

      </ion-item>
    </ion-row>

    <ion-row >
      <ion-col size="6">
        <div class="cta werner-button text" (click)="noButtonClickHandler()">{{noButtonText}}</div>
      </ion-col>
      <ion-col size="6">
        <div class="cta werner-button primary" (click)="yesButtonClickHandler()">{{yesButtonText}}</div>
      </ion-col>
    </ion-row>

  </div>


</div>

