import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppInsights } from 'applicationinsights-js';

@Injectable({ providedIn: 'root' })
export class MonitoringService {
    private config: AppInsights.Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: environment.appInsights.instrumentationKey
    };
    constructor() {
        if (!AppInsights.config) {
            AppInsights.downloadAndSetup(this.config);
        }
    }

    logPageView() {
      AppInsights.trackPageView();
    }
  
    startNavigationEvent(url: string) {   
      if (AppInsights.context) {
          AppInsights.context.operation.id =
              window['Microsoft'].ApplicationInsights.Util.newId();
          AppInsights.context.operation.name = url;
      }
      AppInsights.startTrackEvent(url);
    }
  
    endNavigationEvent(url: string) {
      AppInsights.stopTrackEvent(url, { type: 'PAGE LOAD TIME' });
    }
}

