import { Action } from '@ngrx/store';
// import {PeriodTripsList, Trip} from '../../../../Swagger-Gen';
import { WernerDwpContractsLogicJobEquipment as equipment } from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobEquipment';
import { WernerDwpContractsLogicJobTrailerReservation as trailerReservation } from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobTrailerReservation';
import { WernerDwpContractsLogicJobTrip as trip} from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobTrip';




export enum CurrentTripActionTypes {
    GetCurrentTrip = 'Get Driver Current Trip',
    GetCurrentTripSuccess = 'Get Driver Current Trip Success',
    GetCurrentTripFail = 'Get Driver Current Trip Fail',
    GetTrailerReservation = 'Get Trailer Reservation for Trip',
    GetTrailerReservationSuccess = 'Get Trailer Reservation for Trip Success',
    GetTrailerReservationFail = 'Get Trailer Reservation for Trip Fail',
    GetTrailers = 'Get Trailers',
    GetTrailersSuccess = 'Get Trailers Success',
    GetTrailersFail = 'Get Trailers Fail',
    GetSiteSafetyalertUrl = 'Get Site Saftey URL',
    GetSiteSafetyalertUrlSuccess = 'Get Site Saftey UR Success',
    GetSiteSafetyalertUrlFail = 'Get Site Saftey URL Fail'
}


export class GetCurrentTrip implements Action {
    readonly type = CurrentTripActionTypes.GetCurrentTrip;
    employeeNumber;
    constructor(empNumber) {
        this.employeeNumber = empNumber;
    }
}

export class GetCurrentTripSuccess implements Action {
    readonly type = CurrentTripActionTypes.GetCurrentTripSuccess;
    constructor(public payload: trip) {
        if (this.payload !== null) {
            localStorage.setItem('driver-current-trip', JSON.stringify(this.payload));
        }
    }
}

export class GetCurrentTripFail implements Action {
    readonly type = CurrentTripActionTypes.GetCurrentTripFail;
    constructor(public payload: string) {
    }
}


export class GetTrailerReservation implements Action {
    readonly type = CurrentTripActionTypes.GetTrailerReservation;
    employeeNumber;
    constructor(empNumber) {
        this.employeeNumber = empNumber;
    }
}

export class GetTrailerReservationSuccess implements Action {
    readonly type = CurrentTripActionTypes.GetTrailerReservationSuccess;
    constructor(public payload: trailerReservation) {
        
    }
}

export class GetTrailerReservationFail implements Action {
    readonly type = CurrentTripActionTypes.GetTrailerReservationFail;
    constructor(public payload: string) {
    }
}


export class GetTrailers implements Action {
    readonly type = CurrentTripActionTypes.GetTrailers;
    trailerNumbers;
    constructor(trailerNumbers: Array<string>) {
        this.trailerNumbers = trailerNumbers;
    }
}

export class GetTrailersSuccess implements Action {
    readonly type = CurrentTripActionTypes.GetTrailersSuccess;
    constructor(public payload: Array<equipment>) {
        
    }
}

export class GetTrailersFail implements Action {
    readonly type = CurrentTripActionTypes.GetTrailersFail;
    constructor(public payload: string) {
    }
}


export class GetSiteSafetyalertUrl implements Action {
    readonly type = CurrentTripActionTypes.GetSiteSafetyalertUrl;
    locationCode;
    constructor(locationCode: string) {
        this.locationCode = locationCode;
    }
}

export class GetSiteSafetyalertUrlSuccess implements Action {
    readonly type = CurrentTripActionTypes.GetSiteSafetyalertUrlSuccess;
    constructor(public payload: string) {
        
    }
}

export class GetSiteSafetyalertUrlFail implements Action {
    readonly type = CurrentTripActionTypes.GetSiteSafetyalertUrlFail;
    constructor(public payload: string) {
    }
}


export type GetCurrentTripActions = GetCurrentTrip | GetCurrentTripSuccess | GetCurrentTripFail | GetTrailerReservation |GetTrailerReservationSuccess | GetTrailerReservationFail | GetTrailers | GetTrailersSuccess | GetTrailersFail | GetSiteSafetyalertUrl | GetSiteSafetyalertUrlSuccess | GetSiteSafetyalertUrlFail ;
