<div class="overlay" *ngIf="card.showSizePositionControl">
  <div class="locked">
    Werner News - Locked Position
  </div>
</div>
<ion-segment class="hide-scrollbar" scrollable mode="md">
  <carousel-news-card [preview]="card.preview"></carousel-news-card>
  <div *ngFor="let item of carouselItems | slice : 1" (click)="navigateTo(item.path)">
    <carousel-item-card [item]=item></carousel-item-card>
  </div>
</ion-segment>