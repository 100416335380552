/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExtensionDataObject } from './extensionDataObject';

export interface DriverEmergencyContact { 
    extensionData?: ExtensionDataObject;
    countryCode?: string;
    emergencyContactID?: number;
    employeeID?: string;
    firstName?: string;
    internationalCode?: string;
    lastName?: string;
    locationCode?: string;
    name?: string;
    number?: string;
    relation?: string;
    workDayEmergencyContactId?: string;
}