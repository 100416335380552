
<ion-card class="edge-card vertical-card">
  <card-overlay 
    [card]="card"
    (didTapCard)="tapCard()"
    (didTapResize)="resizeCard()"
    (didTapChange)="changeCard()"
    (didTapRemove)="removeCard()"
  ></card-overlay>
    <div class="content">
      <div class="edge-image">
        <img src="../../../../../assets/Images/Driver-Opportunities-Icon.svg" alt="Driving Oppurtunties">
      </div>
    </div>
</ion-card>