export class LoadingProperties {

    // If true, the loading indicator will animate.
    animated?: boolean = false;

    //If true, the loading indicator will be dismissed when the backdrop is clicked.
    backdropDismiss?: boolean = false;

    //Additional classes to apply for custom CSS. If multiple classes are provided they should be separated by spaces.
    cssClass?: string;

    //Number of milliseconds to wait before dismissing the loading indicator.
    duration?: number = 0;

    //Animation to use when the loading indicator is presented.
    enterAnimation?: any;

    //If true, the keyboard will be automatically dismissed when the overlay is presented.
    keyboardClose?: boolean = true;

    //Animation to use when the loading indicator is dismissed.
    leaveAnimation?: any;

    //Optional text content to display in the loading indicator.
    message?: string;

    //The mode determines which platform styles to use. "ios" | "md" 
    mode?: any;

    //If true, a backdrop will be displayed behind the loading indicator.
    showBackdrop?: boolean = true;

    // The name of the spinner to display.
    // "bubbles" | "circles" | "circular" | "crescent" | "dots" | "lines" | "lines-small" | null | undefined
    spinner?: any;

    //If true, the loading indicator will be translucent. Only applies when the mode is "ios" 
    //and the device supports backdrop-filter.
    translucent?: boolean = false;
}