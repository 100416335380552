/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddressesVM } from './addressesVM';
import { EmailEditVM } from './emailEditVM';
import { EmergencyContactVM } from './emergencyContactVM';
import { PhonesVM } from './phonesVM';

export interface UserEditVM { 
    identityKey?: string;
    addressVM?: AddressesVM;
    phonesVM?: PhonesVM;
    emergencyContactsVM?: EmergencyContactVM;
    email?: EmailEditVM;
}