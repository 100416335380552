import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { NavController } from '@ionic/angular';
import { EdgeCard } from 'src/app/shared/models/edge-card';

@Component({
  selector: 'opportunities-card',
  templateUrl: './opportunities-card.component.html',
  styleUrls: ['./opportunities-card.component.scss'],
})
export class OportunitiesCardComponent {
  @Input("card") card: EdgeCard;
  @Output("resize") resize = new EventEmitter();
  @Output("change") change = new EventEmitter();
  @Output("remove") remove = new EventEmitter();
  OPPORTUNITIES_PAGE_SELECTED_TAB_KEY = 'opportunities-page-selected-tab';

  constructor(
    private navCtrl: NavController
  ) { }

  async tapCard() {
    await Preferences.set({key: this.OPPORTUNITIES_PAGE_SELECTED_TAB_KEY, value:'0'});
    this.card.showSizePositionControl || this.card.preview ? 
    !this.card.showOverlay : this.navCtrl.navigateRoot("/opportunities");
  }

  resizeCard() {
    this.resize.emit(this.card);
  }

  changeCard() {
    this.change.emit(this.card);
  }

  removeCard() {
    this.remove.emit(this.card)
  }

}
