/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TripPakExpenseCategory = 'Tolls' | 'Scales' | 'Permit' | 'Maintenance' | 'Parts' | 'EmergencyFuel' | 'Fee' | 'Shag' | 'Layover' | 'Tarp' | 'Other' | 'DriverLoad' | 'DriverUnload' | 'LumperLoad' | 'LumperUnload' | 'Custom1' | 'Custom2' | 'Custom3';

export const TripPakExpenseCategory = {
    Tolls: 'Tolls' as TripPakExpenseCategory,
    Scales: 'Scales' as TripPakExpenseCategory,
    Permit: 'Permit' as TripPakExpenseCategory,
    Maintenance: 'Maintenance' as TripPakExpenseCategory,
    Parts: 'Parts' as TripPakExpenseCategory,
    EmergencyFuel: 'EmergencyFuel' as TripPakExpenseCategory,
    Fee: 'Fee' as TripPakExpenseCategory,
    Shag: 'Shag' as TripPakExpenseCategory,
    Layover: 'Layover' as TripPakExpenseCategory,
    Tarp: 'Tarp' as TripPakExpenseCategory,
    Other: 'Other' as TripPakExpenseCategory,
    DriverLoad: 'DriverLoad' as TripPakExpenseCategory,
    DriverUnload: 'DriverUnload' as TripPakExpenseCategory,
    LumperLoad: 'LumperLoad' as TripPakExpenseCategory,
    LumperUnload: 'LumperUnload' as TripPakExpenseCategory,
    Custom1: 'Custom1' as TripPakExpenseCategory,
    Custom2: 'Custom2' as TripPakExpenseCategory,
    Custom3: 'Custom3' as TripPakExpenseCategory
};