/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CommonAddress } from './commonAddress';
import { CommonContact } from './commonContact';
import { CommonPhone } from './commonPhone';
import { ProfileEmail } from './profileEmail';
import { ProfileEmergencyContact } from './profileEmergencyContact';
import { ProfileQuestionQuestionnaire } from './profileQuestionQuestionnaire';
import { ProfileUser } from './profileUser';

export interface Profile { 
    user?: ProfileUser;
    addresses?: Array<CommonAddress>;
    phoneNumbers?: Array<CommonPhone>;
    emails?: Array<ProfileEmail>;
    emergencyContacts?: Array<ProfileEmergencyContact>;
    safeMiles?: string;
    safeStops?: string;
    division?: string;
    divisionName ?: string;
    isActive?: boolean;
    leader?: CommonContact;
    fleetManagers?: Array<CommonContact>;
    trainees?: Array<CommonContact>;
    questionnaire?: ProfileQuestionQuestionnaire;
    permissions?: Array<string>;
    driverType?: Array<string>;    
}