import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from "../../../app.state";
import { UserDivisonInfoActionTypes, UserIdsActionTypes, UserPermissionsActionTypes, UserPictureActionTypes, UserProfileActions, UserProfileActionTypes, UserProfileBasicInfoActionTypes, UserProfileFleetManagerActionTypes } from "./user-profile.actions";
import { Profile } from 'src/app/Swagger-Gen-V2';
import { ProfileFleetManagersResponse } from 'src/app/Swagger-Gen-V2/model/ProfileFleetManagersResponse';
import { ProfileDivisionInfoResponse } from 'src/app/Swagger-Gen-V2/model/ProfileDivisionInfoResponse';
import { ProfileIdsResponse } from 'src/app/Swagger-Gen-V2/model/ProfileIdsResponse';
import { ProfileImageResponse } from 'src/app/Swagger-Gen-V2/model/ProfileImageResponse';
import { ProfilePermissionResponse } from 'src/app/Swagger-Gen-V2/model/ProfilePermissionResponse';

export interface State extends fromRoot.State {
    userProfileState: UserProfileState;
    userProfileBasicInfoState: UserProfileBasicInfoState;
    userProfileFleetManagerState: UserProfileFleetManagerState;
    userDivisonInfoState: UserDivisonInfoState;
    userPictureState: UserPictureState;
    userIdsState: UserIdsState;
    userPermissionState: UserPermissionState;
}

export interface UserProfileState {
    userData: Profile;
    error: string;
}

const initialState: UserProfileState = {
    userData: null,
    error: ''
};

export interface UserProfileBasicInfoState {
    userData: Profile;
    error: string;
}

const initialProfileBasicInfoState: UserProfileBasicInfoState = {
    userData: null,
    error: ''
};

// Divison Info

export interface UserDivisonInfoState {
    userData: ProfileDivisionInfoResponse;
    error: string;
}

const initialDivisonInfoState: UserDivisonInfoState = {
    userData: null,
    error: ''
};

// Fleet Manager
export interface UserProfileFleetManagerState {
    userProfileFleetManagersData: ProfileFleetManagersResponse;
    error: string;
}

const initialProfileFleetManagerState: UserProfileFleetManagerState = {
    userProfileFleetManagersData: null,
    error: ''
};

// Profile Ids  '
export interface UserIdsState {
    userData: ProfileIdsResponse;
    error: string;
}


const initialProfileIdsState: UserIdsState = {
    userData: null,
    error: ''
};

// profile picture
export interface UserPictureState {
    userData: ProfileImageResponse;
    error: string;
}

const initialUserPictureState: UserPictureState = {
    userData: null,
    error: ''
};

// profile permission
export interface UserPermissionState {
    userData: ProfilePermissionResponse;
    error: string;
}

const initialUserPermissionState: UserPermissionState = {
    userData: null,
    error: ''
};

const getUserDataFeatureState = createFeatureSelector<UserProfileState>('userProfileState');
const getProfileBasicInfoFeatureState = createFeatureSelector<UserProfileBasicInfoState>('userProfileBasicInfoState');
const getProfileFleetManagerFeatureState = createFeatureSelector<UserProfileFleetManagerState>('userProfileFleetManagerState');
const getDivisonInfoFeatureState = createFeatureSelector<UserDivisonInfoState>('userDivisonInfoState');
const getUserIdsFeatureState = createFeatureSelector<UserIdsState>('userIdsState');
const getUserPictureFeatureState = createFeatureSelector<UserPictureState>('userPictureState');
const getUserPermissionFeatureState = createFeatureSelector<UserPermissionState>('userPermissionState');

export const getUserData = createSelector(
    getUserDataFeatureState,
    state => (state != null) ? state.userData : null
);

export const getUserDataError = createSelector(
    getUserDataFeatureState,
    state => state.error
);

export function userDataReducer(state = initialState, action: UserProfileActions): UserProfileState {
    switch (action.type) {
        case UserProfileActionTypes.UserProfileSuccess:
            return {
                ...state,
                userData: action.payload
            };
        case UserProfileActionTypes.UserProfileFail:
            return {
                ...state,
                userData: null,
                error: action.payload
            };
        default:
            return state;
    }
}

export const getUserBasicInfo = createSelector(
    getProfileBasicInfoFeatureState,
    state => (state != null) ? state.userData : null
);

export const getUserBasicInfoError = createSelector(
    getProfileBasicInfoFeatureState,
    state => state.error
);

export function userBasicInfoReducer(state = initialProfileBasicInfoState, action: UserProfileActions): UserProfileBasicInfoState {
    switch (action.type) {
        case UserProfileBasicInfoActionTypes.UserBasicInfoSuccess:
            return {
                ...state,
                userData: action.payload
            };
        case UserProfileBasicInfoActionTypes.UserBasicInfoFail:
            return {
                ...state,
                userData: null,
                error: action.payload
            };
        default:
            return state;
    }
}


export const getUserFleetManager = createSelector(
    getProfileFleetManagerFeatureState,
    state => {
        return state ? state.userProfileFleetManagersData : null;
    }
);


export const getUserFleetManagerError = createSelector(
    getProfileFleetManagerFeatureState,
    state => state.error
);

export function userFleetManagerReducer(state = initialProfileFleetManagerState, action: UserProfileActions): UserProfileFleetManagerState {
    switch (action.type) {
        case UserProfileFleetManagerActionTypes.UserProfileFleetManagerSuccess:
            return {
                ...state,
                userProfileFleetManagersData: action.payload
            };
        case UserProfileFleetManagerActionTypes.UserProfileFleetManagerFail:
            return {
                ...state,
                userProfileFleetManagersData: null,
                error: action.payload
            };
        default:
            return state;
    }
}



export const getUserDivisonInfo = createSelector(
    getDivisonInfoFeatureState,
    state => {
        return state ? state.userData : null;
    }
);


export const getUserDivisonInfoError = createSelector(
    getDivisonInfoFeatureState,
    state => state.error
);

export function userDivisonInfoReducer(
    state = initialDivisonInfoState,
    action: UserProfileActions
  ): UserDivisonInfoState {
    switch (action.type) {
      case UserDivisonInfoActionTypes.UserDivisonInfoSuccess:
        return {
          ...state,
          userData: action.payload,
          error: ''
        };
      case UserDivisonInfoActionTypes.UserDivisonInfoFail:
        return {
          ...state,
          userData: null,
          error: action.payload
        };
      default:
        return state;
    }
  }
  
export const getUserIds = createSelector(
    getUserIdsFeatureState,
    state => {
        return state ? state.userData : null;
    }
);

export const getUserIdsError = createSelector(
    getUserIdsFeatureState,
    state => state.error
);

export function userIdsReducer(
    state = initialProfileIdsState,
    action: UserProfileActions
  ): UserIdsState {
    switch (action.type) {
      case UserIdsActionTypes.UserIdsSuccess:
        return {
          ...state,
          userData: action.payload,
          error: ''
        };
      case UserIdsActionTypes.UserIdsFail:
        return {
          ...state,
          userData: null,
          error: action.payload
        };
      default:
        return state;
    }
  }


  export const getUserPicture = createSelector(
    getUserPictureFeatureState,
    state => {
        return state ? state.userData : null;
    }
);

export const getUserPictureError = createSelector(
    getUserPictureFeatureState,
    state => state.error
);

export function userPictureReducer(
    state = initialUserPictureState,
    action: UserProfileActions
  ): UserPictureState {
    switch (action.type) {
      case UserPictureActionTypes.UserPictureSuccess:
        return {
          ...state,
          userData: action.payload,
          error: ''
        };
      case UserPictureActionTypes.UserPictureFail:
        return {
          ...state,
          userData: null,
          error: action.payload
        };
      default:
        return state;
    }
  }

    export const getUserPermission = createSelector(
        getUserPermissionFeatureState,
        state => {
            return state ? state.userData : null;
        }
    );

    export const getUserPermissionError = createSelector(
        getUserPermissionFeatureState,
        state => state.error
    );

    export function userPermissionReducer(
        state = initialUserPermissionState,
        action: UserProfileActions
    ): UserPermissionState {
        switch (action.type) {
            case UserPermissionsActionTypes.UserPermissionsSuccess:
                return {
                    ...state,
                    userData: action.payload,
                    error: ''
                };
            case UserPermissionsActionTypes.UserPermissionsFail:
                return {
                    ...state,
                    userData: null,
                    error: action.payload
                };
            default:
                return state;
        }
    }


export const getProfileFleetManagers = createSelector(
    getProfileFleetManagerFeatureState,
    state => {
        return state ? state.userProfileFleetManagersData : null;
    }
);

