import {Injectable, Injector} from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';



/* NgRx */
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as loadStatusActions from './load-status.actions';
import {LoadStatusService} from '../../../../../../Swagger-Gen';

@Injectable()
export class LoadStatusEffects {
    loadStatusService: LoadStatusService = this.injector.get(LoadStatusService);
    constructor(private injector: Injector,
                private actions$: Actions) {

    }

    @Effect()
    loadLoadStatus$: Observable<Action> = this.actions$.pipe(
        ofType(loadStatusActions.LoadStatusActionTypes.GetLoadStatus),
        mergeMap(action =>
                this.loadStatusService.get((action as loadStatusActions.GetLoadStatus).id).pipe(
                    map(loadStatus => (new loadStatusActions.LoadSuccess(loadStatus))),
                    catchError(err => of(new loadStatusActions.LoadFail(err)))
                )
        )
    );

}
