import { Component, Input, OnInit } from '@angular/core';
import {TripStep} from '../../../Swagger-Gen';
import {Platform} from "@ionic/angular";

@Component({
  selector: 'app-critical-load-notes-card',
  templateUrl: './critical-load-notes-card.component.html',
  styleUrls: ['./critical-load-notes-card.component.scss'],
})
export class CriticalLoadNotesCardComponent implements OnInit {

  @Input('title') title = ''; // Stores the title that appears on the card
  // Stores data on trip steps. The data here will be used to show points on map control and draw line between them
  @Input('tripSteps') tripSteps: Array<TripStep> = new Array<TripStep>();
  // Stores critical notes on the load
  @Input('criticalLoadNotes') criticalLoadNotes = '';

  // Instantiates the component
  constructor() { }

  // Handler called when the component is initialized
  ngOnInit() {}

}
