import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProfileActionTypes, GetProfileActions } from "./profile.actions";
import * as fromRoot from "../../../app.state";
import { Awards, UserDisplayVM } from "src/app/Swagger-Gen";
import { WorkdayDisplayVM } from "src/app/Swagger-Gen/model/workdayDisplayVM";

export interface State extends fromRoot.State {
  userProfile: UserProfileState;
}

export interface UserProfileState {
  userProfileInfo: UserDisplayVM;
  workdayProfileInfo: WorkdayDisplayVM;
  userAwards: Awards;
  error: string;
  identityKey: string;
}

const initialState: UserProfileState = {
  userProfileInfo: null,
  workdayProfileInfo:null,
  userAwards: null,
  error: "",
  identityKey: ''
};

const getUserProfileFeatureState = createFeatureSelector<UserProfileState>(
  "userProfile"
);

export const getUserProfileInfo = createSelector(
  getUserProfileFeatureState,
  (state) => (state != null ? state.userProfileInfo : null)
);

export const getWorkDayProfileInfo = createSelector(
  getUserProfileFeatureState,
  (state) => (state != null ? state.workdayProfileInfo : null)
);

export const getUserIdentityKey = createSelector(
  getUserProfileFeatureState,
  (state) => (state != null ? state.identityKey : null)
);

export const getUserAwards = createSelector(
  getUserProfileFeatureState,
  (state) => (state != null ? state.userAwards : null)
);

export const getError = createSelector(
  getUserProfileFeatureState,
  (state) => state.error
);

export function userProfileReducer(
  state = initialState,
  action: GetProfileActions
): UserProfileState {
  switch (action.type) {
    case ProfileActionTypes.GetProfileInfo:
      return {
        ...state,
        userProfileInfo: null,
        error: "",
      };
    case ProfileActionTypes.GetProfileInfoSuccess:
      return {
        ...state,
        userProfileInfo: action.payload,
        error: "",
      };
    case ProfileActionTypes.GetProfileInfoFail:
      return {
        ...state,
        userProfileInfo: null,
        error: action.payload,
      };
      case ProfileActionTypes.GetWorkDayProfileInfo:
      return {
        ...state,
        workdayProfileInfo: null,
        error: "",
      };
    case ProfileActionTypes.GetWorkDayProfileInfoSuccess:
      return {
        ...state,
        workdayProfileInfo: action.payload,
        error: "",
      };
    case ProfileActionTypes.GetWorkDayProfileInfoFail:
      return {
        ...state,
        workdayProfileInfo: null,
        error: action.payload,
      };
    case ProfileActionTypes.GetProfileAwardsSuccess:
      return {
        ...state,
        userAwards: action.payload,
        error: "",
      };
    case ProfileActionTypes.GetProfileInfoFail:
      return {
        ...state,
        userAwards: null,
        error: action.payload,
      };
    case ProfileActionTypes.SetIdentityKey:
      return {
        ...state,
        identityKey: action.identityKey,
      };
    default:
      return state;
  }
}
