import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";

import { Action } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import * as DriverReferralActions from "./driver-referral.actions";
import { RequestService } from "src/app/Swagger-Gen-V2";
import { LoadingUtility } from "src/app/shared/utilities/LoadingUtility";
import { ToasterUtility } from "src/app/shared/utilities/toaster-utility";
@Injectable()
export class DriverReferralEffects {
  constructor(private loading: LoadingUtility, private actions$: Actions, private request:RequestService, private toasterUtility: ToasterUtility) {}

  @Effect()
  postDriverReferral$: Observable<Action> = this.actions$.pipe(
    ofType(DriverReferralActions.DriverReferralActionTypes.PostDriverreferralData),
    mergeMap((action) => {
      return this.request.referralPost
        (
          (action as DriverReferralActions.PostDriverreferralData).DriverRefferalData
        )
        .pipe(
          map(
            (response) => {
              let payloadResponse = {
                "success": true,
                "message":"Thank you for your referral"
              } 
              if (response) {
                payloadResponse["success"] = false
                payloadResponse["message"] = response
                return new DriverReferralActions.PostDriverReferralSuccess(payloadResponse)  
              } else {
                return new DriverReferralActions.PostDriverReferralSuccess(payloadResponse)
              }              
            }              
          ),
          catchError((err) => {
            // hide loader incase of error response
            this.toasterUtility.showToaster('Api Error...!',"Application Failed. Sorry, your Driver Referral did not go through. Please try again later.",'danger');
            this.loading.hideLoader();
            return of(new DriverReferralActions.PostDriverReferralFail(err));
          })
        );
    })
  );



}
