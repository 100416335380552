/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EmergencyContactDisplayVM } from './emergencyContactDisplayVM';
import { Employee } from './employee';

export interface UserDisplayVM { 
    payAddressDisplay?: string;
    payCityStateZipDisplay?: string;
    homeAddressDisplay?: string;
    homCityStateZipDisplay?: string;
    birthDateDisplay?: string;
    phoneNumberDisplay?: string;
    emailDisplay?: string;
    emergencyContacts?: Array<EmergencyContactDisplayVM>;
    userImageUrl?: string;
    firstName?: string;
    lastName?: string;
    nickName ?: string;
    permissions?: Array<string>;
    fleetManager?: Employee;
    fleetManagers?: Array<Employee>;
    trainees?: Array<Employee>;
    trainers?: Array<Employee>;
}
