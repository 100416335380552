export class StorageConstants {
    static EDIT_PROFILE_KEY = 'edit-profile-key';
    static USER_PROFILE_KEY = 'user-profile-key';
    static USER_IDENTITY_KEY = 'user-identity-key';
    static MESSAGES_KEY = 'initial-messages';
    static PROFILE_PICTURE_BASE64 = 'profile-v2-picture-string';
    static EMERGENCY_CONTACTS = 'emergency-contacts';
    static ADDRESS_TYPES = 'address-types';
    static PHONE_TYPES = 'phone-types';
    static ADDRESS_EDIT = 'address-edit';
    static PHONE_EDIT = 'phone-edit';
    static EMAIL_EDIT = 'email-edit';
    static SAFE_Miles = 'safe-miles-value';
    static ORIENTATION_DETAILS = 'orientation-details';
    static CARDS_KEY = 'homeCardsKey';
    static CARDS_CHANGED_KEY = 'cardsChangedKey';
    static CARDS_HISTORY_KEY = 'cardsHistoryKey';
    static PAY_INCREASE_RESPONSE_KEY = 'pay-increase-response';
    static SAFETY_DEFAULT_VALUE  = {
        safeMiles : {
        currentMiles : 0,
        nextMilestone : 0,
        milesToNextGoal : 0
        },
        safeStops : {
            stops : 0
        }
    };    
    static MESSAGE_RECIPIENTS = 'message-recipients';
}
