import {Injectable, Injector} from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';


/* NgRx */
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as PreviousTripsActions from './previous-trips-loads.actions';
import { RequestService } from '../../../../Swagger-Gen-V2/api/request.service';



@Injectable()
export class GetPreviousTripsEffects {
    // previousTripsService: TripsService = this.injector.get(TripsService);
    previousTripsService: RequestService = this.injector.get(RequestService);
    constructor(private injector: Injector, private actions$: Actions) {
    }
    @Effect()
    loadLoadStatus$: Observable<Action> = this.actions$.pipe(
        ofType(PreviousTripsActions.PreviousTripsActionTypes.GetPreviousTrips),
        mergeMap(action =>
            this.previousTripsService.tripsGet('PAST',(action as PreviousTripsActions.GetPreviousTrips).employeeNumber, (action as PreviousTripsActions.GetPreviousTrips).driverId).pipe(
                map(previousTrips => ( new PreviousTripsActions.GetPreviousTripsSuccess(previousTrips))),
                catchError(err => of(new PreviousTripsActions.GetPreviousTripsFail(err)))
            )
        )
    );

}
