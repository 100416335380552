import { Action } from '@ngrx/store';
import { TripPakImageResponse } from 'src/app/Swagger-Gen/model/tripPakImageResponse';
import { UpdateTripPaperWorkLabelResponse } from 'src/app/Swagger-Gen/model/updateTripPaperWorkLabelResponse';
import { JobTrip } from 'src/app/Swagger-Gen-V2/model/jobTrip';
import { Paperwork } from 'src/app/Swagger-Gen-V2/model/Paperwork';
import { ScannedPaperwork } from 'src/app/Swagger-Gen-V2/model/ScannedPaperwork';

export enum PaperworkActionTypes {
    GetJobEnvelopImage = 'Get Job Envelop Image',
    PostJobEnvelop = 'Post Job Envelop ',
    GetJobEnvelopImageSuccess = 'Get Job Envelop Image Success',
    GetJobEnvelopImageFail = 'Get Job Envelop Image Fail',
    PostJobEnvelopSuccess = 'Post Job Envelop  Success',
    PostJobEnvelopFail = 'Post Job Envelop  Fail',
    PostPaperWorkConfirmation= 'Post PaperWork Confirmation',
    GetPaperWorkConfirmation= 'Get PaperWork Confirmation',
    PostPaperWorkConfirmationSuccess= 'Post PaperWork Confirmation Success',
    PostPaperWorkConfirmationFail= 'Post PaperWork Confirmation Fail',
    GetPaperWorkConfirmationSuccess= 'Get PaperWork Confirmation Success',
    GetPaperWorkConfirmationFail= 'Get PaperWork Confirmation Fail',
    ConfirmationCodesToBePosted= 'save cnfirmation Codes to be posted',
    SelectedJob= 'Selected job to generate paperwork',
    UpdatePaperWorkLabel= 'Update PaperWork label',
    UpdatePaperWorkLabelSuccess= 'Update PaperWork label Success',
    UpdatePaperWorkLabelFail= 'Update PaperWork label Fail',
}


export class GetJobEnvelopImage implements Action {
    readonly type = PaperworkActionTypes.GetJobEnvelopImage;
    envelopId = '';
    identityKey = '';
    constructor(public identitykey: string, public id: string) {
        this.envelopId = id;
        this.identityKey = identitykey;
    }
}

export class PostJobEnvelop implements Action {
    readonly type = PaperworkActionTypes.PostJobEnvelop;
    tripEnvelopData: Paperwork;
    constructor(public tripEnvelop: Paperwork) {
      this.tripEnvelopData = tripEnvelop;
    }
}

export class GetJobEnvelopImageSuccess implements Action {
    readonly type = PaperworkActionTypes.GetJobEnvelopImageSuccess;
    constructor(public payload: Array<TripPakImageResponse>) {
        
    }
}

export class GetJobEnvelopImageFail implements Action {
    readonly type = PaperworkActionTypes.GetJobEnvelopImageFail;
    constructor(public payload: string)  {
        
    }
}

export class PostJobEnvelopSuccess implements Action {
    readonly type = PaperworkActionTypes.PostJobEnvelopSuccess;
    constructor(public payload: any)  {
        
    }
}

export class PostJobEnvelopFail implements Action {
    readonly type = PaperworkActionTypes.PostJobEnvelopFail;
    constructor(public payload: string)  {
        
    }
}

////////////

export class GetPaperWorkConfirmation implements Action {
    readonly type = PaperworkActionTypes.GetPaperWorkConfirmation;
    identityKey = '';
    jobId ='';
    constructor( public identitykey: string, public  jobid: string) {
        this.identityKey = identitykey;
        this.jobId = jobid;
    }
}

export class PostPaperWorkConfirmation implements Action {
    readonly type = PaperworkActionTypes.PostPaperWorkConfirmation;
    confirmationCodes: ScannedPaperwork;

    constructor(public confirmationcodes: ScannedPaperwork) {
      this.confirmationCodes = confirmationcodes;
    }
}

export class GetPaperWorkConfirmationSuccess implements Action {
    readonly type = PaperworkActionTypes.GetPaperWorkConfirmationSuccess;
    confirmationCodes: ScannedPaperwork[];
    constructor(public payload: ScannedPaperwork[]) {
        this.confirmationCodes = payload;
    }
}

export class GetPaperWorkConfirmationFail implements Action {
    readonly type = PaperworkActionTypes.GetPaperWorkConfirmationFail;
    constructor(public payload: string)  {
        
    }
}

export class PostPaperWorkConfirmationSuccess implements Action {
    readonly type = PaperworkActionTypes.PostPaperWorkConfirmationSuccess;
    constructor(public payload: boolean)  {
        
    }
}

export class PostPaperWorkConfirmationFail implements Action {
    readonly type = PaperworkActionTypes.PostPaperWorkConfirmationFail;
    constructor(public payload: string)  {
        
    }
}

export class UpdatePaperWorkLabel implements Action {
    readonly type = PaperworkActionTypes.UpdatePaperWorkLabel;
    
    constructor(public payload: ScannedPaperwork)  {
        
    }
}

export class UpdatePaperWorkLabelSuccess implements Action {
    readonly type = PaperworkActionTypes.UpdatePaperWorkLabelSuccess;
    constructor(public payload: UpdateTripPaperWorkLabelResponse)  {
        
    }
}

export class UpdatePaperWorkLabelFail implements Action {
    readonly type = PaperworkActionTypes.UpdatePaperWorkLabelFail;
    constructor(public payload: string)  {
        
    }
}
export class ConfirmationCodesToBePosted implements Action {
    readonly type = PaperworkActionTypes.ConfirmationCodesToBePosted;
    constructor(public confimationCode: ScannedPaperwork) {
      
    }
}

export class SelectedJob implements Action {
    readonly type = PaperworkActionTypes.SelectedJob;
    constructor(public trip: JobTrip) {
      
    }

}



export type PaperWorkActions = GetJobEnvelopImage 
                              | PostJobEnvelop
                              | GetJobEnvelopImageSuccess
                              | GetJobEnvelopImageFail 
                              | PostJobEnvelopSuccess 
                              | PostJobEnvelopFail 
                              | PostPaperWorkConfirmation 
                              | GetPaperWorkConfirmation 
                              | PostPaperWorkConfirmationSuccess 
                              | PostPaperWorkConfirmationFail 
                              | GetPaperWorkConfirmationSuccess 
                              | GetPaperWorkConfirmationFail 
                              | ConfirmationCodesToBePosted 
                              |UpdatePaperWorkLabel
                              |UpdatePaperWorkLabelSuccess
                              |UpdatePaperWorkLabelFail
                              | SelectedJob;
