import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EdgeCard } from 'src/app/shared/models/edge-card';

@Component({
  selector: 'add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss'],
})
export class AddCardComponent implements OnInit {

  // @Input('card') card: EdgeCard;
  // @Input('index') index: number;
  @Output() didTapAdd = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  tapCard() {
    this.didTapAdd.emit();
  }
}
