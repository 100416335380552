<ion-card (click)="selected(pay.type)">
  <ion-item lines="none">
    <div class="title">
      {{paperwork(pay.type) | titlecase}}
    </div>
    <div class="amount" [style.color]=color>
      {{pay.amount | currency}}
    </div>
    <div class="icon">
      <ion-icon name="chevron-forward"></ion-icon>
    </div>
  </ion-item>
</ion-card>