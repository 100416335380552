import {Injectable} from '@angular/core';

@Injectable()
export class PhoneNumberUtility {
    public formatPhoneNumber(value: string) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;
      
        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");
      
        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;
      
        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
        if (phoneNumberLength < 4) return phoneNumber;
      
        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
      
        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`;
      }

      public validatePhoneNumber(number: string){
        // clean the input for any non-digit values.
        if (!number) return false;
        
        const phoneNumber = number.replace(/[^\d]/g, "");
        return /^(1\s|1|52|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(phoneNumber);
      }

      getValidMobileNumber(numberArray){
        if(numberArray === undefined || numberArray === null) return "";
        return (numberArray && numberArray.number && numberArray.number.length > 7)? numberArray.number.toString().substring(3):numberArray.number;
      }        
}