<ion-card style="height:140px !important;"
              *ngIf="filteredAnnouncements.length === 0">
        <ion-card-content>
            <div>
                You have no Announcements
            </div>
        </ion-card-content>
    </ion-card>

  <ion-list class="list" *ngIf="filteredAnnouncements !== null && filteredAnnouncements !== undefined">
    <ion-item 
	  class="pointer"
      lines="full"
      detail="true"
      *ngFor="let messageItem of filteredAnnouncements"
      (click)="showMessage(messageItem)" [class.unread]="messageItem?.isParticipantRead === false">
      <ion-label>
        <div class="edge-label"> {{messageItem.createdDate | date:'MMM dd, HH:mm'}}</div>
        <ion-item-sliding>
          <span [class.unreadSubject]="messageItem?.isParticipantRead === false">
            {{ messageItem.subject }}
          </span>
        </ion-item-sliding>
      </ion-label>
    </ion-item>
  </ion-list>
