import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {LoadStatusSummary, TripStep} from '../../../Swagger-Gen';
import {ActionSheetController, AlertController, NavController} from '@ionic/angular';
import {ActivatedRoute, Router} from "@angular/router";
import {CameraService} from "../../../shared/services/camera/camera.service";
import { Preferences } from '@capacitor/preferences';


@Component({
  selector: 'app-my-load-status-card',
  templateUrl: './my-load-status-card.component.html',
  styleUrls: ['./my-load-status-card.component.scss'],
})
export class MyLoadStatusCardComponent implements OnInit {

  fleetManagerPhoneNumberLink = '#';
  SELECTED_LOAD_STATUS_FOR_MAP = 'selected_load_status_for_map';
  SELECTED_TRIP_STEPS = 'selected_trip_steps';

  @Input('showBottomActionBar') showBottomActionBar = false; // Indicates if the bottom action buttons should be shown
  @Input('title') title = ''; // Stores title that appears at the top of the component
  @Input('loadStatusSummary') loadStatusSummary: LoadStatusSummary = null; // Stores summary information on load status
  @Input('statusDefinition') statusDefinition = ''; // Stores the status of the current load or trailer (i.e. Active, Assigned)
  @Input('tripSteps') tripSteps: Array<TripStep> = null;
  @Output() showPaperworkDrawer = new EventEmitter();

  showCompletePaperWorkButton = false;


  // Instantiates the component
  constructor(private actionSheetController: ActionSheetController, private router: Router,
              private route: ActivatedRoute, public alertController: AlertController,
              private cameraService: CameraService) { }

  // Handler called after the component has been initialized
  ngOnInit() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.router.config.length; i++) {
      // tslint:disable-next-line:prefer-const
      let routePath: string = this.router.config[i].path;
    }
  }

  // Shows an error message when the user clicks on the call fleet manager button but there is no number
  // associated with it
  async showInvalidPhoneNumberMessage() {
    const alert = await this.alertController.create({
      header: 'Error',
      subHeader: 'Missing Phone Number',
      message: 'Sorry, we could not find your transportation manager phone number',
      buttons: ['OK'],
    });

    await alert.present();
    const result = await alert.onDidDismiss();
  }

  // Handler called when the user clicks on the complete paper work button
  completePaperWork() {
    this.showPaperwork();
  }

  // Handler called when the user clicks on the View On Map Button
  async viewCurrentJobOnMap() {
    await Preferences.set({ key: this.SELECTED_LOAD_STATUS_FOR_MAP, value: JSON.stringify(this.loadStatusSummary) });
    await Preferences.set({ key: this.SELECTED_TRIP_STEPS, value: JSON.stringify(this.tripSteps) });
    await this.router.navigate(['trip-steps-map']);
  }

  startPaperwork() {
    this.showPaperworkDrawer.emit();
  }
  
  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Options',
      buttons: [{
        text: 'Generate Job Envelope and Scan',
        handler: () => {
          this.showPaperwork();
        }
      }, {
          text: 'Scan Paperwork Only',
          handler: () => {
            this.scanPaperWork();
          }
        }, {
          text: 'View Existing Paperwork',
          handler: () => {

          }
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            
          }
        }]
    });
    await actionSheet.present();
  }

  showPaperwork() {
    this.router.navigate(['/paperwork']);
  }

  // Opens Camera and enable the user to scan the paper work
  scanPaperWork() {
    this.cameraService.imageFromCamera();
  }

}
