import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoadSelectionComponent } from './load-selection/load-selection.component';
import { LoadsPage } from './loads.page';
import {LoadSelectionDetailsComponent} from './load-selection/load-selection-details/load-selection-details.component';
import {EquipmentLocationMapComponent} from './trip-load-infos/equipment-location-map/equipment-location-map.component';
import { TrailerReservationNoticeMapComponent } from 'src/app/Core/components/my-load-details/trailer-reservation-notice-map/trailer-reservation-notice-map.component';
import { UserAgreementResolver } from 'src/app/login.page.resolver';

const routes: Routes = [
  {
    path: '',
    component: LoadsPage,
    resolve: {
      accepted: UserAgreementResolver,
    },    
  },
  {
    path: 'paperwork',
    loadChildren: () =>
      import("../paperwork/paperwork.module").then(
        (pw) => pw.PaperworkModule
      ),
  },
  {
    path: 'view-trailer',
    component: EquipmentLocationMapComponent
  },
  {
    path: 'view-trailer-reservation-notice',
    component: TrailerReservationNoticeMapComponent
  },
  {
    path: 'find-jobs',
    component: LoadSelectionComponent
  },
  {
    path: 'pre-trip-inspection',
    loadChildren: () => import('./pre-trip-inspection/pre-trip-inspection.module').then( m => m.PreTripInspectionPageModule)
  },
  {
    path: 'load-details',
    component: LoadSelectionDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoadsPageRoutingModule {}
