import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'stack-input',
  templateUrl: './stack-input.component.html',
  styleUrls: ['./stack-input.component.scss'],
})
export class StackInputComponent implements OnInit {

  private _value: string;

  @Input('placeholder') placeholder = "Default placeholder";
  @Input('label') label = "Default Label";

  @Input()
  set text(value: string) {this._value = value; this.ngOnInit();}
  get text(): string { return this._value;}

  @Output()
  TheTextChanged = new EventEmitter<string>();



  constructor() { }

  ngOnInit() {}

  textChanged() {
    this.text = this._value;
    this.TheTextChanged.emit(this._value)
  }

}
