import { Action } from '@ngrx/store';
import {PeriodTripsList, Trip} from '../../../../Swagger-Gen';



export enum PreviousTripsActionTypes {
    GetPreviousTrips = 'Get Previous Trips',
    GetPreviousTripsSuccess = 'Get Previous Trips Success',
    GetPreviousTripsFail = 'Get Previous Trips Fail',
    PreviousTripIdInContext= 'Previous Trip Id in context '
}

export class GetPreviousTrips implements Action {
    readonly type = PreviousTripsActionTypes.GetPreviousTrips;
    driverId = '';
    employeeNumber;
    constructor(public id: string,empNumber) {
        this.driverId = id;
        this.employeeNumber = empNumber;
    }
}

export class PreviousTripIdInContext implements Action {
    readonly type = PreviousTripsActionTypes.PreviousTripIdInContext;
    constructor(public tripIp: string) {
        
    }
}

export class GetPreviousTripsSuccess implements Action {
    readonly type = PreviousTripsActionTypes.GetPreviousTripsSuccess;
    constructor(public payload: Array<any>) {
        if (this.payload != null && this.payload.length > 0 ) {
            localStorage.setItem('driver-past-trips', JSON.stringify(this.payload));
        }
    }
}

export class GetPreviousTripsFail implements Action {
    readonly type = PreviousTripsActionTypes.GetPreviousTripsFail;
    constructor(public payload: string) {
    }
}

export type PreviousTripsActions = GetPreviousTrips | GetPreviousTripsSuccess | GetPreviousTripsFail |PreviousTripIdInContext;


