/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Location { 
    id?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    stateCode?: string;
    stateDescription?: string;
    postalCode?: string;
    countryCode?: string;
    isPublic?: boolean;
    isPrimary?: boolean;
    type?: string;
    behavior?: string;
    latitude?: number;
    longitude?: number;
}