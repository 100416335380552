/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Loadreq } from './loadreq';
import { Loadstop } from './loadstop';

export interface Recommendedload { 
    tripId?: string;
    splitNumber?: number;
    trailerType?: string;
    trailerTypeDesc?: string;
    weight?: number;
    lineHaulMiles?: number;
    liveLoad?: boolean;
    preLoad?: boolean;
    dropTrailer?: boolean;
    liveUnLoad?: boolean;
    loadReq?: Array<Loadreq>;
    loadStops?: Array<Loadstop>;
}