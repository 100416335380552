import { Component, Input } from "@angular/core";

@Component({
  selector: "card-header",
  templateUrl: "./card-header.component.html",
  styleUrls: ["./card-header.component.scss"],
})
export class CardHeaderComponent {
  @Input("showBadge") showBadge = false;
  @Input("cardTitle") cardTitle: string;
  @Input("badgeTitle") badgeTitle: string;
  @Input("lines") lines = "full";

  constructor() {}
}
