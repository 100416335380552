export class EdgeCard {

    // String to determine which type of card component
    type?: string;
    
    // 6 = half width; 12 = full width
    size?: number = 12;
    
    // Display title bar at the top of the card
    showHeaderBar?: boolean = false;

    // The title that appears in the card header
    title?: string;

    // The description that appears in the add card modal
    description?: string;

    // The icon that appears in the add card modal
    icon?: string;

    // Display resize and reposition controls on top of the card
    showSizePositionControl?: boolean = false;

    // Display overlay options on top of the card
    showOverlay?: boolean = false;

    // Hide controls and disable navigation
    preview?: boolean = false;

    // Show the card on the home screen
    isActive?: boolean = true;

    // Position of the card in order
    position?: number;

    // Show title in the body
    showTitleInBody?: boolean = false;
    
}