import {Injectable, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import { AuthService } from './auth/auth.service';
import { RequestService } from './Swagger-Gen-V2';
import { SubSink } from 'subsink';
@Injectable({ providedIn: 'root' })
export class UserAgreementResolver  implements OnDestroy {
    subscriptions$ = new SubSink();
    constructor(private requestService: RequestService,
                private router: Router, private authService : AuthService) {}

    resolve(): Observable<any>|Promise<any>|any {
        return  this.authService.getUserIdentitityKey().then((identityKey) => {
            if (identityKey) {
                this.subscriptions$.sink = this.requestService.agreementGet(identityKey).subscribe(response => {
                    if (response.isAgreed === true) {
                        return true;
                    } else {
                        this.router.navigate(['agreement']).then(() => {
                            return false;
                        });

                    }
                });
            } else {
               return false;
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}
