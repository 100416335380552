import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit, AfterViewInit {

  @Input() selectedPOIName: string;
  @Input() selectedPOIAddress: string;
  @Output() cancelFeedbackEvent: EventEmitter<any> = new EventEmitter();
  @Output() submitFeedbackEvent: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('feedbackTextArea', {static: true}) feedbackTextArea;
  message = '';
  constructor(private toastController: ToastController) { }

  ngOnInit() {}

  async cancelFeedbackSubmission() {
    this.cancelFeedbackEvent.emit();
  }

  async submitFeedback() {
    if (this.message.trim().length <= 0) {
      const toast = await this.toastController.create({
        message: 'Feedback has not been entered. Please try again',
        color: 'danger',
        duration: 2000,
        cssClass: 'ion-text-center',
      });
      toast.present();
    } else {
      this.submitFeedbackEvent.emit(this.message);
    }
  }
  async ngAfterViewInit() {
    // setFocus does not work without a timeout function. Thus using it here.
    setTimeout(() => {
      this.feedbackTextArea.setFocus();
    }, 150);
  }
}
