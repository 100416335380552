<div class="cupertino-pane">
  <ng-content *ngIf="!actionSheetRows"></ng-content>
  <div *ngIf="actionSheetRows" class="ion-action-sheet-content">
    <bottom-drawer-row
      *ngFor="let row of actionSheetRows"
      [title]="row.title"
      [route]="row.route"
      [handler]="row.handler"
    ></bottom-drawer-row>
  </div>
  <br>
  <!-- Leaving this for now as I suspect we'll want it back later -->
  <!-- <div class="cta werner-button primary" *ngIf="isShowBtns" (click)="tappedCancel()">
    Cancel
  </div> -->
</div>
