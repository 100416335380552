import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';
import {select, Store} from "@ngrx/store";
import { Preferences } from '@capacitor/preferences';
import * as fromNews from '../../../../modules/news/state/news.reducer';
import { EdgeCard } from 'src/app/shared/models/edge-card';
@Component({
  selector: 'news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
})
export class NewsCardComponent implements OnInit {
  @Input('imageUrl') imageUrl;
  @Input('articleTitle') articleTitle;
  @Input('articleReleaseDate') articleReleaseDate;
  @Input("card") card: EdgeCard;
  @Output("resize") resize = new EventEmitter();
  @Output("change") change = new EventEmitter();
  @Output("remove") remove = new EventEmitter();
  LOCAL_NEWS_KEY = 'localNewsKey';
  ARRAY_NEWS_KEY = 'arrayNewsKey';
  constructor(
    private newsStore: Store<fromNews.State>,
    private navCtrl: NavController
  ) {

  }

  async ngOnInit() {
    const news = await Preferences.get({key: this.LOCAL_NEWS_KEY});
    const cachedNews = JSON.parse(news.value);
    if (cachedNews !== null) {
      this.articleTitle = cachedNews.title;
      this.imageUrl = cachedNews.teaserImageUrl;
      this.articleReleaseDate = new Date(cachedNews.releaseDate);
    }

    this.newsStore.pipe(select(fromNews.getNewsItems)).subscribe(
        async (latestNews) => {
          if (latestNews) {

            const latestnewsObject = latestNews[0];

            if(latestnewsObject != null) {
              this.articleTitle = latestnewsObject.title;
              this.imageUrl = latestnewsObject.teaserImageUrl;
              this.articleReleaseDate = new Date(latestnewsObject.releaseDate);
              await Preferences.remove({key: this.LOCAL_NEWS_KEY});
              await Preferences.set({key: this.LOCAL_NEWS_KEY, value: JSON.stringify(latestNews)});

            }

          }

        },
        (error) => {}
    );
  }

  tapCard() {
    this.card.showSizePositionControl || this.card.preview ?
    !this.card.showOverlay :
    this.navCtrl.navigateRoot("/news");
  }

  resizeCard() {
    this.resize.emit(this.card);
  }

  changeCard() {
    this.change.emit(this.card);
  }

  removeCard() {
    this.remove.emit(this.card)
  }
}
