/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DriverManagerInfo } from '../model/driverManagerInfo';
import { EmergencyContactEditVM } from '../model/emergencyContactEditVM';
import { UserDisplayVM } from '../model/userDisplayVM';
import { UserEditVM } from '../model/userEditVM';
import { WorkdayDisplayVM } from '../model/workdayDisplayVM';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProfileInformationService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Retrieves data on the drivers managers
     * 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProfileInformationDrivermanagerIdentityKeyGet(identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<DriverManagerInfo>;
    public apiProfileInformationDrivermanagerIdentityKeyGet(identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DriverManagerInfo>>;
    public apiProfileInformationDrivermanagerIdentityKeyGet(identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DriverManagerInfo>>;
    public apiProfileInformationDrivermanagerIdentityKeyGet(identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiProfileInformationDrivermanagerIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DriverManagerInfo>('get',`${this.basePath}/api/profileInformation/drivermanager/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProfileInformationEditIdentityKeyGet(identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<UserEditVM>;
    public apiProfileInformationEditIdentityKeyGet(identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserEditVM>>;
    public apiProfileInformationEditIdentityKeyGet(identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserEditVM>>;
    public apiProfileInformationEditIdentityKeyGet(identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiProfileInformationEditIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserEditVM>('get',`${this.basePath}/api/profileInformation/edit/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProfileInformationEmergencycontactPost(body?: Array<EmergencyContactEditVM>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProfileInformationEmergencycontactPost(body?: Array<EmergencyContactEditVM>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProfileInformationEmergencycontactPost(body?: Array<EmergencyContactEditVM>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProfileInformationEmergencycontactPost(body?: Array<EmergencyContactEditVM>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/profileInformation/emergencycontact`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves data on the awards (safe mile awards, safe stops, etc) that a user (driver) has obtained due to his (her) driving record
     * 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProfileInformationIdentityKeyGet(identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<UserDisplayVM>;
    public apiProfileInformationIdentityKeyGet(identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDisplayVM>>;
    public apiProfileInformationIdentityKeyGet(identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDisplayVM>>;
    public apiProfileInformationIdentityKeyGet(identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiProfileInformationIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserDisplayVM>('get',`${this.basePath}/api/profileInformation/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProfileInformationUpdatePost(body?: UserEditVM, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProfileInformationUpdatePost(body?: UserEditVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProfileInformationUpdatePost(body?: UserEditVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProfileInformationUpdatePost(body?: UserEditVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/profileInformation/update`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves data on the drivers managers
     * 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProfileInformationUserpictureIdentityKeyGet(identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiProfileInformationUserpictureIdentityKeyGet(identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiProfileInformationUserpictureIdentityKeyGet(identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiProfileInformationUserpictureIdentityKeyGet(identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiProfileInformationUserpictureIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/profileInformation/userpicture/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves profile data from workday
     * 
     * @param identityKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProfileInformationWorkdayIdentityKeyGet(identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<WorkdayDisplayVM>;
    public apiProfileInformationWorkdayIdentityKeyGet(identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WorkdayDisplayVM>>;
    public apiProfileInformationWorkdayIdentityKeyGet(identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WorkdayDisplayVM>>;
    public apiProfileInformationWorkdayIdentityKeyGet(identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identityKey === null || identityKey === undefined) {
            throw new Error('Required parameter identityKey was null or undefined when calling apiProfileInformationWorkdayIdentityKeyGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WorkdayDisplayVM>('get',`${this.basePath}/api/profileInformation/workday/${encodeURIComponent(String(identityKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
