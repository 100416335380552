<div class="background">
  <div class="modal">
    <ion-row class="center-image">
      <div *ngIf="status === 'success'">
        <img  src="assets/Images/check.png" alt="">
      </div>
      <div *ngIf="status === 'failure'">
        <img  src="assets/Images/error.svg" alt="">
      </div>
    </ion-row>
    <ion-row>
      <ion-item class="title-row" lines="none">
        <ion-label>{{title}}</ion-label>
      </ion-item>
    </ion-row>
    <ion-row>
      <ion-item  lines="inset">
        <div class="subtitle-row">{{subtitle}}</div>
      </ion-item>
    </ion-row>
      <ion-button (click)="close()">Close</ion-button>
  </div>
</div>