export * from './appVersions.service';
import { AppVersionsService } from './appVersions.service';
export * from './askTed.service';
import { AskTedService } from './askTed.service';
export * from './challengeQuestions.service';
import { ChallengeQuestionsService } from './challengeQuestions.service';
export * from './checks.service';
import { ChecksService } from './checks.service';
export * from './departments.service';
import { DepartmentsService } from './departments.service';
export * from './emergencyContacts.service';
import { EmergencyContactsService } from './emergencyContacts.service';
export * from './expenses.service';
import { ExpensesService } from './expenses.service';
export * from './feedback.service';
import { FeedbackService } from './feedback.service';
export * from './files.service';
import { FilesService } from './files.service';
export * from './home.service';
import { HomeService } from './home.service';
export * from './loadSelections.service';
import { LoadSelectionsService } from './loadSelections.service';
export * from './loadStatus.service';
import { LoadStatusService } from './loadStatus.service';
export * from './messaging.service';
import { MessagingService } from './messaging.service';
export * from './news.service';
import { NewsService } from './news.service';
export * from './opportunities.service';
import { OpportunitiesService } from './opportunities.service';
export * from './orientation.service';
import { OrientationService } from './orientation.service';
export * from './paperWork.service';
import { PaperWorkService } from './paperWork.service';
export * from './payments.service';
import { PaymentsService } from './payments.service';
export * from './pin.service';
import { PinService } from './pin.service';
export * from './pointsOfInterests.service';
import { PointsOfInterestsService } from './pointsOfInterests.service';
export * from './profile.service';
import { ProfileService } from './profile.service';
export * from './profileInformation.service';
import { ProfileInformationService } from './profileInformation.service';
export * from './referenceLibrary.service';
import { ReferenceLibraryService } from './referenceLibrary.service';
export * from './referrals.service';
import { ReferralsService } from './referrals.service';
export * from './safety.service';
import { SafetyService } from './safety.service';
export * from './stories.service';
import { StoriesService } from './stories.service';
export * from './telemetry.service';
import { TelemetryService } from './telemetry.service';
export * from './terminals.service';
import { TerminalsService } from './terminals.service';
export * from './tripPaks.service';
import { TripPaksService } from './tripPaks.service';
export * from './trips.service';
import { TripsService } from './trips.service';
export * from './userAgreementVersion.service';
import { UserAgreementVersionService } from './userAgreementVersion.service';
export * from './userAgreements.service';
import { WorkdayService } from './workday.service';
export * from './workday.service';
export const APIS = [AppVersionsService, AskTedService, ChallengeQuestionsService, ChecksService, DepartmentsService, EmergencyContactsService, ExpensesService, FeedbackService, FilesService, HomeService, LoadSelectionsService, LoadStatusService, MessagingService, NewsService, OpportunitiesService, OrientationService, PaperWorkService, PaymentsService, PinService, PointsOfInterestsService, ProfileService, ProfileInformationService, ReferenceLibraryService, ReferralsService, SafetyService, StoriesService, TelemetryService, TerminalsService, TripPaksService, TripsService, UserAgreementVersionService, WorkdayService];
