import {  Platform } from "@ionic/angular";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
  })

export class PayAuthService { 

    isUserAuthenticated = new BehaviorSubject<boolean>(false);
    constructor(
        private platform: Platform
      ){    

      } 

  }
