/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 
 import { Observable }                                        from 'rxjs';
 
 import { CityState } from '../model/cityState';
 import { OpportunitiesListView } from '../model/opportunitiesListView';
 import { OpportunityDetailsView } from '../model/opportunityDetailsView';
 import { RenageOnOpportunityInputs } from '../model/renageOnOpportunityInputs';
 import { RequestOpportunityInputs } from '../model/requestOpportunityInputs';
 
 import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
 import { Configuration }                                     from '../configuration';
 
 
 @Injectable()
 export class OpportunitiesService {
 
     protected basePath = '/';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
     constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * Retrieves data on cities that match the given keyword
      * 
      * @param keyword 
      * @param identityKey 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiOpportunitiesAutocompletewithcitiesKeywordIdentityKeyGet(keyword: string, identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CityState>>;
     public apiOpportunitiesAutocompletewithcitiesKeywordIdentityKeyGet(keyword: string, identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CityState>>>;
     public apiOpportunitiesAutocompletewithcitiesKeywordIdentityKeyGet(keyword: string, identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CityState>>>;
     public apiOpportunitiesAutocompletewithcitiesKeywordIdentityKeyGet(keyword: string, identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (keyword === null || keyword === undefined) {
             throw new Error('Required parameter keyword was null or undefined when calling apiOpportunitiesAutocompletewithcitiesKeywordIdentityKeyGet.');
         }
 
         if (identityKey === null || identityKey === undefined) {
             throw new Error('Required parameter identityKey was null or undefined when calling apiOpportunitiesAutocompletewithcitiesKeywordIdentityKeyGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<CityState>>('get',`${this.basePath}/api/opportunities/autocompletewithcities/${encodeURIComponent(String(keyword))}/${encodeURIComponent(String(identityKey))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Retrieves opportunities (requested by a member/driver) in a given zip code and use them to construct a list view
      * 
      * @param latitude 
      * @param longitude 
      * @param identityKey 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiOpportunitiesCoordinateslistviewLatitudeLongitudeIdentityKeyGet(latitude: number, longitude: number, identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<OpportunitiesListView>;
     public apiOpportunitiesCoordinateslistviewLatitudeLongitudeIdentityKeyGet(latitude: number, longitude: number, identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpportunitiesListView>>;
     public apiOpportunitiesCoordinateslistviewLatitudeLongitudeIdentityKeyGet(latitude: number, longitude: number, identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpportunitiesListView>>;
     public apiOpportunitiesCoordinateslistviewLatitudeLongitudeIdentityKeyGet(latitude: number, longitude: number, identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (latitude === null || latitude === undefined) {
             throw new Error('Required parameter latitude was null or undefined when calling apiOpportunitiesCoordinateslistviewLatitudeLongitudeIdentityKeyGet.');
         }
 
         if (longitude === null || longitude === undefined) {
             throw new Error('Required parameter longitude was null or undefined when calling apiOpportunitiesCoordinateslistviewLatitudeLongitudeIdentityKeyGet.');
         }
 
         if (identityKey === null || identityKey === undefined) {
             throw new Error('Required parameter identityKey was null or undefined when calling apiOpportunitiesCoordinateslistviewLatitudeLongitudeIdentityKeyGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<OpportunitiesListView>('get',`${this.basePath}/api/opportunities/coordinateslistview/${encodeURIComponent(String(latitude))}/${encodeURIComponent(String(longitude))}/${encodeURIComponent(String(identityKey))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Retrieves data needed to populate the opportunity details screen
      * 
      * @param accountCode 
      * @param identityKey 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiOpportunitiesDetailsviewIdentityKeyAccountCodeGet(accountCode: string, identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<OpportunityDetailsView>;
     public apiOpportunitiesDetailsviewIdentityKeyAccountCodeGet(accountCode: string, identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpportunityDetailsView>>;
     public apiOpportunitiesDetailsviewIdentityKeyAccountCodeGet(accountCode: string, identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpportunityDetailsView>>;
     public apiOpportunitiesDetailsviewIdentityKeyAccountCodeGet(accountCode: string, identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (accountCode === null || accountCode === undefined) {
             throw new Error('Required parameter accountCode was null or undefined when calling apiOpportunitiesDetailsviewIdentityKeyAccountCodeGet.');
         }
 
         if (identityKey === null || identityKey === undefined) {
             throw new Error('Required parameter identityKey was null or undefined when calling apiOpportunitiesDetailsviewIdentityKeyAccountCodeGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<OpportunityDetailsView>('get',`${this.basePath}/api/opportunities/detailsview/${encodeURIComponent(String(identityKey))}/${encodeURIComponent(String(accountCode))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Retrieves data on all the job opportunities that are in a given location and we use them to construct a list view
      * 
      * @param cityCode 
      * @param cityName 
      * @param stateCode 
      * @param country 
      * @param identityKey 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiOpportunitiesLocationlistviewGet(cityCode?: string, cityName?: string, stateCode?: string, country?: string, identityKey?: string, observe?: 'body', reportProgress?: boolean): Observable<OpportunitiesListView>;
     public apiOpportunitiesLocationlistviewGet(cityCode?: string, cityName?: string, stateCode?: string, country?: string, identityKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpportunitiesListView>>;
     public apiOpportunitiesLocationlistviewGet(cityCode?: string, cityName?: string, stateCode?: string, country?: string, identityKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpportunitiesListView>>;
     public apiOpportunitiesLocationlistviewGet(cityCode?: string, cityName?: string, stateCode?: string, country?: string, identityKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
 
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (cityCode !== undefined && cityCode !== null) {
             queryParameters = queryParameters.set('cityCode', <any>cityCode);
         }
         if (cityName !== undefined && cityName !== null) {
             queryParameters = queryParameters.set('cityName', <any>cityName);
         }
         if (stateCode !== undefined && stateCode !== null) {
             queryParameters = queryParameters.set('stateCode', <any>stateCode);
         }
         if (country !== undefined && country !== null) {
             queryParameters = queryParameters.set('country', <any>country);
         }
         if (identityKey !== undefined && identityKey !== null) {
             queryParameters = queryParameters.set('identityKey', <any>identityKey);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<OpportunitiesListView>('get',`${this.basePath}/api/opportunities/locationlistview`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Enables a member to renage from an opportunity that he (she) previous applied to
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiOpportunitiesRenageonopportunityPost(body?: RenageOnOpportunityInputs, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public apiOpportunitiesRenageonopportunityPost(body?: RenageOnOpportunityInputs, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public apiOpportunitiesRenageonopportunityPost(body?: RenageOnOpportunityInputs, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public apiOpportunitiesRenageonopportunityPost(body?: RenageOnOpportunityInputs, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<boolean>('post',`${this.basePath}/api/opportunities/renageonopportunity`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Retrieves opportunities that a member applied for and use them to construct a list view
      * 
      * @param identityKey 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiOpportunitiesRequestedjobslistviewIdentityKeyGet(identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<OpportunitiesListView>;
     public apiOpportunitiesRequestedjobslistviewIdentityKeyGet(identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpportunitiesListView>>;
     public apiOpportunitiesRequestedjobslistviewIdentityKeyGet(identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpportunitiesListView>>;
     public apiOpportunitiesRequestedjobslistviewIdentityKeyGet(identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (identityKey === null || identityKey === undefined) {
             throw new Error('Required parameter identityKey was null or undefined when calling apiOpportunitiesRequestedjobslistviewIdentityKeyGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<OpportunitiesListView>('get',`${this.basePath}/api/opportunities/requestedjobslistview/${encodeURIComponent(String(identityKey))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Enables a member to apply or request an opportunity
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiOpportunitiesRequestopportunityPost(body?: RequestOpportunityInputs, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public apiOpportunitiesRequestopportunityPost(body?: RequestOpportunityInputs, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public apiOpportunitiesRequestopportunityPost(body?: RequestOpportunityInputs, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public apiOpportunitiesRequestopportunityPost(body?: RequestOpportunityInputs, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<boolean>('post',`${this.basePath}/api/opportunities/requestopportunity`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Retrieves opportunities (requested by a member/driver) matching a given keyword and use them to construct a list view
      * 
      * @param keyword 
      * @param identityKey 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiOpportunitiesSearchresultslistviewKeywordIdentityKeyGet(keyword: string, identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<OpportunitiesListView>;
     public apiOpportunitiesSearchresultslistviewKeywordIdentityKeyGet(keyword: string, identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpportunitiesListView>>;
     public apiOpportunitiesSearchresultslistviewKeywordIdentityKeyGet(keyword: string, identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpportunitiesListView>>;
     public apiOpportunitiesSearchresultslistviewKeywordIdentityKeyGet(keyword: string, identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (keyword === null || keyword === undefined) {
             throw new Error('Required parameter keyword was null or undefined when calling apiOpportunitiesSearchresultslistviewKeywordIdentityKeyGet.');
         }
 
         if (identityKey === null || identityKey === undefined) {
             throw new Error('Required parameter identityKey was null or undefined when calling apiOpportunitiesSearchresultslistviewKeywordIdentityKeyGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<OpportunitiesListView>('get',`${this.basePath}/api/opportunities/searchresultslistview/${encodeURIComponent(String(keyword))}/${encodeURIComponent(String(identityKey))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Retrieves opportunities (requested by a member/driver) in a given zip code and use them to construct a list view
      * 
      * @param zipCode 
      * @param identityKey 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiOpportunitiesZipcodelistviewZipCodeIdentityKeyGet(zipCode: string, identityKey: string, observe?: 'body', reportProgress?: boolean): Observable<OpportunitiesListView>;
     public apiOpportunitiesZipcodelistviewZipCodeIdentityKeyGet(zipCode: string, identityKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpportunitiesListView>>;
     public apiOpportunitiesZipcodelistviewZipCodeIdentityKeyGet(zipCode: string, identityKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpportunitiesListView>>;
     public apiOpportunitiesZipcodelistviewZipCodeIdentityKeyGet(zipCode: string, identityKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (zipCode === null || zipCode === undefined) {
             throw new Error('Required parameter zipCode was null or undefined when calling apiOpportunitiesZipcodelistviewZipCodeIdentityKeyGet.');
         }
 
         if (identityKey === null || identityKey === undefined) {
             throw new Error('Required parameter identityKey was null or undefined when calling apiOpportunitiesZipcodelistviewZipCodeIdentityKeyGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<OpportunitiesListView>('get',`${this.basePath}/api/opportunities/zipcodelistview/${encodeURIComponent(String(zipCode))}/${encodeURIComponent(String(identityKey))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
 }
 