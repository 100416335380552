import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge-status-card',
  templateUrl: './badge-status-card.component.html',
  styleUrls: ['./badge-status-card.component.scss'],
})
export class BadgeStatusCardComponent implements OnInit {

  @Input('backgroundColor') backgroundColor = '#BA4039';
  @Input('textColor') textColor = '#FFFFFF';
  @Input('iconName') iconName = 'alert-circle-outline';
  @Input('messageText') message = '';




  constructor() { }

  ngOnInit() {}

}
