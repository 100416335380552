import { Component, Input, OnDestroy, Output, EventEmitter } from "@angular/core";
import { NavController } from "@ionic/angular";
import { EdgeCard } from 'src/app/shared/models/edge-card';

@Component({
  selector: "preassigned-job-card",
  templateUrl: "./preassigned-job-card.component.html",
  styleUrls: ["./preassigned-job-card.component.scss"],
})
export class PreAssignedJobComponent {
  @Input("card") card: EdgeCard;
  @Output("resize") resize = new EventEmitter();
  @Output("change") change = new EventEmitter();
  @Output("remove") remove = new EventEmitter();

  constructor(
    private navCtrl: NavController
  ) { }

  tapCard() {
    this.card.showSizePositionControl || this.card.preview ?
    this.getOverlay() :this.navCtrl.navigateRoot("/loads", { queryParams: { page: "Pre-Assigned" } });
  }

  getOverlay(){
    this.card.showOverlay = !this.card.showOverlay;
  }

  resizeCard() {
    this.resize.emit(this.card);
  }

  changeCard() {
    this.change.emit(this.card);
  }

  removeCard() {
    this.remove.emit(this.card);
  }
}
