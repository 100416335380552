// import { PayCheck } from 'src/app/Swagger-Gen';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SafetyStats } from 'src/app/Swagger-Gen-V2';
import * as fromRoot from '../../../../../app.state';
import { SafetyStatsActionTypes, SafetyStatsViewActions } from './safety-stats.action';

export interface State extends fromRoot.State {
    safetyStatsView: SafetyStatsViewState;
}

export interface SafetyStatsViewState {
    safetyStatsView: SafetyStats;
    error: string;
}

const initialState: SafetyStatsViewState = {
    safetyStatsView: null,
    error: ''
}

const getSafetyStatsFeatureState = createFeatureSelector<SafetyStatsViewState>('safetyStats');

export const getDriverSafetyStats = createSelector(
    getSafetyStatsFeatureState,
    (state) => (state != null ? state.safetyStatsView : null)
);

export const getError = createSelector(
    getSafetyStatsFeatureState,
    state => state.error
);


export function safetyStatsReducer(state = initialState, action: SafetyStatsViewActions): SafetyStatsViewState {
    switch (action.type) {
        case SafetyStatsActionTypes.GetStatsViewSuccess:
            return {
                ...state,
                safetyStatsView: action.payload,
                error: ''
            };
        case SafetyStatsActionTypes.GetStatsView:
            return {
                ...state,
                safetyStatsView: null,
                error: null
            };

        case SafetyStatsActionTypes.GetStatsViewFail:
            return {
                ...state,
                safetyStatsView: null,
                error: action.payload
            };
        default:
            return state;
    }
}