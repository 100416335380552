/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WernerDwpContractsLogicFeedbackFeedbackQuestion } from './wernerDwpContractsLogicFeedbackFeedbackQuestion';
import { WernerDwpContractsLogicFeedbackFeedbackType } from './wernerDwpContractsLogicFeedbackFeedbackType';

export interface FeedbackQuestionnaire { 
    questions?: Array<WernerDwpContractsLogicFeedbackFeedbackQuestion>;
    title?: string;
    isPictureEnabled?: boolean;
    categories?: Array<WernerDwpContractsLogicFeedbackFeedbackType>;
    templateId?: number;
}