import { Injectable } from "@angular/core";
import { Address, EventLocation, TripStep } from "../../../../Swagger-Gen";
@Injectable({
  providedIn: "root",
})
export class GeoJSONService {

  convertPOIs(poiData: any, name: string):any {
    const convertedPois = new GeoJSON();
    const data = new GeoJSONData();
    let feature: GeoJSONFeature = null;
    let geometry: GeoJSONGeometry = null;
    let properties: GeoJSONProperty = null;
    let i = 0;
    if (poiData && typeof poiData === "object" && poiData.length > 0) {
      poiData.forEach((p) => {
        i = i + 1;
        let longitude =
          p && p.location && p.location.coordinates && p.location.coordinates.longitude ? p.location.coordinates.longitude : null;
        let latitude =
          p && p.location && p.location.coordinates &&  p.location.coordinates.latitude ? p.location.coordinates.latitude : null;
        if (longitude && latitude) {
          feature = new GeoJSONFeature();
          geometry = new GeoJSONGeometry();
          properties = new GeoJSONProperty();
          geometry.coordinates = [longitude, latitude];
          properties.name = p.name;
          properties.brand = p.brand;
          properties.location = p.location;
          properties.id = name;
          properties.parkingStalls = p.parkingStalls;
          properties.phoneVoice = p.phoneVoice;
          properties.phoneFax = p.phoneFax;
          properties.showerStalls = p.showerStalls;
          properties.directions = p.directions;
          properties.availableParkingSpots = p.availableParkingSpots;
          properties.totalParkingSpots = p.totalParkingSpots;
          properties.lastUpdatedDatetime = p.lastUpdatedDate;
          properties.open = p.open;
          properties.phone = p.phone;
          properties.direction = p.direction;
          feature.geometry = geometry;
          feature.properties = properties;
          data.features.push(feature);
        }
      });
    }

    convertedPois.data = data;
    return convertedPois;
  }  
}

export class GeoJSON {
  type = "geojson";
  cluster = true;
  clusterRadius = 40;
  clusterMaxZoom =14;
  data: GeoJSONData;
}

export class GeoJSONData {
  type = "FeatureCollection";
  features: Array<GeoJSONFeature> = [new GeoJSONFeature()];
}

export class GeoJSONFeature {
  type = "Feature";
  properties: GeoJSONProperty;
  geometry: GeoJSONGeometry;
}

export class GeoJSONProperty {
  name = "Default Name";
  brand: string = "";
  directions: string = "";
  id: string = "";
  location: Address = {};
  parkingStalls = 0;
  phoneFax = "";
  phone = "";
  showerStalls = 0;
  title: string = "";
  phoneVoice: string = '';
  availableParkingSpots : number = 0;
  lastUpdatedDatetime : string = "";
  open : boolean = true;
  totalParkingSpots : number = 0;
  direction : string = "";
  // category: string - saw this in a few examples, maybe we'll need it later
}

export class GeoJSONGeometry {
  type = "Point";
  coordinates: Array<number>;
}
