import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'carousel-item-card',
  templateUrl: './carousel-item-card.component.html',
  styleUrls: ['./carousel-item-card.component.scss'],
})
export class CarouselItemCardComponent implements OnInit {

  @Input('item') item: any

  constructor() { }

  ngOnInit() {}
}
