import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-pre-assigned-trips-loads',
  templateUrl: './pre-assigned-trips-loads.component.html',
  styleUrls: ['./pre-assigned-trips-loads.component.scss'],
})
export class PreAssignedTripsLoadsComponent {

  driverId = '75676764'; // Stores the id of the logged in driver

  badgeMessage = 'Respond via In-Cab Device before proceeding. Load may change.';
  badgeIcon = 'alert-circle-outline';
  badgeBackgroundColor = '#E05353';
  badgeTextColor = '#FFFFFF';
  @Input('loadTrip') loadTrip: boolean = false;
  @Output() stopIonRefresher = new EventEmitter;
  hideIonRefresher(event){
    this.stopIonRefresher.emit(event);
  }
}
