import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "information-modal",
  templateUrl: "./information-modal.component.html",
  styleUrls: ["./information-modal.component.scss"],
})
export class InformationModalComponent {
  @Input("title") title: string;
  @Input("subtitle") subtitle: string;
  @Output("close") close = new EventEmitter();
  constructor(private router: Router) {}

  closeModal() {
    this.close.emit();
  }

  viewProfile() {
    this.router.navigate(["driver-profile"], { queryParams: { 'tab': 'myInformation'} });
  }
}
