/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MessageDetailsView { 
    messageId?: string;
    title?: string;
    receivedDateText?: string;
    messageContent?: string;
    attachmentsNames?: Array<string>;
    attachmentsUrls?: Array<string>;
    attachmentsTypes?: Array<string>;
    attachmentsBytes?: Array<string>;
    senderName?: string;
    phone?: string;
    email?: string;
}