
<ion-card class="edge-card" [ngClass]="{'static-vertical-card': !isMobile || isTab, 'vertical-card': isMobile}" *ngIf="card">
  <card-overlay 
    [card]="card"
    (didTapCard)="tapCard()"
    (didTapResize)="resizeCard()"
    (didTapChange)="changeCard()"
    (didTapRemove)="removeCard()"
  ></card-overlay>
  <div class="img-div">
    <img src="../../../../../assets/Images/benefits_icon.svg" alt="Benefits">
    <ion-badge class="badge" color="danger" *ngIf="benefitsAnnouncementsUnreadCount > 0"> {{benefitsAnnouncementsUnreadCount}} </ion-badge>
  </div>
</ion-card>