/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Amenities } from './amenities';
import { Identifier } from './identifier';
import { Tier } from './tier';

export interface Provider { 
    id?: number;
    cost?: Array<any>;
    providerId?: string;
    providerType?: string;
    locationId?: number;
    contractId?: number;
    clientCanonicalId?: string;
    npiIdentifier?: number;
    name?: string;
    firstName?: any;
    middleName?: any;
    lastName?: any;
    gender?: any;
    photo?: any;
    locationName?: string;
    addrLine1?: string;
    addrLine2?: any;
    city?: string;
    state?: string;
    postalCode?: number;
    longitude?: number;
    latitude?: number;
    distance?: number;
    isPcp?: boolean;
    isChoicePcp?: boolean;
    isPcpForAccounts?: Array<any>;
    phone?: string;
    fax?: string;
    primarySpecialty?: any;
    relevantSpecialty?: string;
    additionalSpecialties?: Array<any>;
    acceptingNewPatients?: any;
    averageWaitTime?: any;
    bdcInfo?: any;
    coeInfo?: any;
    overallRating?: number;
    ratingsCount?: number;
    percentRecommended?: number;
    completedEducation?: any;
    awardsCount?: number;
    hospitalGroupAffiliationsCount?: number;
    affiliatedMedicalGroups?: any;
    otherLocationCount?: number;
    searchDisclaimers?: Array<any>;
    amenitiesCount?: number;
    amenities?: Amenities;
    languages?: Array<any>;
    awards?: Array<any>;
    website?: any;
    email?: any;
    tiers?: Array<Tier>;
    reviewsCanonicalId?: string;
    futureEffectiveDate?: any;
    futureTerminationDate?: any;
    degreeTypes?: Array<any>;
    erCode?: any;
    ahnProviders?: any;
    copay?: any;
    identifiers?: Array<Identifier>;
    clientQuality?: any;
    searchHighlights?: any;
}
