/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CheckTripDetail { 
    tripId?: string;
    from?: string;
    to?: string;
    numberOfStops?: number;
    deadHeadMiles?: number;
    deadHeadPerDiemRate?: number;
    deadHeadRate?: number;
    deadHeadFlatRate?: number;
    lineHaulMiles?: number;
    lineHaulPerDiemRate?: number;
    lineHaulRate?: number;
    lineHaulFlatRate?: number;
    perDiemTotal?: number;
    tripTotal?: number;
    loadDate?: string;
}