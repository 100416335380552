/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExtensionDataObject } from './extensionDataObject';
import { PhoneType } from './phoneType';

export interface DriverPhone { 
    extensionData?: ExtensionDataObject;
    carrierID?: number;
    countryCode?: string;
    driverPhoneType?: PhoneType;
    extension?: string;
    formattedNumber?: string;
    internationalCode?: string;
    isPrimary?: boolean;
    isPrivate?: boolean;
    locationCode?: string;
    number?: string;
    phoneId?: number;
    workDayPhoneId?: string;
}