import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";

import { Action } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import * as ProfileActions from "./profile.actions";
import { ProfileService } from "src/app/Swagger-Gen";
import { ProfileInformationService } from "../../../Swagger-Gen/api/profileInformation.service";

@Injectable()
export class GetProfileEffects {
  profileService: ProfileService = this.injector.get(ProfileService);
  profileInfoService: ProfileInformationService = this.injector.get(ProfileInformationService);
  constructor(private injector: Injector, private actions$: Actions) { }

  @Effect()
  profileInfo$: Observable<Action> = this.actions$.pipe(
    ofType(ProfileActions.ProfileActionTypes.GetProfileInfo),
    mergeMap((action) => {
      return this.profileInfoService
        .apiProfileInformationIdentityKeyGet(
          (action as ProfileActions.GetProfileInfo).IdentityKey
        )
        .pipe(
          map(
            (profileInfo) =>
              new ProfileActions.GetProfileInfoSuccess(profileInfo)
          ),
          catchError((err) => of(new ProfileActions.GetProfileInfoFail(err)))
        );
    })
  );
  @Effect()
  workdayProfileInfo$: Observable<Action> = this.actions$.pipe(
    ofType(ProfileActions.ProfileActionTypes.GetWorkDayProfileInfo),
    mergeMap((action) => {
      return this.profileInfoService.apiProfileInformationWorkdayIdentityKeyGet
        (
          (action as ProfileActions.GetProfileInfo).IdentityKey
        )
        .pipe(
          map(
            (profileInfo) =>
              new ProfileActions.GetWorkDayProfileInfoSuccess(profileInfo)
          ),
          catchError((err) => of(new ProfileActions.GetWorkDayProfileInfoFail(err)))
        );
    })
  );


  @Effect()
  updateProfile$: Observable<Action> = this.actions$.pipe(
    ofType(ProfileActions.ProfileActionTypes.UpdateProfile),
    mergeMap((action) => {
      return this.profileInfoService.apiProfileInformationUpdatePost(
        (action as ProfileActions.UpdateProfile).updateProfile
      )
        .pipe(
          map(
            (profileInfo) =>
              new ProfileActions.UpdateProfileInfoSuccess(profileInfo)
          ),
          catchError((err) => of(new ProfileActions.UpdateProfileInfoFail(err)))
        )
    })

  );
  @Effect()
  profileAwards$: Observable<Action> = this.actions$.pipe(
    ofType(ProfileActions.ProfileActionTypes.GetProfileAwards),
    mergeMap((action) =>
      this.profileService
        .apiProfileAwardsIdentityKeyGet(
          (action as ProfileActions.GetProfileAwards).IdentityKey
        )
        .pipe(
          map(
            (profileAwards) =>
              new ProfileActions.GetProfileAwardsSuccess(profileAwards)
          ),
          catchError((err) => of(new ProfileActions.GetProfileAwardsFail(err)))
        )
    )
  );
}
