<ion-spinner *ngIf="showSpinner == true"></ion-spinner>

<div style="width:100% !important;background-color:#f2f2f2 !important;">

  <ion-list class="previous-trips-list" *ngIf="tripsByTimePeriods !== null && tripsByTimePeriods !== undefined &&
      tripsByTimePeriods.length > 0"  >

    <app-trips-list class="pointer" *ngFor="let currentPeriodTrips of tripsByTimePeriods;" [periodTrips]="currentPeriodTrips"
                    (showPaperworkDrawer)="showPaperworkDrawer($event)"></app-trips-list>
  </ion-list>

</div>

<div *ngIf=" (tripsByTimePeriods !== null && tripsByTimePeriods.length == 0) ||
(tripsByTimePeriods === null) || (tripsByTimePeriods == undefined)" class="no-completed-trips">
  No completed trips found
</div>

<!-- Bottom Drawer Component -->
<bottom-drawer 
    *ngIf="showBottomDrawer" 
    [title]="'Start Paperwork'" 
    [bottomDrawerShouldHide]=true
    [backdrop]=true 
    [middleOffset]=300
    [actionSheetRows] = actionSheetRows
    (closedDrawer)="showPaperworkDrawer($event)"
    >
</bottom-drawer> 