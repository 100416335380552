import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from "@angular/common/http";

import { Observable } from "rxjs";

import { BASE_PATH } from "../variables";
import { Configuration } from "../configuration";
import { EmployeeResources } from "../model/employeeResources";
import { CustomHttpUrlEncodingCodec } from "../encoder";

@Injectable()
export class EmployeeResourcesService {
  protected basePath = "/";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Retrieves Employee Resources Quick Links
   * @param includeInactive
   * @param identityKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiEmployeeResourcesGet(
    identityKey: string,
    includeInactive: boolean,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<EmployeeResources[]>;
  public apiEmployeeResourcesGet(
    identityKey: string,
    includeInactive: boolean,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<EmployeeResources[]>>;
  public apiEmployeeResourcesGet(
    identityKey: string,
    includeInactive: boolean,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<EmployeeResources[]>>;
  public apiEmployeeResourcesGet(
    identityKey: string,
    includeInactive: boolean,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "includeInactive",
        <any>includeInactive
      );
    }

    if (identityKey === null || identityKey === undefined) {
      throw new Error(
        "Required parameter identityKey was null or undefined when calling apiEmployeeResourcesGet."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<EmployeeResources[]>(
      "get",
      `${this.basePath}/api/employeeresources`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
