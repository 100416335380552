/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ChainBankPoi } from './chainBankPoi';
import { DropYardPoi } from './dropYardPoi';
import { FuelStopPoi } from './fuelStopPoi';
import { RestAreaPoi } from './restAreaPoi';
import { ScalePoi } from './scalePoi';
import { TerminalPoi } from './terminalPoi';
import { TruckStopPoi } from './truckStopPoi';
import { TruckWashPoi } from './truckWashPoi';

export interface PointsOfInterests { 
    proximityZipCode?: string;
    restAreas?: Array<RestAreaPoi>;
    fuelStops?: Array<FuelStopPoi>;
    truckWashes?: Array<TruckWashPoi>;
    terminals?: Array<TerminalPoi>;
    chainBanks?: Array<ChainBankPoi>;
    scales?: Array<ScalePoi>;
    dropYards?: Array<DropYardPoi>;
    truckStops?: Array<TruckStopPoi>;
}