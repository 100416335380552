import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { NavController } from '@ionic/angular';
import { SubSink } from 'subsink';
import { AuthService } from 'src/app/auth/auth.service';
import { MessageThreadDetailResponse } from 'src/app/Swagger-Gen-V2/model/messageThreadDetailResponse';

@Component({
  selector: 'announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
})
export class AnnouncementsComponent implements OnChanges, OnDestroy {

  MESSAGES_KEY = 'initial-messages';
  SELECTED_MESSAGE_KEY = 'selected-message';
  MESSAGE_DETAILS_VIEW_KEY = 'message-details';
  SELECTED_THREAD_ID_KEY = 'selected-thread-id';
  SELECTED_THREAD_KEY = 'selected-thread';
  @Input() announcements;
  filteredAnnouncements: Array<MessageThreadDetailResponse> = []; 
  identityKey = '';
  subscriptions$ = new SubSink();

  constructor(
    private authService: AuthService,
    private navCtrl: NavController
  ) {
    this.authService.getUserIdentitityKey().then((identityKey) => {
      this.identityKey = identityKey;
    });
  }

  async ngOnChanges() {
    if(this.announcements){
      this.filteredAnnouncements = [];
      this.announcements.threads.forEach(async announcement => {
        if (announcement.threadType === 'Broadcast'
          || announcement.threadType === 'GroupDriver') {
          this.filteredAnnouncements.push(announcement);
        }
      });
    }
  }

  // opens message details and shows it
  async showMessage(messageItem: MessageThreadDetailResponse) {

    if (messageItem.threadType === 'Broadcast'
    || messageItem.threadType === 'GroupDriver') {
      this.navCtrl.navigateForward([`broadcast-message-details/${messageItem.threadId}`]);
    }  else {
      this.navCtrl.navigateForward([`personal-message-details/${messageItem.threadId}`]);
    }
  }

  async ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
