import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'poi-modal',
  templateUrl: './poi-modal.component.html',
  styleUrls: ['./poi-modal.component.scss'],
})

export class PoiModalComponent implements OnChanges {

  @Input() poiFilter = [];
  @Output() selectedItem = new EventEmitter();
  @Output() didTapClose = new EventEmitter();
  poiInfo = [];

  ngOnChanges(changes: SimpleChanges): void {
    const poiInfo = changes['poiFilter'];
    if (poiInfo && poiInfo.currentValue) {
      this.poiInfo = JSON.parse(JSON.stringify(poiInfo.currentValue));
    }
}

  applyfilters = () => {
    const self = this;
    this.poiInfo.forEach(function (poi) {
      self.selectedItem.emit(poi);
    });
    this.close();
  }

  close = () => {
    this.didTapClose.emit();
  }
}
