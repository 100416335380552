<ion-card  class="map-card">


    <map 
      class="map" 
      lines="none">
      <!-- [userInteractionEnabled]=true 
      [loadStatusCard]=true> -->
    </map>


</ion-card>



<ion-text>
  <h2 class="card-title-label">{{title}}</h2>
</ion-text>


<p>
    {{criticalLoadNotes}}
    <br/><br/>
</p>

