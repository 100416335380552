<div class="background">
  <div class="edge-value modal">
    <ion-item class="title-row" lines="full">
      <ion-label>Warning</ion-label>
      <button (click)="closeModal()">
        <img src="assets/cross.svg" alt="">
      </button>
    </ion-item>
    <ion-item lines="full">
      <ion-label class="body ion-text-wrap">
        You have unsaved changes. Are you sure you want to exit without saving?
      </ion-label>
    </ion-item>
    <div class="flex-me">
      <div class="cta werner-button outline" (click)="didTapContinue()">
        Continue
      </div>
      <div class="cta werner-button primary" (click)="didTapSave()">
        Save
      </div>
    </div>
  </div>
</div>