import { Component, EventEmitter, Injector, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EdgeCard } from 'src/app/shared/models/edge-card';
import * as fromSafetyStats from 'src/app/modules/safety/components/safety-stats/state/safety-stats.reducer';
import * as safetyStatsActions from 'src/app/modules/safety/components/safety-stats/state/safety-stats.action';
import { takeWhile } from 'rxjs/operators';
import { ProfileInfoService } from 'src/app/modules/profile-v2/service/profileInfo.service';
import { StorageConstants } from 'src/app/shared/utilities/storage-constants';
import { Profile, SafetyStats } from 'src/app/Swagger-Gen-V2';
import { SubSink } from 'subsink';
import { Preferences } from '@capacitor/preferences';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ProfileUtility } from 'src/app/shared/utilities/profile-utility';
import { DispatchService } from 'src/app/shared/services/dispatchers/dispatch.service';
@Component({
  selector: 'safety-card',
  templateUrl: './safety-card.component.html',
  styleUrls: ['./safety-card.component.scss'],
})
export class SafetyCardComponent implements OnInit, OnDestroy {
  @Input("card") card: EdgeCard;
  @Output("resize") resize = new EventEmitter();
  @Output("change") change = new EventEmitter();
  @Output("remove") remove = new EventEmitter();
  SAFETY_PAGE_SELECTED_TAB_KEY = "safety-page-selected-tab";
  statsView: SafetyStats;
  milesPercent = "";
  maxPercent = "1,000,000";
  miles = "";
  nextGoalMiles = "1,000,000";
  nextMilestone = "";
  stopsPercent = "";
  stops = "";
  radius = '75';
  componentActive = true;
  driverId = ""; // stores the driver id
  SAFETY_STATS_KEY = 'driver-safety-stats';
  theValue = 'Safe Miles';
  tab = "Safe Miles";
  selectedTab = 0;
  zeroMiles = false;
  subPages = [
    { title: "Safe Miles", icon: ""},
    { title: "Safe Stops", icon: ""}
  ];
  profileInfoService:ProfileInfoService;
  subscriptions$ = new SubSink();
  employeeNumber : string;
  profileInfo:Profile;

  constructor(
    private store: Store<fromSafetyStats.State>,
    private injector: Injector,
    private router:Router,
    private authService: AuthService,
    private profileUtility : ProfileUtility,
    private dispatchService: DispatchService
  ) { 
    this.profileInfoService = injector.get<ProfileInfoService>(ProfileInfoService);
  }

  async ngOnInit() {
    // Set radius to device width / 5.5 or 100 at the most
    this.profileInfo = await this.profileInfoService.getUserProfileInfo();
    this.employeeNumber = await this.dispatchService.getEmployeeNumber();
    this.radius = (window.innerWidth / 6) < 100 ? (window.innerWidth / 5.5).toString() : '100';
    await this.getSafetyStats();
    this.updateTab();
  }
  
  updateTab() {
    if(this.subPages !== null && this.subPages !== undefined && this.subPages.length > 0) {
      this.theValue = this.subPages[this.selectedTab].title;
      this.tab = this.subPages[this.selectedTab].title;
    }
  }

  async getSafetyStats() {
    this.driverId = await this.authService.getUserIdentitityKey();
    this.store.dispatch(new safetyStatsActions.GetStatsView(this.driverId, this.employeeNumber,this.profileInfo.user.stakeholderId));
    this.store.pipe(
        select(fromSafetyStats.getDriverSafetyStats),
        takeWhile(() => this.componentActive)
    ).subscribe(async (safetyStats) => {
      if (safetyStats) {
        this.statsView = safetyStats;
      }
      this.parseStats();
    });        
  }

  setZeroMiles() {
    this.zeroMiles = true;
  }
  parseStats() {
    if (this.statsView) {
      if (!(this.statsView && this.statsView.safeMiles && this.statsView.safeMiles.currentMiles)) {
        this.setZeroMiles();
      } else {
        // Set default values if the safe miles value is -1 
        if (this.statsView && this.statsView.safeMiles && this.statsView.safeMiles.currentMiles === -1) {
          this.statsView = StorageConstants.SAFETY_DEFAULT_VALUE;
        }
        this.milesPercent = ((this.statsView.safeMiles.currentMiles / this.statsView.safeMiles.nextMilestone) * 100).toString();
        this.miles = this.commaSeparated(this.statsView.safeMiles.currentMiles);
        this.maxPercent = this.statsView.safeMiles.nextMilestone.toString();

        this.nextGoalMiles = this.commaSeparated(this.statsView.safeMiles.nextMilestone);
        this.nextMilestone = this.commaSeparated(this.statsView.safeMiles.nextMilestone);

        this.stopsPercent = this.milesPercent;
        this.stops = this.commaSeparated(this.statsView.safeStops.stops);
      }
    }
  }
  commaSeparated(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
  onSubpageChange(event) {
    this.tab = event.detail.value;
  }

  tapCard() {
    Preferences.set({ key: this.SAFETY_PAGE_SELECTED_TAB_KEY, value: "0" });
    this.card.showSizePositionControl || this.card.preview ?
    !this.card.showOverlay :
    this.router.navigateByUrl("/safety", { replaceUrl: true });
  }

  resizeCard() {
    this.resize.emit(this.card);
  }

  changeCard() {
    this.change.emit(this.card);
  }

  removeCard() {
    this.remove.emit(this.card);
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }  

}