import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams, Platform } from "@ionic/angular";
import { LaunchNavigator } from "@ionic-native/launch-navigator/ngx";

@Component({
  selector: "important-dialog",
  templateUrl: "./important-dialog.component.html",
  styleUrls: ["./important-dialog.component.scss"],
})
export class ImportantDialogComponent implements OnInit {
  address: string;
  constructor(
    public modalController: ModalController,
    private navParams: NavParams,
    private launchNav: LaunchNavigator,
    private platform: Platform
  ) {
    this.address = this.navParams.get("address");
  }

  ngOnInit() {}

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  directionsTo() {
    if (this.platform.is("mobile") && !this.platform.is("mobileweb")) {
      this.launchNav.navigate(this.address).then(
        (success) => alert("Launched navigator"),
        (error) => alert("Error launching navigator: " + error)
      );
    } else {
      let directionsUrl = "https://maps.google.com/maps/dir/?api=1&origin=";
      let destination = "&destination=" + encodeURI(this.address);
      window.open(directionsUrl + destination);
    }
    this.dismiss();
  }
}
