/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CommonPhone { 
    id?: string;
    countryCode?: string;
    areaCode?: number;
    number?: string;
    extension?: string;
    isPublic?: boolean;
    isPrimary?: boolean;
    type?: string;
    deviceType?: string;
    countryIsoCode?: string;
}