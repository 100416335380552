
    <ion-card *ngIf="message !== null && message !== undefined && message.length > 0" class="edge-card message-card" >
      <card-header  cardTitle="Message"></card-header>

      <div class="separator-panel"></div>
      <ion-row>
        <div class="card-content-panel">
          <ion-text>
            {{message}}
          </ion-text>
        </div>
      </ion-row>
      <ion-row class="open-link-row">
        <div *ngIf="resourceUrl !== null && resourceUrl !== undefined && resourceUrl.length > 0"
          class="cta werner-button outline" (click)="openLinkButtonClickHandler()" >
          Open Link
        </div>
      </ion-row>
    </ion-card>
