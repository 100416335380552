import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { LandingPageModule } from "./modules/landing/landing.module";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { ImagePicker } from "@ionic-native/image-picker/ngx";
import { Camera } from "@ionic-native/camera/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { EffectsModule } from "@ngrx/effects";
import { LoadsPageModule } from "./modules/loads/loads.module";
import {
  ApiModule,
  Configuration,
  ConfigurationParameters,
  OrientationService,
  ReferralsService,
} from "./Swagger-Gen";
import { ApiV2Module, ConfigurationV2, ConfigurationV2Parameters } from "./Swagger-Gen-V2";
import { AuthModule } from "./auth/auth.module";
import { IonicStorageModule } from "@ionic/storage-angular";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import {
  faTruckMoving,
  faMoneyBill,
  faMapMarkedAlt,
  faHandshake,
} from "@fortawesome/pro-regular-svg-icons";
import { previousTripsReducer } from "./modules/loads/previous-trips-loads/state/previous-trips-loads.reducer";
import { pastPaychecksReducer } from "./modules/pay/past-paychecks/state/past-paychecks.reducer";
import { paycheckDetailReducer } from "./modules/pay/past-paychecks/paycheck-detail/state/paycheck-detail.reducer";
import { loadStatusReducer } from "./shared/components/cards/load-status-card/load-status/state/load-status.reducer";
import { tripLoadInfosReducer } from "./modules/loads/trip-load-infos/state/trip-load-infos.reducer";
import { preAssignedTripCardReducer } from "./Core/components/pre-assigned-trip-card/state/pre-assigned-trip-card-reducer";
import { Crop } from "@ionic-native/crop/ngx";
import { FileTransfer } from "@ionic-native/file-transfer/ngx";
import { File } from '@ionic-native/file/ngx';
import { environment } from "../environments/environment";
import { NativeStorage } from "@ionic-native/native-storage/ngx";
import { LaunchNavigator } from "@ionic-native/launch-navigator/ngx";
import { SpeechRecognition } from "@ionic-native/speech-recognition/ngx";
import { TextToSpeech } from "@ionic-native/text-to-speech/ngx";
import { AnalyticsService } from "./shared/services/analytics.service";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { AppVersionService } from "./shared/services/app-version.service";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { userProfileReducer } from "./modules/profile/state/profile.reducer";
import { GetProfileEffects } from "./modules/profile/state/profile.effects";
import { rootReducer } from "./state/root.reducer";
import { GetRootEffects } from "./state/root.effects";
import { DispatchService } from "./shared/services/dispatchers/dispatch.service";
import { newsItemReducer } from "./modules/news/state/news.reducer";
import { GetNewsEffects } from "./modules/news/state/news.effects";
import { ProfileInformationService } from "./Swagger-Gen/api/profileInformation.service";
import { LoadingUtility } from "./shared/utilities/LoadingUtility";
import { PdfViewerUtility } from "./shared/utilities/pdf-view-utilities";
import { HTTP } from '@ionic-native/http/ngx';
import { PlayerUtilities } from "./shared/utilities/player-utility";
import { DragulaModule, DragulaService } from "ng2-dragula";
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { CallNumber } from '@ionic-native/call-number/ngx';
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { IdleLogoutService } from "./shared/services/Idle-Logout.service";
import { driverReferralReducer } from "./modules/driver-referral/state/driver-referral.reducer";
import { DriverReferralEffects } from "./modules/driver-referral/state/driver-referral.effects";
import { PhoneNumberUtility } from "./shared/utilities/phone-number-utility";
import { EmailUtility } from "./shared/utilities/email-utility";
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { SettingsService } from "./Swagger-Gen/api/settings.service";
import { ProfileUtility } from "./shared/utilities/profile-utility";
import { TelemetryUtility } from "./shared/utilities/telemetry-utilities";
import { Router } from '@angular/router';
import { OktaAuth } from '@okta/okta-auth-js';
import {  OktaAuthModule,  OKTA_CONFIG } from '@okta/okta-angular';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { NgxImageCompressService } from 'ngx-image-compress';
import { userBasicInfoReducer, userDataReducer, userDivisonInfoReducer, userFleetManagerReducer, userIdsReducer, userPermissionReducer, userPictureReducer } from './modules/profile-v2/state/user-profile.reducer';
import { GetUserProfileEffects } from './modules/profile-v2/state/user-profile.effects';
import { ToasterUtility } from "./shared/utilities/toaster-utility";
import { ProfileInfoService } from "./modules/profile-v2/service/profileInfo.service";
import { LaunchDarklyService } from "./shared/services/launch-darkly.service";
import { HamburgerMenuComponent } from "./modules/home/hamburger-menu/hamburger-menu.component";
import { UrgentCareUtility } from "./shared/utilities/UrgentCare-utility";
const oktaAuth = new OktaAuth(environment.oidc);

export function apiConfigSetup(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.baseApiUrl,
    accessToken: oktaAuth.getAccessToken.bind(oktaAuth),
  };
  return new Configuration(params);
}

export function apiConfigV2Setup(): ConfigurationV2 {
  const params: ConfigurationV2Parameters = {
    basePath: environment.baseApiV2Url,
    accessToken: oktaAuth.getAccessToken.bind(oktaAuth),
  };
  return new ConfigurationV2(params);
}

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
};

library.add(
  fas,
  far,
  fab,
  fal,
  faTruckMoving,
  faMoneyBill,
  faMapMarkedAlt,
  faHandshake
);

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent,HamburgerMenuComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      navAnimation: null,
      scrollPadding: false,
      scrollAssist: false
    }),
    NgIdleKeepaliveModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forFeature("userProfile", userProfileReducer),
    EffectsModule.forFeature([GetProfileEffects]),
    StoreModule.forFeature("driverReferral", driverReferralReducer),
    EffectsModule.forFeature([DriverReferralEffects]),
    StoreModule.forFeature('newsItems', newsItemReducer),
    EffectsModule.forFeature([GetNewsEffects]),
    // v2 Profile API store
    StoreModule.forFeature('userData', userDataReducer),
    StoreModule.forFeature('userProfileBasicInfoState', userBasicInfoReducer),
    StoreModule.forFeature('userProfileFleetManagerState', userFleetManagerReducer),
    StoreModule.forFeature('userDivisonInfoState', userDivisonInfoReducer),
    StoreModule.forFeature('userIdsState', userIdsReducer),
    StoreModule.forFeature('userPictureState', userPictureReducer),
    StoreModule.forFeature('userPermissionState', userPermissionReducer),
    EffectsModule.forFeature([GetUserProfileEffects]),
    EffectsModule.forFeature([GetRootEffects]),
    StoreModule.forFeature('root', rootReducer),
    StoreModule.forRoot({
      previousTripsReducer,
      pastPaychecksReducer,
      paycheckDetailReducer,
      loadStatusReducer,
      tripLoadInfosReducer,
      preAssignedTripCardReducer,

    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),

    EffectsModule.forRoot([]),
    LandingPageModule,

    FontAwesomeModule,

    LoadsPageModule,
    ApiModule,
    ApiV2Module,
    AuthModule,
    IonicStorageModule.forRoot(),
    DragulaModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    OktaAuthModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    DispatchService,
    NativeStorage,
    Geolocation,
    Diagnostic,
    File,
    FileOpener,
    IdleLogoutService,
    NativeGeocoder,
    HTTP,
    Chooser,
    {
      provide: Configuration,
      useFactory: apiConfigSetup,
      multi: false,
    },
    {
      provide: ConfigurationV2,
      useFactory: apiConfigV2Setup,
      multi: false,
    },      
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Camera,
    Crop,
    FileTransfer,
    ImagePicker,
    Geolocation,
    LaunchNavigator,
    SpeechRecognition,
    TextToSpeech,
    AnalyticsService,
    AppVersion,
    AppVersionService,
    ScreenOrientation,
    ProfileInformationService,
    LoadingUtility,
    PdfViewerUtility,
    PlayerUtilities,
    DragulaService,
    InAppBrowser,
    CallNumber,
    OrientationService,
    ReferralsService,
    PhoneNumberUtility,
    EmailUtility,
    StreamingMedia,
    SettingsService,
    ProfileUtility,
    TelemetryUtility,
    ToasterUtility,
    {
      provide: OKTA_CONFIG,
      useValue: {
        oktaAuth,
        onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
          const router = injector.get(Router);
          router.navigate(['/login']);
        }
      }
    },
    NgxImageCompressService,
    ProfileInfoService,
    UrgentCareUtility,
    LaunchDarklyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab, fal);
    library.addIcons(faTruckMoving, faMoneyBill, faMapMarkedAlt, faHandshake);
  }
}