import { Component, Input } from '@angular/core';
import { CommonContact } from 'src/app/Swagger-Gen-V2';

@Component({
  selector: "app-transportation-manager-card",
  templateUrl: "./transportation-manager-card.component.html",
  styleUrls: ["./transportation-manager-card.component.scss"],
})
export class TransportationManagerCardComponent {
  @Input() fleetManagers: CommonContact[];
  defaultImage = "../../assets/Images/user.png";
}
