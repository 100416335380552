import { PaycheckDetailActions, PaycheckDetailActionTypes } from './paycheck-detail.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../../app.state';
import { PayCheck } from 'src/app/Swagger-Gen-V2/model/payCheck';
export interface State extends fromRoot.State {
    paycheckDetail: PaycheckDetailState;
}

export interface PaycheckDetailState {
    paycheckDetail: PayCheck;
    error: string;
}

const initialState: PaycheckDetailState = {
    paycheckDetail: null,
    error: ''
};

const getPaycheckDetailFeatureState = createFeatureSelector<PaycheckDetailState>('paycheckDetail');

export const getDriverPaycheckDetail = createSelector(
    getPaycheckDetailFeatureState,
    state => state.paycheckDetail
);

export const getError = createSelector(
    getPaycheckDetailFeatureState,
    state => state.error
);


export function paycheckDetailReducer(state= initialState, action: PaycheckDetailActions): PaycheckDetailState {
    switch (action.type) {
        case PaycheckDetailActionTypes.GetPaycheckDetailSuccess:
            return {
                ...state,
                paycheckDetail: action.payload,
                error: ''
            };
            case PaycheckDetailActionTypes.GetPaycheckDetail:
                return {
                    ...state,
                    paycheckDetail: null,
                   
                };
    
        case PaycheckDetailActionTypes.GetPaycheckDetailFail:
            return {
                ...state,
                paycheckDetail: null,
                error: action.payload
            };
        default:
            return state;
    }
}
