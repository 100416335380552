/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Inspection { 
    reportNumber?: string;
    reportState?: string;
    inspectionDate?: Date;
    inspectionValue?: number;
    basicName?: string;
    basicId?: string;
    section?: string;
    riskIndicator?: string;
    sectionDescription?: string;
    timeWeight?: number;
    severityWeight?: number;
    points?: number;
    oos?: string;
    isInspection?: boolean;
}