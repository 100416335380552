/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Trailer } from './trailer';

export interface TripLocation { 
    tripId?: string;
    tractorNumber?: string;
    trailerNumber?: string;
    trailerInfos?: Trailer;
    arrivalDate?: Date;
    departureDate?: Date;
    targetArrivalDate?: Date;
    segmentNumber?: number;
    sort?: number;
    locationName?: string;
    locationCode?: string;
    addressInfos?: Address;
    siteSafetyAlertUrl?: string;
    freightBillNumber?: string;
    shipperOrder?: string;
}