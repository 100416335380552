import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Trip, TripStep, TripStepType } from '../../../Swagger-Gen';
import { formatDate } from '@angular/common';
import { Router } from "@angular/router";
import { Platform } from '@ionic/angular';
import { AnalyticsService } from "../../../shared/services/analytics.service";
import { TelemetryUtility } from 'src/app/shared/utilities/telemetry-utilities';
import { TelemetryConstants } from 'src/app/shared/utilities/telemetry-constants';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-trip-step-card',
  templateUrl: './trip-step-card.component.html',
  styleUrls: ['./trip-step-card.component.scss'],
})
export class TripStepCardComponent implements OnInit {

  @Input('showConnectorDot') showConnectorDot = false;
  @Input('showConnectorLine') showConnectorLine = false;
  @Input('isLast') isLast = false;
  @Input('tripStep') tripStep: TripStep = null;
  @Input('cardCanExpand') cardCanExpand = false;
  @Input('isLastRecord') isLastRecord = false;
  @Input('showExpandableArrow') showExpandableArrow: boolean;
  @Input("trip") trip: Trip;
  @Input('paperworkSubmissionVideoUrl') paperworkSubmissionVideoUrl : string;
  @Output() showPaperworkDrawerEvent = new EventEmitter<Trip>();

  expanded = true;
  connectorsColor = '#6D9B00';
  title = '';
  firstSubTitle = '';
  secondSubTitle = '';
  pickUpAddress = '';
  locationRating = 0;
  locationReviewsCount = 0;
  locationAccessCode = '';
  locationLatitude = -1;
  locationLongitude = -1;
  locationPhoneNumber = '';
  shipperReferenceNumber = '5675658';
  pickUpInstructions = '';
  siteInstructions = '';
  starsToRender = 0;
  remainingStarsToRender = 0;
  stars = null;
  showFoodIcon = false;
  showScaleIcon = false;
  showRestAreaIcon = false;
  showRestroomIcon = false;
  remainingStars = new Array(this.remainingStarsToRender);
  instructionsImagesUrls = [];
  isScanPaperworkEnable: boolean = true;
  trailerNumber = '';
  pickUpNumber = '';
  cardType = 'previous-step-card-summary';
  allowCardToExpand = this.cardCanExpand;
  TRIP_STEP_ON_MAP = 'trip-step-on-map';
  haveAlert: boolean = false;
  trailerColumn: number;


  // Instantiates the component
  constructor(private router: Router, public platform: Platform, private analytics: AnalyticsService,
    private telemetryUtility: TelemetryUtility, private inAppBrowser : InAppBrowser
    ) { }


  // Handler called when the component is initialized
  ngOnInit() {
    this.isScanPaperworkEnable = !(this.platform.is("desktop") || this.platform.is("mobileweb"));
    this.showTripStepDetails(this.tripStep);
    this.trailerColumn = 6;
  }

  // Method called when the driver clicks on the start paper work button
  startPaperWork() {
    this.analytics.trackEvent('User', 'Clicked Start Paperwork Button From Previous Job',
      'Clicked-Start-Paperwork-Button-From-Previous-Job');

    this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_PREVIOUS_SCREEN);

    this.showPaperworkDrawerEvent.emit(this.trip);
  }

  // Renders information on this leg of the trip by setting the appropriate properties
  showTripStepDetails(currentStep: TripStep) {
    this.firstSubTitle = currentStep.name;
    this.locationRating = currentStep.averageReview;
    this.locationReviewsCount = currentStep.reviewsCount;
    this.locationPhoneNumber = currentStep.phoneNumber;
    this.locationLatitude = currentStep.location.latitude;
    this.locationLongitude = currentStep.location.longitude;
    this.siteInstructions = currentStep.siteInstructions;
    this.pickUpInstructions = currentStep.pickUpInstructions;
    this.shipperReferenceNumber = currentStep.shipperReferenceNumber;

    this.pickUpNumber = currentStep.pickUpNumber;
    this.trailerNumber = currentStep.trailerNumber;

    this.locationAccessCode = currentStep.accessCode;

    this.pickUpAddress = currentStep.location.city + ', ' + currentStep.location.state;

    if (currentStep.siteInstructionsImagesUrls != null && currentStep.siteInstructionsImagesUrls.length > 0) {
      this.instructionsImagesUrls = currentStep.siteInstructionsImagesUrls;
    }

    this.setTitle(currentStep);
    this.setSecondSubTitle(currentStep);
    this.setLocationRating(currentStep);
    this.setConnectorsColor(currentStep);
    this.setAmenitiesIcons(currentStep);
  }

  // Sets the visibility of the amenities icons
  setAmenitiesIcons(currentStep: TripStep) {
    if (currentStep != null && currentStep.amenities != null) {
      this.showFoodIcon = currentStep.amenities.hasFood;
      this.showRestAreaIcon = currentStep.amenities.hasRestArea;
      this.showRestroomIcon = currentStep.amenities.hasRestroom;
      this.showScaleIcon = currentStep.amenities.hasScale;
    }
  }

  // Sets the color of the connectors depending on the step type
  setConnectorsColor(currentStep: TripStep) {
    switch (currentStep.type) {
      case TripStepType.SHIPPER:
        this.connectorsColor = '#6D9B00';
        break;
      case TripStepType.STOP:
        this.connectorsColor = '#1B365D';
        break;
      case TripStepType.ARRIVAL:
        this.connectorsColor = '#6D9B00';
        break;
    }
  }

  // Sets location rating
  setLocationRating(currentStep: TripStep) {
    this.locationRating = currentStep.averageReview;
    this.starsToRender = Math.trunc(this.locationRating);
    this.remainingStarsToRender = 5 - this.starsToRender;
    this.stars = new Array(this.starsToRender);
  }

  // Sets the second subtitle that is shown on the component
  setSecondSubTitle(currentStep: TripStep) {
    this.secondSubTitle = formatDate(currentStep.arrivalDate, 'MMMM d, yyyy HH:mm', 'en');
  }

  // Sets the title that is shown on the component
  setTitle(currentStep: TripStep) {
    if (currentStep != null) {
      switch (currentStep.type.toString()) {
        case "ARRIVAL":
          this.title = "Destination";
          break;
        case "STOP":
          this.title = "Stop";
          break;
        case "CONSIGNEE":
          this.title = "Consignee";
          break;
        case "SPLIT":
          this.title = "Split";
          break;
        case "SHIPPER":
          this.title = "Origin";
          break;
        default:
          this.title = "UNKNOWN";
          break;
      }

    }
  }

  async onClickUploadProcess() {
    if (this.paperworkSubmissionVideoUrl) {
      this.inAppBrowser.create(this.paperworkSubmissionVideoUrl, "_blank");
    }
  }
}
