<app-stop-options-modal *ngIf="showStopOptionsModal" (tappedCloseButton)="stopOptionsModalCloseButtonClickHandler()">
</app-stop-options-modal>

<ion-item *ngFor="let currentTrip of trips;let j = index;" class="ion-no-padding">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <div class="col">
          <ion-grid class="ion-no-padding">
            <ion-row (click)="showOrHideTrip(currentTrip, j)" class="light-background">
              
              <ion-col size="2">
                <div class="ion-padding-top edge-value time-label">{{formatDateTimeToSmallDate(currentTrip.details.arrivalTime)}}</div>
              </ion-col>

              <ion-col size="5">
                  <div class="edge-value">{{currentTrip.departure}} to {{currentTrip.destination}}</div>
                  <div class="edge-label">{{currentTrip.id}}</div>
              </ion-col>

              <ion-col size="4" class="status-icon">
                <ion-badge class="approved-icon ion-float-right" *ngIf="currentTrip?.details?.paymentStatus == 'Submitted'">SUBMITTED</ion-badge>
                <!-- <ion-badge class="error-icon ion-float-right" *ngIf="currentTrip?.details?.paymentStatus == '1'">RESCAN BOL</ion-badge> -->
                <ion-badge class="paid-icon ion-float-right ion-text-wrap" *ngIf="currentTrip?.details?.paymentStatus == 'P'">PAID {{ currentTrip?.details?.paymentDate | date: 'MM/dd/yyyy' }}</ion-badge>
                <ion-badge class="pending-icon ion-float-right" *ngIf="(currentTrip?.details?.paymentStatus == 'W' || currentTrip?.details?.paymentStatus == 'S')">PENDING</ion-badge>
                <ion-badge class="error-icon ion-float-right" *ngIf="currentTrip?.details?.paymentStatus == ''">MISSING</ion-badge>
                <ion-badge class="error-icon ion-float-right" *ngIf="(currentTrip?.details?.paymentStatus === 'H' && (currentTrip?.details?.holdReason1 === '' && currentTrip?.details?.holdReason2 === ''))">HOLD</ion-badge>
                <ion-badge class="error-icon ion-float-right" *ngIf="(currentTrip?.details?.paymentStatus === 'H' && (currentTrip?.details?.holdReason1 === 'BL MISSING BOL' || currentTrip?.details?.holdReason2 === 'BL MISSING BOL'))">MISSING BOL</ion-badge>
                <ion-badge class="error-icon ion-float-right" *ngIf="(currentTrip?.details?.paymentStatus === 'H' && (currentTrip?.details?.holdReason1 === 'BL RESCAN BOL' || currentTrip?.details?.holdReason2 === 'BL RESCAN BOL'))">RESCAN BOL</ion-badge>
              </ion-col>
              <ion-col size="1">
                <ion-icon class="ion-icon-chevron ion-float-right ion-padding-top" *ngIf="panelsHidden[j] === true" name="chevron-forward-outline">
                </ion-icon>
                <ion-icon *ngIf="panelsHidden[j] === false" class="ion-icon-chevron ion-float-right ion-padding-top" name="chevron-down-outline"></ion-icon>
              </ion-col>              

            </ion-row>
          </ion-grid>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <div class="card-background" *ngIf="panelsHidden[j] === false">
          <ion-card >
            <ion-label class="confirmation-card-title">Confirmation</ion-label>
            <ion-list *ngIf=" paperWorkConfirmation.length > 0">
              <ion-item *ngFor="let pc of paperWorkConfirmation;let i = index;" class="paperwork_confirmation">
                <ion-grid>
                  <ion-row>
                    <ion-col size="2" class="date-col ion-padding-vertical" (click)="onClickPaperWorkConfirmation(pc)">
                      <ion-label>{{pc.scanDateTime | date: "MM/dd"}} 123</ion-label>
                    </ion-col>
                    <ion-col size="7" [ngClass]="{'confirmation_code': !isWebApp}" (click)="onClickPaperWorkConfirmation(pc)" class="ion-padding-vertical">
                      <ion-label>
                        {{pc.label.code}}
                      </ion-label>
                    </ion-col>
                    <ion-col size="1" class="ion-forward ion-padding-vertical" *ngIf="!isWebApp">
                      <ion-icon class="ion-float-right" name="chevron-forward-outline" (click)="onClickPaperWorkConfirmation(pc)"></ion-icon>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-item>
            </ion-list>
            <ion-label text-wrap class="noConfirmation-label ion-text-wrap" *ngIf=" !(paperWorkConfirmation.length > 0)"> <strong>If paperwork has been submitted, please check back later for Confirmation #. </strong></ion-label>
          </ion-card>
      
          <app-trip-step-card [showConnectorDot]="true" *ngFor="let theStep of currentTrip.details.steps; let i = index;"
            [showConnectorLine]="connectorLineIsVisible(i, currentTrip)" [tripStep]="theStep"
            (click)="showStopOptions($event,theStep, currentTrip)" [cardCanExpand]="false" [showExpandableArrow]="false"
            [isLast]="isBeforeLastTripStepCard(i, currentTrip)"  [trip]="currentTrip.details"
            (showPaperworkDrawerEvent)="showPaperworkDrawerEvent($event)"
            [isLastRecord]="currentTrip.details.steps.length - 1 === i" [paperworkSubmissionVideoUrl]="paperworkSubmissionVideoUrl"></app-trip-step-card>
      
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  

  
</ion-item>