/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Department } from './department';
import { Terminal } from './terminal';

export interface ContactsResponse { 
    departments?: Array<Department>;
    terminals?: Array<Terminal>;
}