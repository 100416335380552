<div class="background" (click)="closeModal()">
  <div class="modal">
    <ion-item lines="none" lines="full">
      <ion-label> {{title}} </ion-label>
      <div class="ion-float-right">
        <img src="../../../../assets/Images/cross.png" alt="close icon" (click)="closeModal()" />
      </div>
    </ion-item>
    <ion-item lines="none" class="ion-margin-top">
      <ion-text class="ion-text-wrap">
       
        <ng-container *ngIf="title === 'Mentor Assignment'; else defaultContent">
          Looks like this is a mentor <ion-text class="important-text">Profile > Mentor Contacts.</ion-text>
        </ng-container>

        <ng-container *ngIf="title === 'Mentee Assignment'; else defaultContent">
          Looks like this is a mentee <ion-text class="important-text">Profile > Mentee Contacts.</ion-text>
        </ng-container>

        <ng-template #defaultContent>
          Looks like you have been assigned a {{ subtitle }}! You can find their contact
          information located in <ion-text class="important-text">Profile > Account Contacts.</ion-text>
        </ng-template>
      </ion-text>
    </ion-item>
    <ion-row class="ion-justify-content-center">
      <ion-col size="11">
        <div class="ion-margin-vertical">
          <ion-button expand="block" size="default" (click)="viewProfile()">
            View Profile
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </div>
</div>
