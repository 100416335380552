import { Action } from '@ngrx/store';
import {PeriodTripsList, Trip} from '../../../../Swagger-Gen';
import { JobTrip } from 'src/app/Swagger-Gen-V2/model/jobTrip';



export enum PreAssignedTripCardActionTypes {
    GetPreAssignedTrip = 'Get Driver PreAssigned Trip',
    GetPreAssignedTripSuccess = 'Get Driver PreAssigned Trip Success',
    GetPreAssignedTripFail = 'Get Driver PreAssigned Trip Fail'
}

export class GetPreAssignedTrip implements Action {
    readonly type = PreAssignedTripCardActionTypes.GetPreAssignedTrip;
    driverId = '';
    employeeNumber;
    constructor(public id: string, empNumber) {
        this.driverId = id;
        this.employeeNumber = empNumber;
    }
}

export class GetPreAssignedTripSuccess implements Action {
    readonly type = PreAssignedTripCardActionTypes.GetPreAssignedTripSuccess;
    constructor(public payload: Array<JobTrip>) {
        if (this.payload != null) {
            localStorage.setItem('driver-preassigned-trip', JSON.stringify(this.payload));
        }
    }
}

export class GetPreAssignedTripFail implements Action {
    readonly type = PreAssignedTripCardActionTypes.GetPreAssignedTripFail;
    constructor(public payload: string) {
    }
}


export type PreAssignedTripCardActions = GetPreAssignedTrip | GetPreAssignedTripSuccess | GetPreAssignedTripFail;
