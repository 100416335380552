import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { Location } from "@angular/common";
import {AnalyticsService} from "../../shared/services/analytics.service";
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import {  RequestService } from 'src/app/Swagger-Gen-V2';
import { ProfileInfoService } from '../profile-v2/service/profileInfo.service';
import { TelemetryUtility } from 'src/app/shared/utilities/telemetry-utilities';
import { TelemetryConstants } from 'src/app/shared/utilities/telemetry-constants';
import { LoadingUtility } from 'src/app/shared/utilities/LoadingUtility';
import { JobTrip } from 'src/app/Swagger-Gen-V2/model/jobTrip';
import { AuthService } from 'src/app/auth/auth.service';
import { WernerDwpContractsLogicJobTrip as trip} from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobTrip';
import { Preferences } from '@capacitor/preferences';
import { DispatchService } from 'src/app/shared/services/dispatchers/dispatch.service';
import { ProfilePermissions } from 'src/app/Swagger-Gen-V2/model/ProfilePermissions';
@Component({
  selector: 'app-loads',
  templateUrl: './loads.page.html',
  styleUrls: ['./loads.page.scss'],
})


export class LoadsPage implements OnInit {
  tab = 'Current';
  defaultSelectedIndex = 1;
  isShowFindJobsBtn: boolean = false;
  permissions: Array<string> = [];
  screenSubPages = [{ title: 'Previous', icon: '' }, { title: 'Current', icon: '' },
  { title: 'Pre-Assigned', icon: '' }];
  driverId = '';
  isProduction : boolean = environment.production;
  subscriptions$ = new SubSink();
  permissionInfo : ProfilePermissions;
  previousTrips:Array<JobTrip>;
  currentTrip: trip;
  isPreviousJobLoading:boolean = false;
  isCurrentJobLoading:boolean = false;
  loadPreassignedJob: boolean = false;
  ionRefresherEvent;
  closeBottomDrawer: number = 0;
  constructor(private router: Router,
    private alertController: AlertController,
    private analytics: AnalyticsService,
    private telemetryUtility: TelemetryUtility,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private profileInfoService : ProfileInfoService,
    private requestService: RequestService,
    private loadingUtility: LoadingUtility,
    private authService : AuthService,
    public platform: Platform,
    private dispatchService: DispatchService
    ) {
    }

  async ngOnInit() {
    this.driverId = await this.authService.getUserIdentitityKey();
    if(this.isShowFindJobsBtn === true) {
      this.analytics.trackEvent('User', 'Find Jobs Button Is Shown On Jobs Screen',
          'Find-Jobs-Button-Is-Shown-On-Jobs-Screen');
      this.telemetryUtility.recordTelemetry(TelemetryConstants.FIND_JOB_BUTTON);
    }
  }

  loadJobsData(event) {
    this.ionRefresherEvent = event;
    if(this.tab === 'Current'){
      this.isCurrentJobLoading = true;
      this.getCurrentJob();
      this.hidePullToRefresh(event);
    }else if(this.tab === 'Previous'){
      this.getPreviousJobs();
      this.hidePullToRefresh(event);
    }else if(this.tab === 'Pre-Assigned'){
      this.loadPreassignedJob = true;
    }
  }

  stopIonRefresher(event){    
    if(this.ionRefresherEvent && event === true){
      this.loadPreassignedJob = false;
      this.ionRefresherEvent.target.complete();
    }else{
      this.loadPreassignedJob = false;
    }
  }
  goBack() {
    this.router.navigate(['/']);   
  }


  hidePullToRefresh(event){
    if(event){ 
      event.target.complete();
    } 
  }

  ionViewWillLeave(){
    this.closeBottomDrawer += 1;
    this.isCurrentJobLoading = false;
  }

  async getPreviousJobs(event = ''){
    this.isPreviousJobLoading = true;
    let employeeNumber = await this.dispatchService.getEmployeeNumber();
    if(!employeeNumber){
      employeeNumber = await this.dispatchService.getEmployeeNumber();
    }
    await this.loadingUtility.showLoader('Please wait...');
    this.subscriptions$.sink = this.requestService.tripsGet('PAST', parseInt(employeeNumber), this.driverId).subscribe(
      async previousTrips => {
        this.isPreviousJobLoading = false;
        this.loadingUtility.hideLoader();
        this.hidePullToRefresh(event);
        if (previousTrips) {
          this.previousTrips = previousTrips;
        }
      },
      (error) => {
        this.isPreviousJobLoading = false;
        this.loadingUtility.hideLoader();
        this.hidePullToRefresh(event);
      }
    );    
  }

  async getCurrentJob(){
    this.isCurrentJobLoading = true;
    let employeeNumber = await this.dispatchService.getEmployeeNumber();
    if(!employeeNumber){
      employeeNumber = await this.dispatchService.getEmployeeNumber();
    }
    if(employeeNumber){
      if(this.loadingUtility.loader == null){
        await  this.loadingUtility.showLoader("Loading current job..");
      }
      this.subscriptions$.sink = this.requestService.tripsCurrentGet( parseInt(employeeNumber)).subscribe(
        async currentTrip => {
          this.isCurrentJobLoading = false;
          this.loadingUtility.hideLoader();
          if (currentTrip) {
            this.currentTrip = currentTrip;
          }
        },
        (error) => {
          this.isCurrentJobLoading = false;
          this.loadingUtility.hideLoader();
        }
      );    
    }else{
      this.isCurrentJobLoading = false;
    }

  }

  async ionViewWillEnter(){
    this.isCurrentJobLoading = false;
    this.permissionInfo = await this.profileInfoService.getUserPermissions();
    this.permissions = this.permissionInfo?.permissions ?? [];    
    this.isShowFindJobsBtn = this.permissions.includes('LOAD_SELECTION');
    this.setSelectedTab();
  }

  setSelectedTab(){
    let selectedTab = this.activatedRoute.snapshot.queryParamMap.get("page");
    if(selectedTab){   

      if(selectedTab === 'Previous'){
        this.getPreviousJobs();
      }else if(selectedTab === "Current" && this.isCurrentJobLoading === false){ 
        this.getCurrentJob();
      }
      this.onSubpageChange(selectedTab);
    }
  }

  onSubpageChange(event) {
    
    if(event !== null && event !== undefined && event.toLowerCase() == 'current') {
      this.analytics.trackEvent('User', 'Clicked Current Tab On Jobs Screen',
          'Clicked-Current-Tab-On-Jobs-Screen');

      this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_CURRENT_TAB);

    } else if(event !== null && event !== undefined && event.toLowerCase() == 'pre-assigned') {
      this.analytics.trackEvent('User', 'Clicked Pre-Assigned Tab On Jobs Screen',
          'Clicked-Pre-Assigned-Tab-On-Jobs-Screen');

      this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_PRE_ASSIGNED);
    }
    this.tab = event;
    this.location.replaceState('/loads?page='+event);
  }

  async onClickFindJobs() {
    this.analytics.trackEvent('User', 'Clicked Find Jobs Button On Jobs Screen',
        'Clicked-Find-Jobs-Button-On-Jobs-Screen');
    this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_FIND_JOBS);
    await Preferences.set({key: 'job-page-url', value: JSON.stringify({url:'/loads', tab:'Current'})});
    this.router.navigate(['/loads/find-jobs']);
  }



  async alertDialogBox(message: string) {
    const alert = await this.alertController.create({
      cssClass: 'ion-confirm-alert',
      backdropDismiss: false,
      message: message,
      buttons: [
        {
          text: "Ok",
          handler: () => {
          }
        }
      ],
    });

    await alert.present();
    const result = await alert.onDidDismiss();
  }

  urlToArray(url) {
    var request = {};
    var arr = [];
    var pairs = url.substring(url.indexOf('?') + 1).split('&');
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');

      //check we have an array here - add array numeric indexes so the key elem[] is not identical.
      if (this.endsWith(decodeURIComponent(pair[0]), '[]')) {
        var arrName = decodeURIComponent(pair[0]).substring(0, decodeURIComponent(pair[0]).length - 2);
        if (!(arrName in arr)) {
          arr.push(arrName);
          arr[arrName] = [];
        }

        arr[arrName].push(decodeURIComponent(pair[1]));
        request[arrName] = arr[arrName];
      } else {
        request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }
    }
    return request;
  }
  endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
