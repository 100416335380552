<ion-grid style="height: 100%">
  <ion-row justify-content-center align-items-center style="height: 100%">
    <ion-card class="edge-card">
        <ion-item lines='none' class="base {{item.style}}">
          <ion-icon *ngIf="item.icon" name="{{item.icon}}" class="{{item.style}}"></ion-icon>
          <img *ngIf="item.image" src="{{item.image}}" class="{{item.style}}">
          &nbsp;
          &nbsp; 
          <ion-label class="ion-text-wrap">{{item.title}}</ion-label>
        </ion-item> 
    </ion-card>
  </ion-row>
</ion-grid>
