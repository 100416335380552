<ion-card>
  <ion-card-header>
    <ion-card-title class="edge-value">Transportation Managers</ion-card-title>
  </ion-card-header>
  <ion-card-content *ngIf="fleetManagers && fleetManagers.length > 0">
    <div *ngFor="let fleetManager of fleetManagers" class="ion-padding-bottom">
        <ion-grid>
            <ion-row class="ion-align-items-center">
                <ion-col size-xs="3" size-sm="2" size-md="2" size-lg="2" size-xl="2">
                    <ion-avatar>
                        <ion-icon *ngIf="!fleetManager?.user?.picture" name="person" size="large"></ion-icon>
                        <img *ngIf="fleetManager?.user?.picture" alt="user avatar" [src]="fleetManager?.user?.picture ? fleetManager?.user?.picture : defaultImage"/>
                    </ion-avatar>
                </ion-col>
                <ion-col
                size-xs="9"
                size-sm="10"
                size-md="10"
                size-lg="10"
                size-xl="10"
                >
                <ion-text class="edge-value name">
                    {{ fleetManager?.user?.firstName }}
                    {{ fleetManager?.user?.lastName }}
                </ion-text>
                <ion-text class="edge-value role">
                    {{ fleetManager?.user?.role }}
                </ion-text>
                </ion-col>
            </ion-row>
            </ion-grid>
            <ion-item lines="none" class="ion-no-padding">
            <ion-col size="4">
                <ion-label class="edge-label">Phone</ion-label>
            </ion-col>
            <ion-col size="8">
                <ion-text class="edge-value ion-float-right">
                <a href="tel:{{ fleetManager?.phone?.number }}">
                    {{ fleetManager?.phone?.number | phoneFormat }} 
                </a>
                </ion-text>
            </ion-col>
            </ion-item>
    </div>
  </ion-card-content>
</ion-card>
