import { Component, OnInit, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "map-carousel",
  templateUrl: "./map-carousel.component.html",
  styleUrls: ["./map-carousel.component.scss"],
})
export class MapCarouselComponent implements OnInit {
  carouselItems = [
    {
      title: "Werner Terminals",
      icon: "terminals",
      name: "terminals",
    },
    {
      title: "Drop Yards",
      icon: "dropYards",
      name: "dropyards",
    },
    {
      title: "Scales",
      icon: "scales",
      name: "scales",
    },
    {
      title: "Fuel Stops",
      icon: "fuelStops",
      name: "fuelStops",
    },
    {
      title: "Rest Areas",
      icon: "restAreas",
      name: "restAreas",
    },
    {
      title: "Truck Washes",
      icon: "truckWashes",
      name: "truckWashes",
    },
    {
      title: "Chain Banks",
      icon: "chainBanks",
      name: "chainbanks",
    },
  ];

  sliderConfig = {
    slidesPerView: 1.6,
    spaceBetween: 3,
  };

  @Output() selectedItem = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  selectedItemAt(index) {
    this.selectedItem.emit(this.carouselItems[index]);
  }
}
