/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FileBlob { 
    fileName?: string;
    fileExtension?: string;
    fileSize?: number;
    mimeType?: string;
    fileBytes?: string;
    fileBase64?: string;
    fileUrl?: string;
    createdDate?: Date;
}