/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WernerDwpContractsLogicFeedbackQuestionAndAnswer } from './wernerDwpContractsLogicFeedbackQuestionAndAnswer';

export interface WernerDwpContractsLogicFeedbackFeedbackQuestion { 
    questionId?: number;
    title?: string;
    answerValue?: string;
    text?: string;
    questionType?: string;
    questionAnswerOptions?: Array<WernerDwpContractsLogicFeedbackQuestionAndAnswer>;
}