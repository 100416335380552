import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
@Component({
  selector: "app-bottom-modal",
  templateUrl: "./bottom-modal.component.html",
  styleUrls: ["./bottom-modal.component.scss"],
})
export class BottomModalComponent {
  @Input() selectedPoiProperties;
  @Input() selectedPOIImage;
  constructor(public modalController: ModalController, private iab: InAppBrowser,) {}
  /*
    showFeedback:false - will close modal
    showFeedback:true  - will trigger feedback modal
  */
  closeModal(showFeedback: Boolean) {
    this.modalController.dismiss({
      dismissed: true,
      data: {
        showFeedback: showFeedback,
      },
    });
  }

  openPDFLink() {
    const url = "https://drivers.werner.com/Documents/Cheyenne.pdf"
    this.iab.create(url, '_system', 'location=yes');
  }
}
