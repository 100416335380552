import { Action } from '@ngrx/store';
import { PayCheck } from 'src/app/Swagger-Gen-V2/model/payCheck';

export enum PastPaychecksActionTypes {
    GetPastPaychecks = 'Get Past Paychecks',
    GetPastPaychecksSuccess = 'Get Past Paychecks Success',
    GetPastPaychecksFail = 'Get Past PaychecksFail',
}

export class GetPastPaychecks implements Action {
    readonly type = PastPaychecksActionTypes.GetPastPaychecks;
    driverId = '';
    constructor(public id: string) {
        this.driverId = id;
    }
}

export class GetPastPaychecksSuccess implements Action {
    readonly type = PastPaychecksActionTypes.GetPastPaychecksSuccess;
    constructor(public payload: Array<PayCheck>) {
        if (this.payload != null && this.payload.length > 0 ) {
            localStorage.setItem('driver-past-paychecks', JSON.stringify(this.payload));
        }
    }
}

export class GetPastPaychecksFail implements Action {
    readonly type = PastPaychecksActionTypes.GetPastPaychecksFail;
    constructor(public payload: string) {
    }
}

export type PastPaychecksActions = GetPastPaychecks | GetPastPaychecksSuccess | GetPastPaychecksFail;