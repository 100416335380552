/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IdentityOnlineType } from './identityOnlineType';

export interface EmailEditVM {
    id?: string;
    emailAddress?: string;
    onlineType?: string;
    description?: string;
    onlineTypeId?: number;
    identityOnlineTypes?: Array<IdentityOnlineType>;
    userId?: string;
}
