import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
  name: 'linkify'
})

export class LinkifyPipe implements PipeTransform {

  transform(str: string): string {

    return this.linkify(str);
  }

  private linkify(plainText): string {
    let replacedText;
    //regex to identify all url patterns
    let urlPattren = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

    //Change email addresses to mailto:: links.
    let emailPattren = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;

    // change phone numbers to tel::
    let phoneNumberPattern = /(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}?(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/img;

    let emails = plainText.match(emailPattren);
    emails = emails ? emails.join(',') : '';
    replacedText = plainText.replace(urlPattren, function (url) {
      if (emails.indexOf(url) === -1) {
        let modifiedURL = url.indexOf('://') === -1 ? 'https://' + url : url;
        return '<a class = "linkified-url" href="' + modifiedURL + '" target="_blank">' + url + '</a>';
      } else {
        return url;
      }
    });


    replacedText = replacedText.replace(emailPattren, '<a href="mailto:$1">$1</a>');
    let phoneNumbers = replacedText.match(phoneNumberPattern);

    if (phoneNumbers) {
      phoneNumbers = [... new Set(phoneNumbers)];
      phoneNumbers.forEach(phone => {
        // Remove dot, if it's a 1st character
        if(phone.trim() && phone[0] == ".") phone = phone.toString().substring(1).trim();
        replacedText = replacedText.replace(phone, '<a href="tel:' + phone + '">' + phone + '</a>');
      });
    }


    return replacedText;
  }
}
