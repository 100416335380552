/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CommonPhone } from './commonPhone';
import { Location } from './Location';

export interface LocationContact { 
    id?: string;
    name?: string;
    phoneNumber?: CommonPhone;
    location?: Location;
}