import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'stack-textarea',
  templateUrl: './stack-textarea.component.html',
  styleUrls: ['./stack-textarea.component.scss'],
})
export class StackTextareaComponent implements OnInit {

  private _value: string;

  @Input('placeholder') placeholder = "Default placeholder";
  @Input('label') label = "Default Label";


  @Input()
  set text(value: string) {this._value = value; this.ngOnInit();}
  get text(): string { return this._value;}

  @Output()
  TheTextChanged = new EventEmitter<string>();


  constructor() { }

  ngOnInit() {}


  textChanged() {
    this.text = this._value;
    this.TheTextChanged.emit(this._value)
  }

}
