/**
 * Drive Werner API
 * Drive Werner API
 *
 * OpenAPI spec version: v1
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Customer } from './customer';
import { LoadStatusSummary } from './loadStatusSummary';
import { TripStep } from './tripStep';

export interface Trip { 
    id?: string;
    driverId?: string;
    client?: Customer;
    loadNumber?: string;
    departureLocation?: Address;
    departureTime?: Date;
    arrivalLocation?: Address;
    arrivalTime?: Date;
    steps?: Array<TripStep>;
    loadStatus?: LoadStatusSummary;
    trailerReservationNotice?: LoadStatusSummary;
    criticalLoadNotes?: string;
    isSplitTrip?: boolean;
    splitsCount?: number;
    fleetManagerName?: string;
    type?: number;
    deadHeads?: Array<Address>;
    tractorNumber?: string;
}