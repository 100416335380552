import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { EdgeCard } from 'src/app/shared/models/edge-card';
import { NavController, Platform } from '@ionic/angular';
import { SubSink } from 'subsink';
import { MessagesResponse } from 'src/app/Swagger-Gen-V2/model/messagesResponse';
import { Preferences } from '@capacitor/preferences';
import { DispatchService } from 'src/app/shared/services/dispatchers/dispatch.service';

@Component({
  selector: 'benefit-card',
  templateUrl: './benefit-card.component.html',
  styleUrls: ['./benefit-card.component.scss'],
})
export class BenefitCardComponent implements OnDestroy, OnChanges {

  @Input('card') card: EdgeCard;
  @Output('resize') resize = new EventEmitter();
  @Output('change') change = new EventEmitter();
  @Output('remove') remove = new EventEmitter();
  isMobile: boolean;
  isTab: boolean;
  BENEFITS_SELECTED_TAB_KEY = "benefits-selected-tab";
  benefitsAnnouncementsUnreadCount : number = 0;
  identityKey : string = "";
  subscriptions$ = new SubSink();
  benefitsAnnouncementsMessages : MessagesResponse;
  routingTypeForBenefits : number = 2;
  isAnnouncement : boolean = true;

  constructor (
    private navCtrl: NavController,
    private platform: Platform,
    private dispatchService: DispatchService
  ) { 
    this.isMobile = platform.is('mobile') || platform.is('mobileweb');
    this.isTab = platform.is('tablet')
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.getBenefitsCount();
  }
 
  // Handler called when the component is first constructed
  async ngAfterViewInit() {
   this.getBenefitsCount();
  }

  async getBenefitsCount() {
    this.subscriptions$.sink = this.dispatchService.benefitsCount.subscribe((count) => {
      this.benefitsAnnouncementsUnreadCount = count;
    });
  }

  async tapCard() {
      await Preferences.set({ key: this.BENEFITS_SELECTED_TAB_KEY, value: "0" });
      this.card.showSizePositionControl || this.card.preview ?
       this.getOverlay() : this.navCtrl.navigateForward("/benefits");
  }

  getOverlay() {
    this.card.showOverlay = !this.card.showOverlay;
  }

  resizeCard() {
    this.resize.emit(this.card);
  }

  changeCard() {
    this.change.emit(this.card);
  }

  removeCard() {
    this.remove.emit(this.card);
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
