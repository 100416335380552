<div class="dark-overlay" *ngIf="card.showOverlay" (click)="tappedOverlay()"></div>
<div *ngIf="card.showSizePositionControl">
  <ion-item class="gray-background" lines="none" *ngIf="true">
    <button class="left-button" ion-button (click)="tappedResize()">
      <img style="margin: 4px; width: 16px; height: 16px;" src='../../../../../assets/compress-alt.png' alt="">
    </button>
    <button class="right-button" ion-button>
        <ion-icon slot="end" name="move-outline" class="handle"style="font-size: 1.4rem;"></ion-icon>
    </button>
  </ion-item>
</div>

<div class="overlay" *ngIf="!card.showOverlay" (click)="tappedCard()"></div>
<card-header *ngIf="showHeader(card)" cardTitle="{{card.title}}"></card-header>
<div *ngIf="showTitleInBody(card)">
  <ion-title class="card-title">
    {{card.title}}
  </ion-title>
</div>
<div [ngStyle]="overlayStyle()" *ngIf="card.showOverlay && card.type !== 'Profile'">
  <div class="cta werner-button outline" (click)="tappedRemoveTile()">
    Remove Tile
  </div>
  <div class="cta werner-button outline" (click)="tappedChangeTile()">
    Change Tile
  </div>
</div>
<div [ngStyle]="overlayStyle()" *ngIf="card.showOverlay && card.type === 'Profile'">
  <div>
    <div class="cta werner-button outline" (click)="tappedRemoveTile()">
      Remove Tile
    </div>
    <div class="cta werner-button outline" (click)="tappedChangeTile()">
      Change Tile
    </div>
  </div>
</div>
