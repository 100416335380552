import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { CardHeaderComponent } from "./components/cards/card-header/card-header.component";
import { CommonModule } from "@angular/common";
import { WeCardComponent } from "./components/cards/we-card.component";
import { NewsCardComponent } from "./components/cards/news-card/news-card.component";
import { ScrollingHeaderComponent } from "./components/scrolling-header/scrolling-header.component";
import { ButtonCardComponent } from "./components/cards/button-card/button-card.component";
import { ProfileCardComponent } from "./components/cards/profile-card/profile-card.component";
import { MapComponent } from "./components/map/map.component";
import { StackInputComponent } from "./components/inputs/stack-input/stack-input.component";
import { StackTextareaComponent } from "./components/inputs/stack-textarea/stack-textarea.component";
import { PrimaryButtonComponent } from "./components/buttons/primary-button/primary-button.component";
import { SecondaryButtonComponent } from "./components/buttons/secondary-button/secondary-button.component";
import { LoadStatusCardComponent } from "./components/cards/load-status-card/load-status/load-status-card.component";
import { LoadStatusModule } from "./components/cards/load-status-card/load-status.module";
import { CarouselCardComponent } from "./components/cards/carousel-card/carousel-card.component";
import { CarouselItemCardComponent } from "./components/cards/carousel-card/carousel-item-card/carousel-item-card.component";
import { PayCardComponent } from "./components/cards/pay-card/pay-card.component";
import { ReferalDriverCardComponent } from "./components/cards/referal-driver-card/referal-driver-card.component";
import { MessageCardComponent } from "./components/cards/messages-card/messages-card.component";
import { PreviousJobComponent } from "./components/cards/previous-job-card/previous-job-card.component";
import { PreAssignedJobComponent } from "./components/cards/preassigned-job-card/preassigned-job-card.component";
import { PayCardRowComponent } from "./components/cards/pay-card/pay-card-row/pay-card-row.component";
import { PaperworkItemComponent } from "./components/items/paperwork-item/paperwork-item.component";
import { PaycheckItemComponent } from "./components/items/paycheck-item/paycheck-item.component";
import { BadgeStatusCardComponent } from "../Core/components/badge-status-card/badge-status-card.component";
import { ExpandableCardComponent } from "../Core/components/expandable-card/expandable-card.component";
import { BottomDrawerComponent } from "./components/bottom-drawer/bottom-drawer.component";
import { MyLoadDetailsComponent } from "../Core/components/my-load-details/my-load-details.component";
import { CarouselNewsCardComponent } from "./components/cards/carousel-card/carousel-news-card/carousel-news-card.component";
import { MapCarouselComponent } from "./components/map-carousel/map-carousel.component";
import { MapCarouselItemComponent } from "./components/map-carousel/map-carousel-item/map-carousel-item.component";
import { BottomDrawerRowComponent } from "./components/bottom-drawer/bottom-drawer-row/bottom-drawer-row.component";
import { StickyInputComponent } from "./components/inputs/sticky-input/sticky-input.component";
import { FormsModule, ReactiveFormsModule  } from "@angular/forms";
import { ImportantDialogComponent } from "./components/important-dialog/important-dialog.component";
import { MapService } from "./components/map/service/map.service";
import { AddCardComponent } from './components/cards/add-card/add-card.component';
import { CardOverlayComponent } from './components/cards/card-overlay/card-overlay.component';
import { CardModalComponent } from './components/cards/card-modal/card-modal.component';
import { ModalCardComponent } from './components/cards/modal-card/modal-card.component';
import { ToolbarComponent } from '../modules/home/toolbar/toolbar.component';
import { SaveModalComponent } from './components/save-modal/save-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {ConfirmationActionModalComponent} from "./components/confirmation-action-modal/confirmation-action-modal.component";
import {YesNoActionModalComponent} from "./components/yes-no-action-modal/yes-no-action-modal.component";
import {DocumentViewer} from "@ionic-native/document-viewer";
import { SafetyCardComponent } from './components/cards/safety-card/safety-card.component';
import { PoiModalComponent } from "./components/poi-modal/poi-modal.component";
import { AnnouncementsComponent } from "../modules/messages/components/announcements/announcements.component";
import { MessagesComponent } from "../modules/messages/components/messages/messages.component";
import { BenefitCardComponent } from "./components/cards/benefit-card/benefit-card.component";
import { AppVersion } from "@ionic-native/app-version/ngx";
import {LegacyBannerCardComponent} from "./components/cards/legacy-banner-card/legacy-banner-card.component";
import { OportunitiesCardComponent } from "./components/cards/opportunities-card/opportunities-card.component";
import { WorkdayCardComponent } from './components/cards/workday-card/workday-card/workday-card.component';
import { TrailerReservationNoticeMapComponent } from "../Core/components/my-load-details/trailer-reservation-notice-map/trailer-reservation-notice-map.component";
import { FeedbackModalComponent } from "./components/feedback-modal/feedback-modal.component";
import { BottomModalComponent } from "./components/bottom-modal/bottom-modal.component";
import { IncompleteDocumentMessageCardComponent } from "./components/cards/incomplete-document-message-card/incomplete-document-message-card.component"
import { SubHeaderComponent } from "./components/sub-header/sub-header.component";
import { RouterModule } from "@angular/router";
import { DrivingOptionsComponent } from "./components/driving-options/driving-options.component";
import { JsonFormComponent } from "./components/driving-options/components/json-form/json-form.component";
import { SetChallengeQuestionComponent } from "./components/cards/set-challenge-question/set-challenge-question.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SharedPipesModule } from "../shared/shared-pipes.module";
import { AccountContactsComponent } from 'src/app/shared/components/account-contacts/account-contacts.component';
import { AccountContactsCardComponent } from 'src/app/shared/components/account-contacts/components/account-contacts-card/account-contacts-card.component';
import { EmployeeDetailsComponent } from "./components/account-contacts/components/employee-details/employee-details.component";
import { InformationModalComponent } from "./components/information-modal/information-modal.component";
import { TransportationManagerCardComponent } from "./components/account-contacts/components/transportation-manager-card/transportation-manager-card.component";
import { BlueCoinCardComponent } from "./components/cards/bluecoin-card/blue-coin-card.component";
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
    providers: [MapService, AppVersion],
    imports: [
        IonicModule,
        CommonModule,
        LoadStatusModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        FontAwesomeModule,
        SharedPipesModule,
        MaskitoDirective,
        NgCircleProgressModule.forRoot({
            radius: 100,
            space: -8,
            outerStrokeWidth: 15,
            outerStrokeColor: "#2072DF",
            innerStrokeWidth: 15,
            innerStrokeColor: "#E0EAFD",
            animationDuration: 250,
            titleFontSize: "24",
            titleFontWeight: "500",
            subtitleFontSize: "16",
            subtitleColor: "#7B7A7B",
            showUnits: false
        })
    ],
    declarations: [
        AddCardComponent,
        CardHeaderComponent,
        CardOverlayComponent,
        CardModalComponent,
        ModalCardComponent,
        WeCardComponent,
        NewsCardComponent,
        LoadStatusCardComponent,
        ScrollingHeaderComponent,
        ButtonCardComponent,
        ProfileCardComponent,
        MapComponent,
        MapCarouselComponent,
        MapCarouselItemComponent,
        StackInputComponent,
        StackTextareaComponent,
        PrimaryButtonComponent,
        SecondaryButtonComponent,
        CarouselCardComponent,
        CarouselItemCardComponent,
        CarouselNewsCardComponent,
        PayCardComponent,
        ReferalDriverCardComponent,
        BlueCoinCardComponent,
        MessageCardComponent,
        PreviousJobComponent,
        PreAssignedJobComponent,
        PayCardRowComponent,
        PaperworkItemComponent,
        PaycheckItemComponent,
        BadgeStatusCardComponent,
        ExpandableCardComponent,
        MapComponent,
        BottomDrawerComponent,
        BottomDrawerRowComponent,
        MyLoadDetailsComponent,
        StickyInputComponent,
        ImportantDialogComponent,
        ToolbarComponent,
        SaveModalComponent,
        ConfirmModalComponent,
        YesNoActionModalComponent,
        ConfirmationActionModalComponent,
        SafetyCardComponent,
        PoiModalComponent,
        AnnouncementsComponent,
        MessagesComponent,
        BenefitCardComponent,
        OportunitiesCardComponent,
        LegacyBannerCardComponent,
        WorkdayCardComponent,
        TrailerReservationNoticeMapComponent,
        FeedbackModalComponent,
        BottomModalComponent,
        IncompleteDocumentMessageCardComponent,
        SubHeaderComponent,
        DrivingOptionsComponent,
        JsonFormComponent,
        SetChallengeQuestionComponent,
        AccountContactsComponent,
        AccountContactsCardComponent,
        EmployeeDetailsComponent,
        InformationModalComponent,
        TransportationManagerCardComponent
    ],
    exports: [
        AddCardComponent,
        CardHeaderComponent,
        CardOverlayComponent,
        CardModalComponent,
        ModalCardComponent,
        WeCardComponent,
        NewsCardComponent,
        LoadStatusCardComponent,
        ScrollingHeaderComponent,
        ButtonCardComponent,
        ProfileCardComponent,
        MapComponent,
        MapCarouselComponent,
        MapCarouselItemComponent,
        StackInputComponent,
        StackTextareaComponent,
        PrimaryButtonComponent,
        SecondaryButtonComponent,
        CarouselCardComponent,
        CarouselItemCardComponent,
        CarouselNewsCardComponent,
        PayCardComponent,
        ReferalDriverCardComponent,
        BlueCoinCardComponent,
        MessageCardComponent,
        PreviousJobComponent,
        PreAssignedJobComponent,
        PayCardRowComponent,
        PaperworkItemComponent,
        PaycheckItemComponent,
        BadgeStatusCardComponent,
        ExpandableCardComponent,
        MapComponent,
        BottomDrawerComponent,
        BottomDrawerRowComponent,
        MyLoadDetailsComponent,
        StickyInputComponent,
        ImportantDialogComponent,
        ToolbarComponent,
        SaveModalComponent,
        ConfirmModalComponent,
        NgCircleProgressModule,
        YesNoActionModalComponent,
        ConfirmationActionModalComponent,
        SafetyCardComponent,
        PoiModalComponent,
        AnnouncementsComponent,
        MessagesComponent,
        BenefitCardComponent,
        OportunitiesCardComponent,
        LegacyBannerCardComponent,
        WorkdayCardComponent,
        IncompleteDocumentMessageCardComponent,
        SubHeaderComponent,
        DrivingOptionsComponent,
        JsonFormComponent,
        SetChallengeQuestionComponent,
        AccountContactsComponent,
        AccountContactsCardComponent,
        EmployeeDetailsComponent,
        InformationModalComponent,
        TransportationManagerCardComponent
    ]
})
export class SharedComponentsModule {}
